'use strict'

angular.module('IBR')
.factory 'apiNotification', [ '$http', ($http) ->

    that = {}

    that.getNotification = (data) ->
      $http.post (CONFIG.USE_MOCK || CONFIG.RESOURCE_SERVER) + "/notifications/transfers", data

    that
]

'use strict'

angular.module('IBR')
.factory 'authoritiesFactory', [ '$rootScope', '$q', '$http', ($rootScope, $q, $http) ->

    urlBase = (CONFIG.USE_MOCK || CONFIG.RESOURCE_SERVER) + "/user-profile"
    
    PERMISSIONS = ['CREATE','READ','UPDATE','DELETE','APPROVE','SIGN1', 'SIGN2']
    authoritiesFactory = {}

    authoritiesFactory.updateAuthorities = () ->
#        console.log('updateAuthorities')
        return $http.get urlBase
            .then (response) ->
                user = response.data
                if $rootScope.currentUser
                    $rootScope.currentUser.authorities = user.authorities
                return

    authoritiesFactory.checkAny = (permissions) ->
        all = $rootScope.currentUser.authorities.join ' '
        authorized = false
        for p in permissions
            if all.indexOf(p) > -1
                authorized = true
        return authorized

    authoritiesFactory.checkAuthority = (func, permission, isMatch) ->
        check = () ->
            authorized = false
            authArray = $rootScope.currentUser.authorities
            if func
                if permission
                    if isMatch
                        for authority in authArray
                            if authority.indexOf(func+'_'+permission) > -1
                                authorized = true
                    else
                        if func.indexOf('||') == -1 && func.indexOf('&&') == -1
                            if authArray.indexOf(func+'_'+permission) > -1
                                authorized = true
                        else
                            check = false
                            if func.indexOf('||') > -1
                                func = func.split("||")
                                angular.forEach func, (f) ->
                                    if authArray.indexOf(f+'_'+permission) > -1
                                        check = true
                                    return
                            else if func.indexOf('&&') > -1
                                check = true
                                func = func.split("&&")
                                angular.forEach func, (f) ->
                                    if authArray.indexOf(f+'_'+permission) < 0
                                        check = false
                                    return
                            if check
                                authorized = true
                else
                    if func.indexOf('||') > -1
                        func = func.split("||")
                        angular.forEach func, (f) ->
                            for permission in PERMISSIONS
                                if authArray.indexOf(f+'_'+permission) != -1
                                    authorized = true
                                    return authorized
                    else if func.indexOf('&&') == -1
                        for permission in PERMISSIONS
                            if authArray.indexOf(func+'_'+permission) != -1
                                authorized = true
                                return authorized

            # console.log authorized
            return authorized

        return $q (resolve, reject) ->
            status = false
            if !($rootScope.currentUser && $rootScope.currentUser.authorities)
                $rootScope.$on 'USER_LOADED', () ->
                    try
                        status = check()
                    catch e
                        console.log 'check authority error', e
                        status = false
                    resolve status
            else
                try
                    status = check()
                catch e
                    console.log 'check authority error', e
                    status = false
                resolve status


    authoritiesFactory.logoff = (url) ->

        $http.post(url,{})



    # authoritiesFactory.checkButtons = (buttons, authSchema, func, actions) ->
    #     # console.log('authSchema: '+authSchema, 'func: '+func, 'actions: '+actions.join(','))

    #     return $q.all([
    #         authoritiesFactory.checkAuthority func, 'CREATE'
    #         authoritiesFactory.checkAuthority func, 'SIGN1'
    #         authoritiesFactory.checkAuthority func, 'APPROVE'
    #         authoritiesFactory.checkAuthority func, 'SIGN2'
    #         authoritiesFactory.checkAuthority func, 'SIGN1'
    #     ]).then (results) ->
    #         # console.log authSchema, results, actions
    #         if results[0] && actions.indexOf('CREATE') > -1
    #             buttons.sign1Only = results[1] && actions.indexOf('SIGN_1') > -1

    #             if authSchema is 'SING_APPROVE'
    #                 buttons.sendToSignAndApprove = true
    #                 buttons.approveOnly = results[2] && actions.indexOf('APPROVE') > -1

    #             if authSchema is 'SIGN1_SIGN2'
    #                 buttons.sendToSign = true
    #                 buttons.sign2Only = results[3] && actions.indexOf('SIGN_2') > -1

    #             if authSchema is 'ONE_SIGN'
    #                 buttons.sendToSign = true
    #                 buttons.signBank = results[4] && actions.indexOf('SIGN_1') > -1

    #         return buttons


    authoritiesFactory.checkButtons = (authSchema, func, actions) ->
        # console.log('authSchema: '+authSchema, 'func: '+func, 'actions: '+actions.join(','))
        # фильтрует список доступных действий/кнопок с платежом
        buttons = {
            SEND_TO_SIGN: false
            SEND_TO_SIGN__APPROVE: false
            APPROVE: false
            SIGN_1: false
            SIGN_2: false
            SIGN_1__SEND_TO_CBS: false
        }

        return $q.all([
            authoritiesFactory.checkAuthority func, 'CREATE'
            authoritiesFactory.checkAuthority func, 'SIGN1'
            authoritiesFactory.checkAuthority func, 'APPROVE'
            authoritiesFactory.checkAuthority func, 'SIGN2'
            authoritiesFactory.checkAuthority func, 'SIGN1'
        ]).then (results) ->
            # console.log authSchema, results, actions
            if results[0] && actions.indexOf('CREATE') > -1
                buttons.SIGN_1 = results[1] && actions.indexOf('SIGN_1') > -1

                switch authSchema
                    when 'SING_APPROVE', 'SING_APPROVE'
                        buttons.SEND_TO_SIGN__APPROVE = true
                        buttons.APPROVE = results[2] && actions.indexOf('APPROVE') > -1

                    when 'SIGN1_SIGN2'
                        buttons.SEND_TO_SIGN = true
                        buttons.SIGN_2 = results[3] && actions.indexOf('SIGN_2') > -1

                    when 'ONE_SIGN'
                        buttons.SEND_TO_SIGN = true
                        buttons.SIGN_1__SEND_TO_CBS = results[4] && actions.indexOf('SIGN_1') > -1

                # buttons.SEND_TO_SIGN = true
                # buttons.SIGN_2 = results[3] && actions.indexOf('SIGN_2') > -1

            return buttons

    

    authoritiesFactory
]

'use strict'

angular.module('IBR')
.directive 'pagination', () ->
  {
    restrict: 'E'
    template: [
      '<div class="pagination" ng-if="pages.length > 1">',
        '<a class="mr1" ng-click="callback(currentPage-1)" ng-disabled="currentPage == 1">',
          '<span translate="PAGINATION.PREV"></span>',
        '</a>',
        '<a class="page" ng-repeat-start="page in pages" ng-class="page == currentPage ? \'active\' : \'\'" ng-click="callback(page)" ng-if="showButton(page)">',
          '<span>{{page}}</span>',
        '</a>',
        '<a ng-if="(page == 2 || page == pages.length - 1 ) && !showButton(page)" ng-repeat-end><span>...</span></a>',
        '<a class="ml1" ng-click="callback(currentPage+1)" ng-disabled="currentPage == pages.length">',
          '<span translate="PAGINATION.NEXT"></span>',
        '</a>',
      '</div>'
    ].join('')
    scope:
      setPage: '&',
      pages: '=',
      currentPage: '='
    link: (scope, element) ->
      scope.callback = (page) ->
        scope.setPage {page:page}

      # show only 1m last and current +- 2 pagination buttons
      scope.showButton = (page) ->
        return page <= (scope.currentPage + 2) && page >= (scope.currentPage - 2) || page == 1 || page == scope.pages.length;
  }

'use strict'

angular.module('IBR')
.filter 'accByName', [ '$rootScope', ($rootScope) ->
  (input, key) ->
    if key
      lang = $rootScope.lang.toUpperCase()
      if angular.isArray(input) && input.length
        isNeeded = []
        for account in input
          if account.customName
            if account.customName.toUpperCase().indexOf(key.toUpperCase()) > -1
              isNeeded.push(account)
          else
            if account.nickname
              if account.nickname.toUpperCase().indexOf(key.toUpperCase()) > -1
                isNeeded.push(account)
            else
              if account.fieldLocalizations
                for name in account.fieldLocalizations
                  if name.language == lang
                    if name.translation.toUpperCase().indexOf(key.toUpperCase()) > -1
                      isNeeded.push(account)
                    break
              else
                for name in account.nameLocalizations
                  if name.language == lang
                    if name.translation.toUpperCase().indexOf(key.toUpperCase()) > -1
                      isNeeded.push(account)
                    break
        return isNeeded
      else
        input
    else
      input
]

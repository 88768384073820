tooltip = {
    el: document.getElementById 'tooltip'
    elShowed: false
    isOverTooltip: false
    overTooltipTimeout: null
}
if !tooltip.el
    tooltip.el = document.createElement 'div'
    tooltip.el.id = 'tooltip'
    document.body.appendChild tooltip.el


tooltip.checkIsTooltip = (e) ->
    if e.path
      for el in e.path
        if el.id is 'tooltip'
            return true
      return false
    else return false

tooltip.checkIsToggle = (e) ->
    if e.path
      for el in e.path
        if el.tagName is 'TOOLTIP-TOGGLE'
            return true
      return false
    else return false

tooltip.hideTooltip = () ->
    tooltip.elShowed = false
    tooltip.isOverTooltip = false
    tooltip.el.style.display = 'none'

tooltip.el.addEventListener 'mouseenter', (e) ->
    tooltip.isOverTooltip = true

tooltip.el.addEventListener 'mouseleave', (e) ->
    tooltip.hideTooltip()

document.addEventListener 'scroll', (e) ->
    if !tooltip.checkIsTooltip(e) && tooltip.elShowed
        tooltip.hideTooltip()
, true

document.addEventListener 'click', (e) ->
    if !tooltip.checkIsToggle(e) && tooltip.elShowed
        tooltip.hideTooltip()

angular.module('IBR')
.directive 'tooltip', [ '$compile', ($compile) ->
    {
        scope: {
            data: '=?'
            template: '@?'
            position: '@?'
        }
        restrict: 'E'
        link: (scope, element, attrs) ->

            toggle = element[0]
            template = document.getElementById(scope.template).innerHTML
            content = $compile('<div>'+template+'</div>')(scope)[0]

            isOver = false

            cords = [0, 0]

            getPosition = () ->
                tViewport = toggle.getBoundingClientRect()
                cViewport = tooltip.el.getBoundingClientRect()
                offset = { top: 0, left: 0 }
                switch scope.position
                    when 'top-left'
                        offset = { top: -cViewport.height - 5, left: -cViewport.width + tViewport.width }
                return [
                    tViewport.top + offset.top
                    tViewport.left + offset.left
                ]

            show = () ->
                clearTimeout tooltip.overTooltipTimeout
                if toggle && content
                    isVisible = tooltip.el.style.display is 'block'
                    tooltip.el.innerHTML = '<div class="popup">'+content.innerHTML+'</div>'
                    tooltip.el.style.display = 'block'
                    cords = getPosition(toggle, tooltip.el)
                    tooltip.el.style.top = cords[0] + 'px'
                    tooltip.el.style.left = cords[1] + 'px'
                    
                    tooltip.elShowed = true
 

            toggle.onmouseenter = (e) ->
                show()

            # hide if mouse not over popup
            toggle.onmouseleave = (e) ->
                tooltip.overTooltipTimeout = setTimeout(->
                    if !tooltip.isOverTooltip
                        tooltip.hideTooltip()
                , 300)
    }
]
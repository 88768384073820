'use strict'

angular.module('IBR').component 'googleAuthDialog', {

    templateUrl: '/components/google-auth-dialog.html'
    bindings:
        profile: '<'
    controllerAs: "vm"
    controller: ['$scope', '$translate', '$timeout', 'LxDialogService', 'gaFactory', 'alertModal', ($scope, $translate, $timeout, LxDialogService, gaFactory, alertModal) ->

        OTP_INTERVAL = 300
        GAC_INTERVAL = 300

        window.utils.initStorage(['GA_STEP_NAME', 'GA_STEP_EXP', 'GA_STEP_SECRET'])

        

        vm = this
        vm.loading = false
        vm.form = {}
        vm.errors = {}
        vm.appLinks = [
            {
                title: 'Android'
                url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
            }
            {
                title: 'iPhone'
                url: 'https://itunes.apple.com/pl/app/google-authenticator/id388497605'
            }
            {
                title: 'Windows',
                url: 'https://www.microsoft.com/en-us/p/authenticator/9wzdncrfj3rj'
            }
        ]

        vm.showQr = false
        vm.toggleQr = () ->
            vm.showQr = !vm.showQr

        vm.timer = 0

        vm.os = vm.appLinks[0]

        $scope.$on 'GA_DIALOG_ON', () ->
            if !vm.loading
                vm.activate()

        $scope.$on 'GA_DIALOG_OFF', () ->
            if !vm.loading
                vm.deactivate({})

        init = () ->
            step = window.localStorage.getItem 'GA_STEP_NAME'
            ga_secret = window.localStorage.getItem 'GA_STEP_SECRET'
            ga_step_exp = window.localStorage.getItem 'GA_STEP_EXP'
            continueProcess = true
            if step
                if ga_step_exp
                    time = Number ga_step_exp - new Date().getTime()
                    if time <= 0
                        window.utils.resetStorage(['GA_STEP_NAME', 'GA_STEP_EXP', 'GA_STEP_SECRET'])
                    continueProcess = time > 0
                    setStep step, { ttl: time/1000 }
                else
                    setStep step
                        
                if vm.profile.isGoogleAuthEnabled
                    if continueProcess
                        LxDialogService.open 'off-ga'
                else
                    if continueProcess
                        LxDialogService.open 'on-ga'
                if step == 'gac' && ga_secret && continueProcess
                    vm.gaSecret = ga_secret
                    makeQR getQR vm.gaSecret


        makeQR = (text) ->
            setTimeout( () ->
                el = document.getElementById('qr-code')
                el.innerHTML = ''
                new QRCode(el, {
                    text: text
                    width: 160
                    height: 160
                })
            , 200 )

        vm.selectApp = () ->
            makeQR(vm.os.url);

        getQR = (secret) ->
            secret = secret.replace(/\s/g,'')
            account = vm.profile.username
            issuer = "Bank_Respublika"
            return "otpauth://totp/"+issuer+":"+account+"?secret="+secret+"&issuer="+issuer                


            
        vm.cancel = (isActivation) ->
            window.utils.resetStorage(['GA_STEP_NAME', 'GA_STEP_EXP', 'GA_STEP_SECRET'])
            vm.loading = true  
            if isActivation
                gaFactory.activate({ action: 'CANCEL' })
                    .then (res) ->
                        vm.loading = false
                        if vm.step == 'gac' || vm.step == 'otp'
                            setStep 'install'
                        else
                            setStep null
                    , (err) ->
                        vm.loading = false
            else
                gaFactory.deactivate({ action: 'CANCEL' })
                    .then (res) ->
                        vm.loading = false
                        setStep null
                    , (err) ->
                        vm.loading = false

        vm.resetErrors = ->
            vm.errors = {}


        #ACTIVATE

        vm.activate = (data) ->
            vm.form = {}
            if !data
                LxDialogService.open 'on-ga'
                setStep 'install'
            else
                vm.loading = true
                gaFactory.activate(data)
                    .then (res) ->
                        vm.loading = false

                        if res.data && res.data.verificationType == 'otp'
                            setStep 'otp', res.data.params
                            if res.data.params && res.data.params.ttl
                                OTP_INTERVAL = parseInt res.data.params.ttl
                        else if res.data && res.data.verificationType == 'google_register'
                            vm.gaSecret = res.data.params && res.data.params.secret
                            setStep 'gac', res.data.params
                            makeQR getQR vm.gaSecret
                        else
                            setStep null
                            LxDialogService.close 'on-ga'
                            alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('GA.SUCCESS'), $translate.instant('DIALOG.OK'), () ->
                                $scope.$emit 'GA_UPDATED'
                    , (err) ->
                        vm.loading = false
                        if err.data.errorCode == 'WS-V_123'
                            alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('GA.FAIL'), $translate.instant('DIALOG.OK'), () ->
                                setStep null
                                LxDialogService.close 'on-ga'

        vm.activateInit = ->
            vm.activate({});

        vm.activateOtp = ->
            vm.errors.otpCode = !(vm.form.otpCode && vm.form.otpCode.length is 5)
            if !vm.errors.otpCode
                vm.activate({ otpCode: vm.form.otpCode });

        vm.activateGac = ->
            vm.errors.gac = !(vm.form.gac && vm.form.gac.length is 6)
            if !vm.errors.gac
                vm.activate({ gac: vm.form.gac });
                    
        #DEACTIVATE
        vm.deactivate = (data) ->
            vm.form = {}
            vm.loading = true
            return gaFactory.deactivate(data)
                .then (res) ->
                    vm.loading = false
                    vm.otpCode = ''

                    if res.data && res.data.verificationType == 'otp'
                        setStep 'otp', res.data.params
                        if res.data.params && res.data.params.ttl
                            OTP_INTERVAL = parseInt res.data.params.ttl
                        LxDialogService.open 'off-ga'
                        $scope.$emit('GA_MODAL')
                    else
                        setStep null
                        LxDialogService.close 'off-ga'
                        alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('GA.SUCCESS_DEACTIVATED'), $translate.instant('DIALOG.OK'), () ->
                            $scope.$emit 'GA_UPDATED'
                , (err) ->
                    vm.loading = false
                    $scope.$emit('GA_MODAL')
                    if err.data.errorCode == 'WS-V_123'
                        alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('GA.FAIL'), $translate.instant('DIALOG.OK'), () ->
                            setStep null
                            LxDialogService.close 'off-ga'

        vm.deactivateOtp = ->
            vm.errors.otpCode = !(vm.form.otpCode && vm.form.otpCode.length is 5)
            if !vm.errors.otpCode
                vm.deactivate({ otpCode: vm.form.otpCode });

        vm.resendOtp = (isActivation) ->
            vm.timer = null
            if isActivation
                vm.activate({action: 'RESEND'})
            else
                vm.deactivate({action: 'RESEND'})

        vm.otpTimeout = () ->
            LxDialogService.close 'on-ga'
            LxDialogService.close 'off-ga'
        
        vm.$onChanges = (e) ->
            if e.profile
                init()


        setStep = (step, data) ->
            vm.step = step
            if step
                
                if data && data.ttl
                    vm.timer = data.ttl
                else
                    vm.timer = if step == 'otp' then OTP_INTERVAL else GAC_INTERVAL

                if step == 'otp'
                    enableTime = OTP_INTERVAL - 20
                    vm.otpResendEnabled = vm.timer < enableTime
                    if !vm.otpResendEnabled
                        $timeout( ->
                            vm.otpResendEnabled = true
                        , (vm.timer - enableTime)*1000 )
                    
                expTime = new Date().getTime() + vm.timer*1000
                window.localStorage.setItem 'GA_STEP_EXP', expTime
                window.localStorage.setItem 'GA_STEP_NAME', step
            else
                window.utils.resetStorage(['GA_STEP_NAME', 'GA_STEP_EXP', 'GA_STEP_SECRET'])

            if data && data.secret && step == 'gac'
                window.localStorage.setItem 'GA_STEP_SECRET', data.secret


        return vm
    ]
}
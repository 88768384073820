angular.module('IBR')
.controller 'PaymentDomesticCtrl', [ '$rootScope', '$q', '$timeout', '$translate', '$scope', '$stateParams', 'apiPayment', 'apiTemplates', 'authoritiesFactory', 'transactionActions', 'alertModal', 'asanModal', 'transactionResultModal', 'LxDialogService', ( $rootScope, $q, $timeout, $translate, $scope, $stateParams, apiPayment, apiTemplates, authoritiesFactory, transactionActions, alertModal, asanModal, transactionResultModal, LxDialogService ) ->

    #constants
    CODE_AZIPS = 'AZ'
    CODE_XOHKS = 'XO'
    CODE_BUDGET = 'XT'
    CODE_BUDGET_VAT = 'XV'
    
    DIALOG_MODAL = 'modal-payment-domestic'
    TEMPLATE_NAME_MODAL = 'modal-create-template'

    # this
    vm = this

    vm.loaded = false
    vm.loaders = {}
    vm.payment = {}
    vm.cids = []
    vm.isLocked = false #additional
    vm.validFields = {}
    vm.templateName = ''
    vm.cutOff = false
    vm.currentCode = CODE_AZIPS

    #valid payment object for API
    validatedPayment = {}

    #checkPermissions
    vm.permissions = {}
    $q.all([
        authoritiesFactory.checkAuthority 'AZIPS', 'CREATE'
        authoritiesFactory.checkAuthority 'XOHKS', 'CREATE'
        authoritiesFactory.checkAuthority 'BUDGET', 'CREATE'
        authoritiesFactory.checkAuthority 'BUDGET_VAT', 'CREATE'
    ]).then (responses) ->
        vm.permissions[CODE_AZIPS] = responses[0]
        vm.permissions[CODE_XOHKS] = responses[1]
        vm.permissions[CODE_BUDGET] = responses[2]
        vm.permissions[CODE_BUDGET_VAT] = responses[3]

        if !vm.permissions[vm.currentCode]
            vm.isLocked = true
            vm.loaded = true
            alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ACCESS_DENIED'), 'Ok'

    vm.getTabClass = (code) ->
        className = ''
        if !vm.permissions[code]
            className = 'not-allowed'
        if code is vm.currentCode
            className = 'active'
        return className
    #checkPermissions END

    # payment for initialized
    progress = false
    $scope.$on 'FORM_READY', (event) ->
        if $stateParams.payment && !progress
            $scope.$broadcast 'setPayment', $stateParams.payment #fill form if it is change action
            progress = true

    searchTemplate = (extended) ->
        tempTemplates = []
        type = apiPayment.getPaymentType vm.currentCode
        template = {
            pageParams: {
                pageNumber: 1
                size: 10000
            }
            sort: {
                fieldName: "name"
                order: "ASC"
            }
            filterSpecification: [
                {
                    fieldName: "type"
                    inverted: "false"
                    operator: "EQUALS"
                    values: [ type ]
                }
                {
                    fieldName: "initiatorCid"
                    inverted: "false"
                    operator: "IN"
                    values: if (vm.payment.customer && vm.payment.customer.cid) then [vm.payment.customer.cid] else vm.cids
                }
            ]
        }

        apiTemplates.getTemplates template
            .then (response) ->
                tempTemplates = response.data.records
                return
            .then ->
                vm.templates = tempTemplates
                # unlockTemplateBtn()
                $scope.$broadcast 'TEMPLATES_FOUND'
                if extended
                    LxDialogService.open 'temp-search-dialog'
                else
                    LxDialogService.open 'temp-list-dialog'
            .catch (err) ->
                $scope.$broadcast 'TEMPLATES_ERR'
                # vm.templateFilling = true
        return

    $scope.$on 'TEMPLATE_SEARCH', () ->
        searchTemplate true

    $scope.$on 'TEMPLATE_LIST', () ->
        searchTemplate false

    vm.chooseTemplate = (template) ->
        $scope.$broadcast 'TEMPLATE_SELECT', template, true

    processPayment = (payment) ->
        p = angular.copy(payment, {})
        p.initiatorCid = payment.customer.cid

        if typeof(p.debit) == 'object' && !angular.equals(p.debit, {})
            p.currency = p.debit.currency
            p.debit = p.debit.iban

        if typeof(p.credit) == 'object'
            p.credit = p.credit.iban
        if typeof(p.customer) == 'object'
            p.uuid = p.customer.cid
            delete p.customer

        switch vm.currentCode
            when CODE_BUDGET_VAT
                p.beneficiaryBankName = "Dovlet Xezinedarligi Agentliyi (EDV)"
            when CODE_BUDGET
                p.beneficiaryBankName = "Dovlet Xezinedarligi Agentliyi"
        if p.amount
            p.amount = Number p.amount.replace(/\s/g,'')
        p.status = 0

        for own key of p
            if (typeof p[key] == 'undefined' || !p[key].length && typeof p[key] != 'number')
                delete p[key]
        if p.notes == ''
            delete p.notes
        return p

    processTemplate = (payment) ->

        template = processPayment payment
        for own key of template
            if !template[key].length && key != "customer" && typeof template[key] != 'number'
                delete template[key]
        if template.debit
            template.debitIban = vm.payment.debit.iban
            delete template.debit

        delete template.credit
        delete template.uuid
        delete template.clientRef
        delete template.currency
        delete template.status

        template.creditIban = vm.payment.credit
        template.cid = vm.payment.customer.cid

        return template

    paymentCheck = () ->
        #fake
        validatedPayment = payment = processPayment vm.payment
        $rootScope.customAlert = true

        return apiPayment.validate vm.currentCode, payment
            .then (res) ->
                #successfully validated
                $rootScope.customAlert = false
                vm.cutOff = res.data.length && res.data[0].message && res.data[0].message is "cutOff"

                #get comission
                data = {
                    cid: vm.payment.customer.cid
                    paymentType: vm.currentCode
                    amount: payment.amount
                    currency: payment.currency
                    debit: payment.debit
                    credit: payment.credit
                    beneficiaryBankCode: payment.beneficiaryBankCode
                    customerTaxId: payment.beneficiaryCorporateCustomerTaxId
                    narrative: payment.narrative
                }

                
                return apiPayment.getCommis data
            .then (res) ->
                if res.data.currency != undefined && res.data.amount != undefined
                    vm.payment.commisAmount = res.data.amount
                    vm.payment.commisCurrency = res.data.currency

                return transactionActions.getPaymentButtons vm.currentCode, vm.payment.customer.cid, vm.payment.customer.authSchema, vm.payment.debit.iban
            .then (buttons) ->
                console.log 'paymentDialog open'
                vm.loaders.validation = false
                vm.buttons = buttons
                paymentDialog.open()
            .catch (err) ->
                console.log 'err catch'
                vm.loaders.validation = false
                if $rootScope.customAlert
                    if err && err.data && err.data.constraints && err.data.constraints[0] && err.data.constraints[0].code is '0038'
                        alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ERROR_CORP_LIMIT'), 'Ok'
                    else
                        $scope.$broadcast 'account_iban_not_exist'


    paymentDialog = {}

    paymentDialog.open = () ->
        vm.loaders = {}
        LxDialogService.open DIALOG_MODAL
        return

    paymentDialog.close = () ->
        vm.loaders = {}
        LxDialogService.close DIALOG_MODAL

    vm.closePaymentDialog = () ->
        LxDialogService.close DIALOG_MODAL


    vm.initPayment = (code) ->
        if vm.permissions[code] and vm.loaded
            if vm.currentCode != code
                vm.currentCode = code
                vm.resetPayment true

    vm.resetPayment = (needClearRef) ->
        $scope.$broadcast 'PAYMENT_RESET', needClearRef
    
    vm.presendPayment = () ->
        vm.loaders.validation = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false

    vm.presendTemplate = () ->
        vm.loaders.template = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false

    $scope.$on 'PAYMENT_LOCAL_VALIDATED', (event, isValid) ->
        if isValid
            if vm.loaders.validation
                paymentCheck()
                    # .then () ->
                    #     paymentDialog.open()
                    # .catch () ->
                    #     vm.loaders = {}
            else if vm.loaders.template
                vm.loaders = {}
                LxDialogService.open TEMPLATE_NAME_MODAL
        else
            vm.loaders.validation = false

    vm.sendPayment = (btnKey) ->
        authSchema = vm.payment.customer.authSchema
        switch btnKey
            when 'SEND_TO_SIGN', 'SEND_TO_SIGN__APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create vm.currentCode, false, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create vm.currentCode, btnKey, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        if res.data.responseType is 'COMPLETE'
                            return transactionResultModal.successPaymentModal authSchema, btnKey
                        else
                            $q.reject()
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1', 'SIGN_2'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment vm.currentCode, validatedPayment, btnKey
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1__SEND_TO_CBS'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment vm.currentCode, validatedPayment, 'SIGN_1'
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        validatedPayment.ref = res.data.refSet[0]
                        alertModal.showLoader()
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs validatedPayment.ref #todo where ref from?
                    .then (res) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        return transactionResultModal.sendToBank btnKey, validatedPayment, null, res.data, res.status
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                paymentDialog.close()
                                vm.resetPayment true

    
    vm.sendTemplate = () ->
        if !vm.templateName
            vm.validFields.templateName = true
            return

        template = processTemplate vm.payment
        template.name = vm.templateName
        vm.loaders.template = true
        apiTemplates.addTemplate vm.currentCode, template
            .then (response) ->
                LxDialogService.close TEMPLATE_NAME_MODAL

                header = $translate.instant 'TEMPLATES_PAYMENT.TITLE_DIALOG'
                message = $translate.instant 'INTERNAL_PAYMENT.TEMPLATE_DONE'
                alertModal.showMessage header, message, 'OK'
            .catch (err) ->
                vm.loaders.template = false

    return
]
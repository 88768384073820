'use strict'

angular.module('IBR')
    .directive 'instantPaymentForm', [ '$rootScope', '$translate', '$timeout', '$filter', 'LxDialogService', 'apiPayment', 'apiAccounts',  ($rootScope, $translate, $timeout, $filter, LxDialogService, apiPayment, apiAccounts ) ->
        {
            resctict: 'E'
            templateUrl: '/components/instant-payment-form.html'
            scope:
                cids: '=?'
                payment: '=?'
                loaded: '=?'
                txnType: '=?'
                isTemplate: '=?'
                isValid: '=?'
                isReady: '=?'
                isLocked: '=?'
            controller: ['$scope', 'authoritiesFactory', 'alertModal',  ($scope, authoritiesFactory, alertModal) ->
                $scope.PAYMENT_TYPE_PI = 'PI'
                $scope.PAYMENT_TYPE_PT = 'PT'
                $scope.PAYMENT_TYPE_PV = 'PV'

                $scope.isValid = if angular.isDefined($scope.isValid) then $scope.isValid else true
                $scope.isReady = if angular.isDefined($scope.isReady) then $scope.isReady else false
                $scope.isLocked = if angular.isDefined($scope.isLocked) then $scope.isLocked else false
                $scope.payment = if angular.isDefined($scope.payment) then $scope.payment else {}
                $scope.isTemplate = if angular.isDefined($scope.isTemplate) then $scope.isTemplate else false
                $scope.txnType = if angular.isDefined($scope.txnType) then $scope.txnType else $scope.PAYMENT_TYPE_PI

                $scope.ALIASES = {
                    MOBILE: 'MOBILE'
                    PIN: 'PIN'
                    TIN: 'TIN'
                    IBAN: 'IBAN'
                    EMAIL: 'EMAIL'
                    MERCHANTID: 'MERCHANTID'
                    OBJECTCODE: 'OBJECTCODE'
                }

                $scope.aliases = Object.keys($scope.ALIASES).map (a) ->
                                    return {
                                        label: a
                                        value: $scope.ALIASES[a]
                                    }

                $scope.emailPattern = """/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/"""

                $scope.isEditing = false
                $scope.accounts = []
                $scope.customers = []
                $scope.codes = []
                $scope.classCodes = []
                $scope.validFields = {}
                $scope.code = {}
                $scope.codeclass = ""
                $scope.showCodeInput = false
                $scope.credAccounts = []
                $scope.code = { object: '' }
                $scope.name = { object: '' }
                $scope.limitOk = false

                $scope.alias = {
                    type: undefined
                    value: undefined
                }
                $scope.adisabled = {}
                

                resetADisabled = () ->
                    if $scope.payment.getByAlias
                        $scope.adisabled = {
                            beneficiaryCorporateCustomerName: true
                            credit: true
                            beneficiaryCorporateCustomerTaxId: true

                            beneficiaryBankCode: true
                            beneficiaryBankName: true
                            beneficiaryBankCorrespondentAccountIban: true
                            beneficiaryBankTaxId: true
                            beneficiaryBankSwiftCode: true
                        }
                    else
                        $scope.adisabled = {
                            beneficiaryCorporateCustomerName: $scope.txnType == $scope.PAYMENT_TYPE_PT
                            credit: $scope.txnType == $scope.PAYMENT_TYPE_PV
                            beneficiaryCorporateCustomerTaxId: $scope.txnType == $scope.PAYMENT_TYPE_PT

                            beneficiaryBankCode: true
                            beneficiaryBankName: true
                            beneficiaryBankCorrespondentAccountIban: true
                            beneficiaryBankTaxId: true
                            beneficiaryBankSwiftCode: true
                        }

                $scope.getByAliasChange = () ->
                    resetAliasData(true)
                    if $scope.name.object && !$scope.payment.getByAlias && $scope.txnType == 'PT'
                        $scope.payment.beneficiaryCorporateCustomerName = $scope.name.object.name
                        $scope.payment.beneficiaryCorporateCustomerTaxId = $scope.name.object.taxId
                    
                    resetADisabled()

                $scope.limitsBtnLock = false

                $scope.helperMsg = 'PAYMENT.FIND_HELPER'

                hideStatuses = ['CLOSED']

                $scope.accountChecked = true
                clientRefLoadingTrigger = false



                $scope.payment = {
                    createdFromTemplate: false
                    getByAlias: false
                    beneficiaryCorporateCustomerName: ""
                    credit: ""
                    notes: ""
                    narrative: ""
                    beneficiaryNarrative: ""
                    beneficiaryCorporateCustomerTaxId: ""
                    amount: ""
                    beneficiaryBankCode: ""
                    beneficiaryBankName: ""
                    ipsTemplateAlias: {
                        type: ""
                        value: ""
                    }

                }

                allCustomers = []
                allAccounts = []

                processAccounts = (accounts) ->
                    customerAccounts = {}
                    allCustomers = []
                    accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
                    for account in accounts
                        if account.currency is "AZN"
                            cid = account.corporateCustomer.cid
                            if !(account.status in hideStatuses) && account.iban
                                if !customerAccounts[cid]
                                    allCustomers.push account.corporateCustomer
                                    customerAccounts[cid] = {}
                                    customerAccounts[cid].accounts = []
                                    customerAccounts[cid].corporateCustomer = account.corporateCustomer
                                customerAccounts[cid].accounts.push account
                    filterBySelected()
                    $scope.isReady = $scope.customers.length && accounts && accounts.length
                    customerAccounts

                filterBySelected = () ->
                    $scope.customers = $filter('filterSelectedCustomers')(allCustomers)
                    $scope.cids = $scope.customers.map (customer) ->
                        return customer.cid
                    $scope.accounts = $filter('selectedCustomers')(allAccounts)
                    if $scope.customers.length == 1
                        $scope.payment.customer = $scope.customers[0]
                    $scope.isReady = $scope.customers.length

                $rootScope.$on 'SELECT_CUSTOMERS', () ->
                    filterBySelected()

                resetCode=->
                    $scope.showCodeInput=false
                    $timeout(->
                        $scope.showCodeInput = true
                    )

                initPaymentForm = (txnType) ->
                    resetCode()
                    if $scope.txnType
                        $scope.loaded = false
                        # $scope.isTemplate = false
                        apiAccounts.getPaymentAccounts(txnType).then (response) ->
                            $scope.accounts = processAccounts response.data
                            allAccounts = angular.copy $scope.accounts, []
                            if $scope.customers.length == 1
                                $scope.payment.customer = $scope.customers[0]
                            $scope.loaded = true
                            $scope.$emit('FORM_READY')
                            return
                        .catch (error) ->
                            $scope.loaded = true
                            $scope.$emit('FORM_READY')
                            return

                getClientRef = () ->
                    clientRefLoadingTrigger = true
                    return apiPayment.getRef($scope.txnType, $scope.payment.customer.cid).then (response) ->
                            clientRefLoadingTrigger = false
                            if response.data.clientRef
                                $scope.payment.clientRef = response.data.clientRef
                            return response.data.clientRef
                        , (error) ->
                            clientRefLoadingTrigger = false

                getLvlCodes = () ->
                    apiPayment.getDomesticCodes("lvl").then (response) ->
                        $scope.lvlCodes = response.data.sort (a, b) -> if a.code > b.code then 1 else if b.code > a.code then -1 else 0
                    apiPayment.getDomesticCodes("class", '*').then (response) ->
                        $scope.classCodes = response.data.sort (a, b) -> if a.code > b.code then 1 else if b.code > a.code then -1 else 0
                    return

                resetFields = (button, template) ->
#                    $scope.adisabled = {}
                    $scope.limitMsg = ''
                    clientRef = $scope.payment.clientRef
                    customer = $scope.payment.customer
                    ref =  $scope.payment.ref
                    createDate = $scope.payment.createDate
                    $scope.validFields = {}
                    $scope.payment =
                        lang: $rootScope.currentLang.lngu
                        clientRef: clientRef
                        ref:ref
                        customer: customer
                        beneficiaryCorporateCustomerName: ""
                        credit: ""
                        notes: ""
                        narrative: ""
                        beneficiaryNarrative: ""
                        beneficiaryCorporateCustomerTaxId: ""
                        amount: ""
                        beneficiaryBankCode: ""
                        beneficiaryBankName: ""
                        createdFromTemplate: false
                        getByAlias: false
                        ipsTemplateAlias:{
                            type:""
                            value:""
                        }
                        currency: ""
                    $scope.alias = {}
                    $scope.code = { object: '' }
                    $scope.name = { object: '' }
                    $scope.accountChecked = true
                    $scope.code.class = ""
                    $scope.codeLvl = ""
                    if template && createDate
                      $scope.payment.createDate = createDate

                    domesticData = apiPayment.getDomesticData $scope.txnType
                    angular.extend($scope.payment, domesticData)
                    $scope.limitOk = false

                    resetCode()


                resetAliasData = (resetAliasType, aliasError) ->
                    if resetAliasType
                        $scope.alias = {}
                    else if !aliasError
                        $scope.alias.value = ''
                    $scope.validFields.compare = false
                    $scope.payment.ipsTemplateAlias = {}
                    $scope.payment.ipsTemplateAlias.value = ""
                    $scope.payment.credit = ''
                    $scope.payment.beneficiaryCorporateCustomerName = ''
                    $scope.payment.beneficiaryCorporateCustomerTaxId = ''
                    
                    if $scope.txnType == $scope.PAYMENT_TYPE_PI
                        $scope.code.object = {}
                        $scope.payment.beneficiaryBankCode =  ''
                        $scope.payment.beneficiaryBankName = ''
                        $scope.payment.beneficiaryBankCorrespondentAccountIban = ''
                        $scope.payment.beneficiaryBankTaxId = ''
                        $scope.payment.beneficiaryBankSwiftCode = ''

                    $scope.validFields.credit = false
                    $scope.validFields.codeObject = false
                    $scope.validFields.beneficiaryCorporateCustomerName = false
                    $scope.validFields.beneficiaryCorporateCustomerTaxId = false
                    $scope.accountChecked = true

                    $scope.validFields.beneficiaryBankCode = false
                    $scope.validFields.beneficiaryBankName = false
                    $scope.validFields.beneficiaryBankCorrespondentAccountIban = false
                    $scope.validFields.beneficiaryBankTaxId = false
                    $scope.validFields.beneficiaryBankSwiftCode = false
                    

                $scope.filterClass = (val) ->
                    type = "class"
                    val=val+''
                    searchQuery ="code:*"+val+"*"
                    apiPayment.getDomesticCodes(type, searchQuery).then (response) ->
                        $scope.classCodes = response.data
                    return


                $scope.$watch 'txnType', (value) ->
                    $scope.helperMsg = 'PAYMENT.FIND_HELPER'
                    if value then initPaymentForm(value)
                    resetADisabled()
                    switch value
                        when $scope.PAYMENT_TYPE_PT
                            domesticData = apiPayment.getDomesticData value
                            angular.extend($scope.payment, domesticData)
                            getLvlCodes()
                        when $scope.PAYMENT_TYPE_PV
                            domesticData = apiPayment.getDomesticData value
                            angular.extend($scope.payment, domesticData)
                        else
                            $scope.payment.beneficiaryBankCode = ""
                            $scope.payment.beneficiaryBankName = ""
                            $scope.payment.beneficiaryBankTaxId = ""
                            $scope.payment.beneficiaryBankCorrespondentAccountIban = ""
                            $scope.payment.beneficiaryBankSwiftCode = ""
                            $scope.payment.beneficiaryBankCode = ""
                            $scope.payment.credit = ""

                # $scope.$on 'init', ( = ->
                #     initDomesticPayment()

                $scope.$on 'PAYMENT_RESET', (event, clearRef) ->
                    console.log "PAYMENT_RESET", $scope.payment.getByAlias
                    # $scope.isTemplate = false
                    $scope.payment.getByAlias = false
                    $scope.payment.clientRef = ''
                    resetADisabled()
                    if $scope.isEditing
                        clearRef = true
                        $scope.isEditing = false

                    resetFields()

                $scope.search =
                    codeInput: ''
                    bankNameInput: ''

                $scope.searchDialog = () ->
                    $scope.codes = []
                    $scope.search =
                        codeInput: ''
                        bankNameInput: ''
                    LxDialogService.open "domestic-search-dial"
                    return

                $scope.searchCodeDialog = () ->
                    $scope.classificationCodes = []
                    $scope.search =
                        code: ''
                        description: ''
                    LxDialogService.open "code-class-search"

                $scope.bigSearch = () ->
                    if ($scope.search.codeInput.length > 2 || $scope.search.bankNameInput.length > 2)
                        searchQuery ='(code:*' + $scope.search.codeInput + '*)AND(name:*' + $scope.search.bankNameInput + '*)'
                        switch $scope.txnType
                            when $scope.PAYMENT_TYPE_PT
                                apiPayment.getDomesticCodes("payments", searchQuery).then (response) ->
                                    $scope.codes = response.data
                            else
                                apiPayment.getDomesticCodes("bank", searchQuery).then (response) ->
                                    $scope.codes = response.data
                    else
                        $scope.codes = []
                    return

                $scope.searchCodeClass = () ->
                    if ($scope.search.code.length > 2)
                        searchQuery = '(code:*' + $scope.search.code + '*)'
                        apiPayment.getDomesticCodes("class", searchQuery).then (response) ->
                            $scope.classificationCodes = response.data
                    else
                        $scope.classificationCodes = []
                    return

                $scope.filterCodes = (val, autoselect) ->
                    if(val && val.length > 1)
                        $scope.helperMsg = ''
                        searchQuery ="code:*"+val+"*"
                        switch $scope.txnType
                            when $scope.PAYMENT_TYPE_PT
                                apiPayment.getDomesticCodes("payments", searchQuery).then (response) ->
                                    $scope.codes = response.data
                                    if $scope.codes.length == 0
                                        $scope.helperMsg = 'DOMESTIC_PAYMENT.ACCS_NOT_FOUND'
                                    if $scope.codes.length is 1 && autoselect
                                        $scope.writeAll $scope.codes[0]
                            else
                                apiPayment.getDomesticCodes("bank", searchQuery).then (response) ->
                                    $scope.codes = response.data
                                    if $scope.codes.length == 0
                                        $scope.helperMsg = 'DOMESTIC_PAYMENT.CODES_NOT_FOUND'
                                    if $scope.codes.length is 1 && autoselect
                                        $scope.writeAll $scope.codes[0]
                    else
                        $scope.helperMsg = 'PAYMENT.FIND_HELPER'
                        $scope.codes = []
                    return

                $scope.chooseCode = (val) ->
                    LxDialogService.close "domestic-search-dial"
                    $scope.writeAll(val)
                    return

                $scope.chooseClassificationCode = (val) ->
                  $scope.code.class = val
                  LxDialogService.close "code-class-search"
                  $scope.payment.budgetClassificationCode = val.code
                  return

                $scope.setCodeClass = (val) ->
                    $scope.validFields.codeClass = false
                    $scope.payment.budgetClassificationCode = if val then val.code else ''
                    $scope.payment.budgetClassificationCodeName = if val then val.name else ''

                $scope.setCodeLvl = (val) ->
                    $scope.validFields.codeLvl = false
                    $scope.payment.budgetLevelCode = if val then val.code else  ''
                    $scope.payment.budgetLevelCodeName = if val then val.name else  ''

                $scope.writeAll = (val) ->
                    if $scope.payment.getByAlias
                        return
                    $scope.validFields.code = false
                    if !val
                        if $scope.txnType is $scope.PAYMENT_TYPE_PT
                            delete $scope.name.object
                            delete $scope.payment.beneficiaryCorporateCustomerName
                            delete $scope.payment.credit
                            delete $scope.payment.beneficiaryCorporateCustomerTaxId
                        else
                            delete $scope.code.object
                            delete $scope.payment.beneficiaryBankName
                            delete $scope.payment.beneficiaryBankCorrespondentAccountIban
                            delete $scope.payment.beneficiaryBankTaxId
                            delete $scope.payment.beneficiaryBankSwiftCode
                    else
                        switch $scope.txnType
                            when $scope.PAYMENT_TYPE_PT
                                

                                $scope.name.object = val
                                $scope.payment.beneficiaryCorporateCustomerName = val.name
                                $scope.payment.credit = val.code
                                $scope.payment.beneficiaryCorporateCustomerTaxId = val.taxId
                            else
                                $scope.code.object = val
                                $scope.payment.beneficiaryBankCode = val.code
                                $scope.payment.beneficiaryBankName = val.name
                                $scope.payment.beneficiaryBankSwiftCode = val.swiftCode
                                $scope.payment.beneficiaryBankTaxId = val.taxId
                                $scope.payment.beneficiaryBankCorrespondentAccountIban = val.correspondentAccountIban
                                if $scope.payment.credit
                                    $scope.compareSwift()
                        $scope.codes = []
                    return

                #CBR-1715
                $scope.compareSwift = () ->
                    $scope.validFields.compare = false
                    if(!$scope.payment.credit || !$scope.payment.beneficiaryBankSwiftCode || $scope.payment.getByAlias)
                        return
                    else
                        testCode = $scope.payment.beneficiaryBankCode
                        testSwift = $scope.payment.beneficiaryBankSwiftCode
                        testAcc = $scope.payment.credit
                        $scope.validFields.compare = testSwift.substring(0,4) != testAcc.substring(4,8)
                        #exceptions
                        if CONFIG.BANK_SWIFT && CONFIG.BANK_SWIFT[testCode] && CONFIG.BANK_SWIFT[testCode] == testAcc.substring(4,8)
                            $scope.validFields.compare = false
                    return


                $scope.checkAccount = () ->
                    if $scope.payment.credit
                        if $scope.payment.credit && typeof($scope.payment.credit) == 'string' && $scope.payment.credit.length == 28
                            ibanQuery =
                                iban: $scope.payment.credit
                            apiPayment.checkIban(ibanQuery).then (response) ->
                                if response.data && response.data.result
                                    $scope.accountChecked = true
                                else
                                    $scope.accountChecked = false
                                return
                            , (error) ->
                                $scope.accountChecked = true
                            return
                        else
                            $scope.accountChecked = false
                        return

                $scope.$watch 'payment.customer', ( ->
                    $scope.payment.clientRef = ''
                    $scope.limitOk = false
                    $scope.payment.createdFromTemplate = false
                    if $scope.payment.customer && $scope.payment.customer.cid # && !clientRefLoadingTrigger
                        $scope.payment.debit = ''
                        $scope.payment.currency = ''
                    return
                ), true

                $scope.$watch 'payment.debit', ( ->
                    if $scope.payment.debit
                        $scope.payment.currency = $scope.payment.debit.currency
                        if $scope.payment.debit.iban && !$scope.payment.clientRef && !$scope.isEditing && !$scope.payment.createdFromTemplate
                            getClientRef()
                    return
                ), true

                # $scope.$watch 'payment', ( ->
                #     if !(angular.equals($scope.validFields,{}))
                #         for own key of $scope.payment
                #             if $scope.validFields[key] is true and $scope.payment[key]
                #                 $scope.validFields[key]= false
                # ),true


                $scope.searchTemplate = ->
                    $scope.templateBtnLock = true
                    $scope.$emit 'TEMPLATE_SEARCH'

                $scope.getLimits = ->
                    $scope.limitOk = false
                    $scope.validFields.limitOk = false
                    $scope.limitMsg = false
                    $scope.localValidateLimits()
                    if $scope.isValid
                        $scope.limitsBtnLock = true
                        $scope.$emit 'GET_LIMITS'

                $scope.listTemplate = ->
                    $scope.templateBtnLock = true
                    $scope.$emit 'TEMPLATE_LIST'


                showLimitMsg = (data, type) ->
                    messages = [{ type: type, msg: data.remark }]
                    if data.limitInfo
                        for msg in data.limitInfo.split(';')
                            if msg.length > 2
                                messages.push ({ type: 'txt', msg: msg })

                    alertModal.showLimits $translate.instant('INSTANT_PAYMENT.LIMITS'), messages, $translate.instant('DIALOG.OK')
                

                $scope.$on 'LIMITS_RESULT', (event, result) ->
                    if result.status != 200
                        $scope.limitsBtnLock = false
                        $scope.validFields.limitOk = false
                        $scope.limitOk = false
                    else
                        if result.data.statusMsg == "FAILURE"
                            $scope.limitsBtnLock = false
                            $scope.validFields.limitOk = false
                            $scope.limitOk = false
                            showLimitMsg(result.data, 'err')
                        else
                            $scope.limitsBtnLock = false
                            $scope.limitOk = true
                            showLimitMsg(result.data, 'msg')
                            # $scope.limitMsg = false


                $scope.$on 'TEMPLATES_FOUND', () ->
                    $scope.templateBtnLock = false

                $scope.$on 'TEMPLATES_ERR', () ->
                    $scope.templateBtnLock = false

                $scope.$on 'setPayment', (event, payment) ->
                    setPayment(payment)

                setAliasValues = (payment) ->

                    if payment.getByAlias
                        $scope.payment.getByAlias = true
                        $timeout( ->
                            for at in $scope.aliases
                                if at.value == payment.ipsTemplateAlias.type
                                    $scope.alias.type = at
                        , 200 )
                        $timeout( ->
                            aliasValue = payment.ipsTemplateAlias.value
                            if payment.ipsTemplateAlias.type is 'MOBILE'
                                aliasValue = aliasValue.replace('+994', '')
                            $scope.alias.value = aliasValue
                            setAlias()
                            $scope.checkAlias()
                        , 400)

                setPayment = (payment, alias) ->
                    # console.log 'setPayment', payment
                    $scope.isEditing = true
                    

                    if payment.bulkId
                        $scope.payment.bulkId = payment.bulkId
                    if payment.bulkRef
                        $scope.payment.bulkRef = payment.bulkRef
                    if payment.bulkName
                        $scope.payment.bulkName = payment.bulkName
                    if payment.source
                        $scope.payment.source = payment.source
                    if payment.uuid
                        $scope.payment.uuid = payment.uuid

                    if payment.code || payment.typeCode
                        $scope.txnType = payment.code || payment.typeCode

                    if payment.createDate
                        $scope.payment.createDate = payment.createDate
                    if payment.createdFromTemplate
                        $scope.payment.createdFromTemplate = payment.createdFromTemplate


                        
                    cid = payment.initiatorCid || payment.corporateCustomerInitiatorCid

                    $timeout( ->
                        if !alias
                            $scope.payment.ref = payment.ref || ''
                        $scope.payment.typeCode = $scope.txnType
                        for customer in $scope.customers
                            if customer.cid == cid
                                $scope.payment.customer = customer
                    , 100 )

                    $timeout( ->

                        clientRefLoadingTrigger = true
                        if $scope.accounts[cid]
                            for account in $scope.accounts[cid].accounts
                                if payment.debit == account.iban
                                    $scope.payment.debit = account

                        if payment.clientRef
                            $scope.payment.clientRef = payment.clientRef

                        if payment.debitAmount || payment.amount
                            $scope.payment.amount = (payment.debitAmount || payment.amount) + ''
                        if payment.narrative
                            $scope.payment.narrative = payment.narrative || ''
                        if payment.notes
                            $scope.payment.notes = payment.notes
                        if payment.beneficiaryNarrative && !alias
                            $scope.payment.beneficiaryNarrative = payment.beneficiaryNarrative || ''

                        if payment.bankInfo
                            bankInfoFilling({ transfer: payment })

                        if payment.beneficiaryName
                            $scope.payment.beneficiaryCorporateCustomerName = payment.beneficiaryName


                        if $scope.txnType != $scope.PAYMENT_TYPE_PV
                            $scope.payment.credit = payment.credit

                        if payment.beneficiaryTaxId
                            $scope.payment.beneficiaryCorporateCustomerTaxId = payment.beneficiaryTaxId
                            if payment.code is $scope.PAYMENT_TYPE_PT
                                $scope.name.object =
                                    name: payment.beneficiaryName
                                    code: payment.credit
                                    taxId: payment.beneficiaryTaxId

                                $scope.codeLvl =
                                    code: payment.budgetLevelCode
                                $scope.code.class =
                                    code: payment.budgetClassificationCode
                        
                        # is copy logic
                        if !alias
                            if payment.isCopy
                                getClientRef()
                                    .then () ->
                                        $scope.isEditing = false
                                        delete payment.isCopy
                                        if payment.getByAlias
                                            setAliasValues payment
                            else
                                setAliasValues payment
                        else
                            if payment.isCopy
                                $scope.isEditing = false
                                delete payment.isCopy
                        

                    , 200 )
                    resetADisabled() # console.log 'setPayment end', $scope.payment
                    return

                tmpDebit = {} # variable to cash debit account, were found
                $scope.$on 'TEMPLATE_SELECT', (event, template, isPayment) ->

                    for customer in $scope.customers
                        if customer.cid == template.transfer.corporateCustomerInitiator.cid
                            $scope.payment.customer = customer
                    resetFields(true, true)
                    $scope.payment.createdFromTemplate = true
                    $scope.payment.ipsTemplate = {
                        name: template.name
                        userLogin: template.createdBy
                        fullUserName: template.authorFullName
                        lastModifyDate: template.lastModifiedDate
                    }
                    $scope.payment.narrative = template.transfer.narrative || ''
                    $scope.payment.notes = template.transfer.notes || ''
                    $scope.payment.amount = (template.transfer.amount || '') + ''
                    $scope.payment.beneficiaryNarrative = template.transfer.beneficiaryNarrative || ''


                    $timeout( ->
                        if template.transfer.debit && template.transfer.debit.iban
                            tmpDebit = $filter('filter')($scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts, {iban: template.transfer.debit.iban})[0]
                            $scope.payment.debit = angular.copy tmpDebit, {}
                        else
                            $scope.payment.debit = ''
                            $scope.payment.currency = ''
                        if $scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts[0] && !$scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts[0].deleted
                            $scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts.unshift({
                              deleted: true,
                              customName: "PAYMENT.SELECT_ACCOUNT"
                            })
                    )
                    $timeout(->
                        if !template.transfer.getByAlias
                            beneficiaryInfoFilling(template)
                    )
                    if $scope.txnType is $scope.PAYMENT_TYPE_PI
                        if !template.transfer.getByAlias
                            bankInfoFilling(template)


                    if template.transfer.budgetLevelCode
                        $scope.codeLvl =
                            code: template.transfer.budgetLevelCode
                        $scope.payment.budgetLevelCode = template.transfer.budgetLevelCode

                    if template.transfer.budgetClassificationCode
                        $scope.code.class =
                            code: template.transfer.budgetClassificationCode
                        $scope.payment.budgetClassificationCode = template.transfer.budgetClassificationCode

#                    if $scope.txnType is $scope.PAYMENT_TYPE_PT
#                        console.log "HERE"
#                        $scope.payment.budgetClassificationCode = template.transfer.budgetClassificationCode
#                        $scope.payment.budgetLevelCode = template.transfer.budgetLevelCode
#                        $scope.codeLvl =
#                            code: template.transfer.budgetLevelCode
#                        $scope.code.class =
#                            code: template.transfer.budgetClassificationCode


                    $scope.payment.templateName = template.name

                    # if !isPayment
                    if !$scope.payment.clientRef
                        getClientRef()
                            .then () ->
                                if template.transfer.getByAlias
                                    setAliasValues template.transfer
                                    $scope.payment.createdFromTemplate = true
                    else
                        if template.transfer.getByAlias
                            setAliasValues template.transfer

                    if !template.transfer.getByAlias
                        delete $scope.payment.getByAlias
                        delete $scope.payment.ipsTemplateAlias
                        $scope.alias = {}

                        $timeout( ->
                            if !$scope.isTemplate
                                $scope.checkAlias()
                            $scope.payment.createdFromTemplate = true
                        , 350 )
                    return

                beneficiaryInfoFilling = (template)->
                    $scope.payment.beneficiaryCorporateCustomerName = template.transfer.beneficiary.name
                    $scope.payment.beneficiaryCorporateCustomerTaxId = template.transfer.beneficiary.taxId || ''
                    if $scope.txnType is $scope.PAYMENT_TYPE_PT
                        $scope.payment.credit = template.transfer.credit.iban
                        $scope.name.object =
                            name: template.transfer.beneficiary.name
                            code: template.transfer.credit.iban
                            taxId: template.transfer.beneficiary.taxId

                        $scope.codeLvl =
                            code: template.transfer.budgetLevelCode
                        $scope.code.class =
                            code: template.transfer.budgetClassificationCode
                    else
                        if $scope.txnType != $scope.PAYMENT_TYPE_PV
                            $scope.payment.credit = template.transfer.credit.iban
                    return

                bankInfoFilling = (template) ->
                    if !template.transfer.bankInfo.correspondentAccountIban
                        $scope.filterCodes template.transfer.bankInfo.code, true
                    else
                        $scope.payment.beneficiaryBankCode = template.transfer.bankInfo.code
                        $scope.payment.beneficiaryBankName = template.transfer.bankInfo.name
                        $scope.payment.beneficiaryBankCorrespondentAccountIban = template.transfer.bankInfo.correspondentAccountIban
                        $scope.payment.beneficiaryBankTaxId = template.transfer.bankInfo.taxId
                        $scope.payment.beneficiaryBankSwiftCode = template.transfer.bankInfo.swiftCode

                        $scope.code.object =
                            code: template.transfer.bankInfo.code
                            name: template.transfer.bankInfo.name
                            swiftCode: template.transfer.bankInfo.swiftCode
                            taxId: template.transfer.bankInfo.taxId
                            correspondentAccountIban: template.transfer.bankInfo.correspondentAccountIban
                    # return

                $scope.validCheck = () ->
                    if !$scope.isTemplate
                        $scope.validFields.amount = !$scope.payment.amount
                        $scope.validFields.zeroAmount = parseFloat($scope.payment.amount) <= 0
                        return

                $scope.checkTaxId = () ->
                    if $scope.txnType is $scope.PAYMENT_TYPE_PV
                        $scope.validFields.beneficiaryCorporateCustomerTaxId = !$scope.payment.beneficiaryCorporateCustomerTaxId || ($scope.payment.beneficiaryCorporateCustomerTaxId && $scope.payment.beneficiaryCorporateCustomerTaxId.length < 10)
                    else
                        $scope.validFields.beneficiaryCorporateCustomerTaxId = $scope.payment.beneficiaryCorporateCustomerTaxId  && $scope.payment.beneficiaryCorporateCustomerTaxId.length && $scope.payment.beneficiaryCorporateCustomerTaxId.length < 10

                    return

                validAlias = () ->
                    delete $scope.validFields.alias
                    type = $scope.alias.type && $scope.alias.type.value
                    value = $scope.alias.value
                    valid = !!value
                    if type == $scope.ALIASES.EMAIL
                        valid = /^[0-9a-zA-Z]*[0-9a-zA-Z]|([.!#$%&"*+/-=?^_`\{|}~])+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/.test value
                    if type == $scope.ALIASES.MERCHANTID
                        valid = /^[0-9a-z]{1,35}$/.test value
                    $scope.validFields.aliasType = !type
                    $scope.validFields.aliasValue = !valid
                    return !$scope.validFields.aliasType and !$scope.validFields.aliasValue

                $scope.changeAliasType = () ->
                    delete $scope.validFields.alias
                    resetAliasData()

                setAlias = () ->
                    if $scope.alias.type
                        value = $scope.alias.value
                        if $scope.alias.type.value is 'MOBILE'
                            value = '+994' + $scope.alias.value
                        $scope.payment.ipsTemplateAlias.type = $scope.alias.type.value
                        $scope.payment.ipsTemplateAlias.value = value
                    else
                        $scope.payment.ipsTemplateAlias = {}

                timeOut = null
                $scope.checkAlias = (merchantId) ->
                    
                    setAlias()
                    aliasIsValid = validAlias()

                    if aliasIsValid
                        aliasReq = {
                            getByAlias: true
                            createdFromTemplate: $scope.payment.createdFromTemplate
                            ipsTemplate: $scope.payment.ipsTemplate
                            lang: $rootScope.currentLang.lngu
                            clientRef: $scope.payment.clientRef
                            initiatorCid: $scope.payment.customer.cid
                            debit: $scope.payment.debit.iban
                            currency: $scope.payment.currency
                            ipsTemplateAlias: {
                                type: $scope.payment.ipsTemplateAlias.type
                                value: $scope.payment.ipsTemplateAlias.value
                            }
                        }
                        if !$scope.payment.createdFromTemplate
                            delete aliasReq.ipsTemplate
                        resetADisabled()
                        

                        requestHandler = () ->
                            console.log "requestHandler"
                            $scope.aliasCheckRequest = true
                            apiPayment.getByAlias(aliasReq, $scope.txnType).then (response) ->
                                $scope.aliasCheckRequest = false
                                if response.data.result == 'Failure'
                                    $scope.validFields.alias = response.data.remark
                                    resetAliasData(false, true)
                                else
                                    delete $scope.validFields.alias
                                    if $scope.txnType == 'PI'
                                        delete $scope.validFields.beneficiaryBankCode
                                        delete $scope.validFields.beneficiaryBankName
                                        delete $scope.validFields.beneficiaryBankCorrespondentAccountIban
                                        delete $scope.validFields.beneficiaryBankTaxId
                                        delete $scope.validFields.beneficiaryBankSwiftCode
                                    if $scope.txnType == 'PT'
                                        delete $scope.validFields.beneficiaryCorporateCustomerName
                                        delete $scope.validFields.beneficiaryCorporateCustomerTaxId
                                        delete $scope.validFields.credit

                                    if !response.data.beneficiaryAcc
                                        # $scope.adisabled.credit = false
                                        $scope.validFields.credit = true

                                    if !response.data.beneficiaryName
                                        # $scope.adisabled.beneficiaryCorporateCustomerName = false
                                        $scope.validFields.beneficiaryCorporateCustomerName = true

                                    if !response.data.beneficiaryBankCode
                                        # $scope.adisabled.beneficiaryBankCode = false
                                        $scope.validFields.beneficiaryBankCode = true

                                    if $scope.txnType == $scope.PAYMENT_TYPE_PT && !response.data.beneficiaryTaxId
                                        # $scope.adisabled.beneficiaryCorporateCustomerTaxId = false
                                        $scope.validFields.beneficiaryCorporateCustomerTaxId = true

                                    if !response.data.beneficiaryBankAcc
                                        # $scope.adisabled.beneficiaryBankCorrespondentAccountIban = false
                                        $scope.validFields.beneficiaryBankCorrespondentAccountIban = true

                                    if !response.data.beneficiaryBankName
                                        # $scope.adisabled.beneficiaryBankName = false
                                        $scope.validFields.beneficiaryBankName = true

                                    if !response.data.beneficiaryBankSwift
                                        # $scope.adisabled.beneficiaryBankSwiftCode = false
                                        $scope.validFields.beneficiaryBankSwiftCode = true

                                    if !response.data.beneficiaryBankTaxId
                                        # $scope.adisabled.beneficiaryBankTaxId = false
                                        $scope.validFields.beneficiaryBankTaxId = true

                                    aliasInfo = {
                                        isCopy: true
                                        credit: response.data.beneficiaryAcc
                                        beneficiaryName: response.data.beneficiaryName
                                        beneficiaryTaxId: response.data.beneficiaryTaxId
                                        bankInfo: {
                                            code: response.data.beneficiaryBankCode
                                            correspondentAccountIban: response.data.beneficiaryBankAcc
                                            name: response.data.beneficiaryBankName
                                            swiftCode: response.data.beneficiaryBankSwift
                                            taxId: response.data.beneficiaryBankTaxId
                                         }
                                    }
                                    setPayment(aliasInfo, true)
                            ,
                            (error) ->
                                resetAliasData(false, true)
                                $scope.aliasCheckRequest = false
                                console.log error

                        if merchantId
                            clearTimeout(timeOut)
                            timeOut = setTimeout( ->
                                requestHandler()
                            3000 )
                            startTimer = () ->
                                timeOut
                            startTimer()
                        else
                            requestHandler()
        


                $scope.$watch('payment.clientRef', (val) ->
                    if val
                        $scope.validFields.clientRef = false
                )
                $scope.$watch('payment.beneficiaryCorporateCustomerName', (val) ->
                    if val
                        $scope.validFields.beneficiaryCorporateCustomerName = false
                )
                $scope.$watch('payment.credit', (val) ->
                    if val
                        $scope.validFields.credit = false
                )

                $scope.$on 'PAYMENT_LOCAL_VALIDATE', (event, isTemplate) ->
                    $scope.localValidate isTemplate

                $scope.localValidateLimits = () ->
                    $scope.validFields = {}
                    $scope.validFields.customer = !$scope.payment.customer
                    $scope.validFields.clientRef = !$scope.payment.clientRef
                    $scope.validFields.debit = !$scope.payment.debit || angular.equals($scope.payment.debit, {})

                    isValid = true
                    for key, value of $scope.validFields
                        isValid *= !value
                    $scope.isValid = isValid

                    if !$scope.isValid
                        setTimeout( () ->
                            scrollElement = if $scope.isTemplate then document.querySelector('.dialog__scrollable') else document.documentElement
                            scrollToElement = scrollElement.querySelector('.text-field-error')
                            window.utils.scrollTo scrollElement, scrollToElement, 1000
                        , 500 )


                $scope.localValidate = (isTemplate) ->
                    $scope.validFields = {}
                    $scope.validFields.templateName = false
                    $scope.validFields.customer = !$scope.payment.customer
                    $scope.validFields.clientRef = !$scope.payment.clientRef
                    $scope.validFields.debit = !$scope.payment.debit || angular.equals($scope.payment.debit, {})
                    $scope.validFields.credit = !$scope.payment.credit
                    $scope.validFields.amount = !$scope.payment.amount

                    $scope.validFields.beneficiaryBankCode = !$scope.payment.beneficiaryBankCode
                    $scope.validFields.beneficiaryBankName = !$scope.payment.beneficiaryBankName
                    $scope.validFields.beneficiaryBankCorrespondentAccountIban = !$scope.payment.beneficiaryBankCorrespondentAccountIban
                    $scope.validFields.beneficiaryBankTaxId = !$scope.payment.beneficiaryBankTaxId
                    $scope.validFields.beneficiaryBankSwiftCode = !$scope.payment.beneficiaryBankSwiftCode


                    #????
                    $scope.checkTaxId()

                    if $scope.txnType is $scope.PAYMENT_TYPE_PI && !$scope.payment.getByAlias
                        $scope.validFields.codeObject = !$scope.code.object

                    if $scope.txnType is $scope.PAYMENT_TYPE_PT
                        $scope.validFields.beneficiaryCorporateCustomerTaxId = !$scope.payment.beneficiaryCorporateCustomerTaxId
                        $scope.validFields.codeClass = !$scope.payment.budgetClassificationCode
                        $scope.validFields.codeLvl = !$scope.payment.budgetLevelCode
                    $scope.validFields.paymentDetails = !$scope.payment.narrative || $scope.payment.narrative.length < 1

                    if $scope.txnType != $scope.PAYMENT_TYPE_PT
                        $scope.validFields.beneficiaryCorporateCustomerName = !$scope.payment.beneficiaryCorporateCustomerName

                    $scope.validCheck()

                    validateForTemplate = [
                        'name'
                        'credit'
                        'codeObject'
                        'codeClass'
                        'codeLvl'
                        'beneficiaryCorporateCustomerTaxId'
                        'compare'
                    ]

                    $scope.compareSwift()

                    isValid = true
                    for key, value of $scope.validFields
                        if isTemplate && validateForTemplate.indexOf(key) == -1 #поля нет в массиве для валидации
                            $scope.validFields[key] = false
                            value = false #поля, которые не валидируем задаем валидными
                        isValid *= !value #собираем результат
                        


                    isValid *= $scope.accountChecked
                    $scope.isValid = isValid

                    if $scope.isTemplate
                        $scope.validFields.templateName = !$scope.payment.templateName
                        $scope.isValid = $scope.isValid && !$scope.validFields.templateName

                    #modify
                    # if $scope.code.object && !$scope.payment.beneficiaryBankTaxId
                    #     $scope.payment.beneficiaryBankTaxId = "0000000000"

                    $scope.$emit 'PAYMENT_LOCAL_VALIDATED', $scope.isValid

                    if !$scope.isValid
                        console.log('errors', $scope.validFields)
                        setTimeout( () ->
                            scrollElement = if $scope.isTemplate then document.querySelector('.dialog__scrollable') else document.documentElement
                            scrollToElement = scrollElement.querySelector('.text-field-error')
                            window.utils.scrollTo scrollElement, scrollToElement, 1000
                        , 500 )

                $scope.$on 'storageSet', (event, field, val) ->
                    $scope.payment[field] = val
            ]
        }
]

'use strict'

angular.module('IBR').component 'archiveTable', {

    templateUrl: '/pages/archive/table.html'
    bindings:
        loaded: '='
        loaders: '<'
        constants: '<'
        tConstants: '<'
        filter: '='
        transactions: '='
        batches: '='
        #actions
        search: '='
        getBatch: '='
        pager: '<'
        actionSelect: '&'
        actionSetPage: '&'
        transactionDialog: '='
        batchDialog: '='
        showInfoPendings: '='
    controllerAs: "vm"
    controller: ['$scope', '$timeout', ($scope, $timeout) ->

        vm = this

        #private variables
        searchTimeout = null
        selectedTransactions = []
        selectedBatches = []
        selectedTransactionsInBatch = []

        vm.batches = {}
        vm.allSelected = false
        vm.checked =
            transactions: {}
            batches: {}

        vm.setPage = (page) ->
            vm.actionSetPage()(page)

        vm.searchDelay = () ->
            $timeout.cancel searchTimeout
            searchTimeout = $timeout () ->
                vm.search()
            , 2000
            

        vm.onSelect = () ->
            selectedTransactions = vm.transactions.filter (transaction) ->
                return vm.checked.transactions[transaction.ref]

            selectedBatches = vm.transactions.filter (batch) ->
                return vm.checked.batches[batch.batchId]

            selectedTransactionsInBatch = []
            for batch in selectedBatches
                if vm.batches[batch.batchId]
                    selectedTransactionsInBatch = selectedTransactionsInBatch.concat vm.batches[batch.batchId]

            vm.actionSelect()(selectedTransactions, selectedBatches, selectedTransactionsInBatch)

        vm.onSelectAll = () ->
            if vm.allSelected
                for tr in vm.transactions
                    if tr.ref
                        vm.checked.transactions[tr.ref] = true
                    else if tr.batchId
                        vm.checked.batches[tr.batchId] = true
                vm.onSelect()
            else
                vm.deselectAll()

        vm.deselectAll = () ->
            vm.allSelected = false
            vm.checked =
                transactions: {}
                batches: {}
            selectedTransactions = []
            selectedBatches = []
            selectedTransactionsInBatch = []
            vm.actionSelect()(selectedTransactions, selectedBatches, selectedTransactionsInBatch)

        vm.sortBy = (field) ->
            vm.filter.sortDir = if vm.filter.sortDir is 'ASC' then 'DESC' else 'ASC'
            vm.filter.sort = field
            vm.search()


        vm.getBatch = (batchId) ->


        #income events
        $scope.$on 'DESELECT', () ->
            vm.deselectAll()


        return vm

    ]
}
'use strict'

angular.module('IBR')
# .directive 'authority', [ 'authoritiesFactory','ngIfDirective', (authoritiesFactory,ngIfDirective) ->
#     ngIf = ngIfDirective[0]
#     {
#         transclude: ngIf.transclude
#         priority: ngIf.priority
#         terminal: ngIf.terminal
#         restrict: ngIf.restrict
#         link: (scope, element, attr) ->
#             authoritiesFactory.checkAuthority(attr['function'],attr['permission'])
#                 .then (res)->
#                     # attr.ngIf = res
#                     # ngIf.link.apply ngIf, arguments
#             return
#     }
#   ]
.directive 'authority', [ 'authoritiesFactory', (authoritiesFactory) ->
    {
        link: (scope, element, attr) ->
            authoritiesFactory.checkAuthority attr['function'], attr['permission']
                .then (res)->
                    if res
                        element.show()
                    else
                        element.hide()
            return
    }
]

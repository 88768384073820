'use strict'
angular.module('IBR').controller 'TransactionsCtrl', ['$rootScope', '$scope', '$state', '$stateParams', 'transactionActions', 'appUtils', '$translate', '$q', '$timeout', 'LxDialogService', 'alertModal', 'authoritiesFactory', 'apiAccounts', 'apiPayment', 'apiBulkPayments', 'apiTemplates', 'transactionModal', 'asanModal', 'transactionResultModal', 'apiNotification', 'pagerService', ($rootScope, $scope, $state, $stateParams, transactionActions, appUtils, $translate, $q, $timeout, LxDialogService, alertModal, authoritiesFactory, apiAccounts, apiPayment, apiBulkPayments, apiTemplates, transactionModal, asanModal, transactionResultModal, apiNotification, pagerService) ->
    vm = this
    $scope.vm = vm

    #constants
    NOTE_DIALOG = 'user-note-dial'
    TEMPLATE_NAME_DIALOG = 'user-template-dial'
    DELETE_DIALOG = 'user-delete-dial'

    OPTION_ALL_CUSTOMER = { name: 'ALL' }
    OPTION_ALL_ACCOUNT = { iban: 'ALL' }

    STATUSES = [
        'PENDING_FOR_SIGNATURE'
        'PENDING_FOR_SIGNATURES_1_AND_2'
        'PENDING_FOR_SIGNATURE_2'
        'PENDING_FOR_SIGNATURE_1'
        'PENDING_FOR_RELEASE_TO_CBS'
        'SENT_TO_CBS'
        'SENT_TO_REVISION'
        'DELETED'
        'REJECTED'
        'PENDING_FOR_SIGNATURE_AND_CONFIRMATION'
        'PENDING_FOR_CONFIRMATION'
    ]
    TYPES = [
        "ALL"
        "IT"
        "FX"
        "AZ"
        "XO"
        "XT"
        "XV"
        "FP"
        "RP"
        "PI"
        "PT"
        "PV"
        "SP"
    ]
    CURRENCIES = [
        'ALL'
        'AZN'
        'USD'
        'EUR'
        'RUB'
        'GBP'
    ]
    SOURCES = [
        { value: 'ALL', code: 'ALL' }
        { code: 'BU', value: 'BULK_UPLOAD'}
        { code: 'İO', value: 'INTERNET_OFFICE'}
    ]
    PAGE_SIZES = [10, 50, 100]

    #tmp variables
    tempCustomers = []
    allCanApprove = false

    #scope variables
    vm.showInfoPendings = false
    vm.constants =
        TYPES: TYPES
        STATUSES: STATUSES
        CURRENCIES: CURRENCIES

    vm.tConstants =
        TYPES: TYPES
        SOURCES: SOURCES

    vm.filterDefaults =
        #radio
        status: 'ALL_ACTIVE'
        #table
        page: 1
        onPage: PAGE_SIZES[0]
        currency: 'ALL'
        transactionStatus: 'ALL'
        sort: 'lastModifiedDate'
        sortDir: 'DESC'
        isBulk: false
        #form
        customer: OPTION_ALL_CUSTOMER
        account: OPTION_ALL_ACCOUNT
        type: 'ALL'
        source: SOURCES[0]
        amountMin: ''
        amountMax: ''

    # vm.goTest = () ->
    #     $state.go('archive', {
    #         type: "SP"
    #         iban: "AZ78BRES00380394400262924501"
    #         cid: "629245"
    #         status: "ACCEPTED"
    #     })
    

    vm.loaded = false
    vm.loaders = {}
    vm.actions = {} #API transaction actions

    vm.customers = []
    vm.accounts = []
    vm.customerAccounts = {}
    vm.statusCount = {}
    vm.canApprove = false

    vm.transactions = []
    vm.batches = []

    vm.filter = angular.copy vm.filterDefaults

    vm.pager = {}
    vm.pageSizes = PAGE_SIZES


    vm.selected = {}
    vm.authSchema = null

    vm.selProps = {} #rules for current selection
    vm.actionSet = [] #general allowed actions for page

    if $stateParams.status
        vm.filter.status = $stateParams.status
    else
        vm.filter.status = 'ALL_ACTIVE'

    $scope.$watch 'vm.filter', (val) ->
        $scope.$broadcast 'CHANGE_FILTER'
    , true


    changeCustomers = () ->
        vm.filter.customer = OPTION_ALL_CUSTOMER
        vm.customers = appUtils.filterCustomers tempCustomers
        if vm.customers.length is 1
            vm.filter.customer = vm.customers[0]
        else if vm.customers.length > 1
            vm.customers.unshift OPTION_ALL_CUSTOMER

    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        changeCustomers()

    initTransactions = ->
        apiAccounts.getCustomerAccounts()
            .then (data) ->
                tempCustomers = data.customers
                changeCustomers()
                vm.accounts = data.accounts
                vm.customerAccounts = data.customerAccounts
                vm.canApprove = allCanApprove = data.canApprove
                # vm.getStatusCount()
                vm.search true
            .finally () ->
                vm.loaded = true
        return

    vm.transactions = []
    vm.batches = []


    vm.getBatch = (batch) ->
        apiPayment.getBatchTransactions(batch)

    vm.getBulks = (cids) ->
        console.log cids

    # поиск транзакций
    vm.search = (buttonClick, paginate) ->
        if !paginate
            vm.filter.page = 1 #сбрасываем пагинацию
        vm.loaders.search = true #показываем лодер
        if buttonClick
            vm.getStatusCount()

            if vm.filter.type && vm.filter.type != 'ALL'
                vm.tConstants.TYPES = ['ALL', vm.filter.type]
            else
                vm.tConstants.TYPES = TYPES

            if vm.filter.isBulk
                vm.filter.source = SOURCES[0]
                vm.tConstants.SOURCES = [SOURCES[0], SOURCES[1]]
            else
                vm.tConstants.SOURCES = SOURCES

        if vm.filter.customer.authSchema
            vm.canApprove = vm.filter.customer.authSchema is 'SING_APPROVE' || vm.filter.customer.authSchema is 'SING_APPROVE'
            vm.showInfoPendings = vm.filter.customer.authSchema is 'SIGN1_SIGN2'
        else
            vm.canApprove = allCanApprove

        apiPayment.getActivePayments vm.filter
            .then (res) ->
                vm.actionDeselect() #сбрасываем чекбоксы
                vm.loaders.search = false
                vm.transactions = []
                vm.batches = []

                actionSet = []
                for tr in res.data.records
                    # собираем все разрешенные действия для показа кнопок в футере
                    for action in tr.transactionActionTypeSet
                        if actionSet.indexOf(action) == -1
                            actionSet.push action

                    if tr.batchId && !tr.ref
                        vm.batches.push tr
                    else
                        # modify
                        if tr.code is 'IT' || tr.code is 'FX'
                            tr.beneficiaryName = tr.credit
                        vm.transactions.push tr
                vm.actionSet = actionSet

                vm.pager = pagerService.getPager res.data.totalRecordsCount, vm.filter.page, vm.filter.onPage

                # get count
                result = {}
                result[vm.filter.status] = res.data.totalRecordsCount
                setStatusCount result
            , (error) ->
                vm.loaders.search = false

    vm.refresh = (isOk) ->
        if isOk
            vm.search true, true

    vm.actionSetPage = (page) ->
        vm.filter.page = page
        vm.search false, true

    vm.setPageSize = (size) ->
        vm.filter.onPage = size
        vm.search()


    setStatusCount = (result, reset) ->
        if reset
            ALL = vm.statusCount.ALL_ACTIVE
            vm.statusCount =
                ALL_ACTIVE: ALL
        for status of result
            vm.statusCount[status] = result[status]

    vm.getStatusCount = () ->
        countFilter =
            pageParams:
                pageNumber: 0
                size: 0
            sort:
                fieldName: ''
                order: 'ASC'

        filter = apiPayment.proccessFilters vm.filter
        if filter && filter.length
            countFilter.filterSpecification = filter

        vm.loaders.statusCount = true
        return apiNotification.getNotification countFilter
            .then (response) ->
                result = {}
                response.data.forEach (res) ->
                    result[res.statusNoteType] = res.count
                setStatusCount result, true
                vm.loaders.statusCount = false
            .catch (err) ->
                vm.loaders.statusCount = false


    modalFXErrorShowed = false
    vm.actionSelect = (selectedTransactions, selectedBatches, selectedTransactionsInBatch) ->
        vm.selProps = transactionActions.getSelProps selectedTransactions, selectedBatches, selectedTransactionsInBatch, vm.authSchema
        vm.selectedTransactions = selectedTransactions
        vm.selectedBatches = selectedBatches
        vm.selectedTransactionsInBatch = selectedTransactionsInBatch


        if vm.filter.status is 'PENDING_FOR_SIGNATURE' && !modalFXErrorShowed
            if vm.selProps.exhangeConflict
                alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('INFO.ACTUAL_2'), 'OK', () ->
                    modalFXErrorShowed = true
        

    vm.actionDeselect = () ->
        $rootScope.temporaryTogglers = {}
        $scope.$broadcast 'DESELECT'



    # NOTE DIALOGS AS PROMISES

    noteDialog = {
        open: (type, callback) ->
            vm.templateName = ''
            vm.userNote = ''
            vm.loaders.note = false
            if type is 'NOTE'
                LxDialogService.open NOTE_DIALOG
                vm.sendData = () ->
                    callback vm.userNote
            else if type is 'TEMPLATE'
                vm.sendData = () ->
                    callback vm.templateName
                LxDialogService.open TEMPLATE_NAME_DIALOG
            else if type is 'DELETE'
                vm.sendData = () ->
                    callback vm.userNote
                LxDialogService.open DELETE_DIALOG
        close: () ->
            vm.loaders.note = false
            LxDialogService.close NOTE_DIALOG
            LxDialogService.close TEMPLATE_NAME_DIALOG
            LxDialogService.close DELETE_DIALOG
    }


    ######################### ACTIONS #########################

    # SHOW NOTE DIALOG FOR: 'SEND_TO_CHANGE', 'DELETE', 'SAVE_AS_TEMPLATE'


    #########################################################
    ######################### SINGLE ########################
    #########################################################
    # DOWNLOAD
    vm.actions.download = (type, transaction, lang) ->
        # console.log type, transaction, lang
        $rootScope.loaders[type] = true
        apiPayment.transactionFile(transaction.ref, lang.lngu, type)
            .then (response) ->
                window.utils.downloadFile response
                $rootScope.loaders[type] = false
            .catch () ->
                $rootScope.loaders[type] = false

    # SEND FOR CHANGE
    vm.actions.sendToChange = (transaction, btnKey) ->
        console.log "sendToChange", transaction
        deferred = $q.defer()
        noteDialog.open 'NOTE', (userNote) ->
            data = {
                ref: transaction.ref,
                userNote: userNote
            }
            vm.loaders.note = true
            return apiPayment.sendForChange data
                .then (res) ->
                    noteDialog.close()
                    return transactionResultModal.successModal btnKey
                .then (res) ->
                    deferred.resolve res
                .catch (err) ->
                    vm.loaders.note = false
                    return $q.reject err
        return deferred.promise

    # CREATE TEMPLATE
    vm.actions.saveTemplate = (transaction, btnKey) ->
#        console.log " # CREATE TEMPLATE", transaction
        deferred = $q.defer()
        noteDialog.open 'TEMPLATE', (templateName) ->
            vm.loaders.note = true
#            console.log "CREATE TEMPLATE", transaction

            return apiTemplates.createTemplateFromTransaction transaction, templateName
                .then (res) ->
                    noteDialog.close()
                    return transactionResultModal.successModal btnKey
                .then (res) ->
                    deferred.resolve res
                .catch (err) ->
                    vm.loaders.note = false
        return deferred.promise

    # DELETE
    vm.actions.delete = (transaction, btnKey) ->
        deferred = $q.defer()
        noteDialog.open 'DELETE', (userNote) ->
            data = {
                ref: transaction.ref,
                userNote: userNote
            }
            vm.loaders.note = true
            return apiPayment.delete data
                .then (res) ->
                    noteDialog.close()
                    return transactionResultModal.successModal btnKey
                .then (res) ->
                    deferred.resolve res
                .catch (err) ->
                    vm.loaders.note = false
        return deferred.promise

    #########################################################
    ########################## GROUP ########################
    #########################################################

    vm.actions.downloadGroup = (type, transactions, lang) ->
        $rootScope.loaders[type] = true
        refs = {}
        for tr in transactions
            refs[tr.ref] = tr.ref.substring 6,8

        apiPayment.transactionsFile refs, lang.lngu, type
            .then (response) ->
                window.utils.downloadFile response
                $rootScope.loaders[type] = false
            .catch () ->
                $rootScope.loaders[type] = false

    # DOWNLOAD ONE
    vm.actionDownloadSelected = (type, lang) ->
        vm.actions.downloadGroup type, vm.selectedTransactions, lang


    # GROUP SEND TO CHANGE
    vm.actions.sendGroupToChange = (transactions, batches, btnKey) ->
        deferred = $q.defer()
        noteDialog.open 'NOTE', (userNote) ->
            data = { userNote: userNote }
            if transactions && transactions.length
                data.transferCursors = transactionActions.getCursors transactions
            if batches && batches.length
                data.batchIds = batches.map (batch) ->
                    return batch.batchId

            vm.loaders.note = true
            apiPayment.sendGroupTransactions 'SEND_TO_CHANGE', data
                .then (res) ->
                    noteDialog.close()
                    if res.data.errors && res.data.errors.length || res.data.warnings && res.data.warnings.length
                        return transactionResultModal.groupModal btnKey, transactions, null, res.data, res.status
                    else
                        return transactionResultModal.successModal btnKey
                .then (res) ->
                    deferred.resolve res
                .catch (err) ->
                    vm.loaders.note = false

        return deferred.promise

    # GROUP DELETE
    vm.actions.deleteGroup = (transactions, batches, btnKey) ->
        deferred = $q.defer()
        noteDialog.open 'DELETE', (userNote) ->
            data = { userNote: userNote }
            if transactions && transactions.length
                data.transferCursors = transactionActions.getCursors transactions
            if batches && batches.length
                data.batchIds = batches.map (batch) ->
                    return batch.batchId

            vm.loaders.note = true
            return apiPayment.sendGroupTransactions 'DELETE', data
                .then (res) ->
                    noteDialog.close()
                    if res.data.errors && res.data.errors.length || res.data.warnings && res.data.warnings.length
                        return transactionResultModal.groupModal btnKey, transactions, null, res.data, res.status
                    else
                        return transactionResultModal.successModal btnKey
                .then (res) ->
                    deferred.resolve res
                .catch (err) ->
                    console.log err
                    vm.loaders.note = false
        return deferred.promise



    ########### END ACTIONS ###########
     
    # btnKey передаем в экшн, тк он можен понадобится для интерпретации сообщений при выполнении сервиса (например может быть подписание + отправка)

    vm.transactionAction = (transaction, btnKey) ->
        
        ref = transaction.ref
        code = ref.substring 6, 8

        switch btnKey
            when 'SIGN_1', 'SIGN_2'
                transactionModal.lock btnKey
                return asanModal.signOne transaction, btnKey
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.successModal btnKey
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true
                    .catch () ->
                        transactionModal.unlock()
                        

            when 'SIGN_1__SEND_TO_CBS', 'SIGN_2__SEND_TO_CBS'
                signAction = btnKey.substring 0, 6
                transactionModal.lock btnKey
                return asanModal.signOne transaction, signAction
                    .then (res) ->
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs ref
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.sendToBank btnKey, null, null, res.data, res.status
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.unlock()
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                transactionModal.closeInfo()
                                vm.refresh true

            when 'SEND_TO_CBS'
                transactionModal.lock btnKey
                return apiPayment.sendToCbs ref
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.sendToBank btnKey, null, null, res.data, res.status
                    .then (isOk) ->
                        if isOk
                            transactionModal.closeInfo()
                            vm.refresh true
                    .catch (err) ->
                        console.log err
                        transactionModal.unlock()

            when 'REJECT'
                transactionModal.lock btnKey
                return apiPayment.rejectPayment data
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.successModal btnKey
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true
                    .catch () ->
                        transactionModal.unlock()
                        

            when 'APPROVE'
                transactionModal.lock btnKey
                return apiPayment.approve ref
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.successModal btnKey
                    .then () ->
                        transactionModal.closeInfo()
                        vm.refresh true
                    .catch () ->
                        transactionModal.unlock()
                        

            when 'APPROVE__SEND_TO_CBS'
                transactionModal.lock btnKey
                return apiPayment.approve ref
                    .then (res) ->
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs ref
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.sendToBank btnKey, null, null, res.data, res.status
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.unlock()
                        
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_APPROVED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                transactionModal.closeInfo()
                                vm.refresh true

            when 'SEND_TO_CHANGE'
                vm.actions.sendToChange transaction, btnKey
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true

            when 'CHANGE'
                
                transactionModal.closeInfo()
                switch code
                    when 'FX'
                        $state.go 'exchange-payment', { payment: transaction }
                    when 'IT'
                        $state.go 'internal-payment', { payment: transaction }
                    when 'AZ'
                        $state.go 'domestic-payment', { payment: transaction }
                    when 'XO'
                        $state.go 'domestic-payment', { payment: transaction }
                    when 'XT'
                        $state.go 'domestic-payment', { payment: transaction }
                    when 'XV'
                        $state.go 'domestic-payment', { payment: transaction }

                    when 'PI'
                        $state.go 'instant-payment', { payment: transaction }
                    when 'PT'
                        $state.go 'instant-payment', { payment: transaction }
                    when 'PV'
                        $state.go 'instant-payment', { payment: transaction }

                    when 'FP'
                        $state.go 'swift-payment', { payment: transaction }
                    when 'RP'
                        $state.go 'swift-payment', { payment: transaction }
                    when 'SP'
                        $state.go 'salary-payment', { payment: transaction }
                return
            when 'SAVE_AS_TEMPLATE'
                vm.actions.saveTemplate transaction, btnKey
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.closeInfo()
                        
            when 'DELETE'
                vm.actions.delete transaction, btnKey
                    .then (isOk) ->
                        transactionModal.closeInfo()
                        vm.refresh isOk
                    .catch (err) ->
                        transactionModal.closeInfo()
                        


    isFail = (res) ->
        return res.data.errors && res.data.errors.length || res.data.warnings && res.data.warnings.length

    vm.groupAction = (transactions, batches, btnKey) ->

        data = {}
        batchId = null
        if transactions && transactions.length
            data.transferCursors = transactionActions.getCursors transactions
        if batches && batches.length
            batchId = batches[0].batchId
            data.batchIds = batches.map (batch) ->
                return batch.batchId

        switch btnKey
            when 'SIGN_1', 'SIGN_2'
                transactionModal.lock btnKey
                asanModal.signGroup transactions, batchId, btnKey
                    .then (res) ->
                        console.log('signGroup success', res)
                        transactionModal.unlock()
                        
                        if res.data.errors && res.data.errors.length || res.data.warnings && res.data.warnings.length
                            return transactionResultModal.groupModal btnKey, transactions, batches, res.data, res.status
                        else
                            return transactionResultModal.successModal btnKey
                    .then (isOk) ->
                        console.log 'isOk', isOk
                        if isOk
                            transactionModal.closeGroup()
                            vm.refresh true
                    .catch (err) ->
                        console.log('err', err)
                        transactionModal.unlock()
                        

            when 'SIGN_1__SEND_TO_CBS', 'SIGN_2__SEND_TO_CBS'
                transactionModal.lock btnKey
                signAction = btnKey.substring 0, 6
                asanModal.signGroup transactions, batchId, signAction
                    .then (res) ->
                        $rootScope.customAlert = true
                        # console.log res
                        if batches && batches.length
                            btrs = batches[0].transactions
                            data = {
                                transferCursors: transactionActions.getCursors btrs
                            }
                        return apiPayment.sendGroupTransactions 'SEND_TO_CBS', data
                    .then (res) ->
                        # console.log res
                        transactionModal.unlock()
                        return transactionResultModal.sendToBank btnKey, transactions, batches, res.data, res.status
                    .then (res) ->
                        
                        transactionModal.closeGroup()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.unlock()
                        
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                transactionModal.closeGroup()
                                vm.refresh true

            when 'SEND_TO_CBS'
                transactionModal.lock btnKey
                return apiPayment.sendGroupTransactions 'SEND_TO_CBS', data
                    .then (res) ->
                        transactionModal.unlock()
                        return transactionResultModal.sendToBank btnKey, transactions, batches, res.data, res.status
                    .then (res) ->
                        transactionModal.closeGroup()
                        vm.refresh true
                    .catch (err) ->
                        console.log err
                        transactionModal.unlock()
                        

            when 'APPROVE'
                transactionModal.lock btnKey
                return apiPayment.sendGroupTransactions 'APPROVE', data
                    .then (res) ->
                        transactionModal.unlock()
                        if isFail res
                            return transactionResultModal.groupModal btnKey, transactions, batches, res.data, res.status
                        else
                            return transactionResultModal.successModal btnKey
                    .then (res) ->
                        transactionModal.closeGroup()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.unlock()
                        

            when 'APPROVE__SEND_TO_CBS'
                transactionModal.lock btnKey
                return apiPayment.sendGroupTransactions 'APPROVE', data
                    .then (res) ->
                        # console.log 'APPROVE', res
                        # $rootScope.customAlert = true
                        if isFail res
                            return res
                        else
                            return apiPayment.sendGroupTransactions 'SEND_TO_CBS', data
                    .then (res) ->
                        transactionModal.unlock()
                        if isFail res
                            return transactionResultModal.groupModal btnKey, transactions, batches, res.data, res.status
                        else
                            return transactionResultModal.sendToBank btnKey, transactions, batches, res.data, res.status
                    .then (res) ->
                        transactionModal.closeGroup()
                        
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.unlock()
                        
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_APPROVED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                transactionModal.closeGroup()
                                vm.refresh true

            when 'SEND_TO_CHANGE'
                vm.actions.sendGroupToChange transactions, batches, btnKey
                    .then () ->
                        transactionModal.closeGroup()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.closeGroup()
                        
            when 'DELETE'
                vm.actions.deleteGroup transactions, batches, btnKey
                    .then () ->
                        transactionModal.closeGroup()
                        vm.refresh true
                    .catch (err) ->
                        transactionModal.closeGroup()
                        

    ########### END COMMON ACTIONS ###########


    ###### TRANSACTION DIALOG #####
    vm.transactionDialog = (transaction, btnKey) ->
        if !transaction
            transaction = vm.selectedTransactions[0]
        transactionModal.openInfo transaction, btnKey, vm.transactionAction, vm.actions.download


    # если выбрано несколько пачек и/или несколько трензакций
    vm.transactionsDialog = (btnKey) ->
        transactionModal.openGroup vm.selectedTransactions, vm.selectedBatches, btnKey, vm.groupAction
        
    vm.batchDialog = (batch, btnKey) ->
        batches = if batch then [ batch ] else vm.selectedBatches #потому что вызвалось если только один
        transactionModal.openGroup null, batches, btnKey, vm.groupAction

    ###### END TRANSACTION DIALOG #####



    #controller onload
    authoritiesFactory.checkAuthority('ACCOUNTS', 'READ').then (response) ->
        if !response
            vm.loaded = true
            vm.transactionsLoaded = true
            alertModal.showError $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('ERRORS.ACCOUNTS_READ'), 'Ok'
        else
            initTransactions() #initialization


    $scope.$on '$destroy', () ->
        transactionModal.unlock()
        
    return vm
]
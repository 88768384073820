'use strict'

angular.module('IBR')
.factory 'apiOperations', [ '$http', ($http) ->
    urlBase = CONFIG.RESOURCE_SERVER + "/accounts"
    apiOperations = {}

    apiOperations.getOperations = ->
        $http.get urlBase + '/activities'

    apiOperations.getFilteredOperations = (cidArr)->
        $http.post urlBase + '/activities/search', cidArr

    apiOperations
]

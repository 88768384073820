angular.module('IBR').factory 'fileValidation', ['$translate', 'apiPayment', ($translate, apiPayment) ->
    that = {}

    that.creditIban = ''
    
    PATTERN = {
        number: /^([0-9]*)$/
        all: /^([0-9a-zA-Zа-яА-ЯёЁəöüıçşğÇÖÜĞİŞƏ\s.,:()\-\+]*)$/
        amount: /^\d+(\.\d{1,2})?$/
        name: /^([a-zA-Zа-яА-ЯёЁəöüıçşğÇÖÜĞİŞƏ\s\-]*)$/
        note: /^([0-9a-zA-Zа-яА-ЯəöüıçşğÇÖÜĞİŞƏ\s.,:()\-\+]*)$/
        base: /^([0-9a-zA-ZəöüıçşğÇÖÜĞİŞƏ\s.,:()\-\+]*)$/
    }

    getTransferType = (cellValue) ->
        if ['IT', 'AZ', 'XO', 'XT', 'XV'].includes cellValue
            return cellValue
        cellValue = cellValue.toString().trim()
        return switch cellValue
            when 'Внутрибанк. перевод' || 'Internal transfer' || 'Bankdaxili köçürmə' then 'IT'
            when 'Перевод по AZİPS' || 'Payment by AZIPS' || 'Köçürmə AZİPS-lə' then 'AZ'
            when 'Перевод по XÖHKS' || 'Payment by XÖHKS' || 'Köçürmə XÖHKS-lə' then 'XO'
            when 'Бюдж. платёж (ГАК)' || 'Budget payment (STA)' || 'Büdcə ödənişi (DXA)' then 'XT'
            when 'Бюдж. платёж (НДС)' || 'Budget payment (VAT)' || 'Büdcə ödənişi (ƏDV)' then 'XV'
            else ''


    that.filterBulk = (rows) ->
        for row in rows
            if row.transferCode && row.transferCode.value
                row.transferType.value = row.transferCode.value
                delete row.transferCode
            else if row.transferType.value
                row.transferType.value = getTransferType row.transferType.value
                
            if row.transferType.value == 'IT'
                delete row.beneficiaryName
                delete row.beneficiaryTaxId
                delete row.beneficiaryBankCode
                delete row.budgetClassificationCode
                delete row.budgetLevelCode
                delete row.additionalInfo

            if row.transferType.value == 'AZ'
                delete row.budgetClassificationCode
                delete row.budgetLevelCode

            if row.transferType.value == 'XO'
                delete row.budgetClassificationCode
                delete row.budgetLevelCode
                delete row.additionalInfo
                
            if row.transferType.value == 'XT'
                delete row.beneficiaryName
                delete row.beneficiaryTaxId

                domesticData = apiPayment.getDomesticData 'XT'
                for prop of domesticData
                    row[prop] = { value: domesticData[prop] }
                row.beneficiaryBankName = { value: "Dovlet Xezinedarligi Agentliyi" }

                delete row.additionalInfo

            if row.transferType.value == 'XV'
                delete row.beneficiaryAccountIban
                
                domesticData = apiPayment.getDomesticData 'XV'
                for prop of domesticData
                    row[prop] = { value: domesticData[prop] }
                row.beneficiaryBankName = { value: "Dovlet Xezinedarligi Agentliyi (EDV)" }

                delete row.budgetClassificationCode
                delete row.budgetLevelCode
                delete row.additionalInfo

    that.checkIban = (iban, type) ->
        # if type == 'IT' && iban.indexOf('BRES') != 4
        #     return 'CELL_VALIDATION.INVALID_ACCOUNT'
            
        if iban && iban.length is 28

            # isOwn = false
            # for a in that.accounts
            #     if iban == a.iban
            #         return 'CELL_VALIDATION.INVALID_THIRD_PERSON_ACC'
            if that.debitIban == iban
                return 'CELL_VALIDATION.INVALID_THIRD_PERSON_ACC'

            # if that.debitIban
            #     if type == 'IT' && that.debitIban.substring(14,17) != iban.substring(14,17)
            #         return 'CELL_VALIDATION.INVALID_CURRENCY'

            return null
        else
            return 'CELL_VALIDATION.INVALID_ACCOUNT'

    RowErrors = () ->
        this.errors = []
        return this

    RowErrors::add = (trKey, key, row) ->
        if !row[key]
            return
        this.errors.push $translate.instant(trKey).replace('$', '['+row[key].cell+']')

    validateBulkRow = (row) ->
        errors = new RowErrors()
        #### VALIDATION ####
        #transferType
        type = row.transferType && row.transferType.value

        # transferType
        if type
            if !['IT', 'AZ', 'XO', 'XT', 'XV'].includes type
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'transferType', row
        else
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'transferType', row

        # clientRef
        # if row.clientRef && row.clientRef.value
            

        # beneficiaryName
        if row.beneficiaryName && row.beneficiaryName.value
            if !PATTERN.base.test row.beneficiaryName.value
                errors.add 'CELL_VALIDATION.INVALID_CHARACTERS', 'beneficiaryName', row
            if row.beneficiaryName.value.length > 35
                errors.add 'CELL_VALIDATION.LIMIT_CHARACTERS', 'beneficiaryName', row
        else if ['AZ', 'XO', 'XV'].includes type
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'beneficiaryName', row

        # beneficiaryAccountIban
        if row.beneficiaryAccountIban && row.beneficiaryAccountIban.value
            ibanError = that.checkIban row.beneficiaryAccountIban.value, type
            if ibanError
                errors.add ibanError, 'beneficiaryAccountIban', row
        else if ['IT', 'AZ', 'XO', 'XT'].includes type
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'beneficiaryAccountIban', row

        # beneficiaryTaxId
        if row.beneficiaryTaxId && row.beneficiaryTaxId.value
            if row.beneficiaryTaxId.value.length != 10
                errors.add 'CELL_VALIDATION.INVALID_TAXID', 'beneficiaryTaxId', row
            else if !PATTERN.number.test row.beneficiaryTaxId.value
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'beneficiaryTaxId', row
        else if type == 'XV'
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'beneficiaryTaxId', row

        # beneficiaryBankCode
        if row.beneficiaryBankCode && row.beneficiaryBankCode.value
            val = row.beneficiaryBankCode.value
            if val.length != 6
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'beneficiaryBankCode', row

            one = ['AZ', 'XO'].includes(type) && (val == '210005' || val == '210027')
            two = type == 'XT' && row.beneficiaryBankCode.value != '210005'
            three = type == 'XV' && row.beneficiaryBankCode.value != '210027'

            if one || two || three
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'beneficiaryBankCode', row

        else if ['AZ', 'XO'].includes type
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'beneficiaryBankCode', row

        # budgetClassificationCode
        if row.budgetClassificationCode && row.budgetClassificationCode.value
            # console.log 'budgetClassificationCode'
        else if type == 'XT'
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'budgetClassificationCode', row

        # budgetLevelCode
        if row.budgetLevelCode && row.budgetLevelCode.value
            # console.log 'budgetLevelCode'
        else if type == 'XT'
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'budgetLevelCode', row

        # additionalInfo
        if row.additionalInfo && row.additionalInfo.value
            if !PATTERN.base.test row.additionalInfo.value
                errors.add 'CELL_VALIDATION.INVALID_CHARACTERS', 'additionalInfo', row
            if row.additionalInfo.value.length > 195
                errors.add 'CELL_VALIDATION.LIMIT_CHARACTERS', 'additionalInfo', row

        # debitAmount
        if row.debitAmount && row.debitAmount.value
            if !PATTERN.amount.test row.debitAmount.value
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'debitAmount', row
            else if type == 'XO' && row.debitAmount.value > 40000
                errors.add 'CELL_VALIDATION.INVALID_AMOUNT', 'debitAmount', row
        else
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'debitAmount', row

        # narrative
        if row.narrative && row.narrative.value
            if row.narrative.value.length > 140 || (type == 'XT' && row.narrative.value.length > 70)
                errors.add 'CELL_VALIDATION.LIMIT_CHARACTERS', 'narrative', row
            if !PATTERN.base.test row.narrative.value
                errors.add 'CELL_VALIDATION.INVALID_CHARACTERS', 'narrative', row
        else
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'narrative', row

        # userNote
        if row.userNote && row.userNote.value
            if !PATTERN.note.test row.userNote.value
                errors.add 'CELL_VALIDATION.INVALID_CHARACTERS', 'userNote', row
            if row.userNote.value.length > 180
                errors.add 'CELL_VALIDATION.LIMIT_CHARACTERS', 'userNote', row

        return errors.errors


    validateSalaryRow = (row) ->
        errors = new RowErrors()
        #name
        if row.name && row.name.value
            if row.name.value.length > 70
                errors.add 'CELL_VALIDATION.LIMIT_CHARACTERS', 'name', row
        #iban
        if row.iban && row.iban.value
            ibanError = that.checkIban(row.iban.value)
            if ibanError
                errors.add ibanError, 'iban', row
        else
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'iban', row

        #summ
        if row.summ && row.summ.value #|| isNaN row.summ
            val = row.summ.value.toString().replace(',', '.')
            if isNaN val
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'summ', row
            if !PATTERN.amount.test Number(val).toFixed(2)
                errors.add 'CELL_VALIDATION.INVALID_FORMAT', 'summ', row
        else
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'summ', row

        #base
        if row.base && row.base.value
            if row.base.value.length > 140
                errors.add 'CELL_VALIDATION.LIMIT_CHARACTERS', 'base', row
            if !PATTERN.base.test row.base.value
                errors.add 'CELL_VALIDATION.INVALID_CHARACTERS', 'base', row
        else
            errors.add 'CELL_VALIDATION.INVALID_REQUIRED', 'base', row

        #note
        if row.note && row.note.value
            if row.note.value.length > 180
                errors.add  'CELL_VALIDATION.LIMIT_CHARACTERS', 'note', row
            if !PATTERN.all.test row.note.value
                errors.add 'CELL_VALIDATION.INVALID_CHARACTERS', 'note', row

        return errors.errors

    that.validateBulk = (dataset) ->
        return dataset.reduce (prev, current, index) ->
            return prev.concat(validateBulkRow current)
        , []

    that.validateSalary = (dataset) ->
        return dataset.reduce (prev, current) ->
            return prev.concat(validateSalaryRow current)
        , []

    return that
]
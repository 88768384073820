'use strict'

angular.module('IBR')
.factory 'apiUser', [ '$http','$rootScope', ($http, $rootScope) ->
    urlBase = (CONFIG.USE_MOCK || CONFIG.RESOURCE_SERVER) + "/user-profile"

    that = {}
    that.setUser = () ->
        $http.get urlBase
            .then (response) ->
                user = JSON.stringify response.data
                localStorage.setItem 'user', user
                $rootScope.currentUser = response.data
                return

    that.getUser = () ->
        $http.get urlBase

    that
]

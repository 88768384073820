'use strict'

angular.module('IBR')
.directive 'preventReturn', ['$timeout', ($timeout) ->
  {
    restrict:'A'
    link: (scope, element, attributes)->
      # element.attr "autocomplete", "off"
      element.on('keypress', (e) ->
        if e.which == 13
          e.preventDefault()
      )
  }
]

'use strict'

angular.module('IBR')
.filter 'templateFilter', () ->
    (templates, search) ->
        if !search
            return templates
        return templates.filter((template)->
            return (
                if search.name then template.name.toLowerCase().indexOf(search.name.toLowerCase()) > -1 else true &&
                if search.iban then template.transfer.credit.iban.toLowerCase().indexOf(search.iban.toLowerCase()) > -1 else true &&
                if search.beneficiaryName then template.transfer.beneficiary.name.toLowerCase().indexOf(search.beneficiaryName.toLowerCase()) > -1 else true &&
                if search.bankInfoName then template.transfer.bankInfo.name.toLowerCase().indexOf(search.bankInfoName.toLowerCase()) > -1 else true
            )
        )

'use strict'

getSelectedKeys = (obj) ->
    keys = []
    for prop of obj
        if obj[prop]
            keys.push prop
    return keys

angular.module('IBR')
.controller 'AcquiringCtrl', [ '$scope', '$state', '$rootScope', '$q', '$timeout', '$translate', 'apiAcquiring', 'alertModal', ($scope, $state, $rootScope, $q, $timeout, $translate, apiAcquiring, alertModal) ->

    vm = this

    vm.loaded = false
    vm.isFeaching = false
    vm.languages = 
        az: 'AZE'
        en: 'ENG'
        ru: 'RUS'
    vm.errors =
        client: true
        terminals: true
        merchants: true

    vm.showInactive = false
    vm.noActiveTerminals = false

    vm.nickname = ''
    vm.nicknameUuid = ''

    vm.filterData = {}
    vm.exportFilter =
        startDate: ''
        stopDate: ''
        lang:  vm.languages[localStorage.getItem 'language']
        cid: null
        iban: null
        merchants: []
        terminals: {}
    
    vm.cUuid = null # current selected client cid_iban

    vm.dateRange = new DateRange({
        yearLimit: true,
        moreNow: true
    })

    #new
    vm.tFilters = {}
    vm.tFilters_strict = {}
    vm.selected = 
        clients: {}
        merchants: {}
        terminals: {}
        
    getItem = (uuid) ->
        for c in vm.clients
            if c.uuid == uuid
                return c
            if c.merchants
                for m in c.merchants
                    if m.uuid == uuid
                        return m
                    for t in m.terminals
                        if t.uuid == uuid
                            return t
        return null


    initServices = ->
        customers = {}
        cids = $rootScope.customers.map (customer) ->
            customers[customer.cid] = customer
            return customer.cid
        # LOAD CLIENTS
        vm.loaded = false
        # console.log('initServices')
        apiAcquiring.getAccounts(cids)
            .then (res)->
                vm.loaded = true
                vm.clients = []
                for client in res.data
                    cid = client.corporateCustomer.cid
                    client.uuid = cid + '_' + client.iban
                    if customers.hasOwnProperty cid
                        client.corporateCustomer = customers[cid]
                    vm.clients.push client

    activeTerminalsCount = 0
    inActiveTerminalsCount = 0
    vm.toggleClient = (client) ->
        # console.log('toggleClient')
        if client.active
            client.active = false
            return
        client.active = false
        client.loading = true
        # LOAD MERCHANTS AND TERMINALS
        allTerminals = {}
        return apiAcquiring.getMerchants(client.corporateCustomer.cid, client.iban)
            .then (res) ->
                vm.loaded = true
                client.active = true
                client.loading = false
                client.merchants = []
                for merchant in res.data
                    merchant.uuid = client.uuid + '_' + merchant.id
                    if !merchant.terminals then merchant.terminals = []
                    for terminal in merchant.terminals
                        terminal.uuid = merchant.uuid + '_' + terminal.id

                        if terminal.status == 'ACTIVE'
                            activeTerminalsCount++
                        else
                            inActiveTerminalsCount++

                    client.merchants.push merchant

                # calc noActiveTerminals
                vm.noActiveTerminals = activeTerminalsCount == 0

            .catch (err) ->
                vm.loaded = true
                client.loading = false

    updateTerminalSelect = () ->
        # console.log('updateTerminalSelect')
        terminals = []
        selectedTerminals = []
        if vm.cUuid
            tUuids = getSelectedKeys vm.selected.terminals
            for client in vm.clients
                if vm.cUuid is client.uuid && client.merchants
                    for merchant in client.merchants
                        if merchant.terminals
                            for terminal in merchant.terminals
                                if terminal.status is 'ACTIVE' || vm.showInactive
                                    terminal.selected = tUuids.indexOf(terminal.uuid) > -1
                                    if terminal.selected
                                        selectedTerminals.push(terminal.customName || terminal.name)
                                    terminals.push terminal

        vm.filterData.allSelected = terminals.length && terminals.length is selectedTerminals.length
        vm.filterData.terminals = terminals
        vm.filterData.selectedTerminals = selectedTerminals.join(',')

        # console.log('updateTerminalSelect')

    vm.toggleShowInactive = ->
        # calc noActiveTerminals
        # console.log('toggleShowInactive')
        # vm.selected.merchants = {}
        #filter active
        for c in vm.clients
            if c.merchants
                for m in c.merchants
                    if c.merchants
                        for t in m.terminals
                            if !vm.showInactive && vm.selected.terminals[t.uuid] && t.status != 'ACTIVE'
                                vm.selected.terminals[t.uuid] = false
        getSelected()

    vm.updateSelection = ->
        # console.log('updateSelection')
        if vm.filterData.terminals
            for terminal in vm.filterData.terminals
                vm.selected.terminals[terminal.uuid] = vm.filterData.allSelected

    vm.toggleSelectAll = ->
        # console.log('toggleSelectAll')
        vm.selected.terminals = {}
        if vm.filterData.terminals
            for terminal in vm.filterData.terminals
                vm.selected.terminals[terminal.uuid] = vm.filterData.allSelected

    getSelected = (selectChanged) ->
        # console.log('getSelected')
        cUuids = []
        merchants = []
        merchantsTerminals = {}

        for mUuid of vm.selected.merchants
            if vm.selected.merchants[mUuid]
                tmp = mUuid.split('_')
                cUuid = tmp[0]+'_'+tmp[1]
                mId = tmp[2]
                if cUuids.indexOf(cUuid) is -1
                    cUuids.push cUuid
                merchants.push mId

        tLength = 0
        for tUuid of vm.selected.terminals
            if vm.selected.terminals[tUuid]
                tLength++
                tmp = tUuid.split('_')
                cUuid = tmp[0]+'_'+tmp[1]
                mId = tmp[2]
                tId = tmp[3]
                if cUuids.indexOf(cUuid) is -1
                    cUuids.push cUuid
                if !merchantsTerminals[mId] then merchantsTerminals[mId] = []
                merchantsTerminals[mId].push tId

        vm.errors = 
            client: cUuids.length != 1
            merchants: !merchants.length
            terminals: !tLength

        # console.log(vm.errors)

        vm.exportFilter.merchants = merchants
        vm.exportFilter.terminals = merchantsTerminals

        # if one client
        vm.cUuid = null
        if !vm.errors.client
            vm.cUuid = cUuids[0]
            cData = vm.cUuid.split('_')
            vm.exportFilter.cid = cData[0]
            vm.exportFilter.iban = cData[1]
        if !selectChanged
            updateTerminalSelect()
        

    ### BIND CHECKBOXES ###

    selectTerminal = (uuid) ->
        # console.log('selectTerminal -> getSelected')
        getSelected()

    selectMerchant = (uuid) ->
        val = vm.selected.merchants[uuid]
        for c in vm.clients
            if c.merchants
                for m in c.merchants
                    if m.uuid is uuid
                        for t in m.terminals
                            if t.status is 'ACTIVE' || vm.showInactive
                                vm.selected.terminals[t.uuid] = val

        # console.log('selectMerchant -> getSelected')
        getSelected()

    selectClient = (uuid) ->
        # console.log('selectClient')
        val = vm.selected.clients[uuid]
        for c in vm.clients
            if c.uuid is uuid
                if c.merchants
                    for m in c.merchants
                        vm.selected.merchants[m.uuid] = val
                        for t in m.terminals
                            if t.status is 'ACTIVE' || vm.showInactive
                                vm.selected.terminals[t.uuid] = val
        # console.log('selectClient -> getSelected')
        getSelected()

    vm.selectTerminal = (uuid) ->
        selectTerminal(uuid)

    vm.selectMerchant = (uuid) ->
        selectMerchant(uuid)

    vm.selectClient = (uuid) ->
        client = vm.clients.find (c) ->
            return c.uuid is uuid
        if client.merchants 
            selectClient(uuid)
        else
            vm.toggleClient(client).then () ->
                selectClient(uuid)

    ### BIND CHECKBOXES END ###
    vm.pdfMerchants = () ->
        vm.isFeaching = true
        apiAcquiring.pdfMerchants vm.exportFilter
            .then () ->
                vm.isFeaching = false
            , () ->
                vm.isFeaching = false
    vm.pdfTerminals = () ->
        vm.isFeaching = true
        apiAcquiring.pdfTerminals vm.exportFilter
            .then () ->
                vm.isFeaching = false
            , () ->
                vm.isFeaching = false
                
    vm.xmlTerminals = () ->
        vm.isFeaching = true
        apiAcquiring.xmlTerminals vm.exportFilter
            .then () ->
                vm.isFeaching = false
            , () ->
                vm.isFeaching = false

    vm.editNickname = ($event, uuid, name) ->
        $event.stopPropagation()
        vm.nicknameUuid = uuid
        vm.nickname = name

    vm.setClientNickname = ->
        id = vm.nicknameUuid.split('_')[1]
        item = getItem(vm.nicknameUuid)
        if item.customName != vm.nickname
            apiAcquiring.setClientNickname(id, vm.nickname).then () ->
                alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('MESSAGES.CHANGE_ACCEPTED'), $translate.instant('MESSAGES.OK_INFO'), (answer) ->
                    return
                item.customName = vm.nickname
                vm.nicknameUuid = ''
                vm.nickname = ''
        else
            vm.nicknameUuid = ''
            vm.nickname = ''
    
    vm.setMerchantNickname = ->
        id = vm.nicknameUuid.split('_')[2]
        item = getItem(vm.nicknameUuid)
        if item.customName != vm.nickname
            apiAcquiring.setMerchantNickname(id, vm.nickname).then () ->
                alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('MESSAGES.CHANGE_ACCEPTED'), $translate.instant('MESSAGES.OK_INFO'), (answer) ->
                    return
                item.customName = vm.nickname
                vm.nicknameUuid = ''
                vm.nickname = ''
        else
            vm.nicknameUuid = ''
            vm.nickname = ''

    
    vm.setTerminalNickname = ->
        id = vm.nicknameUuid.split('_')[3]
        item = getItem(vm.nicknameUuid)
        if item.customName != vm.nickname
            apiAcquiring.setTerminalNickname(id, vm.nickname).then () ->
                alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('MESSAGES.CHANGE_ACCEPTED'), $translate.instant('MESSAGES.OK_INFO'), (answer) ->
                    return
                item.customName = vm.nickname
                vm.nicknameUuid = ''
                vm.nickname = ''
        else
            vm.nicknameUuid = ''
            vm.nickname = ''


    vm.showAccountStatement = (iban, customer) ->
        $state.go('statement', {iban: iban, customer: customer})
        return

    
    if $rootScope.loaded
        initServices()
    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        initServices()
    $rootScope.$on 'USER_LOADED', () ->
        initServices()

    return
]

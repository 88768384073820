'use strict'

angular.module('IBR')
.controller 'WidgetCreditCtrl', [ '$rootScope', '$scope', '$state', '$filter', 'apiCredits', 'authoritiesFactory', 'alertModal', 'LxDialogService', ($rootScope, $scope, $state, $filter, apiCredits, authoritiesFactory, alertModal, LxDialogService) ->

    vm = this

    vm.credits = {}
    vm.loaded = false
    hideStatuses = ['CLOSED']
    vm.isOpened = false
    vm.btnLoaders = {}

    vm.customerCaption = true
    vm.headerClass = 'relative'

    vm.openCredit = (accountNo) ->
        $state.go('credits', {accountNo: accountNo})
        return

    processCredits = (credits) ->
        customerCredits = {}
        credits = $filter('orderBy')(credits, 'corporateCustomer.name')
        angular.forEach credits, (credit) ->
            credit.isInput = false
            if !(credit.status in hideStatuses)
                cid = credit.corporateCustomer.cid
                if !customerCredits[cid]
                    customerCredits[cid] = {}
                    customerCredits[cid].credits = []
                    customerCredits[cid].corporateCustomer = credit.corporateCustomer
                    delete credit.corporateCustomer
                customerCredits[cid].credits.push credit
                return
        customerCredits

    initCredits = () ->
        if $rootScope.currentUser.corporateCustomers.length <= 1
            vm.customerCaption = false

        apiCredits.getCredits().then (response) ->
            vm.credits = processCredits response.data
            vm.loaded = true
            return
        ,(error) ->
            vm.loaded = true
            vm.error = true
            return
        return

    vm.updateCreditName = (credit, name, accountNo) ->
        if (name != undefined) && (name || credit.nickname) && (name != credit.nickname)
            apiCredits.setNickname(accountNo, name).then (response) ->
                credit.nickname = name
                alertModal.showMessage $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('MESSAGES.CHANGE_ACCEPTED'), $filter('translate')('MESSAGES.OK_INFO'), (answer) ->
                    return
                return
        return

    vm.updateCreditNameEnter = (credit, keyEvent, name, accountNo) ->
        if keyEvent.which == 13
            vm.updateCreditName(credit, name, accountNo)
        return

    vm.loadCredits = () ->
        vm.isOpened = !vm.isOpened
        if vm.isOpened
            authoritiesFactory.checkAuthority 'CREDITS_WIDGET', 'READ'
                .then (res) ->
                    if res then initCredits()
        return

    vm.isInput = (credit) ->
        credit.isInput = !credit.isInput
        return

    # DEBT REPAYMENT SHEDULE
    vm.showSchedule = (number, cid) ->
        $scope.$broadcast 'SHOW_CREDIT_SCHEDULE', number, cid


    return
]

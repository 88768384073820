'use strict'
angular.module('IBR').component 'messageBlock', {
    template: '<div id="message-block" ng-if="vm.visible" class="message-block" ng-class="{\'static\': vm.static}"><div class="message-wrapper"><div class="icon"></div><div ng-bind-html="vm.html"></div></div></div>'
    controllerAs: "vm"
    controller: ['$scope', '$rootScope', '$sce', '$timeout', ($scope, $rootScope, $sce, $timeout) ->
        vm = this
        vm.html = ''
        vm.static = false
        vm.visible = window.CONFIG.SHOW_MESSAGE
        timeout = null

        window.addEventListener 'resize', () ->
            $timeout.cancel(timeout)
            timeout = $timeout(align, 100)

        align = () ->
            authBox = document.getElementById('auth-window').getBoundingClientRect()
            messageBox = document.getElementById('message-block').getBoundingClientRect()

            topSpace = (window.outerHeight - authBox.height)*0.5 - 45
            vm.static = messageBox.height + 60 > topSpace
            
        getHtml = (lng) ->
            if window.CONFIG.MESSAGE
                text = window.CONFIG.MESSAGE[lng]

                html = text
                for key in text.match(/\[(.*?)\]/g)
                    group = key.replace(/(\[|\])/g, '').split('|')
                    html = html.replace(key, '<a target="_blank" href="'+group[1]+'">'+group[0]+'</a>')

                vm.html = $sce.trustAsHtml html
                timeout = $timeout(align, 100)

        $rootScope.$on '$translateChangeSuccess', (event, data) ->
            $scope.html = getHtml data.language
            return


        return vm
    ]
}
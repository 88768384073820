'use strict'

angular.module('IBR')
.directive 'currencyFormat', [ '$filter', ($filter) ->
    {
        restrict:'A'
        require:'ngModel'
        link: (scope,elem)->

            elem.on 'keydown', (event) ->
                val =  elem.val()
                pointIndex = val.indexOf('.')
                caretIndex = elem[0].selectionStart
                vals = val.split '.'

                if vals[0] && vals[0].replace(/\s/g, '').length == 16 && ( pointIndex == -1 || caretIndex <= pointIndex )
                    if event.which >= 48 && event.which <= 57 #numbers
                        event.preventDefault()


            elem.inputmask("numeric",{
                radixPoint: "."
                groupSeparator: " "
                digits: 2
                autoGroup: true
                prefix: ''
                rightAlign: false
                allowMinus: false
                oncleared: ()->
                    self.value = ''
            })
    }
  ]

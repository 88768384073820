'use strict'

angular.module('IBR')
.controller 'CreditsCtrl', [ '$rootScope', '$scope', '$stateParams', '$filter', 'apiCredits', 'authoritiesFactory', 'alertModal', 'LxDialogService', ($rootScope, $scope, $stateParams, $filter, apiCredits, authoritiesFactory, alertModal, LxDialogService) ->

    vm = this

    DEFAULT_CURRENCIES_TYPE_ALL = {
        value: "",
        title: "ALL"
    }

    CURRENCIES = [
        DEFAULT_CURRENCIES_TYPE_ALL,
        {value: 'AZN', title: 'AZN'},
        {value: 'USD', title: 'USD'},
        {value: 'RUB', title: 'RUB'},
        {value: 'EUR', title: 'EUR'},
        {value: 'GBP', title: 'GBP'},
    ]

    vm.credits = {}
    vm.loaded = false
    vm.btnLoaders = {}
    hideStatuses = ['CLOSED']
    vm.currentCredits = {}
    vm.creditFilter = {
        currency: DEFAULT_CURRENCIES_TYPE_ALL
    }

    vm.currencies = CURRENCIES
    vm.customerCaption = true
    vm.headerClass = 'relative'

    setPassedCredit = (accountNo) ->
        accountNo = $stateParams.accountNo
        if accountNo
            if !vm.currentCredits[accountNo]
                vm.currentCredits[accountNo] = true
            vm.creditFilter.accountNo = accountNo
        vm.loaded = true
        return

    processCredits = (credits) ->
        if $rootScope.currentUser
            if $rootScope.currentUser.corporateCustomers
                if $rootScope.currentUser.corporateCustomers.length <= 1
                    vm.customerCaption = false
                    
        customerCredits = {}
        credits = $filter('orderBy')(credits, 'corporateCustomer.name')
        angular.forEach credits, (credit) ->
            credit.isInput = false
            cid = credit.corporateCustomer.cid
            if !customerCredits[cid]
                customerCredits[cid] = {}
                customerCredits[cid].credits = []
                customerCredits[cid].blockedCredits = []
                customerCredits[cid].corporateCustomer = credit.corporateCustomer
                delete credit.corporateCustomer
            if !(credit.status in hideStatuses)
                customerCredits[cid].credits.push credit
            else
                customerCredits[cid].blockedCredits.push credit
            return
        customerCredits

    initCredits = () ->
        apiCredits.getCredits().then (response) ->
            vm.credits = processCredits response.data
            setPassedCredit()
            return
        , (error) ->
            vm.loaded = true
            return
        return

    initCredits()

    vm.setCurrentCredit = (accountNo) ->
        $rootScope.$broadcast 'TOGGLERS_UPDATED'
        if vm.currentCredits[accountNo]
            delete vm.currentCredits[accountNo]
        else
            vm.currentCredits[accountNo] = true
        return

    vm.updateCreditName = (credit, name, accountNo) ->
        if (name != undefined) && (name || credit.nickname) && (name != credit.nickname)
            apiCredits.setNickname(accountNo, name).then (response) ->
                credit.nickname = name
                alertModal.showMessage $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('MESSAGES.CHANGE_ACCEPTED'), $filter('translate')('MESSAGES.OK_INFO'), (answer) ->
                    return
                return
        return

    vm.updateCreditNameEnter = (credit, keyEvent, name, accountNo) ->
        if keyEvent.which == 13
            vm.updateCreditName(credit, name, accountNo)
        return

    vm.isInput = (credit) ->
        credit.isInput = !credit.isInput
        return


    # DEBT REPAYMENT SHEDULE
    vm.showSchedule = (number, cid) ->
        $scope.$broadcast 'SHOW_CREDIT_SCHEDULE', number, cid


    return
]

'use-strict'

angular.module('IBR')
.directive 'enterPrevent', () ->
  {
    restrict: "A"
    link: (scope, element) ->
      element.on("keypress", (e)->
        if e.which is 13
          e.preventDefault()
          e.stopPropagation()
      )
  }



'use strict'

angular.module('IBR')
.factory 'appUtils', [ '$rootScope', ( $rootScope ) ->

    that = {}

    #filter selected customers
    that.filterCustomers = (customers) ->
        out = []
        selected = angular.copy $rootScope.selectedCorporateCustomers
        if !selected || !selected.length
            out = angular.copy customers
        else
            cids = customers.map (customer) ->
                return customer.cid
            out = selected.filter (customer) ->
                return cids.indexOf(customer.cid) > -1
        return out

    #filter account by selected customers
    that.filterAccounts = (accounts, currentCustomers) ->
        customers = that.filterCustomers currentCustomers
        cids = customers.map (customer) ->
            return customer.cid
        return accounts.filter (account) ->
            return cids.indexOf(account.corporateCustomer.cid) > -1

    that.accountsByCid = (accounts, cid) ->
        return accounts.filter (account) ->
            return account.corporateCustomer.cid is cid
                
    that
]
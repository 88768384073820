'use strict'

angular.module('IBR')
    .directive 'exchangePaymentForm', ['$rootScope', '$translate', '$timeout', '$filter', 'LxDialogService', 'apiPayment', 'apiAccounts', ($rootScope, $translate, $timeout, $filter, LxDialogService, apiPayment, apiAccounts ) ->
        {
            resctict: 'E'
            templateUrl: '/components/exchange-payment-form.html'
            scope: {
                payment: '=?'
                loaded: '=?'
                isLocked: '=?'
                isTemplate: '=?'
            }
            controller: [ '$scope', 'authoritiesFactory', 'alertModal', ($scope, authoritiesFactory, alertModal) ->

                $scope.payment = if angular.isDefined($scope.payment) then $scope.payment else {}

                PAYMENT_CODE = 'FX'

                $scope.isEditing = false
                $scope.accounts = []
                $scope.customers = []
                $scope.validFields = {}
                clientRefLoadingTrigger = false
                hideStatuses = ['CLOSED']
                lockFilterProcess = false

                # 1625
                $scope.rateText = ''
                $scope.payment.calcConvType = 'D' # debit -> credit
                # end 1625
                

                $scope.accountsTo = []

                $scope.filterProcess = () ->
                    if $scope.payment.customer && !lockFilterProcess
                        $scope.accountsTo = []
                        delete $scope.payment.credit
                        if $scope.credAccounts
                            for i in $scope.credAccounts
                                eqCid = $scope.payment.customer.id is i.customerId
                                eqCnum = i.iban && $scope.payment.debit.iban && i.iban.slice(20, 26) is $scope.payment.debit.iban.slice(20, 26)
                                eqIban = i.iban is $scope.payment.debit.iban
                                eqCurrency = i.currency is $scope.payment.debit.currency
                                isCurrent = i.type == 'CURRENT'
                                if !eqIban && !eqCurrency && eqCid && eqCnum && isCurrent
                                    $scope.accountsTo.push(i)
                    return

                allCustomers = []
                allAccounts = []
                processAccounts = (accounts) ->
                    customerAccounts = {}
                    accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
                    for account in accounts
                        cid = account.corporateCustomer.cid
                        if !(account.status in hideStatuses) && account.iban
                            if !customerAccounts[cid]
                                allCustomers.push account.corporateCustomer
                                customerAccounts[cid] = {}
                                customerAccounts[cid].accounts = []
                                customerAccounts[cid].corporateCustomer = account.corporateCustomer
                            customerAccounts[cid].accounts.push account
                    filterBySelected()
                    # $scope.isReady = $scope.customers.length && accounts && accounts.length
                    customerAccounts

                filterBySelected = () ->
                    $scope.customers = $filter('filterSelectedCustomers')(allCustomers)
                    $scope.accounts = $filter('selectedCustomers')(allAccounts)
                    if $scope.customers.length == 1
                        $scope.payment.customer = $scope.customers[0]
                    # $scope.isReady = $scope.customers.length

                $rootScope.$on 'SELECT_CUSTOMERS', () ->
                    filterBySelected()

                initPaymentForm = ->
                    apiAccounts.getPaymentAccounts(PAYMENT_CODE)
                    .then (response) ->
                        $scope.accounts = processAccounts response.data
                        allAccounts = angular.copy $scope.accounts, []
                        if $scope.customers.length == 1
                            $scope.payment.customer = $scope.customers[0]
                        apiPayment.getCreditAccounts().then (response) ->
                            $scope.credAccounts = response.data
                            $scope.loaded = true
                            $scope.$emit 'FORM_READY'
                        .catch (error) ->
                            $scope.loaded = true
                            $scope.$emit 'FORM_READY'
                            return
                        return


                authoritiesFactory.checkAuthority 'EXCHANGE', 'CREATE'
                    .then (res) ->
                        if res
                            initPaymentForm()
                        else
                            $scope.loaded = true
                            $scope.isLocked = true
                            alertModal.showError $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('ERRORS.ACCESS_DENIED'), 'Ok'

                getClientRef = ->
                    clientRefLoadingTrigger = true
                    apiPayment.getRef(PAYMENT_CODE, $scope.payment.customer.cid).then (response) ->
                        if response.data.clientRef
                            $scope.payment.clientRef = response.data.clientRef
                        clientRefLoadingTrigger = false
                        return
                    , (error) ->
                        clientRefLoadingTrigger = false
                        return
                    return

                # 1625
                $scope.changeAmount = (type) ->
                    $scope.resetErrors()
                    $scope.payment.narrative = ''
                    $scope.rateText = ''
                    if type == 'debit'
                        $scope.payment.purchaseAmount = ''
                        $scope.payment.calcConvType = 'D'
                    else if type == 'credit'
                        $scope.payment.amount = ''
                        $scope.payment.calcConvType = 'C'

                $scope.calc =->
                    currFrom = { currency: '', amount: 0 }
                    currTo = { currency: '', amount: 0 }
                    if $scope.payment.calcConvType is 'D' && $scope.payment.amount
                        currFrom = { currency: $scope.payment.debit.currency, amount:  Number $scope.payment.amount.replace(/\s/g,'') }
                        currTo.currency = $scope.payment.credit.currency
                    if $scope.payment.calcConvType is 'C' && $scope.payment.purchaseAmount
                        currTo = { currency: $scope.payment.credit.currency, amount: Number $scope.payment.purchaseAmount.replace(/\s/g,'') }
                        currFrom.currency = $scope.payment.debit.currency
                    
                    if currFrom.amount is 0
                        delete currFrom.amount
                    if currTo.amount is 0
                        delete currTo.amount
                    
                    $scope.fetchingConversion = true
                    apiPayment.calcExchangeRate(currFrom, currTo, $scope.payment.customer.cid)
                        .then (response) ->
                            $scope.fetchingConversion = false
                            d = response.data
                            $scope.rateText = d.directConversionRate.multiplicity + ' ' + d.directConversionRate.baseCurrency + ' - ' + d.directConversionRate.rate + ' ' + d.directConversionRate.targetCurrency + ' (' + d.indirectConversionRate.multiplicity + ' ' + d.indirectConversionRate.baseCurrency + ' - ' + d.indirectConversionRate.rate + ' ' + d.indirectConversionRate.targetCurrency + ')'
                            
                            paymentAmount = $filter('sumformat')(d.fromAmount)
                            purchaseAmount = $filter('sumformat')(d.toAmount)

                            $scope.payment.amount = paymentAmount
                            $scope.payment.purchaseAmount = purchaseAmount

                            $scope.narrativeText = 'SATILAN MƏBLƏĞ ' + paymentAmount  + ' ' + $scope.payment.debit.currency  + ', ALINAN MƏBLƏĞ  ' + purchaseAmount + ' ' + $scope.payment.credit.currency + ', MƏZƏNNƏ: ' + $scope.rateText
                            $scope.payment.narrative = $scope.narrativeText
                        , (err) ->
                            $scope.fetchingConversion = false

                $scope.checkNarrative = () ->
                    if $scope.payment.narrative.length < $scope.narrativeText.length
                        $scope.payment.narrative = $scope.narrativeText
                    else if $scope.payment.narrative.indexOf($scope.narrativeText) < 0
                        $scope.payment.narrative = $scope.narrativeText + $scope.payment.narrative
                # end 1625


                $scope.switchAccount = (type) ->
                    if type == 'self'
                        $scope.otherAccount = !$scope.otherAccount
                    else
                        $scope.selfAccount = !$scope.selfAccount
                    delete $scope.payment.credit
                    return

                $scope.$watch 'payment', ( ->
                    if !(angular.equals($scope.validFields,{}))
                        for own key of $scope.payment
                            if $scope.validFields[key] is true and $scope.payment[key]
                                $scope.validFields[key]= false
                ), true

                # $scope.$watch 'paymentData', ( ->
                #     if !(angular.equals($scope.validFields,{}))
                #         for own key of $scope.paymentData
                #             if $scope.validFields[key] is true and $scope.paymentData[key]
                #                 $scope.validFields[key]= false
                # ),true

                $scope.$watch 'payment.customer', ( ->
                    $scope.payment.clientRef = ''
                    if $scope.payment.customer && $scope.payment.customer.cid && !clientRefLoadingTrigger
                        $scope.accountsTo = []
                        $scope.payment.debit = ''
                        $scope.payment.amount = ''
                        $scope.payment.purchaseAmount = ''
                        $scope.payment.selfAccount = {}
                        $scope.payment.rateText = ''
                        $scope.payment.narrative = ''
                    return
                ), true

                $scope.$watch 'payment.debit', ( ->
                    if $scope.payment.debit && $scope.payment.debit.iban && !$scope.payment.clientRef && !$scope.isEditing
                        getClientRef()
                    $scope.payment.amount = ''
                    $scope.payment.purchaseAmount = ''
                    $scope.payment.rateText = ''
                    $scope.payment.narrative = ''
                ), true

                $scope.$watch 'payment.credit', ( ->
                    $scope.payment.amount = ''
                    $scope.payment.purchaseAmount = ''
                    $scope.payment.rateText = ''
                    $scope.payment.narrative = ''
                ), true


                resetFields = () ->
                    $scope.validFields = {}
                    $scope.rateText = ''
                    $scope.payment.narrative = ''
                    for own key of $scope.payment
                        if (key != 'clientRef' && key != 'customer' && key != 'debit' && key != 'selfAccount')
                            delete $scope.payment[key]
                        else if (key == 'debit') || (key == 'selfAccount')
                            $scope.payment[key] = ''
                    return

                $scope.$on 'PAYMENT_RESET', (event, clearRef) ->
                    if $scope.isEditing
                        clearRef = true
                        $scope.isEditing = false
                    resetFields()
                    if clearRef
                        $scope.payment.clientRef = ''

                $scope.$on 'setPayment', (event, payment) ->
                    $scope.isEditing = true
                    if payment.isCopy
                        $scope.isEditing = false
                        delete payment.isCopy

                    # resetFields(true)
                    cid = payment.initiatorCid || payment.corporateCustomerInitiatorCid

                    $timeout( ->
                        $scope.payment.ref = payment.ref || ''
                        for customer in $scope.customers
                            if customer.cid == cid
                                $scope.payment.customer = customer
                    , 100 )

                    $timeout( ->
                        clientRefLoadingTrigger = true
                        lockFilterProcess = true
                        for account in $scope.accounts[cid].accounts
                            if payment.debit == account.iban
                                $scope.payment.debit = account

                        for account in $scope.accountsTo
                            if payment.credit == account.iban
                                $scope.payment.credit = account

                        if payment.clientRef
                            $scope.payment.clientRef = payment.clientRef

                        $scope.payment.notes = payment.notes || ''
                        # 1625
                        $scope.payment.calcConvType = payment.calcConvType || 'D'

                    , 200 )

                    $timeout( ->
                        lockFilterProcess = false
                        if payment.debitAmount
                            $scope.payment.amount = payment.debitAmount + ''
                        if payment.creditAmount
                            $scope.payment.purchaseAmount = payment.creditAmount + ''
                    , 300)

                    return

                $scope.resetErrors = () ->
                    if parseFloat($scope.payment.purchaseAmount) >= 0 || parseFloat($scope.payment.amount) >= 0
                        $scope.validFields.purchaseAmountNotNull = false
                        $scope.validFields.saleAmountNotNull = false
                    if $scope.payment.amount || $scope.payment.purchaseAmount
                        $scope.validFields.saleAmount = false
                        $scope.validFields.purchaseAmount = false


                $scope.$on 'PAYMENT_LOCAL_VALIDATE', () ->

                    $scope.validFields.customer = !$scope.payment.customer
                    $scope.validFields.clientRef = !$scope.payment.clientRef
                    $scope.validFields.debit = !$scope.payment.debit
                    $scope.validFields.credit = !$scope.payment.credit

                    # 1625 comment
                    # $scope.validFields.saleAmount = $scope.validFields.purchaseAmount = !$scope.payment.amount && !$scope.payment.purchaseAmount
                    # 1625 uncomment
                    $scope.validFields.saleAmount = !$scope.payment.amount
                    $scope.validFields.purchaseAmount = !$scope.payment.purchaseAmount
                    # end 1625

                    $scope.validFields.saleAmountNotNull = $scope.payment.amount && parseFloat($scope.payment.amount) <= 0
                    $scope.validFields.purchaseAmountNotNull = $scope.payment.purchaseAmount && parseFloat($scope.payment.purchaseAmount) <= 0

                    isValid = true
                    for key, value of $scope.validFields
                        isValid *= !value #собираем результат
                    $scope.$emit 'PAYMENT_LOCAL_VALIDATED', isValid

                    if !isValid
                        setTimeout( () ->
                            scrollElement = if $scope.isTemplate then document.querySelector('.dialog__scrollable') else document.documentElement
                            scrollToElement = scrollElement.querySelector('.text-field-error')
                            window.utils.scrollTo scrollElement, scrollToElement, 1000
                        , 500 )

                $scope.$on 'storageSet', (event, field, val) ->
                    $scope.payment[field] = val

            ]
        }
    ]

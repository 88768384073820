'use strict'

angular.module('IBR')
.controller 'ServicesCtrl', [ '$rootScope','$scope', '$filter', '$q', '$timeout', '$translate','apiProfile','authoritiesFactory','apiAccounts', 'apiCredits', 'depositsFactory', 'apiServices', 'LxDialogService','alertModal', ($rootScope,$scope,$filter,$q,$timeout,$translate,apiProfile,authoritiesFactory,apiAccounts,apiCredits,depositsFactory,apiServices,LxDialogService,alertModal) ->

    vm = this
    vm.loaded = false
    vm.enabled = false
    catalogLoaded = false #flag for catalog loading
    configurationData = [] #raw notification data for current cid
    contactsIsUpdating = false # flag for prevent request if actual contacts changed
    isDeletingRow = null # flag for prevent request after set limitAmount: 0 for empty row
    vm.catalogLoaded = false
    vm.btnsDisabled = false

    vm.isActiveAddSMS = true
    vm.isActiveAddEMAIL = true

    vm.isContactsOpen = true #widget flag
    vm.contacts = []
    vm.actualSMS = [] #SMS contacts for notification table
    vm.actualEMAIL = [] #EMAIL contacts for notification table

    vm.langs = ["RUS", "AZE", "ENG"]
    vm.configurations = {}
    vm.catalog = {}

    vm.loadingId = null #flag row loading indication
    vm.loadingIban = null #flag row loading indication

    catalogInitial =
        SYSTEM: []
        ACCOUNTS: []
        DEPOSITS: []
        CREDITS: []
        accounts: []
        deposits: []
        credits: []

    catalogTmp = angular.copy catalogInitial
    vm.catalog = angular.copy catalogInitial

    EMAIL_PATTERN = /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/
    PHONE_PATTERN = /^(50|51|55|70|77)[0-9]{7}$/

    TYPES_WITH_ACCOUNTS = [
        'TRANSFER_SENT_TO_REVISION_NOTIFICATION'
        'TRANSFER_READY_FOR_CONFIRMATION_NOTIFICATION'
        'TRANSFER_ACCEPTED_NOTIFICATION'
        'TRANSFER_REJECTED_NOTIFICATION'
    ]

    TYPES_WITH_CREDITS = [
        'CREDITS_CHANGED_NOTIFICATION'
    ]

    TYPES_WITH_DEPOSITS = [
        'DEPOSITS_CHANGED_NOTIFICATION'
    ]

    vm.TYPES_WITH_LIMIT = [
        'TRANSFER_SENT_TO_REVISION_NOTIFICATION'
        'TRANSFER_READY_FOR_CONFIRMATION_NOTIFICATION'
        'TRANSFER_ACCEPTED_NOTIFICATION'
        'TRANSFER_REJECTED_NOTIFICATION'
    ]

    # deleteByUuid = (array, uuid) ->
    #     for item, index in array
    #         if item.uuid is uuid
    #             array.splice index, 1

    # updateByuuid = (array, uuid, data) ->
    #     for item, index in array
    #         if item.uuid is uuid
    #             array.splice index, 1
    #             array[index] = data

    getContactsByUuids = (uuids) ->
        return vm.actualSMS.concat(vm.actualEMAIL).filter (item) ->
            uuids.indexOf(item.uuid) > -1

    mergeContacts = (contacts, types) ->
        uuids = []
        if types
            for type in types
                if contacts && contacts[type]
                    for contact in contacts[type]
                        uuids.push contact.uuid
        return uuids

    deleteContact = (index, contact) ->
        contact.isLoading = true
        if !contact.uuid
            vm.contacts.splice index, 1
            checkAddBtns()
        else
            vm.btnsDisabled = true
            $rootScope.tmpString = contact.contactType
            apiProfile.deleteContact contact.uuid
                .then (res) ->
                    vm.btnsDisabled = false
                    vm.contacts.splice index, 1
                    mapActualContacts()
                    checkAddBtns()
                , (err) ->
                    contact.isLoading = false
                    vm.btnsDisabled = false
                    
    checkContactUniq = () ->
        tmp = {}
        for contact in vm.contacts
            tmp[contact.contactValue] = true

        return Object.keys(tmp).length is vm.contacts.length
   
    mapActualContacts = () ->
        contactsIsUpdating = true
        vm.actualSMS = angular.copy(vm.contacts).filter (item) ->
            return item && item.uuid && item.contactType == 'SMS'
        vm.actualEMAIL = angular.copy(vm.contacts).filter (item) ->
            return item && item.uuid && item.contactType == 'EMAIL'
        mapConfiguration()
        $timeout( ->
            contactsIsUpdating = false
        , 200 )

    vm.mapConfiguration = () ->
        mapConfiguration()

    mapConfiguration = () ->
        vm.configurations = {}
        for row in configurationData
            rowData = angular.copy row    
            rowData.id = rowData.notificationId
            contacts = getContactsByUuids row.contacts
            contactsGroup = {}

            contactTypes = []
            for contact in contacts
                if !contactsGroup[contact.contactType]
                    contactsGroup[contact.contactType] = []
                contactsGroup[contact.contactType].push contact

            contactTypes = Object.keys contactsGroup
            if rowData.accountNumber
                if !vm.configurations[rowData.id]
                    vm.configurations[rowData.id] = {}
                    
                vm.configurations[rowData.id][rowData.accountNumber] = 
                    notificationId: rowData.notificationId
                    uuid: rowData.uuid
                    contactTypes: contactTypes
                    contacts: contactsGroup
                    lang: rowData.lang
                    # limitAmount: 0

                if rowData.uuid
                    $rootScope.togglersNotifications['notification_'+rowData.id] = true
                
                if rowData.limitAmount
                    vm.configurations[rowData.id][rowData.accountNumber].limitAmount = rowData.limitAmount

            else
                rowData.contactTypes = contactTypes
                rowData.contacts = contactsGroup
                vm.configurations[rowData.id] = rowData


    checkAddBtns = () ->
        smsLength = 0
        emailLength = 0
        for c in vm.contacts
            if c.contactType is 'SMS'
                smsLength++
            if c.contactType is 'EMAIL'
                emailLength++

        vm.isActiveAddSMS = smsLength < 10 && vm.isEditableContacts
        vm.isActiveAddEMAIL = emailLength < 10 && vm.isEditableContacts
    
    vm.toggleContacts = () ->
        vm.isContactsOpen = !vm.isContactsOpen

    vm.pushContact = (type) ->
        vm.contacts.push { contactType: type, contactValue: '', localId: utils.makeid(6) }
        checkAddBtns()

    vm.editContact = (contact) ->
        contact.isEditing = true
        contact.error = false

    tmpContactValue = ''
    vm.startEditContact = (contact) ->
        tmpContactValue = contact.contactValue

    vm.cancelEditContact = (contact) ->
        contact.isEditing = false
        contact.error = false
        contact.contactValue = tmpContactValue

    vm.saveContact = (contact, index) -> 
        index = index || vm.contacts.indexOf contact
        if contact.contactType is 'EMAIL'
            contact.error = !EMAIL_PATTERN.test contact.contactValue
        else if contact.contactType is 'SMS'
            contact.error = !PHONE_PATTERN.test contact.contactValue
        contact.notuniq = !checkContactUniq()

        if contact.error || contact.notuniq
            return
        vm.btnsDisabled = true
        contact.isLoading = true

        apiProfile.saveContact contact
            .then (res) ->
                vm.contacts[index] = res.data.contact
                vm.contacts[index].isMain = contact.isMain
                contact.isLoading = false
                vm.btnsDisabled = false
                mapActualContacts()
            , (err) ->
                contact.isLoading = false
                vm.btnsDisabled = false

    vm.deleteContact = (contact) ->
        index = vm.contacts.indexOf contact
        contact = vm.contacts[index]
        if contact.contactValue
            delMsg = $translate.instant('SERVICES.DELETE_THE_NUBER')
            delMsg = delMsg.replace '$', $filter('phoneformat')(contact.contactValue)
            alertModal.confirm $translate.instant('DIALOG.ATTENTION'), delMsg, () ->
                deleteContact(index, contact)
        else
            deleteContact(index, contact)

    

    vm.getConfig = () ->
        if catalogLoaded && vm.currentCustomer
            vm.catalogLoaded = false
            apiServices.getConfig vm.currentCustomer.cid 
                .then (res) ->
                    $timeout () ->
                        vm.catalogLoaded = true
                    , 500
                    configurationData = res.data
                    mapConfiguration()

                , (err) ->
                    vm.catalogLoaded = true
                    console.log err


    vm.saveConfig = (id, notificationType, iban) ->

        if contactsIsUpdating || isDeletingRow || !vm.catalogLoaded
            return
            
        currentRow = if iban then vm.configurations[id][iban] else vm.configurations[id]

        if !currentRow
            return

        row = angular.copy currentRow
        row.notificationId = id
        currentRow.isEditing = true

        #validation
        #check fields 
        
        isSMS = currentRow.contacts && currentRow.contacts.SMS && currentRow.contacts.SMS.length
        isEMAIL = currentRow.contacts && currentRow.contacts.EMAIL && currentRow.contacts.EMAIL.length
        isContactTypes = currentRow.contactTypes && currentRow.contactTypes.length
        isTypeSMS = isContactTypes && currentRow.contactTypes.indexOf('SMS') > -1
        isTypeEMAIL = isContactTypes && currentRow.contactTypes.indexOf('EMAIL') > -1

        if !isTypeEMAIL && currentRow.contacts then currentRow.contacts.EMAIL = []
        if !isTypeSMS && currentRow.contacts then currentRow.contacts.SMS = []

        isEmpty = (currentRow.limitAmount is '' || currentRow.limitAmount is undefined) && !isContactTypes && !isSMS && !isEMAIL && !currentRow.lang
        # isEmpty = !isContactTypes && !isSMS && !isEMAIL && !currentRow.lang
        
        currentRow.error = {}
        currentRow.error.contactTypes = !isContactTypes
        currentRow.error.sms = isTypeSMS && !isSMS
        currentRow.error.smsLength = isSMS && currentRow.contacts.SMS.length > 2
        currentRow.error.email = isTypeEMAIL && !isEMAIL
        currentRow.error.emailLength = isEMAIL && currentRow.contacts.EMAIL.length > 2
        currentRow.error.lang = !currentRow.lang
        currentRow.error.limitAmount = vm.TYPES_WITH_LIMIT.indexOf(notificationType) > -1 && (currentRow.limitAmount is '' || currentRow.limitAmount is undefined)

        isValid = true
        for prop of currentRow.error
            isValid = isValid && !currentRow.error[prop]

        if isEmpty
            currentRow.error = {}
            currentRow.isEditing = false

        #validation end

        row.cid = vm.currentCustomer.cid
        row.contacts = mergeContacts row.contacts, row.contactTypes
        if iban
            row.accountNumber = iban
        delete row.contactTypes
        delete row.error
        delete row.isEditing
        if row.limitAmount
            row.limitAmount = row.limitAmount.replace(/\s/g, '')
        #action

        if isValid
            vm.loadingId = row.notificationId
            vm.loadingIban = row.accountNumber
            apiServices.saveConfig(row)
                .then (res)->
                    # update configurationData
                    if row.uuid #exist
                        for item, index in configurationData
                            if item.uuid is res.data.uuid
                                configurationData[index] = res.data
                    else #is new
                        configurationData.push res.data

                    currentRow.isEditing = false
                    currentRow.uuid = res.data.uuid
                    vm.loadingId = null
                    vm.loadingIban = null
                , (err) ->
                    vm.loadingId = null
                    vm.loadingIban = null

    clearConfig = (id, iban) ->
        isDeletingRow = true
        currentRow = if iban then vm.configurations[id][iban] else vm.configurations[id]
        deleteRow = (id, iban) ->
            if iban
                vm.configurations[id][iban] = { limitAmount: 0 }
            else
                delete vm.configurations[id]
            $timeout () ->
                isDeletingRow = false
            , 100

        if !currentRow.uuid
            deleteRow(id, iban)
            return
        currentRow.notificationId = id
        vm.loadingId = currentRow.notificationId
        vm.loadingIban = currentRow.accountNumber
        apiServices.clearConfig(currentRow).then (res)->
            currentRow.isEditing = false
            for item, index in configurationData
                if item && (item.uuid is currentRow.uuid)
                    configurationData.splice index, 1
                    break
            deleteRow(id, iban)
            vm.loadingId = null
            vm.loadingIban = null
        return

    vm.clearConfig = (id, iban) ->
        alertModal.confirm(
            $translate.instant('DIALOG.ATTENTION'),
            $translate.instant('DIALOG.CLEAR_INFO'),
            () -> clearConfig(id, iban),
            $translate.instant('DIALOG.CLEAR'),
            $translate.instant('DIALOG.CANCEL'),
        )

    getContacts = () ->
        defaultContactInfo = $rootScope.currentUser.contactInfo
        return apiProfile.getContacts()
            .then (response) ->
                contacts = []
                if response.data.contacts.SMS
                    contacts = contacts.concat response.data.contacts.SMS
                if response.data.contacts.EMAIL
                    contacts = contacts.concat response.data.contacts.EMAIL

                defaultSMSindex = -1
                defaultEMAILindex = -1
                contacts.forEach (contact, index) ->
                    if contact.contactValue == defaultContactInfo.phoneNumber
                        defaultSMSindex = index
                    if contact.contactValue == defaultContactInfo.email
                        defaultEMAILindex = index

                if defaultSMSindex is -1
                    defaultContact = 
                        contactType: 'SMS'
                        isMain: true
                        contactValue: defaultContactInfo.phoneNumber
                    length = contacts.push defaultContact
                    vm.saveContact defaultContact, length-1
                else
                    contacts[defaultSMSindex].isMain = true

                if defaultEMAILindex is -1
                    defaultContact = 
                        contactType: 'EMAIL'
                        isMain: true
                        contactValue: defaultContactInfo.email
                    length = contacts.push defaultContact
                    vm.saveContact defaultContact, length-1
                else
                    contacts[defaultEMAILindex].isMain = true

                vm.contacts = contacts
                mapActualContacts()
                vm.loaded = true


    initCatalog = () ->
        return $q.all [ apiServices.getCatalog(), apiAccounts.getAccounts(), apiCredits.getCredits(), depositsFactory.getDeposits() ]
            .then (responses) ->
                catalogTmp = angular.copy catalogInitial
                catalogTmp.length = responses[0].data && responses[0].data.length

                for item in responses[0].data
                    if TYPES_WITH_ACCOUNTS.indexOf(item.notificationType) > -1
                        catalogTmp.ACCOUNTS.push item
                    else if TYPES_WITH_DEPOSITS.indexOf(item.notificationType) > -1
                        catalogTmp.DEPOSITS.push item
                    else if TYPES_WITH_CREDITS.indexOf(item.notificationType) > -1
                        catalogTmp.CREDITS.push item
                    else
                        catalogTmp.SYSTEM.push item

                catalogTmp.accounts = responses[1].data.map (account) ->
                    return {
                        customName: account.customName
                        cid: account.corporateCustomer.cid
                        iban: account.iban
                        currency: account.currency
                    }
                catalogTmp.credits = responses[2].data.map (account) ->
                    return {
                        customName: account.nickname
                        cid: account.corporateCustomer.cid
                        iban: account.accountNo
                        currency: account.currency
                    }
                catalogTmp.deposits = responses[3].data.map (account) ->
                    return {
                        customName: account.nickname
                        cid: account.corporateCustomer.cid
                        iban: account.iban
                        currency: account.currency
                    }
                catalogLoaded = true

    vm.updateCatalog = () ->
        console.log 'updateCatalog'
        $rootScope.togglersNotifications = {}
        cid = vm.currentCustomer.cid
        vm.catalogLength = catalogTmp.length
        vm.catalog =
            ACCOUNTS: catalogTmp.ACCOUNTS
            CREDITS: catalogTmp.CREDITS
            DEPOSITS: catalogTmp.DEPOSITS
            SYSTEM: catalogTmp.SYSTEM

        vm.accounts = catalogTmp.accounts.filter (account) ->
            return account.cid == cid
        vm.credits = catalogTmp.credits.filter (credit) ->
            return credit.cid == cid
        vm.deposits = catalogTmp.deposits.filter (deposit) ->
            return deposit.cid == cid

        vm.getConfig()
        

    vm.showAgreement = () ->
        LxDialogService.open "notification-agreement"

    # init services
    servicesInited = false
    initServices = () ->
        getContacts()
            .then () ->
                return initCatalog()
            .then () ->
                servicesInited = true
                vm.updateCatalog()

    # initial functions
    init = () ->
        vm.currentCustomer = $rootScope.customers[0]
        vm.enabled = $rootScope.currentUser.isNotificationsEnabled
        vm.isEditableContacts = authoritiesFactory.checkAny ['SIGN1', 'SIGN2', 'APPROVE']
        vm.isActiveAddSMS = vm.isEditableContacts
        vm.isActiveAddEMAIL = vm.isEditableContacts
        vm.loaded = true
        if vm.enabled
            initServices()
        


    if $rootScope.loaded
        init()
    $rootScope.$on 'USER_LOADED', () ->
        init()

    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        if $rootScope.customers.length
            vm.currentCustomer = $rootScope.customers[0]

        

    vm.agreeAction = (agree) ->
        if agree
            apiProfile.enableNotifications().then () ->
                LxDialogService.close "notification-agreement"
                vm.enabled = true
                initServices()
        else
            LxDialogService.close "notification-agreement"
                

    return vm
]

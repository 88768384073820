angular.module('IBR')
.directive 'link', () ->
    {
        restrict: 'A'
        link: (scope, element, attrs)->
            element[0].onclick = (e) ->
                if e.target.className == 'a'
                    window.open attrs.link, '_blank'
    }
.directive 'ibrLink', [ '$rootScope', ($rootScope) ->
    {
        restrict: 'A'
        link: (scope, element, attrs)->
            lang = localStorage.getItem 'language'
            link = null

            map =
                az: 'az'
                en: 'en'
                ru: 'ru'

            if attrs.ibrLinkMap
                tmp = attrs.ibrLinkMap.split(',')
                map =
                    az: tmp[0].trim()
                    en: tmp[1].trim()
                    ru: tmp[2].trim()

            decoreLink = () ->
                link = attrs.ibrLink
                element.attr "href", link.replace('@', map[lang])

            decoreLink()

            $rootScope.$on '$translateChangeSuccess', (event, data) ->
                if data.language
                    lang = data.language
                    decoreLink()
                return
    }
]

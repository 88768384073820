angular.module('IBR')
.controller 'SwiftPaymentCtrl', [ '$rootScope', '$q', '$timeout', '$translate', '$scope', '$stateParams', 'authoritiesFactory', 'apiPayment', 'apiTemplates', 'transactionActions', 'alertModal', 'asanModal', 'transactionResultModal', 'LxDialogService', ( $rootScope, $q, $timeout, $translate, $scope, $stateParams, authoritiesFactory, apiPayment, apiTemplates, transactionActions, alertModal, asanModal, transactionResultModal, LxDialogService ) ->

    # this
    vm = this

    #constants
    CODE_INTERNATIONAL = 'FP'
    CODE_INTERNATIONAL_RUB = 'RP'

    vm.currentCode = CODE_INTERNATIONAL

    vm.loaded = false
    vm.loaders = {}
    vm.payment = {
        typeCode: "SWF"
        interBank: false
    }
    vm.cids = []
    vm.isLocked = false #additional
    vm.validFields = {}
    vm.templateName = ''
    vm.cutOff = false

    vm.maskOptions =
        clearOnBlur: false
        addDefaultPlaceholder: false

    #valid payment object for API
    validatedPayment = {}

    
    DIALOG_MODAL = 'modal-payment-swift'
    TEMPLATE_NAME_MODAL = 'modal-create-template'

    #checkPermissions
    vm.permissions = {}
    $q.all([
        authoritiesFactory.checkAuthority 'INTERNATIONAL', 'CREATE'
        authoritiesFactory.checkAuthority 'INTERNATIONAL_RUB', 'CREATE'
    ]).then (responses) ->
        vm.permissions[CODE_INTERNATIONAL] = responses[0]
        vm.permissions[CODE_INTERNATIONAL_RUB] = responses[1]

        if !vm.permissions[vm.currentCode]
            vm.isLocked = true
            vm.loaded = true
            alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ACCESS_DENIED'), 'Ok'

    vm.getTabClass = (code) ->
        className = ''
        if ['SWF', 'SRT', 'ABA', 'OTH'].indexOf(code) > -1
            code = 'FP'

        if !vm.permissions[code]
            className = 'not-allowed'
        if code is vm.currentCode
            className = 'active'
        return className
    #checkPermissions END



    # payment for initialized
    progress = false
    $scope.$on 'FORM_READY', (event) ->
        if $stateParams.payment && !progress
            $scope.$broadcast 'setPayment', $stateParams.payment #fill form if it is change action
            progress = true

    searchTemplate = (extended) ->
        tempTemplates = []
        type = apiPayment.getPaymentType vm.currentCode
        template = {
            pageParams: {
                pageNumber: 1
                size: 10000
            }
            sort: {
                fieldName: "name"
                order: "ASC"
            }
            filterSpecification: [
                {
                    fieldName: "type"
                    inverted: "false"
                    operator: "EQUALS"
                    values: [ type ]
                }
                {
                    fieldName: "initiatorCid"
                    inverted: "false"
                    operator: "IN"
                    values: if (vm.payment.customer && vm.payment.customer.cid) then [vm.payment.customer.cid] else vm.cids
                }
            ]
        }

        apiTemplates.getTemplates template
            .then (response) ->
                tempTemplates = response.data.records
                return
            .then ->
                vm.templates = tempTemplates
                # unlockTemplateBtn()
                $scope.$broadcast 'TEMPLATES_FOUND'
                if extended
                    LxDialogService.open 'temp-search-dialog'
                else
                    LxDialogService.open 'temp-list-dialog'
            .catch (err) ->
                $scope.$broadcast 'TEMPLATES_ERR'
                # vm.templateFilling = true
        return

    $scope.$on 'TEMPLATE_SEARCH', () ->
        searchTemplate true

    $scope.$on 'TEMPLATE_LIST', () ->
        searchTemplate false

    vm.chooseTemplate = (template) ->
        $scope.$broadcast 'TEMPLATE_SELECT', template, true

    processPayment = (payment) ->
        p = angular.copy(payment, {})
        console.log p
        p.initiatorCid = payment.customer.cid

        p.internationalTransferTypeCode = vm.payment.typeCode

        if p.typeCode == "OTH"
            delete p.beneficiaryBankInfoSwiftCode

        if vm.currentCode == 'FP'

            if p.typeCode == "OTH"
                p.beneficiaryBankCorrespondentAccountIban = p.beneficiaryBankInfo.correspondentAccountIban
            else
                delete p.beneficiaryBankInfo.correspondentAccountIban
        
            if p.typeCode != "SWF"
                p.beneficiaryBankInfoName = p.beneficiaryBankInfo.name
                p.beneficiaryBankInfoAddress = p.beneficiaryBankInfo.address

                delete p.beneficiaryBankInfo

        if typeof p.debit == 'object'
            p.currency = p.debit.currency
            p.debit = p.debit.iban
        if typeof p.credit == 'object'
            vm.accountChecked = true
            p.credit = p.credit.iban
        if typeof p.customer == 'object'
            delete p.customer

        for own key of p
            if (typeof p[key] == 'undefined' || !p[key].length && key != "customer" && typeof p[key] != 'number' && typeof p[key] != 'object')
                delete p[key]
        if p.amount
            p.amount = parseFloat(p.amount.replace(/\s/g,''))
        p.status = 0

        if !p.notes
            delete p.notes

        delete p.typeCode

        if !payment.interBank
            delete p.intermediaryBankInfo
        delete p.interBank

        console.log p
        return p

    processTemplate = (payment) ->
        template = processPayment payment
        if vm.payment.typeCode && vm.payment.typeCode != 'SWF' && vm.payment.typeCode != "RP"
            template.beneficiaryBankInfo = {}
            if template.beneficiaryBankInfoName
                template.beneficiaryBankInfo.name = template.beneficiaryBankInfoName
                delete template.beneficiaryBankInfoName
            if template.beneficiaryBankInfoAddress
                template.beneficiaryBankInfo.address = template.beneficiaryBankInfoAddress
                delete template.beneficiaryBankInfoAddress
            if template.beneficiaryBankCorrespondentAccountIban
                template.beneficiaryBankInfo.correspondentAccountIban = template.beneficiaryBankCorrespondentAccountIban
                delete template.beneficiaryBankCorrespondentAccountIban
            if template.beneficiaryBankInfoSwiftCode
                template.beneficiaryBankInfo.bic = template.beneficiaryBankInfoSwiftCode
            switch vm.payment.typeCode
                when 'ABA'
                    if template.beneficiaryBankInfo.bic.indexOf("//FW") == -1
                        template.beneficiaryBankInfo.bic = "//FW" + template.beneficiaryBankInfo.bic
                when 'SRT'
                    if template.beneficiaryBankInfo.bic.indexOf("//SC") == -1
                        template.beneficiaryBankInfo.bic = "//SC" + template.beneficiaryBankInfo.bic

        if vm.payment.debit
            template.debitIban = vm.payment.debit.iban
        template.typeCode = vm.payment.typeCode
        template.creditIban = vm.payment.credit
        template.cid = vm.payment.customer.cid
        delete template.debit
        delete template.credit
        delete template.uuid
        delete template.initiatorCid
        delete template.beneficiaryBankInfoSwiftCode
        delete template.clientRef
        delete template.currency
        delete template.status

        return template

    paymentCheck = () ->
        #fake
        validatedPayment = payment = processPayment vm.payment
        code = validatedPayment.internationalTransferTypeCode || vm.currentCode
        $rootScope.customAlert = true

        return apiPayment.validate code, payment
            .then (res) ->

                #successfully validated
                $rootScope.customAlert = false

                vm.cutOff = res.data.length && res.data[0].message && res.data[0].message is "cutOff"


                data = {
                    cid: vm.payment.customer.cid
                    paymentType: vm.currentCode
                    amount: payment.amount
                    currency: payment.currency
                    debit: payment.debit
                    credit: payment.credit
                    beneficiaryBankCode: payment.beneficiaryBankInfoSwiftCode
                    # customerTaxId: payment.beneficiaryCorporateCustomerTaxId
                    narrative: payment.narrative
                }
                
                return apiPayment.getCommis data
            .then (res) ->
                if res.data.currency != undefined && res.data.amount != undefined
                    vm.payment.commisAmount = res.data.amount
                    vm.payment.commisCurrency = res.data.currency || payment.currency

                return transactionActions.getPaymentButtons vm.currentCode, vm.payment.customer.cid, vm.payment.customer.authSchema, vm.payment.debit.iban
            .then (buttons) ->
                vm.loaders.validation = false
                vm.buttons = buttons
                paymentDialog.open()
            .catch (err) ->
                vm.loaders.validation = false
                if $rootScope.customAlert
                    if err && err.data && err.data.constraints && err.data.constraints[0] && err.data.constraints[0].code is '0038'
                        alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ERROR_CORP_LIMIT'), 'Ok'
                    else
                        $scope.$broadcast 'account_iban_not_exist'


    paymentDialog = {}

    paymentDialog.open = () ->
        vm.loaders = {}
        LxDialogService.open DIALOG_MODAL
        return

    paymentDialog.close = () ->
        vm.loaders = {}
        LxDialogService.close DIALOG_MODAL

    vm.closePaymentDialog = () ->
        LxDialogService.close DIALOG_MODAL

        

    vm.initPayment = (code) ->
        if vm.loaded
            if vm.currentCode != code
                vm.currentCode = code
                vm.resetPayment true

    vm.resetPayment = (needClearRef) ->
        $scope.$broadcast 'PAYMENT_RESET', needClearRef
    
    vm.presendPayment = () ->
        vm.loaders.validation = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false

    vm.presendTemplate = () ->
        vm.loaders.template = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false

    $scope.$on 'PAYMENT_LOCAL_VALIDATED', (event, isValid) ->
        if isValid
            if vm.loaders.validation
                paymentCheck()
                    # .then () ->
                    #     paymentDialog.open()
                    # .catch () ->
                    #     vm.loaders = {}
            else if vm.loaders.template
                vm.loaders = {}
                LxDialogService.open TEMPLATE_NAME_MODAL
        else
            vm.loaders.validation = false

    vm.sendPayment = (btnKey) ->
        console.log 'sendPayment', btnKey
        authSchema = vm.payment.customer.authSchema
        code = validatedPayment.internationalTransferTypeCode || vm.currentCode
        switch btnKey
            when 'SEND_TO_SIGN', 'SEND_TO_SIGN__APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create code, false, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create code, btnKey, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        if res.data.responseType is 'COMPLETE'
                            return transactionResultModal.successPaymentModal authSchema, btnKey
                        else
                            $q.reject()
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1', 'SIGN_2'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment code, validatedPayment, btnKey
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1__SEND_TO_CBS'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment code, validatedPayment, 'SIGN_1'
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        validatedPayment.ref = res.data.refSet[0]
                        alertModal.showLoader()
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs validatedPayment.ref #todo where ref from?
                    .then (res) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        return transactionResultModal.sendToBank btnKey, validatedPayment, null, res.data, res.status
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                paymentDialog.close()
                                vm.resetPayment true

    
    vm.sendTemplate = () ->
        if !vm.templateName
            vm.validFields.templateName = true
            return

        template = processTemplate vm.payment
        template.name = vm.templateName
        vm.loaders.template = true
        apiTemplates.addTemplate vm.currentCode, template
            .then (response) ->
                LxDialogService.close TEMPLATE_NAME_MODAL

                header = $translate.instant 'TEMPLATES_PAYMENT.TITLE_DIALOG'
                message = $translate.instant 'INTERNAL_PAYMENT.TEMPLATE_DONE'
                alertModal.showMessage header, message, 'OK'
            .catch (err) ->
                vm.loaders.template = false

    return
]
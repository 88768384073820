'use strict'

angular.module('IBR')
.controller 'AccountsCtrl', [ '$rootScope', '$scope', '$state', '$stateParams', '$filter', '$translate', 'LxNotificationService', 'apiAccounts', 'alertModal', 'LxDialogService', ($rootScope, $scope, $state, $stateParams, $filter, $translate, LxNotificationService, apiAccounts, alertModal, LxDialogService) ->
    vm = this

    DEFAULT_CURRENCIES_TYPE_ALL = {
        value: "",
        title: "ALL"
    }

    CURRENCIES = [
        DEFAULT_CURRENCIES_TYPE_ALL,
        {value: 'AZN', title: 'AZN'},
        {value: 'USD', title: 'USD'},
        {value: 'RUB', title: 'RUB'},
        {value: 'EUR', title: 'EUR'},
        {value: 'GBP', title: 'GBP'},
    ]

    vm.accounts = {}
    vm.accountFilter = {
        currency: DEFAULT_CURRENCIES_TYPE_ALL
    }
    vm.loaded = false
    hideStatuses = ['CLOSED']
    vm.currentAccounts = {}
    vm.headerClass = 'relative'
    vm.currencies = CURRENCIES

    vm.customerCaption = true

    setPassedAccount = () ->
        iban = $stateParams.iban
        if iban
            if !vm.currentAccounts[iban]
                vm.currentAccounts[iban] = true
            vm.accountFilter.iban = iban
        vm.loaded = true
        return

    processAccounts = (accounts) ->
        customerAccounts = {}
        accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
        angular.forEach accounts, (account) ->
            account.isInput = false
            cid = account.corporateCustomer.cid
            if !customerAccounts[cid]
                customerAccounts[cid] = {}
                customerAccounts[cid].accounts = []
                customerAccounts[cid].blockedAccounts = []
                customerAccounts[cid].corporateCustomer = account.corporateCustomer
            if !(account.status in hideStatuses)
                customerAccounts[cid].accounts.push account
            else
                customerAccounts[cid].blockedAccounts.push account
        customerAccounts

    initAccounts = () ->
        apiAccounts.getAccounts().then (response) ->

            if $rootScope.currentUser
                if $rootScope.currentUser.corporateCustomers
                    if $rootScope.currentUser.corporateCustomers.length <= 1
                        vm.customerCaption = false

            vm.accounts = processAccounts response.data
            setPassedAccount()
            # activeTab = document.querySelector('.tabs__link.statement')
            # if !activeTab.classList.contains('active-link') then activeTab.classList.add('active-link')
            return
        ,(error) ->
            vm.loaded = true
            # activeTab = document.querySelector('.tabs__link.statement')
            # if !activeTab.classList.contains('active-link') then activeTab.classList.add('active-link')
            return
        return

    initAccounts()

    vm.setCurrentAccount = (iban) ->
        if vm.currentAccounts[iban]
            delete vm.currentAccounts[iban]
        else
            vm.currentAccounts[iban] = true
        return

    vm.updateAccountName = (account, name, iban) ->
        if (name != undefined) && (name || account.customName) && (name != account.customName)
            apiAccounts.updateAccountName(iban, name).then (response) ->
                account.customName = name
                alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('MESSAGES.CHANGE_ACCEPTED'), $translate.instant('MESSAGES.OK_INFO'), (answer) ->
                    return
                return
        return

    vm.updateAccountNameEnter = (account, keyEvent, name, iban) ->
        if keyEvent.which == 13
            vm.updateAccountName(account, name, iban)
        return

    vm.showAccountStatement = (account) ->
        $state.go('statement', {iban: account.iban, customer: account.customer.id})
        return

    vm.showAccountHistory = (account) ->
        $state.go('archive', {iban: account.iban, customer: account.customer.id})
        return

    vm.isInput = (account) ->
        account.isInput = !account.isInput
        return

    # ACCOUNT DETAIL WIDGET
    vm.showDetails = (account, cid) ->
        $scope.$broadcast 'SHOW_ACCOUNT_DETAILS', account, cid

    return
]

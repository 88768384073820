'use strict'

angular.module('IBR')
.controller 'PendingNotificationsCtrl', [ '$scope', '$state', '$rootScope', 'apiNotification', ($scope, $state, $rootScope, apiNotification) ->

  vm = this
  vm.notifications = {}
  vm.cids = []
  vm.loaded = false
  vm.initialLoaded = false

  $scope.colorMap = {
    REJECTED: 'color-rejected'
    REJECTED_BY_CBS: 'color-rejected'
    PENDING_FOR_SEND_TO_CBS: 'color-pending-send'
    SENT_TO_CHANGE: 'color-change'
    SENT_TO_CBS: 'color-sent'
    PENDING_FOR_SIGNATURE: 'color-pending-sign'
    PENDING_FOR_APPROVE: 'color-pending-approve'
    CREATION_ERROR: 'color-rejected'
  }

  filter =
    pageParams:
      pageNumber: 0
      size: 0
    sort:
      fieldName: ''
      order: 'ASC'

  
  filteredNotification = () ->
    vm.notifications = {}
    vm.loaded = false
    for customer in $rootScope.selectedCorporateCustomers
      vm.cids.push customer.cid

    if vm.cids.length
      filter.filterSpecification = [
        {
          fieldName: "initiatorCid"
          values: vm.cids
          operator: "IN"
          inverted: "false"
        }
      ]
    else
      filter.filterSpecification = []

    apiNotification.getNotification(filter).then (response) ->
      vm.notifications = sortNotification response.data
      vm.loaded = true
      return


  $rootScope.$on 'USER_LOADED', () ->
    if !vm.initialLoaded
      filteredNotification()
      vm.initialLoaded = true

  if $rootScope.loaded
    if !vm.initialLoaded
      filteredNotification()
      vm.initialLoaded = true

  $scope.$on 'SELECT_CUSTOMERS', (event, isInitial) ->
    if !isInitial
      filteredNotification()
      vm.cids = []

  vm.transactionsNavigate = (status) ->
    if status == 'CREATION_ERROR'
      $state.go 'bulk-payments'
    else
      $state.go 'transactions', { status: status }
    return

  sortNotification = (notificationArr) ->

    sortedArr = []
    for notification in notificationArr
      switch notification.statusNoteType
        when 'PENDING_FOR_SIGNATURE' then sortedArr[0] = notification
        when 'PENDING_FOR_APPROVE' then sortedArr[1] = notification
        when 'PENDING_FOR_SEND_TO_CBS' then sortedArr[2] = notification
        when 'SENT_TO_CHANGE' then sortedArr[3] = notification
        when 'SENT_TO_CBS' then sortedArr[4] = notification
        when 'REJECTED_BY_CBS' then sortedArr[5] = notification
        when 'CREATION_ERROR' then sortedArr[6] = notification
    return sortedArr.filter(Object)

  return
]

'use strict'


#!/[^0-9a-zA-Zа-яА-ЯёЁəöüıçşğÇÖÜĞİŞƏ.,:()\-\+]/.test(string)

angular.module('IBR')
.directive 'charFilter', () ->
  {
    restrict: "A"
    require: "ngModel"
    link: (scope, element, attributes) ->
      def = {}
      allChars = "["
      casing = 'null'
      attr = attributes.charFilter
      if attr.indexOf('9') > -1 then allChars+='0-9'
      if attr.indexOf('e') > -1 then allChars+='A-Za-z'
      if attr.indexOf('c') > -1 then allChars+='А-Яа-яёЁ'
      if attr.indexOf('a') > -1 then allChars+='əöüıçşğÇÖÜĞİŞƏ'
      if attr.indexOf('u') > -1 then casing='upper'
      if attr.indexOf('s') > -1 then allChars+=' '
      if attr.indexOf('.') > -1 then allChars+='.,:()\\-+'
      if attr.indexOf('@') > -1 then allChars+='@.!#$%&"*+/-=?^_`\\{|}~'
      if attr.indexOf('f') > -1 then allChars+='0-9a-z'
      allChars+="]"
      if allChars.length > 2
        def = {
          validator: allChars,
          casing: casing
        }
      else
        def = {
          casing: casing
        }
      element.inputmask({
          mask: "*{*}",
          greedy: false
          definitions: {
            '*': def
          }
        oncleared: () ->
          self.value = ''
        })
  }

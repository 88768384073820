angular.module('IBR')
.directive 'ngInitial', () ->
    {
        restrict: 'A'
        require:'ngModel'
        scope:
            ngModel: '='
            ngInitial: '='
        link: (scope, element, attrs, ngModelCtrl) ->
            if !scope.ngModel
                scope.ngModel = ngModelCtrl.$viewValue = ngModelCtrl.$modelValue = scope.ngInitial
                ngModelCtrl.$render()

    }
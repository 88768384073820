'use strict'

angular.module('IBR').component 'archiveSearchForm', {

    templateUrl: '/pages/archive/search-form.html'
    bindings:
        loaded: '<'
        loaders: '<'
        constants: '<'
        customers: '<'
        accounts: '<'
        customerAccounts: '<'
        filter: '='
        bulks: '<'
        canApprove: '<'
        formFilterDefaults: '<'
        statusCount: '<'
        search: '='
        getBulks: '='
    controllerAs: "vm"
    controller: ['$rootScope', '$timeout', '$filter', 'apiBulkPayments', ($rootScope, $timeout, $filter, apiBulkPayments) ->

        vm = this

        defBulk = { bulkName: 'ALL' }
        vm.form = 
            accounts: []
            customers: []
            filter: {}
            bulks: [defBulk]

        # vm.filter.bulk = defBulk

        # binding date inputs
        vm.dateRange = new DateRange({
            moreNow: true
        })

        vm.statuses = [
            'ALL_ARCHIVE'
            'ACCEPTED'
            'EXPIRED'
            'DELETED'
        ]

        vm.errors = {}
        vm.validateAmount = () ->
            vm.errors.amountMin = vm.errors.amountMax = false
            compareErr = vm.filter.amountMax && vm.filter.amountMin && (Number(vm.filter.amountMin) >= Number(vm.filter.amountMax))
            if vm.filter.amountMin
                vm.errors.amountMin = isNaN(vm.filter.amountMin) || compareErr
            if vm.filter.amountMax
                vm.errors.amountMax = isNaN(vm.filter.amountMax) || compareErr


        vm.setFormAccounts = () ->
            vm.checkBulks()
            vm.filter.account = vm.formFilterDefaults.account
            vm.form.accounts = []
            if vm.accounts && vm.accounts.length && vm.customerAccounts
                if vm.filter.customer && vm.filter.customer.cid
                    vm.form.accounts = angular.copy vm.customerAccounts[vm.filter.customer.cid].accounts
                else
                    vm.form.accounts = angular.copy vm.accounts
                vm.form.accounts.unshift vm.formFilterDefaults.account

        vm.$onChanges = (e) ->
            if e.customers && vm.customers
                vm.form.customers = angular.copy vm.customers
                vm.form.customers.unshift vm.formFilterDefaults.customer
            if e.accounts || e.customers
                vm.setFormAccounts()



        vm.checkBulks = ->
            vm.form.bulks = [defBulk]
            vm.filter.bulk = defBulk
            if vm.filter.isBulk
                cids = vm.customers
                    .map (c) ->
                        return c.cid
                    .filter (c) ->
                        return c
                if vm.filter.customer && vm.filter.customer.cid
                    cids = [vm.filter.customer.cid]

                # console.log cids
                if cids.length
                    vm.loaders.bulks = true
                    apiBulkPayments.getList 'ARCHIVE', cids
                        .then (res) ->
                            vm.loaders.bulks = false
                            bulks = [defBulk]
                            for b in res.data
                                bulks.push { bulkId: b.bulkId, bulkName: b.ref+' ('+b.bulkName+')', ref: b.ref }

                            vm.form.bulks = $filter('orderBy')(bulks, '-bulkId')

                        .catch (err) ->
                            vm.loaders.bulks = false
                else
                    vm.form.bulks = []

        return vm
    ]
}
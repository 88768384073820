'use strict'

angular.module('IBR')
.factory 'apiPayment', [ '$http', '$rootScope', '$timeout', '$q', '$translate', ($http,$rootScope,$timeout,$q, $translate) ->

	BASE_URL = CONFIG.USE_MOCK || CONFIG.RESOURCE_SERVER
	urlActivePayments = BASE_URL + "/transfers/view/active"
	urlArchivePayments = BASE_URL + "/transfers/view/archive"

	PAYMENT_URL = {
		IT: "/transfers/internal/account-to-account"
		FX: "/transfers/exchange"
		AZ: "/transfers/domestic/azips"
		XO: "/transfers/domestic/xohks"
		XT: "/transfers/domestic/budget"
		XV: "/transfers/domestic/budget_vat"

		PI: "/transfers/domestic/ips/pi"
		PT: "/transfers/domestic/ips/pt"
		PV: "/transfers/domestic/ips/pv"

		FP: "/transfers/international"
		SWF: "/transfers/international/swift"
		SRT: "/transfers/international/english"
		ABA: "/transfers/international/american"
		OTH: "/transfers/international/other"
		BIC: ''
		RP: "/transfers/international-rub"
		SP: "/transfers/salary"
	}

	PAYMENT_VALIDATE_URL = {
		IT: "/transfers/internal/account-to-account/validate"
		FX: "/transfers/exchange/validate"
		AZ: "/transfers/domestic/azips/validate"
		XO: "/transfers/domestic/xohks/validate"
		XT: "/transfers/domestic/budget/validate"
		XV: "/transfers/domestic/budget_vat/validate"

		PI: "/transfers/domestic/ips/pi/validate"
		PT: "/transfers/domestic/ips/pt/validate"
		PV: "/transfers/domestic/ips/pv/validate"

		SWF: "/transfers/international/validate/swift"
		SRT: "/transfers/international/validate/english"
		ABA: "/transfers/international/validate/american"
		OTH: "/transfers/international/validate/other"
		BIC: ''
		RP: "/transfers/international-rub/validate"
		SP: "/transfers/salary/validate"
	}

	PAYMENT_LIMITS_URL = {
		PI: "/transfers/domestic/ips/pi/check-limits"
		PT: "/transfers/domestic/ips/pt/check-limits"
		PV: "/transfers/domestic/ips/pv/check-limits"
	}


	getPaymentURL = (code, custom) ->
		urls = if custom then angular.extend({},PAYMENT_URL, custom) else PAYMENT_URL
		return BASE_URL + urls[code]

	getPaymentValidateURL = (code) ->
		return BASE_URL + PAYMENT_VALIDATE_URL[code]

	getPaymentLimitsURL = (code) ->
		return BASE_URL + PAYMENT_LIMITS_URL[code]

	PAYMENT_TYPES = {
		IT: 'INTERNAL_ACCOUNT_TO_ACCOUNT'
		FX: 'EXCHANGE'
		AZ: 'AZIPS'
		XO: 'XOHKS'
		XT: 'BUDGET'
		XV: 'BUDGET_VAT'
		PI: 'PI'
		PT: 'PT'
		PV: 'PV'
		FP: 'INTERNATIONAL'
		RP: 'INTERNATIONAL_RUB'
		SP: 'SALARY'
	}
	that = {}

	that.getPaymentType = (code) ->
		if code
			return PAYMENT_TYPES[code] || ''
		return ''


	that.getByAlias = (data, type) ->
		return $http.post BASE_URL + '/transfers/domestic/ips/' + type.toLowerCase() + '/alias-info', data

	#GET DOMESTIC CODES
	that.getDomesticCodes = (type, searchQuery) ->
		url = CONFIG.AUTH_SERVER + "/ib-references/v1/search/"
		switch type
			when "bank" then $http.get url + "banks?q=" + searchQuery
			when "lvl" then $http.get url + "budget-levels?q=*"
			when "payments" then $http.get url + "budget-payments?q=" + searchQuery
			when "class" then $http.get url + "budget-classifications?q=" + searchQuery
			when "swift" then $http.get url + "swifts?q=" + searchQuery
			when "swift_rub" then $http.get url + "rus_bic?q=" + searchQuery
			else

	#GET REF
	that.getRef = (txnType, cid) -> 
		$http.post getPaymentURL(txnType, { IT: '/transfers/internal' } ) + '/client-ref', { initiatorCid: cid }

	#GET COMMISSION
	that.getCommis = (data) ->
		return $http.post BASE_URL + "/transfers/commission/calculation", data

	that.getLimits = (txnType, data) ->
		return $http.post getPaymentLimitsURL(txnType), data

	#STATEMENTS
	that.searchAccountStatements = (searchQuery) ->
		$http.get CONFIG.RESOURCE_SERVER + "/accounts"+'/'+searchQuery.iban+'/statement?from='+searchQuery.startDate+'&to='+searchQuery.stopDate+'&cid='+searchQuery.cid

	#STATEMENT PRINT
	that.printStatement = (searchQuery, foreign) ->
		urlPostfix = searchQuery.iban+'/statement/'+searchQuery.fileType+'?from='+searchQuery.startDate+'&to='+searchQuery.stopDate+'&cid='+searchQuery.cid

		if foreign
			return $http.get CONFIG.RESOURCE_SERVER + '/file-contents/foreign/'+searchQuery.cid+'/'+urlPostfix+'&lang='+searchQuery.lang.lngu, {responseType : 'arraybuffer'}
		else
			return $http.get CONFIG.RESOURCE_SERVER + '/file-contents/'+urlPostfix+'&lang='+searchQuery.lang.key, {responseType : 'arraybuffer'}
	

	that.validate = (code, data) ->
		return $http.post getPaymentValidateURL(code), data

	#CREATE
	that.create = (code, action, data) ->
		ref = data.ref || data.reference

		if ref
			prefix = '/change'
			switch action
				when 'SIGN_1' then prefix = '/change-sign1'
				when 'SIGN_2' then prefix = '/change-sign2'
				when 'APPROVE' then prefix = '/change-approve'
			return $http.put getPaymentURL(code) + prefix + '/' + ref , data
		else
			prefix = ''
			switch action
				when 'SIGN_1' then prefix = '/create-sign1'
				when 'SIGN_2' then prefix = '/create-sign2'
				when 'APPROVE' then prefix = '/create-approve'
			return $http.post getPaymentURL(code) + prefix, data


	#CHECK CUT OFF
	that.checkCutOff = (code) ->
		# console.log type
		$http.get CONFIG.RESOURCE_SERVER + '/transfers/check/operation-day/' + code

	#GET BLACKOUT
	that.getBlackout = (code) ->
		$http.get BASE_URL + "/transfers/check/blackout/" + code

	#CHECK INTERNAL IBAN
	that.checkAccount = (iban) ->
		$http.post CONFIG.RESOURCE_SERVER + "/accounts/credit/check", iban

	#CHECK DOMESTIC IBAN
	that.checkIban = (iban) ->
		$http.post CONFIG.RESOURCE_SERVER + "/accounts/iban/validate", iban

	#CHECK EMPLOYERS IBANS
	that.checkSalaryAccounts = (cid, debitIban, creditIbans) ->
		$http.post CONFIG.RESOURCE_SERVER + "/transfers/salary/employees/check", { cid: cid, debitAccount: debitIban, creditAccounts: creditIbans }

	#GET CREDIT ACCOUNTS
	that.getCreditAccounts = () ->
		$http.get BASE_URL + "/accounts/info"

	#GET_ACTIONS
	that.getAction = (code, data) -> 
		console.log(getPaymentURL(code)+'/actions')
		$http.post getPaymentURL(code)+'/actions', data

	#APPROVE
	that.approve = (ref) ->
		code = ref.substring 6, 8
		$http.post getPaymentURL(code)+'/approve/'+ref

	#SIGN
	# that.sign = (code,type,data) ->
	# 	prefix =''
	# 	if type == 'SIGN_1' or type == 'SIGN_1_AND_SEND_TO_CBS' or type == 'SIGN_1' or type == 'SIGN_1_AND_SEND_TO_CBS' or type == 'SIGN_1__SEND_TO_CBS'
	# 		prefix = '/sign1'
	# 	else if type == 'SIGN_2' or type == 'SIGN_2_AND_SEND_TO_CBS' or type == 'SIGN_2__SEND_TO_CBS'
	# 		prefix = '/sign2'

	# 	$http.post getPaymentURL(code) + prefix, data

	that.sign = (action, data) ->
		code = data.reference.substring 6, 8
		prefix = if action is 'SIGN_1' then '/sign1' else '/sign2'
		$http.post getPaymentURL(code) + prefix, data

	#SEND-TO-CBS

	that.sendToCbs = (ref) ->
		code = ref.substring 6, 8
		$http.post getPaymentURL(code)+'/send-to-cbs/'+ref

	#SEND-FOR-CHANGE
	that.sendForChange = (data) ->
		code = data.ref.substring 6, 8
		$http.post getPaymentURL(code) + '/send-for-change', data

	#DELETE
	that.delete = (data) ->
		code = data.ref.substring 6, 8
		$http.post getPaymentURL(code) + '/delete', data

	#GET BATCH
	that.getBatch = (id) ->
		$http.get BASE_URL + "/transfers/view/batch/" + id


	that.getBatchTransactions = (batch) ->
		if batch.transactions && batch.transactions.length
			return $timeout(->
				return batch.transactions
			)
		batch.loading = true
		return that.getBatch batch.batchId
			.then (res) ->
				batch.loading = false
				batch.transactions = res.data
				return res.data
			, (err) ->
				batch.loading = false

	that.mergeTransactions = (transactions, batches) ->
		trs = []
		if transactions && transactions.length
			for tr in transactions
				trs.push tr
		if batches && batches.length
			requests = batches.map that.getBatchTransactions
			return $q.all requests
				.then (res) ->
					# console.log res
					for batch in batches
						for t in batch.transactions
							trs.push t
					return trs
		else
			return $timeout( ->
				return trs
			)

	#REJECT
	that.rejectPayment = (data) ->
		code = data.ref.substring 6, 8
		$http.post getPaymentURL(code) + '/reject', data

	# SEND_GROUP
	that.sendGroupTransactions = (action, data) ->
		prefix = ''
		switch action
			when "APPROVE"
				prefix = 'approve'
			when "DELETE"
				prefix = 'delete'
			when "SEND_TO_CBS"
				prefix = 'send-to-cbs'
			when "SIGN_1"
				prefix = 'sign1'
			when "SIGN_2"
				prefix = 'sign2'
			when "SEND_TO_CHANGE"
				prefix = "send-for-change"

		$http.post BASE_URL + "/transfers/group/" + prefix, data

	getPayments = (active, filter) ->
		url = if active then urlActivePayments else urlArchivePayments
		reqBody =
			pageParams:
				pageNumber: filter.page
				size: filter.onPage
			filterSpecification: that.proccessFilters filter
			sort:
				fieldName: filter.sort || 'lastModifiedDate'
				order : filter.sortDir || 'ASC'

		$http.post url + '/' + filter.status, reqBody

	that.getActivePayments = (filter) ->
		getPayments(true, filter)

	that.getArchivePayments = (filter) ->
		getPayments(false, filter)

	#GET DETAILS
	that.getPaymentDetail = (ref) ->
		code = ref.substring 6, 8
		$http.get getPaymentURL(code) + '/' + ref

	#GET HISTORY
	that.getPaymentHistory = (ref) ->
		# console.log type
		code = ref.substring 6, 8
		$http.get getPaymentURL(code) + '/history/'+ ref


	#GET FILE
	that.transactionFile = (ref, lang, fileType) ->
		code = ref.substring 6, 8
		if fileType is 'ADOC'
			$http.get CONFIG.RESOURCE_SERVER + "/file-contents/asan-documents/" + code + "/" + ref, { responseType: 'blob' }
		else
			$http.get CONFIG.RESOURCE_SERVER + "/file-contents/transfer-statement-documents/" + code + "/" + ref + "/" + lang + "/" + fileType, { responseType: 'blob' }
	
	that.transactionsFile = (refs, lang, fileType) ->
		data = {
			lang: lang,
			transfers: refs
		}
		$http.post CONFIG.RESOURCE_SERVER+ '/file-contents/transfer-statement-documents/'+ fileType, data, { responseType: 'blob' }


	#CALC RATE
	that.calcExchangeRate = (currFrom, currTo, cid) ->
		data =
			cid: cid
			fromCurrency: currFrom.currency
			toCurrency: currTo.currency

		if currFrom.amount
			data.fromAmount = currFrom.amount
		if currTo.amount
			data.toAmount = currTo.amount

		$http.post BASE_URL + "/currency/conversion", data


	that.proccessFilters = (filter) ->
		filterSpecification = []
		if !filter.customer || !filter.customer.cid
			filter.cids = $rootScope.customers.map (c) ->
				return c.cid
		else
			filter.cids = null

		if filter.account && filter.account.iban != 'ALL'
			filterSpecification.push {
				fieldName: "debit"
				values: [filter.account.iban]
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.type && filter.type != 'ALL'
			type = that.getPaymentType filter.type
			filterSpecification.push {
				fieldName: "type"
				values: [type]
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.source && filter.source.value == 'INTERNET_OFFICE'
			filterSpecification.push {
				fieldName: "source"
				values: ['INTERNET_OFFICE']
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.isBulk || (filter.source && filter.source.value == 'BULK_UPLOAD')
			filterSpecification.push {
				fieldName: "source"
				values: ['BULK_UPLOAD']
				operator: "EQUALS"
				inverted: "false"
			}

			if filter.bulk && filter.bulk.ref
				filterSpecification.push {
					fieldName: "bulkRef"
					values: [filter.bulk.ref]
					operator: "EQUALS"
					inverted: "false"
				}

		if filter.customer && filter.customer.cid
			filterSpecification.push {
				fieldName : "initiatorCid"
				values : [filter.customer.cid]
				operator : "EQUALS"
				inverted : "false"
			}
		else if filter.cids
			filterSpecification.push {
				fieldName : "initiatorCid"
				values : filter.cids
				operator : "IN"
				inverted : "false"
			}

		if filter.startDate
			sDate = new Date(filter.startDate.setHours(0,0,0,0))
			filterSpecification.push({
				fieldName: "lastModifiedDate"
				values: [sDate.toIsoString()]
				operator: "MORE_THAN"
				inverted: "false"
			})

		if filter.stopDate
			# fDate = new Date(vm.transactionFilter.stopDate)
			fDate = new Date(filter.stopDate.setHours(23,59,59,99))
			filterSpecification.push({
				fieldName: "lastModifiedDate"
				values: [fDate.toIsoString()]
				operator: "LESS_THAN"
				inverted: "false"
			})

		if filter.clientRef
			filterSpecification.push {
				fieldName: "clientRef"
				values: [filter.clientRef]
				operator: "LIKE"
				inverted: "false"
			}

		if filter.debit
			filterSpecification.push {
				fieldName: "debit"
				values: [filter.debit],
				operator: "LIKE"
				inverted: "false"
			}

		amountMin = Number filter.amountMin
		if filter.amountMin
			filterSpecification.push {
				fieldName: "debitAmount"
				values: [amountMin],
				operator: "MORE_THAN_OR_EQUALS"
				inverted: "false"
			}

		amountMax = Number filter.amountMax
		if filter.amountMax
			filterSpecification.push {
				fieldName: "debitAmount"
				values: [amountMax],
				operator: "LESS_THAN_OR_EQUALS"
				inverted: "false"
			}

		if filter.paymentDetails
			filterSpecification.push {
				fieldName: "narrative"
				values: [filter.paymentDetails],
				operator: "LIKE"
				inverted: "false"
			}

		# from table
		if filter.narrative
			filterSpecification.push {
				fieldName: "narrative"
				values: [filter.narrative],
				operator: "LIKE"
				inverted: "false"
			}


		if filter.currency && filter.currency != 'ALL'
			filterSpecification.push {
				fieldName: "debitCurrency"
				values: [filter.currency],
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.beneficiaryName
			filterSpecification.push {
				fieldName: "beneficiaryName"
				values: [filter.beneficiaryName],
				operator: "LIKE"
				inverted: "false"
			}

		# from table
		if filter.transactionStatus != 'ALL'
			filterSpecification.push {
				fieldName: "status"
				values: [filter.transactionStatus],
				operator: "EQUALS"
				inverted: "false"
			}

		filterSpecification


	that.getDomesticData = (code) ->
		data = {}
		if code is 'XT' or code is 'PT'
			data.beneficiaryBankCode = "210005"
			data.beneficiaryBankName = $translate.instant('DOMESTIC_PAYMENT.TITLE_TREASURY_FULL')
			data.beneficiaryBankTaxId = "1401555071"
			data.beneficiaryBankCorrespondentAccountIban = "AZ41NABZ01360100000000003944"
			data.beneficiaryBankSwiftCode = "CTREAZ22"

		if code is 'XV' or code is 'PV'
			data.beneficiaryBankCode = "210027"
			data.beneficiaryBankName = $translate.instant('DOMESTIC_PAYMENT.TITLE_VAT_FULL')
			data.beneficiaryBankTaxId = "1401555071"
			data.beneficiaryBankCorrespondentAccountIban = "AZ07NABZ01360100000000004944"
			data.beneficiaryBankSwiftCode = "CTREAZ24"
			data.credit = "AZ48CTRE00000000000000014001"

		return data

	that
]

'use strict'

angular.module('IBR').factory 'transactionActions', ['$rootScope', '$filter', '$translate', 'apiPayment', 'authoritiesFactory', ($rootScope, $filter, $translate, apiPayment, authoritiesFactory) ->
    that = {}

    that.authSchemes = {}

    getAuthSchemes = () ->
        for customer in $rootScope.currentUser.corporateCustomers
            that.authSchemes[customer.cid] = customer.authSchema

    if $rootScope.loaded
        getAuthSchemes()
    $rootScope.$on 'USER_LOADED', () ->
        getAuthSchemes()

    that.buttons = 

        SAVE_AS_TEMPLATE:
            show: true
            key: 'SAVE_AS_TEMPLATE'
            title: 'Сохранить как шаблон'
            color: "white"
            class: "btn--outline"

        COPY:
            show: true
            key: 'COPY'
            title: 'Создать повторно'
            color: "primary"

        DELETE:
            show: true
            key: 'DELETE'
            title: 'Удалить'
            color: "red"

        #payment actions
        SEND_TO_SIGN:
            show: true
            key: 'SEND_TO_SIGN'
            title: 'Отправить на подпись'
            color: "primary"

        SEND_TO_CHANGE:
            show: true
            key: 'SEND_TO_CHANGE'
            title: 'Отправить на доработку'
            color: "primary"

        CHANGE:
            show: true
            key: 'CHANGE'
            title: 'Доработать'
            color: "primary"

        SIGN_1:
            show: true
            key: 'SIGN_1' #action key (passed to transactionWindowAction(key))
            title: 'Подписать'
            color: "primary"

        SIGN_2:
            show: true
            key: 'SIGN_2'
            title: 'Подписать'
            color: "primary"

        SIGN_1__SEND_TO_CBS:
            show: true
            key: 'SIGN_1__SEND_TO_CBS'
            title: 'Подписать и отправить в банк'
            color: "primary"

        SIGN_2__SEND_TO_CBS:
            show: true
            key: 'SIGN_2__SEND_TO_CBS'
            title: 'Подписать и отправить в банк'
            color: "primary"

        SEND_TO_CBS:
            show: true
            key: 'SEND_TO_CBS'
            title: 'Отправить в Банк'
            color: "primary"

        APPROVE:
            show: true
            key: 'APPROVE'
            title: 'Подтвердить'
            color: "primary"

        APPROVE__SEND_TO_CBS:
            show: true
            key: 'APPROVE__SEND_TO_CBS'
            title: 'Подтвердить и отправить в банк'
            color: "primary"


        #payment actions
        SEND_TO_SIGN__APPROVE:
            show: true
            key: 'SEND_TO_SIGN__APPROVE'
            title: 'Отправить на подпись / подтверждение'
            color: "primary"

    that.getPaymentButtons = (code, cid, authSchema, debitIban) ->
        data = {
            cid: cid
            accountNo: debitIban
        }
        type = apiPayment.getPaymentType code
        buttons = []
        return apiPayment.getAction code, data
            .then (res) ->
                return authoritiesFactory.checkButtons authSchema, type, res.data
            .then (btns) ->
                console.log btns
                for btnKey of that.buttons
                    if btns[btnKey]
                        buttons.push that.buttons[btnKey]
                return buttons

                

    that.getTransactionButtons = (transaction, authSchema) ->
        btns = that.getButtons transaction, authSchema
        return btns.map (btnKey) ->
            return that.buttons[btnKey]

    that.getAuthScheme = (cid) ->
        return that.authSchemes[cid] || null


    that.getErrorMsg = (code, desc) ->
        txt = switch code
            when '0073' then $translate.instant('EXCHANGE_PAYMENT.BLACKOUT')
            when '0058' then $translate.instant('MESSAGES.SIGN_BANK_INVALID_CREDENTIALS')
            when '0069' then $translate.instant('ERRORS.ERROR_0069')
            when '1002' then $translate.instant('MESSAGES.CBS_NO_ERUNI_ERROR')
            when '1850','1002' then $translate.instant('MESSAGES.CBS_NO_ERUNI_ERROR')
            when '0055','0027' then $translate.instant('MESSAGES.SIGN_BANK_ERROR')
            else desc
        txt

    that.chooseActionDisplayed = (status, scheme) ->
        # console.log status
        txt = switch status
            when 'PENDING_FOR_SIGNATURE', 'CREATED', 'CREATION_ERROR'
                switch scheme
                    when 'ONE_SIGN','SIGN1_SIGN2' then 'SENT_FOR_SIGNATURE'
                    when 'SING_APPROVE' then 'CONFIRMED'

            when 'SENT_TO_REVISION' then 'RETURNED_FOR_EDITING'
            when 'PENDING_FOR_RELEASE_TO_CBS'
                switch scheme
                    when 'ONE_SIGN','SIGN1_SIGN2' then 'SIGNED'
                    when 'SING_APPROVE' then 'SIGNED_CONFIRMED'
            when 'SENT_TO_CBS' then 'SENT_TO_THE_BANK'
            when 'PENDING_FOR_SIGNATURE_AND_CONFIRMATION' then 'SENT_FOR_SIGNATURE_CONFIRMATION'
            when 'PENDING_FOR_CONFIRMATION','PENDING_FOR_SIGNATURE_1','PENDING_FOR_SIGNATURE_2' then 'SIGNED'
            when 'PENDING_FOR_SIGNATURES_1_AND_2' then 'SENT_FOR_SIGNATURE'
            when 'REJECTED' then 'REJECTED_BY_THE_BANK'
            when 'ACCEPTED' then 'EXECUTED'
            when 'EXPIRED' then 'DELETED_BY_THE_SYSTEM'
            when 'DELETED' then 'DELETED'
            else status
        txt

    that.trCursors = (transactions) ->
        return transactions.map (tr) ->
            return {
                ref: tr.ref
                code: tr.ref.substring 6, 8
                clientRef: tr.clientRef
                amount: tr.debitAmount || tr.creditAmount
                currency: if tr.debitAmount then tr.debitCurrency else tr.creditCurrency
            }

    that.getCursors = (transactions) ->
        return transactions.map (tr) ->
            return {
                ref: tr.ref
                code: tr.ref.substring 6, 8
            }

    # кнопки для диалога
    # набор доступных действий в соответствии со схемой авторизации, фильтрамы по статусу и типу транзакции
    that.getButtons = (transaction, authSchema) ->
        buttons = []
        # console.log $rootScope

        actions = transaction.transactionActionTypeSet || [] #доступные действия для текущей транзакции

        status = transaction.status
        authSchema = if authSchema then authSchema else that.getAuthScheme transaction.initiatorCid
        if transaction.ref
            code = transaction.ref.substring 6, 8
        #if batch
        else if transaction.transactions && transaction.transactions.length
            code = transaction.transactions[0].ref.substring 6, 8

        if transaction.status == 'ACCEPTED' || transaction.status == 'DELETED' || transaction.status == 'EXPIRED'
            buttons.push 'COPY'

        ## not EXCHANGE and not DELETED and not EXPIRED and not batch
        if code != 'FX' && code != 'SP' && transaction.status != 'DELETED' && transaction.status != 'EXPIRED' && !transaction.transactions
            buttons.push 'SAVE_AS_TEMPLATE'

        if actions.indexOf('DELETE') > -1
            buttons.push 'DELETE'

        if actions.indexOf('SEND_TO_CHANGE') > -1
            buttons.push 'SEND_TO_CHANGE'

        if actions.indexOf('CHANGE') > -1
            buttons.push 'CHANGE'
        # if actions.indexOf('REJECT') > -1
        #     buttons.push 'REJECT'

        # console.log('transaction actions authSchema status', transaction, actions, authSchema, status)

        switch authSchema
        
            when 'ONE_SIGN'

                if status == 'PENDING_FOR_SIGNATURE'

                    if actions.indexOf('SIGN_1') > -1
                        buttons.push 'SIGN_1'
                        buttons.push 'SIGN_1__SEND_TO_CBS'
                    if actions.indexOf('SIGN_2') > -1
                        buttons.push 'SIGN_2'
                        buttons.push 'SIGN_2__SEND_TO_CBS'

            when 'SING_APPROVE', 'SING_APPROVE'

                if status == 'PENDING_FOR_SIGNATURE'

                    if actions.indexOf('SIGN_1') > -1
                        buttons.push 'SIGN_1'
                        buttons.push 'SIGN_1__SEND_TO_CBS'
                    if actions.indexOf('SIGN_2') > -1
                        buttons.push 'SIGN_2'
                        buttons.push 'SIGN_2__SEND_TO_CBS'

                else if status == 'PENDING_FOR_SIGNATURE_AND_CONFIRMATION'

                    if actions.indexOf('SIGN_1') > -1
                        buttons.push 'SIGN_1'
                    if actions.indexOf('SIGN_2') > -1
                        buttons.push 'SIGN_2'
                    if actions.indexOf('APPROVE') > -1
                        buttons.push 'APPROVE'

                else if status == 'PENDING_FOR_CONFIRMATION'

                    if actions.indexOf('APPROVE') > -1
                        buttons.push 'APPROVE'
                        buttons.push 'APPROVE__SEND_TO_CBS'

            when 'SIGN1_SIGN2'

                if status == 'PENDING_FOR_SIGNATURE'

                    if actions.indexOf('SIGN_1') > -1
                        buttons.push 'SIGN_1'
                        buttons.push 'SIGN_1__SEND_TO_CBS'

                if status == 'PENDING_FOR_SIGNATURE_1'

                    if actions.indexOf('SIGN_1') > -1
                        buttons.push 'SIGN_1'
                        buttons.push 'SIGN_1__SEND_TO_CBS'

                else if status == 'PENDING_FOR_SIGNATURE_2'

                    if actions.indexOf('SIGN_2') > -1
                        buttons.push 'SIGN_2'
                        buttons.push 'SIGN_2__SEND_TO_CBS'

                else if status == 'PENDING_FOR_SIGNATURES_1_AND_2'

                    if actions.indexOf('SIGN_1') > -1
                        buttons.push 'SIGN_1'
                    if actions.indexOf('SIGN_2') > -1
                        buttons.push 'SIGN_2'

        if actions.indexOf('SEND_TO_CBS') > -1
            buttons.push 'SEND_TO_CBS'

        return buttons

    that.getSelProps = (selectedTransactions, selectedBatches, selectedTransactionsInBatch, authSchema) ->
        selProps =
            commonButtons: []
            isOneCid: false #все выбранные транзакции имеют один cid
            canGroupSignature: false #возможно груповое подписание

            batches: false #выбрана хотя бы одна пачка
            transactions: false #выбрана хотя бы одна транзакция
            multiple: false #выбраннобольше одного рядка

            singleBatch: false #выбрана одна пачка
            singleTransaction: false #выбрана одна транзакция

            fewTransactions: false #выбрано несколько транзакций
            fewBatches: false #выбрано несколько пачек

            allIsExchange: false #все выбранное - переводы
            allIsNotExchange: false #все выбранное - не переводы
            allIsNotSalary: false #все выбранное - не зарплата

            exhangeConflict: false
            spConflict: false

        #generate selection props
        allSelected = selectedTransactions.concat selectedBatches

        selProps.batches = !!selectedBatches.length
        selProps.transactions = !!selectedTransactions.length

        selProps.fewBatches = selectedBatches.length > 1
        selProps.fewTransactions = selectedTransactions.length > 1

        selProps.singleBatch = !selectedTransactions.length && selectedBatches.length == 1
        selProps.singleTransaction = !selectedBatches.length && selectedTransactions.length == 1


        if selProps.batches || selProps.transactions
            #temp variables
            isOneCid = true
            isTemplateAllowed = true
            allIsExchange = true
            allIsSalary = true
            allIsNotExchange = true
            allIsNotSalary = true
            commonButtons = {}
            canGroupSignature = true

            firstCid = allSelected[0].initiatorCid
            for tr in allSelected
                isOneCid = isOneCid && tr.initiatorCid == firstCid
                isTemplateAllowed = isTemplateAllowed && tr.isTemplateAllowed
                allIsExchange = allIsExchange && (tr.type == 'EXCHANGE' || tr.code == 'FX') #true если среди только FX, false если один или все другие типы
                allIsNotExchange = allIsNotExchange && (tr.type != 'EXCHANGE' && tr.code != 'FX') #true если среди отмеченных нет FX
                allIsSalary = allIsSalary && (tr.type == 'SALARY' || tr.code == 'SP')
                allIsNotSalary = allIsNotSalary && (tr.type != 'SALARY' && tr.code != 'SP')
                #check actions (get common buttons for every selected transaction)
                for btn in that.getButtons(tr, authSchema)
                    if !commonButtons[btn] then commonButtons[btn] = 0
                    commonButtons[btn] += 1

                canGroupSignature = canGroupSignature && (tr.status == "PENDING_FOR_SIGNATURE" || tr.status == "PENDING_FOR_SIGNATURES_1_AND_2" || tr.status == "PENDING_FOR_SIGNATURE_AND_CONFIRMATION")
                
            selProps.isOneCid = isOneCid
            selProps.isTemplateAllowed = isTemplateAllowed
            selProps.allIsExchange = allIsExchange
            selProps.allIsNotExchange = allIsNotExchange
            selProps.allIsSalary = allIsSalary
            selProps.allIsNotSalary = allIsNotSalary

            selProps.exhangeConflict = !allIsNotExchange && !allIsExchange
            selProps.spConflict = !allIsNotSalary && !allIsSalary
            
            selProps.canGroupSignature = canGroupSignature
            selProps.multiple = selectedTransactions.length + selectedBatches.length > 1


            # the button is common if the count of the button is identical to count of selected transactions
            selProps.commonButtons = []
            for btn of commonButtons
                if commonButtons[btn] == allSelected.length
                    selProps.commonButtons.push btn

        return selProps

    return that
]
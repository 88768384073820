'use strict'

angular.module('IBR')
.controller 'TemplatesCtrl', [ '$q', '$rootScope', '$scope', '$state', '$filter', '$timeout', '$translate', 'pagerService', 'apiAccounts', 'authoritiesFactory', 'apiPayment', 'apiTemplates', 'alertModal', 'LxNotificationService', 'LxDialogService', ($q, $rootScope, $scope, $state, $filter, $timeout, $translate, pagerService, apiAccounts, authoritiesFactory, apiPayment, apiTemplates, alertModal, LxNotificationService, LxDialogService) ->

    PAGE_SIZES = [50, 100]

    FAIL_DIALOG_ID = "fail-actions-dial"

    vm = this

    vm.templateFilter = {}
    vm.addId = 'template-add-dial'
    vm.loaded = false
    vm.dialogType = "internal"
    # vm.allTemplates = []
    vm.templates = []
    initialTemplates = [] #when we sorts acc-ts by type or amount, we can use it to returnin' to starting view
    vm.allTemplatesChecked = false
    vm.ownSelected = false
    vm.templateChecked = {}
    vm.templatesSelected = []

    vm.selfAccount = true
    vm.otherAccount = false
    vm.selfAccounts = []
    vm.tmpTemplate = {}
    vm.currentTemplate = {}
    vm.interBank = false # for swift payments

    vm.pageSize = PAGE_SIZES[0]
    vm.pageSizes = PAGE_SIZES
    vm.pageNumber = 1
    vm.pager = {}

    vm.formLoaded = false

    vm.isSorted = true #value to c, is the array sorted by less values
    domesticTypes = ["AZ", "XO", "XT", "XV"] #array of domestic types
    swiftTypes = ["FP", "RP"] #array of swift types

    translateMessages =->
        vm.FPtypes = [
            {name: $filter('translate')('PAYMENT.SWIFT_CODE'), value: 'SWF'},
            {name: $filter('translate')('SWIFT_PAYMENT.CODES_ABA'), value: 'ABA'},
            {name: $filter('translate')('SWIFT_PAYMENT.CODES_SORT'), value: 'SRT'},
            {name: $filter('translate')('SWIFT_PAYMENT.CODES_OTHER'), value: 'OTH'},
        ]
        vm.messages = {
            templateSuccess: $filter('translate')('TEMPLATES_PAYMENT.UPDATE_TEMPLATE_SUCCESS')
            deleteTemplateSuccess: $filter('translate')('TEMPLATES_PAYMENT.DELETE_TEMPLATE_SUCCESS')
            deleteTemplates: $filter('translate')('DIALOG.ATTENTION')
            deleteTemplatesQ: $filter('translate')('TEMPLATES_PAYMENT.DELETE_TEMPLATES_Q')
            deleteNoTemplate: $filter('translate')('ERRORS.NO_AUTHORITY')
        }

    translateMessages()
    $rootScope.$on '$translateChangeSuccess', () ->
        translateMessages()
        return

    vm.typesList = [
        {
            title: 'ALL.ALL'
            short: 'ALL.ALL'
            code: ''
            type: ''
        }
        {
            title: 'INTERNAL_PAYMENT.TITLE'
            short: 'SHORT_PAYMENTS.INTERNAL'
            code: 'IT'
            type: 'INTERNAL_ACCOUNT_TO_ACCOUNT'
        }
        {
            title: 'DOMESTIC_PAYMENT.TITLE_AZIPS'
            short: 'SHORT_PAYMENTS.AZIPS'
            code: 'AZ'
            type: 'AZIPS'
        }
        {
            title: 'DOMESTIC_PAYMENT.TITLE_XOHKS'
            short: 'SHORT_PAYMENTS.XOHKS'
            code: 'XO'
            type: 'XOHKS'
        }
        {
            title: 'DOMESTIC_PAYMENT.TITLE_TREASURY'
            short: 'SHORT_PAYMENTS.TREASURY'
            code: 'XT'
            type: 'BUDGET'
        }
        {
            title: 'DOMESTIC_PAYMENT.TITLE_VAT'
            short: 'SHORT_PAYMENTS.VAT'
            code: 'XV'
            type: 'BUDGET_VAT'
        }
        {
            title: 'INSTANT_PAYMENT.TITLE_PI'
            short: 'SHORT_PAYMENTS.PI'
            code: 'PI'
            type: 'PI'
        }
        {
            title: 'INSTANT_PAYMENT.TITLE_PT'
            short: 'SHORT_PAYMENTS.PT'
            code: 'PT'
            type: 'PT'
        }
        {
            title: 'INSTANT_PAYMENT.TITLE_PV'
            short: 'SHORT_PAYMENTS.PV'
            code: 'PV'
            type: 'PV'
        }
        {
            title: 'SWIFT_PAYMENT.FOREIGHN_TITLE'
            short: 'SHORT_PAYMENTS.FOREIGHN'
            code: 'FP'
            type: 'INTERNATIONAL'
        }
        {
            title: 'SWIFT_PAYMENT.RUB_TITLE'
            short: 'SHORT_PAYMENTS.RUB'
            code: 'RP'
            type: 'INTERNATIONAL_RUB'
        }
    ]

    vm.codeTransl = {
        'IT': 'INTERNAL_PAYMENT.TITLE'
        'AZ': 'DOMESTIC_PAYMENT.TITLE_AZIPS'
        'XO': 'DOMESTIC_PAYMENT.TITLE_XOHKS'
        'XT': 'DOMESTIC_PAYMENT.TITLE_TREASURY'
        'XV': 'DOMESTIC_PAYMENT.TITLE_VAT'
        'PI': 'INSTANT_PAYMENT.TITLE_PI'
        'PT': 'INSTANT_PAYMENT.TITLE_PT'
        'PV': 'INSTANT_PAYMENT.TITLE_PV'
        'FP': 'SWIFT_PAYMENT.FOREIGHN_TITLE'
        'RP': 'SWIFT_PAYMENT.RUB_TITLE'
    }

    vm.codeTranslShort = {
        'IT': 'SHORT_PAYMENTS.INTERNAL'
        'AZ': 'SHORT_PAYMENTS.AZIPS'
        'XO': 'SHORT_PAYMENTS.XOHKS'
        'XT': 'SHORT_PAYMENTS.TREASURY'
        'XV': 'SHORT_PAYMENTS.VAT'
        'FP': 'SHORT_PAYMENTS.FOREIGHN'
        'RP': 'SHORT_PAYMENTS.RUB'
        'PI': 'SHORT_PAYMENTS.PI'
        'PT': 'SHORT_PAYMENTS.PT'
        'PV': 'SHORT_PAYMENTS.PV'
    }

    vm.templateFilter = {
        type: vm.typesList[0]
        name: ''
        fromAccount: ''
        toAccount: ''
        narrative: ''
        currentCustomer: ''
    }

    initCustomers = () ->
        if !$rootScope.currentUser || !$rootScope.currentUser.corporateCustomers
            $rootScope.$on 'USER_LOADED', () ->
                initCustomers()
            return;
        $timeout(->
            if $rootScope.selectedCorporateCustomers.length
                vm.customers = angular.copy($rootScope.selectedCorporateCustomers)
            else
                vm.customers = angular.copy($rootScope.currentUser.corporateCustomers)
            if vm.customers.length is 1
                vm.templateFilter.currentCustomer = vm.customers[0]
            else if vm.customers.length > 1
                vm.customers.unshift({ name: 'ALL', cid: '' })
                if !vm.loaded
                    vm.templateFilter.currentCustomer = vm.customers[0]
                    vm.filterTemplates()
        )
    initCustomers()

    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        if $rootScope.selectedCorporateCustomers.length
            vm.customers = angular.copy($rootScope.selectedCorporateCustomers)
            if vm.customers.length is 1
                vm.templateFilter.currentCustomer = vm.customers[0]
            else if vm.customers.length > 1
                vm.customers.unshift({ name: 'ALL', cid: '' })
                if vm.templateFilter.currentCustomer is vm.customers[0]
                    vm.filterTemplates()
        else
            initCustomers()

    getAccounts = ->
        # watch clear value
        $scope.$watch 'templatectrl.templateFilter', ( (val, oldval) ->
            if val.currentCustomer != oldval.currentCustomer
                vm.filterTemplates()
            else
                for prop of val
                    if typeof val[prop] == 'undefined'
                        vm.filterTemplates()
                        break
        ), true
        apiAccounts.getAccounts().then (response) ->
            vm.accounts = response.data
        

    # vm.sortTemplates = () ->
    #     rows = []
    #     angular.forEach vm.allTemplates, (template) ->
    #         #set type
    #         for type in vm.typesList
    #             if template.transfer.code == type.code
    #                 template.type = type
    #
    #         #filter by code
    #         if vm.templateFilter.type && vm.templateFilter.type.code
    #             if template.transfer.code == vm.templateFilter.type.code
    #                 rows.push(template)
    #         else
    #             rows.push(template)
    #     vm.templates = rows


    vm.allTemplatesCheck = () ->
        if vm.allTemplatesChecked
            angular.forEach vm.templates, (template) ->
                vm.templateChecked[template.uuid] = true
                return
        else
            angular.forEach vm.templates, (template) ->
                vm.templateChecked[template.uuid] = false
                return
        return

    vm.addTemplate = ->
        vm.dialogType = 'internal'
        LxDialogService.open vm.addId
        return

    vm.goTemplate = ->
        document.body.scrollTop = 0
        $state.go(vm.dialogType + '-payment')
        return

    checkTempPermissions = ->
#        $timeout(->
        permissions = $rootScope.currentUser.authorities
        vm.canCreateInt = permissions.indexOf('INTERNAL_ACCOUNT_TO_ACCOUNT_CREATE') != -1
        canCreateXo = permissions.indexOf('XOHKS_CREATE') != -1
        canCreateBudget = permissions.indexOf('BUDGET_CREATE') != -1
        canCreateAz = permissions.indexOf('AZIPS_CREATE') != -1
        canCreateVat = permissions.indexOf('BUDGET_VAT_CREATE') != -1
        vm.canCreateFSwift = permissions.indexOf('INTERNATIONAL_CREATE') != -1
        canCreatePi = permissions.indexOf('AZIPS_CREATE') != -1
        canCreatePt = permissions.indexOf('XOHKS_CREATE') != -1
        canCreatePv = permissions.indexOf('BUDGET_CREATE') != -1

        vm.canTemplate = vm.canCreateInt || canCreateXo || canCreateBudget || canCreateAz || canCreateVat || vm.canCreateFSwift
        vm.canDomestic = canCreateXo || canCreateBudget || canCreateAz || canCreateVat
        vm.canCreateInstant = canCreatePi || canCreatePt || canCreatePv
#        ,200)
        return

    vm.setPage = (page) ->
        vm.filterTemplates(page)

    vm.setPageSize = (pageSize) ->
        vm.pageSize = pageSize
        vm.filterTemplates()


    vm.filterTemplates = (page) ->
        if page
            vm.pageNumber = page
        else
            vm.pageNumber = 1
        for key of vm.templateFilter
            if typeof vm.templateFilter[key] == 'undefined'
                vm.templateFilter[key] = ''

        templateQuery =
            pageParams:
                "pageNumber": vm.pageNumber
                "size": vm.pageSize
            sort:
                "fieldName": "name"
                "order": "ASC"
            filterSpecification: []

        filters = []
        for prop of vm.templateFilter
            if prop == 'type' && vm.templateFilter.type.code
                filters.push({
                    fieldName:"type"
                    inverted:"false"
                    operator:"EQUALS"
                    "values":[vm.templateFilter.type.type]
                })
            if vm.templateFilter[prop] && prop != 'type'

                if prop == 'currentCustomer'
                    if vm.templateFilter.currentCustomer && vm.templateFilter.currentCustomer.cid
                        filters.push({
                            "fieldName": "initiatorCid"
                            "inverted": "false"
                            "operator": "LIKE"
                            "values": ["%"+vm.templateFilter.currentCustomer.cid+"%"]
                        })
                    else
                        if vm.templateFilter.currentCustomer && !vm.templateFilter.currentCustomer.cid
                            values = vm.customers.map (cust) ->
                                cust.cid
                            .filter (cid) ->
                                cid
                            filters.push({
                                "fieldName": "initiatorCid"
                                "inverted": "false"
                                "operator": "IN"
                                "values": values
                            })
                else
                    filters.push({
                        "fieldName": prop
                        "inverted": "false"
                        "operator": "LIKE"
                        "values": ["%"+vm.templateFilter[prop]+"%"]
                    })

        templateQuery.filterSpecification = filters

        # if vm.templateFilter.currency
        #     for filter in templateQuery.filterSpecification
        #         console.log filter

        if vm.isSorted
            templateQuery.sort =
                "fieldName": "amount"
                "order": "ASC"
        else
            templateQuery.sort =
                "fieldName": "amount"
                "order": "DESC"

        apiTemplates.getTemplates(templateQuery).then (response) ->
            #vm.allTemplates = response.data.records
            vm.templateChecked = {}
            vm.templates = response.data.records
            vm.pager = pagerService.getPager response.data.totalRecordsCount, vm.pageNumber, vm.pageSize
            # vm.sortTemplates();
            if !vm.loaded
                $timeout(->
                    checkTempPermissions()
                , 300)
                vm.loaded = true
            return
        .catch (err) ->
            vm.loaded = true
            return

        return

    vm.template = {}
    #TEMPLATE ACTIONS
    vm.templateOpen = (uuid) ->
        vm.template = {}
        vm.tmpTemplate = {}
        vm.formLoaded = false

        for t in vm.templates
            if t.uuid == uuid
                vm.tmpTemplate = t

        if vm.tmpTemplate.uuid
            vm.isEditing = false
            vm.currentTemplate = angular.copy vm.tmpTemplate
            LxDialogService.open "template-dial"
        return

    #template form is ready fo filling
    $scope.$on 'FORM_READY', ( ->
        vm.formLoaded = true
        $scope.$broadcast('TEMPLATE_SELECT', vm.currentTemplate)
    )

    vm.editingTemplate = ->
        vm.isEditing = true
        return

    vm.redirect = ->
        console.log "redirect", vm.currentTemplate
        $state.go 'instant-payment', { template: vm.currentTemplate}



    vm.cancelEditingTemplate = ->
        vm.currentTemplate = angular.copy vm.tmpTemplate
        vm.isEditing = false
        $scope.$broadcast('TEMPLATE_SELECT', vm.currentTemplate)
        return

    vm.updateTemplate = () ->
        vm.saveBtnLock = true
        uuid = vm.currentTemplate.uuid
        type = vm.currentTemplate.transfer.code
        $scope.$broadcast('PAYMENT_LOCAL_VALIDATE', true)
        $timeout(->
            if vm.isValid
                template = angular.copy vm.template
                template.name = template.templateName
                template.cid = template.customer.cid

                if template.debit
                    if template.debit.iban
                        template.debitIban = template.debit.iban
                    else if !template.debit.deleted
                        template.debitIban = template.debit
                if template.credit
                    if template.credit.iban
                        template.creditIban = template.credit.iban
                    else
                        template.creditIban = template.credit

                if !template.narrative
                    delete template.narrative

                if !template.notes
                    delete template.notes

                if !template.beneficiaryNarrative
                    delete template.beneficiaryNarrative

                if template.amount
                    template.amount = template.amount.replace(/\s/g,'')

                delete template.templateName
                delete template.customer
                delete template.credit
                delete template.debit
                delete template.clientRef

                if type == 'FP'
                    if template.typeCode != "OTH"
                        if template.beneficiaryBankInfo.hasOwnProperty('correspondentAccountIban') then delete template.beneficiaryBankInfo.correspondentAccountIban
                    if template.typeCode != "SWF"
                        if(template.beneficiaryBankInfoSwiftCode)
                            template.beneficiaryBankInfo.bic = template.beneficiaryBankInfoSwiftCode
                        switch template.typeCode
                            when 'ABA'
                                if template.beneficiaryBankInfo.bic.indexOf("//FW") == -1
                                    template.beneficiaryBankInfo.bic = "//FW" + template.beneficiaryBankInfo.bic
                            when 'SRT'
                                if template.beneficiaryBankInfo.bic.indexOf("//SC") == -1
                                    template.beneficiaryBankInfo.bic = "//SC" + template.beneficiaryBankInfo.bic
                    delete template.beneficiaryBankInfoSwiftCode #TODO: Why we r using this variable?
                else if type == 'RP'
                    console.log(template)

                # console.log template
                apiTemplates.editTemplate(type, uuid, template).then (response) ->
                    vm.saveBtnLock = false
                    vm.filterTemplates()
                    alertModal.showMessage $filter('translate')('DIALOG.ATTENTION'), vm.messages.templateSuccess, 'Ok', (answer) ->
                        vm.currentTemplate.name = template.name
                        vm.isEditing = false
                        return
                    return
                .catch (err) ->
                    console.error 'Error while saving template:', err
                    vm.saveBtnLock = false
            else
                vm.saveBtnLock = false
        )
        return

    deleteAlert = (uuids, result) ->

        resUuids = result.map (res) ->
            return res.resourceId
        fails = []
        # find not processed
        for uuid in uuids
            if resUuids.indexOf(uuid) == -1
                fails.push({
                    name: uuid
                    message: vm.messages.deleteNoTemplate
                })

        failsTemplates = {}
        for template in vm.templates
            if resUuids.indexOf(template.uuid) > -1
                failsTemplates[template.uuid] = template

        for res in result
            if !res.success
                message = if res.errors[0].code == '0008' then $filter('translate')('ERRORS.NO_AUTHORITY') else res.errors[0].message
                fails.push({
                    name: failsTemplates[res.resourceId].name
                    message: message
                })

        if fails.length
            if uuids.length > 1
                vm.fails = fails
                LxDialogService.open FAIL_DIALOG_ID
            else
                alertModal.showError $filter('translate')('DIALOG.ATTENTION'), fails[0].message, 'Ok', (answer) ->
                    return
        else
            alertModal.showMessage $filter('translate')('DIALOG.ATTENTION'), vm.messages.deleteTemplateSuccess, 'Ok', (answer) ->
                LxDialogService.close "template-dial"
                return

    deleteOneTemplate = (template) ->
        apiTemplates.deleteTemplates(template.transfer.code, {uuids: [template.uuid]}).then (response) ->
            vm.filterTemplates()
            vm.templateChecked = {}
            vm.allTemplatesChecked = false
            deleteAlert([template.uuid],response.data)
            return

    vm.templateDeleteChecked = () ->
        alertModal.confirm vm.messages.deleteTemplates, vm.messages.deleteTemplatesQ, () ->
            template = {}
            batch = {}
            for uuid in vm.templatesSelected
                for tmp in vm.templates
                    if uuid == tmp.uuid
                        template = tmp
                        if !batch[template.transfer.code]
                            batch[template.transfer.code] = []
                        batch[template.transfer.code].push template.uuid

            if vm.templatesSelected.length == 1
                deleteOneTemplate(template)
            else if vm.templatesSelected.length > 1
                requests = []
                allUuids = []
                for own code, uuids of batch
                    allUuids = allUuids.concat(uuids)
                    requests.push apiTemplates.deleteTemplates(code, {uuids: uuids})

                $q.all(requests).then (results) ->
                    resData = []
                    for res in results
                        for r in res.data
                            resData.push(r)
                    deleteAlert(allUuids,resData)
                    vm.filterTemplates()
                    vm.allTemplatesChecked = false


    vm.deleteTemplate = (template) ->
        alertModal.confirm vm.messages.deleteTemplates, vm.messages.deleteTemplatesQ, () ->
            deleteOneTemplate(template)

    compareAmount = (firstVal, secondVal) ->
        if firstVal.transfer.amount
            valOne = firstVal.transfer.amount
        else
            valOne = 0
        if secondVal.transfer.amount
            valTwo = secondVal.transfer.amount
        else
            valTwo = 0
        if vm.isSorted
            return valOne - valTwo
        else
            return valTwo - valOne

    vm.sortAmount = () ->
        vm.isSorted = !vm.isSorted
        vm.filterTemplates()
        return

    $scope.$watch 'templatectrl.templateChecked', ( ->
        selected = []
        for own uuid, checked of vm.templateChecked
            if checked
                selected.push uuid

        vm.templatesSelected = selected

        vm.manySelected = if selected.length > 1 then true else false
        vm.allTemplatesChecked = if vm.templates.length == selected.length then true else false


        ownSelected = true
        vm.templates.map((item, index) ->
            if selected.indexOf(item.uuid) > -1
                ownSelected *= item.isMyTemplate
        )
        vm.ownSelected = ownSelected
    ), true


    authoritiesFactory.checkAuthority('ACCOUNTS', 'READ').then (response) ->
        if !response
            vm.loaded = true
            alertModal.showError $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('ERRORS.ACCOUNTS_READ'), 'Ok'
        else
            getAccounts()

    return
]

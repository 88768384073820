'use strict'

angular.module('IBR').component 'datasFileInput', {

    template: '<div class="datas-file-input datas-file-input-field" ng-class="vm.fileName ? \'notempty\' : \'empty\'">'+
        '<div class="filename" lx-tooltip="{{vm.fileName}}">'+
            '<span>{{ vm.fileName }}</span>'+
            '<div ng-if="vm.fileName" class="clear" ng-click="vm.clearFile()">'+
                '<i class="mdi mdi-close"></i>'+
            '</div>'+
        '</div>'+
        '<div class="fileinput">'+
            '<input accept={{vm.accept}} type="file"/>'+
            '<button ng-disabled="vm.disabled" ng-if="!vm.fileName" class="btnx btnx-primary">{{ vm.labelSelect }}</button>'+
            '<button ng-if="vm.fileName" class="btnx btnx-success" ng-click="vm.readFile()">{{ vm.labelRead }}</button>'+
        '</div>'+
    '</div>'
    bindings: {
        accept: '@'
        disabled: '<'
        onRead: '='
        labelSelect: '<'
        labelRead: '<'
    }
    controllerAs: "vm"
    controller: ['$scope', '$translate', '$element', '$attrs', '$timeout', ($scope, $translate, $element, $attrs, $timeout) ->
        vm = this
        file = null
        vm.fileName = ''
        fileinput = $element.find('input[type=file]')[0]
        fileinput.onchange = () ->
            if fileinput.files.length
                file = fileinput.files[0]
                vm.fileName = file.name
                $scope.$apply()

        vm.readFile = () ->
            if file
                vm.onRead file
                vm.clearFile()

        vm.clearFile = () ->
            file = null
            fileinput.value = ''
            vm.fileName = ''

        $scope.$on 'CLEAR_FILE', () ->
            vm.clearFile()

        return vm
            
        
    ]
}

angular.module('IBR').component 'datasFileButton', {

    template: '<div class="datas-file-input" ng-class="vm.fileName ? \'notempty\' : \'empty\'">'+
        '<div class="fileinput">'+
            '<input accept={{vm.accept}} type="file"/>'+
            '<button class="btnx btnx-primary">'+
                '<ng-transclude></ng-transclude>'+
            '</button>'+
        '</div>'+
    '</div>',
    transclude: true,
    bindings: {
        accept: '@'
        disabled: '<'
        onChange: '='
    }
    controllerAs: "vm"
    controller: ['$scope', '$translate', '$element', '$attrs', '$timeout', ($scope, $translate, $element, $attrs, $timeout) ->
        vm = this
        file = null
        vm.fileName = ''
        fileinput = $element.find('input[type=file]')[0]
        fileinput.onchange = () ->
            
            if fileinput.files.length
                file = fileinput.files[0]
                vm.onChange file
                vm.fileName = file.name
                $scope.$apply()

            fileinput.value.replace(/C:\\fakepath\\/i, '')

        vm.readFile = () ->
            if file
                vm.onRead file
                vm.clearFile()

        vm.clearFile = () ->
            file = null
            fileinput.value = ''
            vm.fileName = ''

        $scope.$on 'CLEAR_FILE', () ->
            vm.clearFile()

        return vm
            
        
    ]
}
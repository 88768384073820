ua = window.navigator.userAgent
msie = ua.indexOf("MSIE ")
if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
  ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)))
  document.documentElement.setAttribute('data-ie', ieVersion)

Date::toIsoString = ->
  tzo = -@getTimezoneOffset()
  dif = if tzo >= 0 then '+' else '-'

  pad = (num) ->
    norm = Math.floor(Math.abs(num))
    (if norm < 10 then '0' else '') + norm

  @getFullYear() + '-' + pad(@getMonth() + 1) + '-' + pad(@getDate()) + 'T' + pad(@getHours()) + ':' + pad(@getMinutes()) + ':' + pad(@getSeconds()) + dif + pad(tzo / 60) + ':' + pad(tzo % 60)

String::getUrlParams = ->
  hashes = @slice(@indexOf('?') + 1).split('&')
  params = {}
  hashes.map (hash) ->
    tmp = hash.split('=')
    params[tmp[0]] = decodeURIComponent tmp[1]
  params

if !Array::includes
  Object.defineProperty Array.prototype, 'includes', value: (searchElement, fromIndex) ->
    sameValueZero = (x, y) ->
      x == y or typeof x == 'number' and typeof y == 'number' and isNaN(x) and isNaN(y)
    if this == null
      throw new TypeError('"this" is null or not defined')
    o = Object(this)
    len = o.length >>> 0
    if len == 0
      return false
    n = fromIndex | 0
    k = Math.max((if n >= 0 then n else len - Math.abs(n)), 0)
    while k < len
      if sameValueZero(o[k], searchElement)
        return true
      k++
    false

# Array::sum = (prop) ->
#     total = 0
#     i = 0
#     while i < this.length
#       total += this[i][prop]
#     return total

if !Object.assign
  Object.defineProperty Object, 'assign',
    enumerable: false
    configurable: true
    writable: true
    value: (target, firstSource) ->
      'use strict'
      if target == undefined or target == null
        throw new TypeError('Cannot convert first argument to object')
      to = Object(target)
      i = 1
      while i < arguments.length
        nextSource = arguments[i]
        if nextSource == undefined or nextSource == null
          i++
          continue
        keysArray = Object.keys(Object(nextSource))
        nextIndex = 0
        len = keysArray.length
        while nextIndex < len
          nextKey = keysArray[nextIndex]
          desc = Object.getOwnPropertyDescriptor(nextSource, nextKey)
          if desc != undefined and desc.enumerable
            to[nextKey] = nextSource[nextKey]
          nextIndex++
        i++
      to

if !Object.values
  Object.defineProperty Object, 'values',
    enumerable: false
    configurable: true
    writable: false
    value: (target) ->
      'use strict'
      if target == undefined or target == null
        throw new TypeError('Cannot convert first argument to object')
      to = Object(target)
      values = []
      for val of to
        values.push to[val]

      return values

Math.easeInOutQuad = (t, b, c, d) ->
  t /= d / 2
  if t < 1
    return c / 2 * t * t + b
  t--
  -c / 2 * (t * (t - 2) - 1) + b


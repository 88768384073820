'use strict'

angular.module('IBR')
.controller 'WidgetOperationsCtrl', [ '$rootScope', '$scope','apiOperations','authoritiesFactory', ($rootScope, $scope,apiOperations,authoritiesFactory) ->

    vm = this
    vm.opened = false
    vm.operations = []
    baseLimit = 4
    vm.displayLimit = baseLimit
    vm.tooMany = false
    vm.loaded = false

    customerHandler = ()->
        vm.error = false
        vm.loaded = false
        cids = $rootScope.selectedCorporateCustomers.map (customer) ->
            customer.cid

        if cids && cids.length is 1
            vm.tooMany = false
            apiOperations.getFilteredOperations({cids: cids}).then (response) ->
                vm.operations = response.data
                vm.loaded = true
                return
            ,() ->
                vm.loaded = true
                vm.error = true
                return
            return
        else
            vm.operations = []
            vm.tooMany = true
            vm.loaded = true
            return
        return

    vm.init = () ->
        if !vm.loaded
            authoritiesFactory.checkAuthority 'ACCOUNT_ACTIVITIES_WIDGET', 'READ'
                .then (res)->
                    if res
                        customerHandler()
                        $rootScope.$on 'SELECT_CUSTOMERS', () ->
                            customerHandler()
                        ,true

    vm.toggleLimit = (limit) ->
        length = vm.operations.length
        if limit
            vm.displayLimit = limit
        else
            vm.displayLimit = length
        return
    return
]

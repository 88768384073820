'use strict'

angular.module('IBR')
.factory 'transactionModal', [ '$rootScope', '$timeout', '$q', '$translate', 'alertModal', 'LxDialogService', 'apiPayment', 'apiBulkPayments', 'transactionActions', ($rootScope, $timeout, $q, $translate, alertModal, LxDialogService, apiPayment, apiBulkPayments, transactionActions) ->

    that = {}

    TRANSACTION_MODAL = 'transaction-modal'
    TRANSACTION_GROUP_MODAL = 'transaction-group-modal'
    CUT_OFF_CHECK = [
        'PENDING_FOR_SIGNATURE'
        'PENDING_FOR_SIGNATURE_1'
        'PENDING_FOR_SIGNATURE_2'
        'PENDING_FOR_SIGNATURES_1_AND_2'
        'PENDING_FOR_CONFIRMATION'
        'PENDING_FOR_SIGNATURE_AND_CONFIRMATION'
        'SENT_TO_REVISION'
        'PENDING_FOR_RELEASE_TO_CBS'
    ]

    that.downloadAction = (type, transaction, lang ) ->
        $rootScope.loaders[type] = true
        apiPayment.transactionFile(transaction.ref, lang.lngu, type)
            .then (response) ->
                window.utils.downloadFile response
                $rootScope.loaders[type] = false
            .catch () ->
                $rootScope.loaders[type] = false
    
    that.lock = (btnKey) ->
        console.log 'call lock', btnKey
        $rootScope.modals.transaction.loaders[btnKey] = true
        $rootScope.modals.transactions.loaders[btnKey] = true
        $rootScope.modals.transaction.lock = true
        $rootScope.modals.transactions.lock = true

    that.unlock = () ->
        console.log 'call unlock'
        $rootScope.modals.transaction.loaders = {}
        $rootScope.modals.transactions.loaders = {}
        $rootScope.modals.transaction.lock = false
        $rootScope.modals.transactions.lock = false


    that.getBulkPaymentDetails = (p) ->
        # console.log p
        details = {
            uuid: p.uuid
            source: 'BULK_UPLOAD'
            bulkId: p.bulkId
            code: p.transferType
            amount: p.debitAmount
            clientRef: p.clientRef
            commissionDTO: p.commission
            corporateCustomerInitiatorCid: p.corporateCustomerInitiator.cid
            corporateCustomerInitiatorname: p.corporateCustomerInitiator.name
            credit: p.credit.iban
            creditCurrency: p.currency
            debit: p.debit.iban
            debitCurrency: p.debit.currency
            # isToOwn: true
            narrative: p.narrative
            status: p.status
            beneficiaryName: p.beneficiaryName
            beneficiaryTaxId: p.beneficiaryTaxId
            budgetClassificationCode: p.budgetClassificationCode
            budgetLevelCode: p.budgetLevelCode
            beneficiaryNarrative: p.additionalInfo
            notes: p.userNote

            bankInfo: p.bankInfoDTO
        }




        return $timeout(->
            return { data: details }
        )


    that.openInfo = (transaction, btnKeys, actionCallback, actionDownloadCallback) ->

        if !actionDownloadCallback
            actionDownloadCallback = that.downloadAction
            
        $rootScope.modals.transaction.currentLang = $rootScope.currentLang
        
        if btnKeys && !angular.isArray btnKeys
            btnKeys = [ btnKeys ]
        
        emptyRequest = $timeout(->
            return {}
        )
        reqs = [
            emptyRequest
            emptyRequest
            emptyRequest
            emptyRequest
        ]

        if transaction.ref
            code = transaction.ref.substring 6, 8
            reqs[0] = apiPayment.getPaymentDetail transaction.ref
            reqs[1] = apiPayment.getPaymentHistory transaction.ref
        else
            code = transaction.transferType
            reqs[0] = that.getBulkPaymentDetails transaction

        # if transaction.ref && CUT_OFF_CHECK.indexOf transaction.status > -1 && code != 'FX'
        #     reqs[2] = apiPayment.checkCutOff code

        # console.log transaction
        if transaction.bulkId && transaction.uuid
            reqs[3] = apiBulkPayments.getHistory transaction.uuid

        transaction.loading = true

        $q.all(reqs)
            .then (responses) ->
                # console.log responses
                transaction.loading = false
                paymentDetails = responses[0].data
                paymentHistory = (responses[1].data && responses[1].data.historyDetails) || []
                paymentBulkHistory = responses[3].data || []
                cutOffMessage = responses[2].data && responses[2].data.length && responses[2].data[0].code || null
                actionButtons = []

                $rootScope.modals.transaction.cutOffMessage = ''
                if cutOffMessage
                    $rootScope.modals.transaction.cutOffMessage = $translate.instant('PAYMENT.' + cutOffMessage)

                $rootScope.modals.transaction.trHasNotes = false
                
                history = paymentHistory.concat paymentBulkHistory

                # console.log 'history', history

                for item in history
                    item.date = if ( item.meta.user && item.meta.user.LOGIN == 'Bank' ) then item.data.lastModifiedDate else item.meta.dateTime
                    if !$rootScope.modals.transaction.trHasNotes
                        if item.data.userNote || item.data.cbsNote || (item.meta.action is "SEND_TO_CBS" && (item.data.errorDesc || item.data.errorCode))
                            $rootScope.modals.transaction.trHasNotes = true
                    if item.meta.action is "SEND_TO_CBS"
                        item.actDoing = "SENT_TO_THE_BANK"
                        if item.data.errorDesc || item.data.errorCode
                            code = item.data.errorCode || ''
                            desc = item.data.errorDesc || code
                            item.data.userNote = transactionActions.getErrorMsg code, desc
                    else
                        status = item.data.status
                        action = item.meta.action

                        if ['INITIATE', 'VALIDATE'].includes action
                            item.actDoing = action
                        else
                            authScheme = transactionActions.getAuthScheme item.data.corporateCustomerInitiator.cid
                            item.actDoing = transactionActions.chooseActionDisplayed status, authScheme
                            # console.log status, authScheme, item.actDoing

                        if item.data.cbsNote
                            item.data.userNote = item.data.cbsNote
                    item


                # if history.length

                $rootScope.modals.transaction.paymentDetails = paymentDetails
                $rootScope.modals.transaction.history = history

                #set buttons
                if btnKeys
                    actionButtons = btnKeys.map (key) ->
                        return transactionActions.buttons[key]
                else
                    actionButtons = transactionActions.getTransactionButtons transaction
                $rootScope.modals.transaction.actionButtons = actionButtons

                #set action callback
                if actionCallback
                    $rootScope.modals.transaction.callAction = (btnKey) ->
                        if $rootScope.modals.transaction.lock
                            return
                        actionCallback paymentDetails, btnKey

                $rootScope.modals.transaction.download = (type) ->
                    actionDownloadCallback type, paymentDetails, $rootScope.modals.transaction.currentLang

                LxDialogService.open TRANSACTION_MODAL
                # else
                #     alertModal.showMessage $translate.instant('DIALOG.TRANSFER'), 'The data for this record is not available. Please contact the bank', 'Ok', () ->
            .catch (err) ->
                console.log err
                transaction.loading = false

    that.openGroup = (transactions, batches, btnKey, actionCallback) ->
        if !transactions && !batches
            return

        apiPayment.mergeTransactions(transactions, batches)
            .then (trs) ->
                dialogData = {}
                if !trs.length
                    return
                for tr in trs
                    code = tr.ref.substring 6, 8
                    if code is 'FX'
                        if tr.debitAmount
                            currency = tr.debitCurrency
                            amount = tr.debitAmount
                        else
                            currency = tr.creditCurrency
                            amount = tr.creditAmount
                    else
                        currency = tr.debitCurrency
                        amount = tr.debitAmount || 0
                    commission = tr.commissionAmount || 0

                    if dialogData[code] && dialogData[code].currencies
                        if dialogData[code].currencies[currency]
                            dialogData[code].currencies[currency].amount += amount
                            dialogData[code].currencies[currency].commission += commission
                            dialogData[code].currencies[currency].transactions.push(tr)
                        else
                            dialogData[code].currencies[currency] = {
                                amount: amount
                                commission: commission
                                transactions: [tr]
                            }
                    else
                        dialogData[code] = { currencies: {} }
                        dialogData[code].currencies[currency] = {
                            amount: amount
                            commission: commission
                            transactions: [tr]
                        }
                $rootScope.modals.transactions.transactionsData = dialogData
            
                #set buttons
                actionButtons = []
                
                if batches.length is 1 && !(transactions && transactions.length) && !btnKey
                    # диалог одной пачки
                    actionButtons = transactionActions.getTransactionButtons batches[0]
                else
                    actionButtons = [ transactionActions.buttons[btnKey] ]

                    
                $rootScope.modals.transactions.actionButtons = actionButtons

                #set action callback
                $rootScope.modals.transactions.callAction = (btnKey) ->
                    console.log 'actionCallback', btnKey
                    actionCallback transactions, batches, btnKey

                # if batch
                #     if CUT_OFF_CHECK.indexOf batch.status > -1
                #         apiPayment.checkCutOff batch.transactions.type

                LxDialogService.open TRANSACTION_GROUP_MODAL

    that.closeInfo = (notReset) ->
        if !notReset
            that.unlock()
        LxDialogService.close TRANSACTION_MODAL

    that.closeGroup = (notReset) ->
        if !notReset
            that.unlock()
        LxDialogService.close TRANSACTION_GROUP_MODAL

    $rootScope.modals.transaction.close = (notReset) ->
        that.closeInfo(notReset)
    $rootScope.modals.transactions.close = (notReset) ->
        that.closeGroup(notReset)

    that
]

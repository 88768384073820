'use strict'

angular.module('IBR')
.directive 'swiftPaymentForm', [ '$rootScope', '$timeout', '$filter', '$stateParams', 'LxDialogService', 'apiPayment', 'apiAccounts', ($rootScope, $timeout, $filter, $stateParams, LxDialogService, apiPayment, apiAccounts ) ->
    {
        resctict: 'E'
        templateUrl: '/components/swift-payment-form.html'
        scope:
            cids: '=?'
            payment: '=?'
            loaded: '=?'
            txnType: '=?'
            isTemplate: '=?'
            isValid: '=?'
            isReady: '=?'
            isLocked: '=?'
            typeCode: '=?'
        controller: ['$scope', 'authoritiesFactory', 'alertModal',  ($scope, authoritiesFactory, alertModal) ->
            $scope.PAYMENT_TYPE_FOREIGHN = 'FP'
            $scope.PAYMENT_TYPE_RUB = 'RP'

            $scope.isValid = if angular.isDefined($scope.isValid) then $scope.isValid else true
            $scope.isReady = if angular.isDefined($scope.isReady) then $scope.isReady else false
            $scope.isLocked = if angular.isDefined($scope.isLocked) then $scope.isLocked else false
            $scope.payment = if angular.isDefined($scope.payment) then $scope.payment else {}
            $scope.isTemplate = if angular.isDefined($scope.isTemplate) then $scope.isTemplate else false
            $scope.txnType = if angular.isDefined($scope.txnType) then $scope.txnType else null

            if !$scope.payment.typeCode
                $scope.payment.typeCode = 'SWF'


            $scope.isEditing = false
            $scope.accounts = []
            $scope.customers = []
            $scope.swifts = []
            $scope.swift = { object: '', objectInt: '' }

            $scope.swiftObject_msg = 'PAYMENT.FIND_HELPER'

            $scope.validFields = {}
            $scope.maskOptions =
                clearOnBlur: false
                addDefaultPlaceholder: false

            $scope.kppDisabled = true

            codeNames =
                "SWIFT":"SWF"
                "AMERICAN":"ABA"
                "ENGLISH":"SRT"
                "OTHER":"OTH"

            hideStatuses = ['CLOSED']
            clientRefLoadingTrigger = false

            $scope.search =
                bankNameInput: ''
                codeInput: ''
                swiftInput: ''
                bankAddressInput: ''

            $scope.searchDialog = (type) ->
                if type
                    $scope.interSearch = true
                else
                    $scope.interSearch = false
                $scope.swifts = []

                if $scope.txnType == $scope.PAYMENT_TYPE_FOREIGHN
                    $scope.search.bankNameInput = ''
                    $scope.search.codeInput = ''
                    $scope.search.swiftInput = ''
                    $scope.search.bankAddressInput = ''
                else
                    $scope.search.bicInput = ''
                    $scope.search.nameInput = ''

                LxDialogService.open 'swift-search-dial'
                return

            $scope.bigSearch = () ->
                if $scope.txnType == $scope.PAYMENT_TYPE_FOREIGHN
                    if ($scope.search.codeInput.length > 2 || $scope.search.bankNameInput.length > 2 || $scope.search.swiftInput.length > 2 || $scope.search.bankAddressInput.length > 2)
                        searchQuery = '' 
                        if $scope.search.swiftInput.length
                           searchQuery += 'bic:*' + $scope.search.swiftInput + '*' 
                        
                        if $scope.search.bankNameInput.length
                            if searchQuery
                                searchQuery += " AND "
                            searchQuery += 'name:*' + $scope.search.bankNameInput + '*'

                        if $scope.search.bankAddressInput.length
                            if(searchQuery)
                                searchQuery += " AND "
                            searchQuery += 'city:*' + $scope.search.bankAddressInput + '*'
                    
                        if $scope.search.codeInput.length
                            if(searchQuery)
                                searchQuery +=" AND "
                            searchQuery +='branchCode:*' + $scope.search.codeInput + '*'
                        else
                            searchQuery = '('  + searchQuery + ') OR (' + searchQuery + ' AND (NOT _exists_:branchCode))'
                            
                        type = "swift"
                        apiPayment.getDomesticCodes(type, searchQuery).then (response) ->
                            $scope.swifts = response.data
                    else
                        $scope.swifts = []
                    return
                else
                    if $scope.search.bicInput.length > 2 || $scope.search.nameInput.length > 2
                        searchQuery = '(bic:*' + $scope.search.bicInput + '*)'
                        if($scope.search.nameInput.length)
                            searchQuery += 'AND(name:*' + $scope.search.nameInput + '*)'
                        type = "swift_rub"
                        apiPayment.getDomesticCodes(type, searchQuery).then (response) ->
                            $scope.swifts = response.data
                    else
                        $scope.swifts = []
                    return

            getClientRef = () ->
                clientRefLoadingTrigger = true
                data =
                    initiatorCid: $scope.payment.customer.cid

                apiPayment.getRef($scope.txnType, $scope.payment.customer.cid).then (response) ->
                        clientRefLoadingTrigger = false
                        if response.data.clientRef
                            $scope.payment.clientRef = response.data.clientRef
                    , (error) ->
                        clientRefLoadingTrigger = false
                        
                return

            resetFields = () ->
                $scope.validFields = {}
                for own key of $scope.payment
                    if ['customer', 'clientRef', 'typeCode'].indexOf(key) == -1
                        delete $scope.payment[key]
                $scope.swift.object = ''

                $scope.payment.beneficiaryName = ""
                $scope.payment.beneficiaryAddress = ""
                $scope.payment.notes = ""
                $scope.payment.credit = ""
                $scope.payment.narrative = ""
                $scope.payment.beneficiaryNarrative = ""
                $scope.payment.amount = ""
                $scope.payment.beneficiaryBankInfoSwiftCode = ""
                $scope.payment.beneficiaryBankInfo = {
                    name: ""
                    address: ""
                    correspondentAccountIban: ""
                }

                #@TODO refactor
                if $scope.payment.typeCode is 'SWF'
                    $scope.resetBank('ABA')
                    $timeout(->
                        $scope.resetBank('SWF')
                    )
                else
                    $scope.resetBank($scope.payment.typeCode)

                return

            allCustomers = []
            allAccounts = []

            processAccounts = (accounts) ->
                customerAccounts = {}
                accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
                allCustomers = []
                for account in accounts
                    cid = account.corporateCustomer.cid
                    if !(account.status in hideStatuses) && account.iban
                        if !customerAccounts[cid]
                            allCustomers.push account.corporateCustomer
                            customerAccounts[cid] = { accounts: [], corporateCustomer: account.corporateCustomer }
                        if $scope.txnType == 'FP'
                            if account.currency != "AZN" && account.currency != "RUB"
                                customerAccounts[cid].accounts.push account
                        else
                            customerAccounts[cid].accounts.push account
                filterBySelected()
                $scope.isReady = $scope.customers.length && accounts && accounts.length
                # $scope.$emit('FORM_READY')
                customerAccounts

            filterBySelected = () ->
                $scope.customers = $filter('filterSelectedCustomers')(allCustomers) #список кастомеров из списка аккаунтов, отфильтрованных по выбранным в шапке
                # генерируем список cid из доступных (из аккаунтов, если нет - из выбранных, если нет - из доступных)
                $scope.cids = $filter('currentCustomers')($scope.customers).map (customer) ->
                    return customer.cid
                $scope.accounts = $filter('selectedCustomers')(allAccounts)
                if $scope.customers.length == 1
                    $scope.payment.customer = $scope.customers[0]
                $scope.isReady = $scope.customers.length

            $rootScope.$on 'SELECT_CUSTOMERS', () ->
                filterBySelected()

            initPaymentForm = ->
                console.log('initPaymentForm', $scope.txnType)
                # activeTab = document.querySelector('.tabs__link.transactions')
                # if !activeTab.classList.contains('active-link') then activeTab.classList.add('active-link')
                $rootScope.temporaryTogglers['swift'] = $scope.txnType
                $scope.loaded = false
                if $scope.txnType
                    $rootScope.temporaryTogglers['swift'] = $scope.txnType
                    $scope.accounts = {}
                    apiAccounts.getPaymentAccounts($scope.txnType).then (response) ->
                        $scope.accounts = processAccounts response.data
                        allAccounts = angular.copy $scope.accounts, []
                        if $scope.customers.length == 1
                            $scope.payment.customer = $scope.customers[0]
                            #getClientRef()
                        $scope.loaded = true
                        $scope.$emit('FORM_READY')
                        return
                    .catch (error) ->
                        $scope.loaded = true
                        $scope.$emit('FORM_READY')
                        return


            #init with params
            if $stateParams.payment
                $scope.txnType = $stateParams.payment.code
                $scope.payment.typeCode = $stateParams.payment.internationalTransferTypeCode

            authoritiesFactory.checkAuthority 'INTERNATIONAL', 'CREATE'
                .then (res) ->
                    if res
                        $scope.$watch 'txnType', (val) ->
                            if val
                                delete $scope.payment.customer
                                if val == $scope.PAYMENT_TYPE_RUB
                                    $scope.payment.typeCode = $scope.PAYMENT_TYPE_RUB
                                else
                                    $scope.payment.typeCode = 'SWF'
                                    
                                initPaymentForm()
                    else
                        $scope.loaded = true
                        $scope.isLocked = true
                        alertModal.showError $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('ERRORS.ACCESS_DENIED'), 'Ok'



            $scope.resetBank = (code) ->
                # console.log('resetBank', code)
                if !$scope.payment.customer
                    return
                $scope.payment.typeCode = code
                $scope.swift.object = ''
                $scope.payment.beneficiaryBankInfoSwiftCode = ""
                $scope.payment.beneficiaryBankInfo = {
                    name: ""
                    address: ""
                    correspondentAccountIban: ""
                }
                $scope.swifts = []
                $scope.validFields.beneficiaryBankInfoSwiftCode = false
                if $scope.validFields.beneficiaryBankInfo
                    $scope.validFields.beneficiaryBankInfo.name = false
                    $scope.validFields.beneficiaryBankInfo.address = false
                $scope.validFields.swift = false
                $scope.resetInt(true)
                return

            $scope.resetInt = (force) ->
                if force then $scope.payment.interBank = false
                if $scope.swift.objectInt then delete $scope.swift.objectInt
                if $scope.payment.intermediaryBankInfo then delete $scope.payment.intermediaryBankInfo
                return

            $scope.validCheck = () ->
                $scope.validFields.zeroAmount = false
                if parseFloat($scope.payment.amount) <= 0 then $scope.validFields.zeroAmount = true
                return

            $scope.filterSwifts = (val, searchType) ->
                val=val+''
                if val.length < 3
                    $scope.swiftObject_msg = 'PAYMENT.FIND_HELPER'
                    $scope.swifts = []
                    return

                rg = /[^A-Za-z0-9]{1,}/g
                if rg.test(val) then val = val.replace(rg, '')
                $scope.swiftObject_msg = ''
                type = if $scope.txnType == 'RP' then "swift_rub" else "swift"

                if type is 'swift'
                    searchQuery ="(bic:"+val+" AND (NOT _exists_:branchCode)) OR (bic:" + val + ")"
                    if val.length > 8
                        searchQuery = "(bic:" + val.slice(0,8) + ")AND(branchCode:" + val.slice(8) + "*)"
                    else if val.length < 8
                        searchQuery ="(bic:*"+val+"* AND (NOT _exists_:branchCode)) OR (bic:*" + val + "*)"
                else
                    searchQuery ="(bic:*"+val+"*)"

                apiPayment.getDomesticCodes(type, searchQuery).then (response) ->
                    $scope.swifts = response.data
                    if $scope.swifts.length == 0
                        $scope.swiftObject_msg = if $scope.txnType == $scope.PAYMENT_TYPE_FOREIGHN then 'SWIFT_PAYMENT.SWIFT_CODE_NOT_FOUND' else 'SWIFT_PAYMENT.BIC_CODE_NOT_FOUND'
                    else if $scope.swifts.length is 1 && searchType
                        $scope.chooseSwift $scope.swifts[0], searchType
                return


            $scope.chooseSwift = (val, searchType) ->
                console.log 'chooseSwift', searchType == 'i', $scope.interSearch
                $scope.setBankInfo val, searchType == 'i' || $scope.interSearch
                LxDialogService.close "swift-search-dial"
                return


            # setBeneficiaryBankInfo = (val) ->
            #     console.log('setSwiftObject', val)
            #     if !val
            #         delete $scope.payment.beneficiaryBankInfoSwiftCode
            #         delete $scope.payment.beneficiaryBankInfo
            #     else
            #         $scope.payment.beneficiaryBankInfoSwiftCode = val.bic
            #         $scope.payment.beneficiaryBankInfo =
            #             name: val.name
            #             address: val.city
            #             bic: val.bic
            #             branchCode: val.branchCode
            #             correspondentAccountIban: val.correspondentAccount
            #     $scope.swifts = []
            #     $scope.validFields.swift = false
            #     return

            # vm.setSwiftObjectInt = (val) ->
            #     console.log('setSwiftObjectInt', val)
            #     if !val
            #         delete $scope.payment.intermediaryBankInfo
            #     else
            #         $scope.payment.intermediaryBankInfo = {
            #             name: val.name
            #             address: val.city
            #             bic: val.bic
            #             branchCode: val.branchCode
            #         }
            #     $scope.swifts = []
            #     $scope.validFields.intermediaryBankInfo_bic = false
            #     return

            $scope.$on 'PAYMENT_LOCAL_VALIDATE', (event, isTemplate) ->
                $scope.validFields.templateName = false
                $scope.validFields.customer = !$scope.payment.customer
                $scope.validFields.clientRef = !$scope.payment.clientRef
                $scope.validFields.debit = !$scope.payment.debit
                $scope.validFields.beneficiaryName = !$scope.payment.beneficiaryName

                $scope.validFields.amount = !$scope.payment.amount
                $scope.validFields.zeroAmount = parseFloat($scope.payment.amount) <= 0
                $scope.validFields.narrative = !($scope.payment.narrative && $scope.payment.narrative.length >= 1)
                $scope.validFields.beneficiaryBankInfo_name = !($scope.payment.beneficiaryBankInfo && $scope.payment.beneficiaryBankInfo.name)

                $scope.validFields.intermediaryBankInfo_bic = $scope.payment.interBank && !$scope.payment.intermediaryBankInfo

                # $scope.validFields.intermediaryBankInfo_address = $scope.payment.interBank && !$scope.payment.intermediaryBankInfo.address
                # $scope.validFields.intermediaryBankInfo_name = $scope.payment.interBank && !$scope.payment.intermediaryBankInfo.name



                # modify fields
                if $scope.txnType == $scope.PAYMENT_TYPE_FOREIGHN
                    $scope.validFields.beneficiaryAddress = !$scope.payment.beneficiaryAddress
                    $scope.validFields.credit = !($scope.payment.credit && $scope.payment.credit.length >= 3)

                    switch $scope.payment.typeCode
                        when 'OTH'
                            $scope.validFields.beneficiaryBankInfo_correspondentAccountIban = $scope.payment.beneficiaryBankInfo.correspondentAccountIban && $scope.payment.beneficiaryBankInfo.correspondentAccountIban.length < 2
                        when 'ABA'
                            if $scope.payment.beneficiaryBankInfoSwiftCode
                                if $scope.payment.beneficiaryBankInfoSwiftCode.indexOf("//FW") > -1
                                    $scope.validFields.beneficiaryBankInfoSwiftCode = !($scope.payment.beneficiaryBankInfoSwiftCode.length == 13)
                                else
                                    $scope.validFields.beneficiaryBankInfoSwiftCode = !($scope.payment.beneficiaryBankInfoSwiftCode.length == 9)
                            else
                                $scope.validFields.beneficiaryBankInfoSwiftCode = true
                        when 'SRT'
                            if $scope.payment.beneficiaryBankInfoSwiftCode
                                if $scope.payment.beneficiaryBankInfoSwiftCode.indexOf("//SC") > -1
                                    $scope.validFields.beneficiaryBankInfoSwiftCode = !($scope.payment.beneficiaryBankInfoSwiftCode.length == 10)
                                else
                                    $scope.validFields.beneficiaryBankInfoSwiftCode = !($scope.payment.beneficiaryBankInfoSwiftCode.length == 6)
                            else
                                $scope.validFields.beneficiaryBankInfoSwiftCode = true
                        when 'SWF'
                            $scope.validFields.swift = !$scope.swift.object.bic


                validateForTemplate = [
                    'beneficiaryName',
                    'credit',
                    'beneficiaryAddress',
                    'beneficiaryBankInfo_name',
                    'swift',
                    'intermediaryBankInfo_bic',
                    'beneficiaryBankInfoSwiftCode'
                ]


                # for template
                for key, value of $scope.validFields
                    if isTemplate && validateForTemplate.indexOf(key) == -1 #поля нет в массиве для валидации
                        $scope.validFields[key] = false
                        value = false #поля, которые не валидируем задаем валидными

                if $scope.txnType == $scope.PAYMENT_TYPE_RUB
                    $scope.validFields.swift = !($scope.swift && $scope.swift.object && $scope.swift.object.bic)
                    $scope.validFields.invalidPrivateAccount = $scope.payment.privateAccount && $scope.payment.privateAccount.length != 11
                    $scope.validFields.credit = !$scope.payment.credit
                    $scope.validFields.invalidCredit = $scope.payment.credit && $scope.payment.credit.length != 20

                    if $scope.payment.beneficiaryTaxId
                        $scope.validFields.invalidTaxId = !($scope.payment.beneficiaryTaxId.length >= 10 && $scope.payment.beneficiaryTaxId.length <= 12)
                        if $scope.payment.beneficiaryTaxId.length == 10
                            $scope.validFields.kpp = !$scope.payment.kpp
                            $scope.validFields.invalidKpp = $scope.payment.kpp && $scope.payment.kpp.length != 9
                    else
                        $scope.validFields.invalidTaxId = false
                        $scope.validFields.kpp = false

                isValid = true
                for key, value of $scope.validFields
                    isValid = isValid && !value #собираем результат

                if isValid #если проходит проверки, валидируем поля перед показом пользователю
                    switch $scope.payment.typeCode
                        when 'ABA'
                            if ($scope.payment.beneficiaryBankInfoSwiftCode && $scope.payment.beneficiaryBankInfoSwiftCode.indexOf("//FW") == -1)
                                $scope.payment.beneficiaryBankInfoSwiftCode = "//FW" + $scope.payment.beneficiaryBankInfoSwiftCode
                        when 'SRT'
                            if ($scope.payment.beneficiaryBankInfoSwiftCode && $scope.payment.beneficiaryBankInfoSwiftCode.indexOf("//SC") == -1)
                                $scope.payment.beneficiaryBankInfoSwiftCode = "//SC" + $scope.payment.beneficiaryBankInfoSwiftCode

                $scope.isValid = isValid

                if $scope.isTemplate
                    $scope.validFields.templateName = !$scope.payment.templateName
                    $scope.isValid = $scope.isValid && !$scope.validFields.templateName
                
                $scope.$emit 'PAYMENT_LOCAL_VALIDATED', $scope.isValid
                if !$scope.isValid
                    # console.log($scope.validFields)
                    setTimeout( () ->
                        scrollElement = if $scope.isTemplate then document.querySelector('.dialog__scrollable') else document.documentElement
                        scrollToElement = scrollElement.querySelector('.text-field-error')
                        window.utils.scrollTo scrollElement, scrollToElement, 1000
                    , 500 )

            $scope.$watch 'payment', ( ->
                if !(angular.equals($scope.validFields,{}))
                    for own key of $scope.payment
                        if $scope.validFields[key] is true and $scope.payment[key]
                            $scope.validFields[key]= false
                    if $scope.validFields.beneficiaryBankInfo_name
                        $scope.validFields.beneficiaryBankInfo_name = !$scope.payment.beneficiaryBankInfo.name
                    if $scope.validFields.beneficiaryBankInfo_correspondentAccountIban
                        $scope.validFields.beneficiaryBankInfo_correspondentAccountIban = $scope.payment.typeCode == 'OTH' && $scope.payment.beneficiaryBankInfo.correspondentAccountIban && $scope.payment.beneficiaryBankInfo.correspondentAccountIban.length < 2

                $scope.kppDisabled = !$scope.payment.beneficiaryTaxId || $scope.payment.beneficiaryTaxId && $scope.payment.beneficiaryTaxId.length != 10
                if $scope.kppDisabled
                    $scope.payment.kpp = ''

            ), true

            $scope.$watch 'payment.customer', ( ->
                $scope.payment.clientRef = ''
                if $scope.payment.customer && $scope.payment.customer.cid && !clientRefLoadingTrigger
                    $scope.payment.debit = ''
                return
            ), true

            $scope.$watch 'payment.debit', ( ->
                if $scope.payment.customer && $scope.payment.debit && $scope.payment.debit.iban && !$scope.payment.clientRef && !$scope.isEditing
                    getClientRef()
                return
            ), true


            $scope.searchTemplate = ->
                $scope.templateBtnLock = true
                $scope.$emit 'TEMPLATE_SEARCH'

            $scope.listTemplate = ->
                $scope.templateBtnLock = true
                $scope.$emit 'TEMPLATE_LIST'

            $scope.$on 'storageSet', (event, field, val) ->
                $scope.payment[field] = val

            $scope.$on 'TEMPLATES_FOUND', () ->
                $scope.templateBtnLock = false

            $scope.$on 'TEMPLATES_ERR', () ->
                $scope.templateBtnLock = false

            $scope.$on 'PAYMENT_RESET', (event, clearRef) ->
                # console.log('PAYMENT_RESET')
                if $scope.isEditing
                    clearRef = true
                    $scope.isEditing = false
                resetFields()
                if clearRef
                    $scope.payment.clientRef = ''


            $scope.setBankInfo = (val, isInt) ->
                #reset errors
                $scope.swifts = []
                $scope.validFields.swift = false
                $scope.validFields.intermediaryBankInfo_bic = false
                
                if !val
                    delete $scope.payment.intermediaryBankInfo
                    delete $scope.payment.beneficiaryBankInfo
                    delete $scope.payment.beneficiaryBankInfoSwiftCode
                    return

                if isInt
                    $scope.swift.objectInt = val
                    $scope.payment.intermediaryBankInfo =
                        name: val.name
                        address: val.city || val.address
                        bic: val.bic
                        branchCode: val.branchCode
                else
                    $scope.swift.object = val
                    value =
                        name: val.name
                        city: val.city
                        address: val.address || val.city
                        correspondentAccountIban: val.correspondentAccountIban || val.correspondentAccount
                        branchCode: val.branchCode

                    value.bic = val.swiftCode || val.bic

                    #delete undefined props
                    for prop of value
                        if !value[prop] then delete value[prop]
                    
                    $scope.payment.beneficiaryBankInfoSwiftCode = val.swiftCode || val.bic
                    $scope.payment.beneficiaryBankInfo = value

            searchBankInfo = (val, searchType) ->
                if !(val && val.swiftCode)
                    return
                $scope.payment.interBank = searchType == 'i'
                searchValue = if val.branchCode then val.swiftCode + val.branchCode else val.swiftCode
                $scope.filterSwifts searchValue, searchType


                # $scope.swift.objectInt =
                #     name: interBankInfo.name
                #     city: interBankInfo.address
                #     bic: interBankInfo.swiftCode
                #     branchCode: interBankInfo.branchCode

                # $scope.payment.intermediaryBankInfo =
                #     name: interBankInfo.name
                #     address: interBankInfo.address
                #     bic: interBankInfo.swiftCode
                #     branchCode: interBankInfo.branchCode

            $scope.$on 'setPaymentType', (event, paymentType) ->
                console.log 'setPaymentType', paymentType
                $scope.txnType = paymentType

            $scope.$on 'setPayment', (event, payment) ->

                console.log('setPayment')

                $scope.isEditing = true
                if payment.isCopy
                    $scope.isEditing = false
                    delete payment.isCopy


                if payment.bulkId
                    $scope.payment.bulkId = payment.bulkId
                if payment.bulkRef
                    $scope.payment.bulkRef = payment.bulkRef
                if payment.bulkName
                    $scope.payment.bulkName = payment.bulkName
                if payment.source
                    $scope.payment.source = payment.source
                if payment.uuid
                    $scope.payment.uuid = payment.uuid

                # resetFields(true)
                $scope.txnType = payment.code || payment.typeCode || payment.internationalTransferTypeCode
                $scope.payment.typeCode = payment.internationalTransferTypeCode
                cid = payment.initiatorCid || payment.corporateCustomerInitiatorCid

                $timeout( ->
                    $scope.payment.ref = payment.ref || ''
                    for customer in $scope.customers
                        if customer.cid == cid
                            $scope.payment.customer = customer
                , 100 )

                $timeout( ->

                    clientRefLoadingTrigger = true
                    if $scope.accounts[cid]
                        for account in $scope.accounts[cid].accounts
                            if payment.debit == account.iban
                                $scope.payment.debit = account

                    if payment.clientRef
                        $scope.payment.clientRef = payment.clientRef

                    if payment.credit
                        $scope.payment.credit = payment.credit

                    $scope.payment.amount = (payment.debitAmount || payment.amount) + ''
                    $scope.payment.narrative = payment.narrative || ''
                    $scope.payment.notes = payment.notes || ''
                    $scope.payment.beneficiaryNarrative = payment.beneficiaryNarrative || ''

                    #beneficiary
                    if payment.beneficiaryName
                        $scope.payment.beneficiaryName = payment.beneficiaryName
                    if payment.beneficiaryAddress
                        $scope.payment.beneficiaryAddress = payment.beneficiaryAddress


                    if $scope.txnType == $scope.PAYMENT_TYPE_RUB
                        if payment.privateAccount
                            $scope.payment.privateAccount = payment.privateAccount
                        if payment.kpp
                            $scope.payment.kpp = payment.kpp
                        if payment.beneficiaryTaxId
                            $scope.payment.beneficiaryTaxId = payment.beneficiaryTaxId


                    if $scope.txnType == $scope.PAYMENT_TYPE_RUB || $scope.payment.typeCode == 'SWF'
                        searchBankInfo payment.bankInfo, 'b'
                    else
                        $scope.setBankInfo payment.bankInfo

                    if $scope.txnType != $scope.PAYMENT_TYPE_RUB
                        searchBankInfo payment.intermediaryBankInfo, 'i'


                , 200 )


                return

            tmpDebit = {} # variable to cash debit account, were found

            $scope.$on 'TEMPLATE_SELECT', (event, template, isPayment) ->

                console.log(template.transfer)

                if !isPayment then $scope.isEditing = true #If filling fields from template for payment, we don't block Customer Field

                for customer in $scope.customers
                    if customer.cid == template.transfer.corporateCustomerInitiator.cid
                        $scope.payment.customer = customer
                resetFields(true)
                
                $scope.payment.amount = (template.transfer.amount || '') + ''
                $scope.payment.narrative = template.transfer.narrative || ''
                $scope.payment.notes = template.transfer.notes || ''
                $scope.payment.beneficiaryNarrative = template.transfer.beneficiaryNarrative || ''
                $scope.payment.typeCode = codeNames[template.transfer.internationalTransferType]

                
                if $scope.txnType == $scope.PAYMENT_TYPE_RUB
                    if template.transfer.privateAccount
                        $scope.payment.privateAccount = template.transfer.privateAccount
                    if template.transfer.kpp
                        $scope.payment.kpp = template.transfer.kpp
                    if template.transfer.beneficiary && template.transfer.beneficiary.taxId
                        $scope.payment.beneficiaryTaxId = template.transfer.beneficiary.taxId

                $timeout( ->
                    $scope.payment.typeCode = codeNames[template.transfer.internationalTransferType]
                    if template.transfer.debit && template.transfer.debit.iban
                        tmpDebit = $filter('filter')($scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts, {iban: template.transfer.debit.iban})[0]
                        $scope.payment.debit = angular.copy tmpDebit, {}
                    else
                        $scope.payment.debit = ''
                    # if $scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts[0] && !$scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts[0].deleted
                    #     $scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts.unshift({
                    #         deleted: true,
                    #         customName: "PAYMENT.SELECT_ACCOUNT"
                    #     })

                    if template.transfer.credit
                        $scope.payment.credit = template.transfer.credit.iban

                    #beneficiary
                    if template.transfer.beneficiary.name
                        $scope.payment.beneficiaryName = template.transfer.beneficiary.name
                    if template.transfer.beneficiary.address
                        $scope.payment.beneficiaryAddress = template.transfer.beneficiary.address

                    
                    if $scope.txnType == $scope.PAYMENT_TYPE_RUB || $scope.payment.typeCode == 'SWF'
                        searchBankInfo template.transfer.bankInfo, 'b'
                    else
                        $scope.setBankInfo template.transfer.bankInfo

                    if $scope.txnType != $scope.PAYMENT_TYPE_RUB
                        searchBankInfo template.transfer.intermediaryBankInfo, 'i'
            
                , 200 )

                $scope.payment.templateName = template.name
        ]
    }
]
#"044501123"

'use strict'

angular.module('IBR')
.directive 'storageField', [ '$timeout', '$rootScope', ($timeout, $rootScope) ->
  {
    restrict: 'E'
    transclude: true
    scope: {
      pntType : '@type'                                                 #['IT', 'FX', 'AZ', 'XV', 'XT', 'XO', 'FP', '']
    }
    template: '<div ng-transclude></div>' +
      '<div class="storage-list" ng-if="showSet && variants.length" >' +
        '<p ng-repeat="variant in variants" ng-click="choose(variant)"> {{variant}} </p>' +
      '</div>'

    controller: ['$scope', ($scope) ->
      $scope.showSet = false
      $scope.variants = []

      $scope.toggleShow = ()->
        $scope.showSet = !$scope.showSet
        return

      $scope.choose = (txt) ->
        $scope.$emit('storageSet', $scope.fldName, txt)
        return
    ]
    link: (scope, element) ->
      userId = ''                                                       #add variable to cash userId
      scope.fldName = ''                                                #name of cashed field
      storedValues = {}                                                 #add variable to cash values, were set

      textArea = element.find('textarea')
      e = if textArea.length then textArea else element.find('input')   #child input element

      scope.fldName = e.attr('name')

      #if to user-profile, loaded to this moment, waiting for loading
      if !$rootScope.currentUser || !$rootScope.currentUser.username
        $rootScope.$on 'USER_LOADED', () ->
          userId = $rootScope.currentUser.username
      else
        userId = $rootScope.currentUser.username

      #onFocus getItems and show in view
      e[0].addEventListener('focus', () ->
        # scope.toggleShow()
        scope.showSet = true
        storedValuesJSON = localStorage.getItem(userId);
        if storedValuesJSON
          storedValues = JSON.parse(storedValuesJSON)
          if storedValues[scope.pntType] && storedValues[scope.pntType][scope.fldName] then scope.variants = storedValues[scope.pntType][scope.fldName]
      )

      #OnBlur set value to saved Array
      e[0].addEventListener('blur', () ->
        # scope.toggleShow()
        scope.showSet = false
        value = e[0].value.trim()
        if value
          if !storedValues[scope.pntType] then storedValues[scope.pntType] = {}
          if storedValues[scope.pntType][scope.fldName]
            if storedValues[scope.pntType][scope.fldName].indexOf(value) is -1
              storedValues[scope.pntType][scope.fldName].unshift value
              if storedValues[scope.pntType][scope.fldName].length > 5
                storedValues[scope.pntType][scope.fldName] = storedValues[scope.pntType][scope.fldName].slice(0, 5)
          else
            storedValues[scope.pntType][scope.fldName] = [value]

        localStorage.setItem(userId, JSON.stringify(storedValues))
      )

      e[0].addEventListener('keydown', () ->
        scope.showSet = false
      )
  }
]
'use strict'

angular.module('IBR')
.directive('ttMobile', ->
    {
        restrict: 'A'
        controller: [ '$element', '$rootScope', 'LxDialogService', '$sce', ($element, $rootScope, LxDialogService, $sce) ->
            if window.outerWidth < 768
                $element[0].addEventListener 'click', () ->
                    popup = $element[0].querySelector('.popup')
                    if popup && popup.innerHTML 
                        $rootScope.tooltip = $sce.trustAsHtml popup.innerHTML
                        LxDialogService.open 'tooltip-dialog'
        ]
    }
)
'use strict'

angular.module('IBR')
.factory 'AuthFactory', [ '$rootScope', '$state', '$http', '$translate', 'LxDialogService', 'alertModal', ($rootScope, $state, $http, $translate, LxDialogService, alertModal) ->

  that = {}
  that.isLogined = false

  CLIENT_ID = if CONFIG.LOCAL then 'cbr-ib-web-client-local' else 'cbr-ib-web-client'
  BASIC_TOKEN = 'Basic ' + btoa(CLIENT_ID + ":USER_PASSWORD")
  that.authUrl = (CONFIG.USE_MOCK || CONFIG.AUTH_SERVER) + '/authorization/oauth/verification'
  that.authData = {
    scope: "app"
    client_id: CLIENT_ID
    grant_type: "custom"
  }
  
  init = () ->
    that.auth_code = ''
    localStorage.setItem 'token', BASIC_TOKEN

  request = (data) ->
    body = if that.auth_code then angular.extend({ auth_code: that.auth_code }, data) else angular.extend({}, that.authData, data)
    return $http.post that.authUrl, body

  that.setAuthCode = (auth_code) ->
    that.auth_code = auth_code

  # LOGIN
  that.login = (username, password) ->
    init()
    return request({
      auth_type: "pass"
      username: username
      password: password
    })

  # CHANGE PASSWORD
  that.changePasswordConfirm = (old_password, new_password, conf_password) ->
    return request({
      old_password: old_password
      new_password: new_password
      conf_password: conf_password
    })

  that.changePasswordCancel = () ->
    return request({ action: 'cancel' })

  # OTP
  that.otpSend = (otp_code) ->
    return request({ otp_code: otp_code })

  that.otpCancel = () ->
    return request({ action: 'cancel' })

  that.otpResend = () ->
    return request({ action: 'resend' })

  # ASAN LOGIN
  that.asanLogin = (phone_number, asan_id, user_lang) ->
    init()
    return request({
      auth_type: 'asan'
      asan_id: asan_id
      phone_number: '+994' + phone_number
      user_lang: user_lang || 'aze'
    })

  that.asanPoling = () ->
    return request({})

  that.asanConfirm = (verification_code) ->
    return request({ verification_code: verification_code })

  that.asanCancel = () ->
    return request({ action: 'cancel' })

  # GOOGLE PASS LOGIN
  that.googleLogin = (username, password) ->
    init()
    return request({
      auth_type: "google_pass"
      username: username
      password: password
    })

  that.googleConfirm = (gac) ->
    return request({ gac: gac })

  that.googleCancel = () ->
    return request({ action: 'cancel' })


  #login function end

  tickIdle = null
  tickToken = null

  that.successLogin = (params) ->
    that.reset()
    localStorage.setItem('expires_in', new Date().getTime() + params.expires_in * 1000)
    localStorage.setItem('token', 'Bearer' + params.access_token)
    tickIdle = setInterval idleTimer, 1000
    tickToken = setInterval tokenTimer, 10000
    that.isLogined = true
    # console.log 'successLogin'
    $rootScope.$broadcast 'LOGIN'

  that.isLogin = ->
    token = localStorage.getItem('token')
    that.isLogined = !!token && token.indexOf('Bearer') > -1

    # console.log $rootScope.local.autoLogout
    if !tickIdle && that.isLogined && !CONFIG.USE_MOCK #&& $rootScope.local.autoLogout
      tickIdle = setInterval idleTimer, 1000

    if !tickToken = setInterval tokenTimer, 10000
      tickToken = setInterval tokenTimer, 10000
    return that.isLogined

  that.reset = ->
    that.isLogined = false
    # close all modals
    for dialogNode in document.querySelectorAll('.dialog.dialog--is-shown')
      LxDialogService.close dialogNode.id
    #language
    # проблема разлогинивания здесь! почему-то не очищается таймер tickIdle после логаута (продолжил тикать 154 секунды до остановки)
    clearInterval tickIdle
    clearInterval tickToken
    console.log 'AuthFactory reset'

    tickIdle = null
    tickToken = null
    window.utils.removeStorage([
      'token'
      'expires_in'
      'idleTime'
      'selectedCids'
      'GA_STEP_NAME'
      'GA_STEP_EXP'
      'GA_STEP_SECRET'
    ])
    $rootScope.isBlockedSelect = false

  that.logout = ->
    # delete $http.defaults.headers.common['Authorization']
    #kill token
    token = localStorage.getItem('token')
    if token
      token = token.replace('Bearer', '')
      $http.get(CONFIG.AUTH_SERVER + CONFIG.LOGOUT_URL + '?access_token=' + token)
    that.reset()
    $state.go $rootScope.initialState
    alertModal.blinkTab(false)
    return

  ##############################
  ######### TIMERS #############
  ##############################

  timeLeftDialogOpened = false
  idleTimer = ->
    # console.log('tick')
    now = new Date().getTime() / 1000
    idleTime = localStorage.getItem('idleTime')
    if idleTime
      $rootScope.timeLeft = parseInt(idleTime - now)
      if $rootScope.timeLeft < CONFIG.AUTH_LEFT_TIME && $rootScope.timeLeft > 0
        console.log('time: ', $rootScope.timeLeft < CONFIG.AUTH_LEFT_TIME)
        # show alert
        if !timeLeftDialogOpened && $state.current.name != 'login'
          console.log('!timeLeftDialogOpened')
          alertModal.blinkTab $translate.instant('ALL.SESSION_ENDING_WARNING')
          LxDialogService.open 'timeLeftDialog'
          timeLeftDialogOpened = true
      if $rootScope.timeLeft < 0
        alertModal.blinkTab false
        LxDialogService.close 'timeLeftDialog'
        timeLeftDialogOpened = false
        console.log('idleTimer logout')
        that.logout()
      if $rootScope.timeLeft > CONFIG.AUTH_LEFT_TIME
        alertModal.blinkTab false
        LxDialogService.close 'timeLeftDialog'
    else
      localStorage.setItem('idleTime', now + CONFIG.AUTH_INTERVAL)

  tokenTimer = ->
    now = new Date().getTime() / 1000
    tokenTime = localStorage.getItem('expires_in')
    if tokenTime && tokenTime < now + 10
      # token will expire in 10 sec
      LxDialogService.close 'timeLeftDialog'
      that.reset()
      if $state.current.name == 'login'
        console.log('tokenTimer logout')
        that.logout()
      else
        alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('SESSION.END_TEXT'), 'Ok', (answer) ->
          console.log('tokenTimer alert logout')
          that.logout()

  continueWork = ->
    timeLeftDialogOpened = false
    LxDialogService.close 'timeLeftDialog'
    localStorage.setItem('idleTime', new Date().getTime()/1000 + CONFIG.AUTH_INTERVAL)

  document.addEventListener 'click', () ->
    if !timeLeftDialogOpened && that.isLogined
      continueWork()

  document.addEventListener 'keydown', () ->
    if !timeLeftDialogOpened && that.isLogined
      continueWork()

  $rootScope.authContinue = ->
    continueWork()

  $rootScope.authLogout = ->
    LxDialogService.close 'timeLeftDialog'
    console.log('from template logout')
    that.logout()

  $rootScope.$on 'AUTO_LOGOUT_ON', () ->
    tickIdle = setInterval idleTimer, 1000

  $rootScope.$on 'AUTO_LOGOUT_OFF', () ->
    clearInterval tickIdle

  return that
]

'use strict'

angular.module('IBR')
.factory 'transactionResultModal', [ '$rootScope', '$state', '$q', '$translate', 'alertModal', 'LxDialogService', 'apiPayment', ($rootScope, $state, $q, $translate, alertModal, LxDialogService, apiPayment) ->

    that = {}
    GROUP_RESULT = 'modal-group-result'

    $rootScope.modals.result = {}
    $rootScope.modals.result = {}

    that.timeoutModal = (res) ->
        # костыль CBR-1989
        deferred = $q.defer()
        if res is 'ERRORS.TIMEOUT_PAYMENT_SUCCESS'
            header = $translate.instant 'DIALOG.TRANSFER'
            txt = $translate.instant res
            alertModal.showMessage header, txt, 'OK', (answer) ->
                # navigate to transactions
                $rootScope.customAlert = false
                $state.go 'transactions'
                deferred.reject()
        else
            deferred.resolve(res)
        
        return deferred.promise

    that.successPaymentModal = (authSchema, btnKey) ->
        # console.log 'successPaymentModal', authSchema, btnKey
        deferred = $q.defer()
        txt = ''
        header = $translate.instant 'DIALOG.TRANSFER'
        switch btnKey
            when 'SEND_TO_SIGN' then txt = $translate.instant('MESSAGES.SEND_TO_SIGN')
            when 'SIGN_1'
                switch authSchema
                    when 'ONE_SIGN'
                        txt = $translate.instant 'MESSAGES.SIGN_1_ONLY_ONE_SIGN'
                    when 'SING_APPROVE', 'SING_APPROVE'
                        txt = $translate.instant 'MESSAGES.SIGN_1_ONLY_SIGN_APPROVE'
                    when 'SIGN1_SIGN2'
                        txt = $translate.instant 'MESSAGES.SIGN_1_ONLY_SIGN_1_SIGN_2'
            when 'SIGN_2'
                txt = $translate.instant 'MESSAGES.SIGN_2_ONLY'
            when 'SIGN_1__SEND_TO_CBS'
                txt = $translate.instant 'MESSAGES.SIGN_BANK_SUCCESS'
            when 'SEND_TO_SIGN__APPROVE'
                txt = $translate.instant 'MESSAGES.SEND_TO_SIGN_AND_APPROVE'
            when 'APPROVE'
                txt = $translate.instant 'MESSAGES.APPROVE_ONLY'


        alertModal.showMessage header, txt, 'OK', (answer) ->
            deferred.resolve()
        
        return deferred.promise

    that.successModal = (btnKey) ->
        deferred = $q.defer()

        txt = ''
        header = $translate.instant 'DIALOG.TRANSFER'
        switch btnKey
            when 'SIGN_1', 'SIGN_2'
                txt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__SIGNED'
            when 'APPROVE'
                txt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__CONFIRMED'
            when 'SAVE_AS_TEMPLATE'
                txt = $translate.instant 'INTERNAL_PAYMENT.TEMPLATE_DONE'
                header = $translate.instant 'TEMPLATES_PAYMENT.TITLE_DIALOG'
            when 'SEND_TO_CHANGE'
                txt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__SENT_CHANGE'
                header = $translate.instant 'DIALOG.ATTENTION'
            when 'DELETE'
                txt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__DELETED_MOVED'

        alertModal.showMessage header, txt, 'Ok', (answer) ->
            deferred.resolve(btnKey != 'SAVE_AS_TEMPLATE') # true -> need reload
        
        return deferred.promise

    that.groupModal = (btnKey, transactions, batches, data, status) ->
        deferred = $q.defer()
        warnings = data.warnings


        $rootScope.modals.result.close = () ->
            LxDialogService.close GROUP_RESULT
            $rootScope.modals.result = {}
            deferred.resolve()


        if data.errorCode
            # console.log $translate.instant 'ERRORS.ERROR_SERVER_DESCRIPTION'
            alertModal.showError $translate.instant 'DIALOG.ATTENTION', $translate.instant 'ERRORS.ERROR_SERVER_DESCRIPTION', 'Ok', () ->
                deferred.reject()


        apiPayment.mergeTransactions(transactions, batches)
            .then (trs) ->

                errorTxt = ''
                successTxt = ''

                switch btnKey
                    when 'SIGN_1', 'SIGN_2'
                        errorTxt = $translate.instant 'MESSAGES.SEND_TO_SIGN_ERROR'
                        successTxt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__SIGNED'
                    when 'SEND_TO_CHANGE'
                        errorTxt = $translate.instant 'MESSAGES.SEND_CHANGE_ERROR'
                        successTxt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__SENT_CHANGE'
                    when 'DELETE'
                        errorTxt = $translate.instant 'MESSAGES.DELETE_ERROR'
                        successTxt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__DELETED_MOVED'
                    when 'APPROVE'
                        errorTxt = $translate.instant 'MESSAGES.SEND_TO_SIGN_ERROR'
                        successTxt = $translate.instant 'TRANSACTIONS.TRANSACTION_DOINGS__CONFIRMED'

                if batches && batches.length is 1 && !transactions.length
                    if btnKey == 'SEND_TO_CHANGE' || btnKey == 'DELETE'
                        if warnings[0] && warnings[0].message == '0008'
                            errorTxt = $translate.instant 'ERRORS.NO_AUTHORITY_BATCH'

                    alertModal.showMessage $translate.instant('DIALOG.TRANSFER'), errorTxt, 'Ok', (answer) ->
                        deferred.resolve true

                    return
                
                else
                    failesArray = {}
                    for w in warnings
                        msg = errorTxt
                        if w.message == '0036'
                            msg = $translate.instant 'ERRORS.ERROR_DATA_DESCRIPTION'
                        else if w.message == '0037'
                            msg = $translate.instant 'ERRORS.ERROR_CAST_LIMIT'
                        failesArray[w.code] = msg

                    results = trs.map (tr) ->
                        if tr.ref
                            message = if failesArray[tr.ref] then { type: 'err', msg: failesArray[tr.ref] } else { type: 'msg', msg: successTxt }
                            tr.messages = [message]
                        return tr

                    # console.log results

                    $rootScope.modals.result.results = results
                    LxDialogService.open GROUP_RESULT

        return deferred.promise

    
    that.sendToBank = (btnKey, transactions, batches, data, status) ->

        # console.log(btnKey, transactions, batches, data, status)
        
        refreshAfterClose = true

        deferred = $q.defer()
        $rootScope.modals.result.close = () ->
            LxDialogService.close GROUP_RESULT
            $rootScope.modals.result = {}
            deferred.resolve()

        if data.errorCode
            alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ERROR_SERVER_DESCRIPTION'), 'Ok', () ->
                deferred.reject()


        # console.log 'sendToBank modal result'
        apiPayment.mergeTransactions(transactions, batches)
            .then (trs) ->
                # console.log trs
                headerText = $translate.instant('DIALOG.TRANSFER')
                results = []

                if status is 202
                    alertModal.showMessage $translate.instant('DIALOG.TRANSFER'), $translate.instant('MESSAGES.SEND_TO_CBS_GROUP_ACCEPTED'), 'Ok', () ->
                        # console.log('sendGroupToBank OK')
                        deferred.resolve(true)
                    return

            
                if data.transactionResults && data.transactionResults.length

                    results = []

                    for res in data.transactionResults

                        result = {}
                        if trs && trs.length
                            result = trs.find (t) ->
                                t.ref is res.resourceId.ref

                        if !result
                            result = {}

                        # console.log result, res

                        msgEl1 = { type: 'msg', msg: '' }
                        msgEl2 = { type: 'msg', msg: '' }

                        if res.success
                            msgEl1.type = 'msg'
                            msgEl1.msg = $translate.instant 'MESSAGES.SIGN_BANK_SUCCESS'

                            if res.warnings && res.warnings.length
                                codes = res.warnings.map (w) ->
                                    return w.code
                                if codes.indexOf('operationDay') > -1
                                    msgEl1.msg = $translate.instant 'MESSAGES.SIGN_BANK_SUCCESS_WARNINGS'
                                if codes.indexOf('blackOut') > -1
                                    msgEl1.msg = $translate.instant 'PAYMENT.COMPLETE_BLACKOUT'
                        else #res.success = false
                            if btnKey is 'SEND_TO_CBS'
                                refreshAfterClose = false

                            msgEl1.type = 'err'
                            msgEl1.msg = $translate.instant 'MESSAGES.SIGN_BANK_ERROR'
                            if res.errors && res.errors.length
                                if res.errors[0].code == 'ERUNI'
                                    msgEl1.msg = $translate.instant 'MESSAGES.SIGN_BANK_ERROR_ERUNI'
                                else if res.errors[0].code == '0058'
                                    headerText = $translate.instant 'DIALOG.ATTENTION'
                                    msgEl1.msg = $translate.instant 'MESSAGES.SIGN_BANK_INVALID_CREDENTIALS'
                                else if res.errors[0].code == '0071'
                                    msgEl1.msg = $translate.instant 'MESSAGES.SEND_TO_CBS_EXPIRED'
                                else if res.errors[0].code == '0073'
                                    msgEl1.msg = $translate.instant 'EXCHANGE_PAYMENT.BLACKOUT'
                                else if res.errors[0].code == '0069'
                                    msgEl1.type = 'default'
                                    msgEl1.msg = $translate.instant 'MESSAGES.SEND_TO_CBS_WAIT'
                                else
                                    msgEl1.msg = $translate.instant 'MESSAGES.CBS_NO_ERUNI_ERROR'

                            if btnKey == 'SIGN_1__SEND_TO_CBS' || btnKey == 'SIGN_2__SEND_TO_CBS'
                                msgEl2 = {
                                    type: 'msg'
                                    msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED'
                                }
                            else if btnKey == 'APPROVE__SEND_TO_CBS'
                                # console.log 'APPROVE__SEND_TO_CBS'
                                msgEl2 = {
                                    type: 'msg'
                                    msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_APPROVED'
                                }

                        if !result.messages
                            result.messages = []
                        if msgEl1.msg
                            result.messages.push msgEl1
                        if msgEl2.msg
                            result.messages.push msgEl2

                        results.push result

                    # console.log 'results', results

                    if results.length == 1
                        alertModal.show headerText, results[0].messages, 'Ok', (answer) ->
                            deferred.resolve refreshAfterClose
                            return
                    else
                        $rootScope.modals.result.results = results
                        LxDialogService.open GROUP_RESULT
                
        return deferred.promise


    that
]

# реквизиты счета

angular.module('IBR').component 'accountDetails', {

    templateUrl: '/popup/account-details.html'
    bindings:
        accounts: '<'
    controllerAs: "vm"
    controller: ['$rootScope', '$scope', '$filter', 'LxDialogService', 'apiAccounts', ($rootScope, $scope, $filter, LxDialogService, apiAccounts) ->

        vm = this

        vm.btnLoaders = {}

        detQery = 
            accountNumbers: []
            cid: ''
            lang: $rootScope.lang

        vm.det =
            lang: $rootScope.currentLang
            cid: ''
            account: {}
            accounts: []
            selectedAccounts: {}
            details: {}

        vm.det.changeLang = ->
            detQery.lang = vm.det.lang.lngu

        vm.det.showSelect = () ->
            vm.det.allSelected = false
            vm.det.selectedAccounts[vm.det.account.iban] = true
            LxDialogService.open 'account-select-dialog'

        vm.det.selectAll = () ->
            if !vm.det.allSelected
                vm.det.selectedAccounts = {}
                vm.det.selectedAccounts[vm.det.account.iban] = true
            else
                for account in vm.det.accounts
                    vm.det.selectedAccounts[account.iban] = true


        

        vm.showDetails = (account, cid) ->

            if account && cid
                vm.det.selectedAccounts = {}
                vm.det.lang = $rootScope.currentLang
                vm.det.cid = cid
                vm.det.accounts = vm.accounts[cid].accounts
                vm.det.account = account

                detQery.lang = vm.det.lang.lngu
                detQery.cid = cid
                detQery.accountNumbers = [vm.det.account.iban]
            else
                #from selected
                detQery.accountNumbers = [vm.det.account.iban]
                for iban of vm.det.selectedAccounts
                    if vm.det.selectedAccounts[iban]
                        detQery.accountNumbers.push iban

            vm.det.buttonDisabled = true
            apiAccounts.getDetails detQery
                .then (res) ->
                    vm.det.buttonDisabled = false
                    vm.det.details = res.data
                    LxDialogService.close 'account-select-dialog'
                    LxDialogService.open 'account-details-dialog'
                , (err) ->
                    vm.det.buttonDisabled = false

        $scope.$on 'SHOW_ACCOUNT_DETAILS', (event, account, cid) ->
            vm.showDetails account, cid
            

        vm.det.download = () ->
            vm.det.buttonDisabled = true
            apiAccounts.downloadDetails detQery, { responseType : 'arraybuffer' }
                .then (res) ->
                    vm.det.buttonDisabled = false
                , (err) ->
                    vm.det.buttonDisabled = false
        
        vm.det.mailto = () ->
            vm.det.buttonDisabled = true
            
            subject = $filter('trs')('ACCOUNT.EMAIL_SUBJECT', vm.det.lang.key)
            subject = subject.replace('$', vm.det.details.beneficiaryName) # @todo need refactor
            
            # body = document.getElementById('account-details').textContent
            
            apiAccounts.getDetails detQery
                .then (res) ->
                    vm.det.buttonDisabled = false
                    apiAccounts.sendEmail subject, apiAccounts.processDetailsEmail(res.data, vm.det.lang.key)
                , (err) ->
                    vm.det.buttonDisabled = false
        

        return vm
    ]
}
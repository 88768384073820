'use strict'

angular.module('IBR')
.controller 'LoginCtrl', [ '$rootScope', '$translate', '$state', '$scope', '$sce', '$timeout', 'AuthFactory', ($rootScope, $translate, $state, $scope, $sce, $timeout, AuthFactory) ->

  # ticking = '' #variable of timer
  timeLeft = 301 #+ 1 sec for first tick

  $scope.showMessage = window.CONFIG.SHOW_MESSAGE

  # tick = () ->
  #   timeLeft--
  #   ticking = $timeout( ->
  #     if timeLeft
  #       tick()
  #     else
  #       window.location.reload(true)
  #   , 1000 )

  # tick()

  # form types
  # ===============
  # 'LOGIN_DEFAULT'
  # 'CHANGE_PASSWORD'
  # 'LOGIN_ASAN'
  # 'LOGIN_GA'
  # 'ASAN_CODE'
  # 'SEND_OTP'
  # 'SEND_GAC'
  # 'ASAN_CODE'

  $scope.formType = 'LOGIN_DEFAULT'
  $scope.form = { showPassword: {} }
  $scope.loaded = true
  $scope.errorMsg = ''
  $scope.resendLocked = true

  otpAttempts = 0
  gaAttempts = 0

  checkResponse = (res) ->
    if res.data.access_token
      AuthFactory.successLogin res.data
      return true
    
    if res.data.error
      $scope.loaded = true
      $scope.errorMsg = 'ERRORS.' + res.data.error
      # console.log res.data.error_description
      return true

    return false


  $scope.formAction = (isBackAction) ->
    console.log 'formAction', $scope.formType, isBackAction

    $scope.errorMsg = ''
    switch $scope.formType

      when 'LOGIN_DEFAULT'
        $scope.form.otpCode = ''

        if !$scope.form.username || !$scope.form.password
          $scope.errorMsg = 'ERRORS.EMPTY_FIELDS'
          return

        $scope.loaded = false
        AuthFactory.login $scope.form.username, $scope.form.password
          .then (res) ->
            
            if checkResponse res
              #wait for dashboard loaded
              return

            $scope.loaded = true

            if res.data.auth_code && res.data.verify_form
              AuthFactory.setAuthCode res.data.auth_code

              if res.data.verify_form.indexOf('otp') > -1
                $scope.formType = 'SEND_OTP'
                resendLock()

              else if res.data.verify_form.indexOf('change-pwd') > -1
                $scope.formType = 'CHANGE_PASSWORD'

          .catch (err) ->
            console.log err
            $scope.loaded = true

        return


      when 'SEND_OTP'

        if isBackAction
          AuthFactory.otpCancel()
          $scope.formType = 'LOGIN_DEFAULT'
          return

        if !$scope.form.otpCode
          $scope.errorMsg = 'ERRORS.EMPTY_FIELDS'
          return

        $scope.loaded = false
        AuthFactory.otpSend $scope.form.otpCode
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              return

            if res.data.auth_code && res.data.verify_form
              AuthFactory.setAuthCode res.data.auth_code
              if res.data.verify_form.indexOf('change-pwd') > -1
                $scope.formType = 'CHANGE_PASSWORD'
            
          .catch (err) ->
            $scope.loaded = true

        return

      when 'CHANGE_PASSWORD'

        if isBackAction
          AuthFactory.changePasswordCancel()
          $scope.formType = 'LOGIN_DEFAULT'
          return

        if !$scope.form.oldpassword || !$scope.form.newpassword || !$scope.form.confpassword
          $scope.errorMsg = 'ERRORS.EMPTY_FIELDS'
          return

        if $scope.form.newpassword != $scope.form.confpassword
          $scope.errorMsg = 'ERRORS.PWD_NOT_MATCH'
          return

        if $scope.form.newpassword.length < 8 || $scope.form.newpassword.length > 20
          $scope.errorMsg = 'ERRORS.PASS_LENGTH'
          return

        if $scope.form.oldpassword == $scope.form.newpassword
          $scope.errorMsg = 'ERRORS.SAME_PWD'
          return

        literalRegex = /[a-z]/
        literalCapitalRegex = /[A-Z]/
        numbericRegex = /\d/
        latinRegex = /[а-яА-ЯəöüıçşğÇÖÜĞŞƏ\s]/

        if !literalRegex.test($scope.form.newpassword) || !literalCapitalRegex.test($scope.form.newpassword)
          $scope.errorMsg = 'ERRORS.PASS_LITERAL'
          return

        if latinRegex.test($scope.form.newpassword)
          $scope.errorMsg = 'ERRORS.PASS_LATIN'
          return

        if !numbericRegex.test($scope.form.newpassword)
          $scope.errorMsg = 'ERRORS.PASS_NUMBERIC'
          return

        $scope.loaded = false
        AuthFactory.changePasswordConfirm $scope.form.oldpassword, $scope.form.newpassword, $scope.form.confpassword
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              return

          .catch (err) ->
            $scope.loaded = true
        
        return


      when 'LOGIN_GA'
        $scope.form.verification_code = ''

        if !$scope.form.username || !$scope.form.password
          $scope.errorMsg = 'ERRORS.EMPTY_FIELDS'
          return
        
        $scope.loaded = false
        AuthFactory.googleLogin($scope.form.username, $scope.form.password)
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              return

            if res.data.auth_code && res.data.verify_form
              AuthFactory.setAuthCode res.data.auth_code

              if res.data.verify_form.indexOf('google') > -1
                $scope.formType = 'SEND_GAC'

          .catch (err) ->
            $scope.loaded = true

      when 'SEND_GAC'
        if isBackAction
          AuthFactory.googleCancel()
          $scope.formType = 'LOGIN_GA'
          return

        $scope.loaded = false
        AuthFactory.googleConfirm $scope.form.gac
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              return

            if res.data.auth_code && res.data.verify_form
              AuthFactory.setAuthCode res.data.auth_code
              if res.data.verify_form.indexOf('change-pwd') > -1
                $scope.formType = 'CHANGE_PASSWORD'

          .catch (err) ->
            $scope.loaded = true

      when 'LOGIN_ASAN'
        
        if !$scope.form.phoneNumber || !$scope.form.asanId
          $scope.errorMsg = 'ERRORS.EMPTY_FIELDS'
          $scope.loaded = true
          return
          

        $scope.loaded = false
        AuthFactory.asanLogin $scope.form.phoneNumber, $scope.form.asanId
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              return

            if res.data.auth_code && res.data.verify_details && res.data.verify_details.verification_code
              AuthFactory.setAuthCode res.data.auth_code
              $scope.form.verification_code = res.data.verify_details.verification_code
              $scope.formType = 'ASAN_CODE'

              return AuthFactory.asanPoling()
            
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              $scope.formType = 'LOGIN_ASAN'
              return

          .catch (err) ->
            console.log err
            $scope.formType = 'LOGIN_ASAN'
            $scope.loaded = true

        return


      when 'ASAN_CODE'
        if isBackAction
          AuthFactory.asanCancel()
          $scope.formType = 'LOGIN_ASAN'
          return
        
        $scope.loaded = false
        AuthFactory.asanConfirm $scope.form.verification_code
          .then (res) ->
            $scope.loaded = true
            if checkResponse res
              return

            if res.data.auth_code && res.data.verify_form
              AuthFactory.setAuthCode res.data.auth_code
              if res.data.verify_form.indexOf('change-pwd') > -1
                $scope.formType = 'CHANGE_PASSWORD'

          .catch (err) ->
            $scope.loaded = true
            $scope.formType = 'LOGIN_ASAN'

        return

  $scope.resendOtp = () ->
    $scope.loaded = false
    $scope.form.otpCode = ''
    AuthFactory.otpResend()
      .then (res) ->
        $scope.loaded = true
        resendLock()
      .catch (err) ->
        $scope.loaded = true

      
  resendLock = () ->
    $scope.resendLocked = true
    $timeout( ->
      $scope.resendLocked = false
    , 20000 )

  $scope.optTimeout = ->
    $scope.formType = 'LOGIN_DEFAULT'
    $scope.loaded = true

  $scope.gaTimeout = ->
    $scope.formType = 'LOGIN_GA'
    $scope.loaded = true

  $scope.asanTimeout = ->
    $scope.formType = 'LOGIN_ASAN'
    $scope.loaded = true

  $scope.loginTimeout = ->
    $scope.form = {}
    

  $scope.$watch 'errorMsg', (val) ->
    if val && $translate.instant(val) == val
      $scope.errorMsg = 'ERRORS.ERROR_UNDEFINED'

  #strt lock otp resend
  # $scope.$watch 'formType', (val) ->
  #   if val is 'SEND_OTP'
  #     resendLock()

  # reset errors
  $scope.$watch 'form', ( ->
    $scope.errorMsg = ''
    timeLeft = 301
  ), true


  $scope.$on 'MOUSEUP', () ->
    $scope.form.showPassword = {}

  mouseDownListener = (e) ->
    if e.target.id == 'btnEye1' || e.target.id == 'btnEye2' || e.target.id == 'btnEye3'
      $timeout( ->
        $scope.form.showPassword[e.target.id] = true
      )
      

  mouseUpListener = (e) ->
    $timeout( ->
      $scope.form.showPassword = {}
    )

  document.addEventListener 'mousedown', mouseDownListener
  document.addEventListener 'touchstart', mouseDownListener
  document.addEventListener 'mouseup', mouseUpListener
  document.addEventListener 'touchend', mouseUpListener

  $scope.$on '$destroy', () ->
    # $timeout.cancel ticking
    document.removeEventListener 'mousedown', mouseDownListener
    document.removeEventListener 'touchstart', mouseDownListener
    document.removeEventListener 'mouseup', mouseUpListener
    document.removeEventListener 'touchend', mouseUpListener

  return
]

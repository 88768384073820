'use strict'

angular.module('IBR')
.directive 'loader', [ '$filter', ($filter) ->
    {
        restrict:'A'
        scope: {
            loader: '='
        }
        link: (scope, element, attributes)->
            loader = document.createElement("div")
            loader.className = 'loader-circular'
            # loader.innerHTML = '<div></div><div></div><div></div><div></div><div></div><div></div>'
            element[0].appendChild(loader)

            scope.$watch 'loader', (val)->
                if val
                    element.removeClass('loading')
                else
                    element.addClass('loading')
    }
]
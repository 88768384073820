'use strict'

angular.module('IBR')
.directive 'dialog', () ->
    {
        scope:
            dialog: '&'
        restrict: 'A'
        controller: [ '$scope', '$element', '$attrs', ($scope, $element, $attrs)->
            $element.on 'click', (e)->
                if !(e.target.className == "pointer" || e.target.tagName == "I" || e.target.tagName == "BUTTON" || e.target.tagName == "LABEL" || (e.target.tagName == "INPUT" && e.target.type == "checkbox") || !$scope.dialog)
                    $scope.dialog()
                    return
            return
        ]
    }

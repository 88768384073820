'use strict'

angular.module('IBR').component 'trSearchForm', {

    templateUrl: '/pages/transactions/search-form.html'
    bindings:
        loaded: '<'
        loaders: '<'
        constants: '<'
        customers: '<'
        accounts: '<'
        customerAccounts: '<'
        filter: '='
        bulks: '<'
        canApprove: '<'
        formFilterDefaults: '<'
        statusCount: '<'
        search: '='
        getBulks: '='
    controllerAs: "vm"
    controller: ['$rootScope', '$scope', '$filter', 'apiBulkPayments', ($rootScope, $scope, $filter, apiBulkPayments) ->

        vm = this

        defBulk = { bulkName: 'ALL' }
        vm.form = 
            accounts: []
            customers: []
            filter: {}
            bulks: [defBulk]

        inited = false
        $scope.$watch 'vm.filter', (filter) ->
            if !inited
                vm.form.filter = angular.copy filter
            inited = true
        , true

        vm.errors = {}

        vm.firstLineStatuses = [
            'ALL_ACTIVE'
            'PENDING_FOR_SEND_TO_CBS',
            'SENT_TO_CHANGE',
            'SENT_TO_CBS',
            'REJECTED_BY_CBS'
        ]

        vm.secondLineStatuses = [
            'PENDING_FOR_SIGNATURE'
            'PENDING_FOR_APPROVE'
        ]
        

        vm.validateAmount = () ->
            vm.errors.amountMin = vm.errors.amountMax = false
            compareErr = vm.filter.amountMax && vm.filter.amountMin && Number vm.filter.amountMin >= Number vm.filter.amountMax
            if vm.filter.amountMin
                vm.errors.amountMin = isNaN(vm.filter.amountMin) || compareErr
            if vm.filter.amountMax
                vm.errors.amountMax = isNaN(vm.filter.amountMax) || compareErr

        vm.setFormAccounts = () ->
            vm.loaders.waitSearch = true
            vm.filter.account = vm.formFilterDefaults.account
            vm.form.accounts = []
            vm.checkBulks()
            if vm.accounts && vm.accounts.length && vm.customerAccounts
                if vm.filter.customer && vm.filter.customer.cid
                    vm.form.accounts = angular.copy vm.customerAccounts[vm.filter.customer.cid].accounts
                else
                    vm.form.accounts = angular.copy vm.accounts
            vm.form.accounts.unshift(vm.formFilterDefaults.account)

        vm.$onChanges = (e) ->
            if e.customers && vm.customers
                vm.form.customers = angular.copy vm.customers
                vm.form.customers.unshift(vm.formFilterDefaults.customer)
            if e.accounts || e.customers
                vm.setFormAccounts()

        vm.form.search = (a) ->

            status = vm.filter.status
            eq1 = vm.form.filter.customer.name != vm.filter.customer.name
            eq2 = vm.form.filter.account.iban != vm.filter.account.iban
            eq3 = vm.form.filter.type != vm.filter.type
            eq4 = vm.form.filter.amountMin != vm.filter.amountMin
            eq5 = vm.form.filter.amountMax != vm.filter.amountMax

            if eq1 || eq2 || eq3 || eq4 || eq5
                status = 'ALL_ACTIVE'
            angular.extend vm.filter, vm.form.filter
            vm.filter.status = status
            vm.search a


        vm.checkBulks = ->
            vm.form.bulks = [defBulk]
            vm.form.filter.bulk = defBulk
            if vm.form.filter.isBulk
                cids = vm.customers
                    .map (c) ->
                        return c.cid
                    .filter (c) ->
                        return c
                if vm.form.filter.customer && vm.form.filter.customer.cid
                    cids = [vm.form.filter.customer.cid]

                # console.log cids
                if cids.length
                    vm.loaders.bulks = true
                    apiBulkPayments.getList 'ACTIVE', cids
                        .then (res) ->
                            vm.loaders.bulks = false
                            bulks = [defBulk]
                            for b in res.data
                                bulks.push { bulkId: b.bulkId, bulkName: b.ref+' ('+b.bulkName+')', ref: b.ref }
                            vm.form.bulks = $filter('orderBy')(bulks, '-bulkId')
                        .catch (err) ->
                            vm.loaders.bulks = false

        return vm
    ]
}
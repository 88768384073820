'use strict'

angular.module('IBR')
.controller 'PagesListCtrl', [ '$rootScope', '$q', '$location', '$state', 'authoritiesFactory', 'LxNotificationService', 'alertModal', ($rootScope, $q, $location, $state, authoritiesFactory, LxNotificationService, alertModal) ->
    vm = this

    vm.internalPayment = false
    vm.exchange = false
    vm.international = false
    vm.domestic = false
    vm.accounts = false
    vm.deposits = false
    vm.credits = false
    vm.services = false
    vm.other = false
    vm.acquiring = false
    vm.salary = false
    vm.bulkPayments = false
    vm.instantPayment = true

    getPermission = () ->
        $q.all([
            authoritiesFactory.checkAuthority 'INTERNAL_ACCOUNT_TO_ACCOUNT' #0
            authoritiesFactory.checkAuthority 'ACCOUNTS', 'READ' #1
            authoritiesFactory.checkAuthority 'DEPOSITS', 'READ' #2
            authoritiesFactory.checkAuthority 'CREDITS', 'READ' #3
            authoritiesFactory.checkAuthority 'EXCHANGE' #4
            authoritiesFactory.checkAuthority 'INTERNATIONAL' #5
            authoritiesFactory.checkAuthority 'AZIPS||XOHKS||BUDGET||BUDGET_VAT' #6
            authoritiesFactory.checkAuthority 'ACCOUNT_STATEMENTS', 'READ' #7
            authoritiesFactory.checkAuthority 'NOTIFICATION', 'READ', true
            authoritiesFactory.checkAuthority 'ACQUIRING', 'READ', true
            authoritiesFactory.checkAuthority 'SALARY', 'CREATE', true
            authoritiesFactory.checkAuthority 'BULK_UPLOAD', 'READ', true #to do for bulk
        ]).then (results) ->
            vm.internalPayment = results[0]
            vm.accounts = results[1]
            vm.deposits = results[2]
            vm.credits = results[3]
            vm.exchange = results[4]
            vm.international = results[5]
            vm.domestic = results[6]
            vm.accStatement = results[7]
            vm.services = results[8]
            vm.payments = vm.domestic || vm.internalPayment || vm.exchange || vm.international
            vm.acquiring = results[9]
            vm.salary = results[10]
            vm.bulkPayments = results[11]

            vm.instantPayment = results[6]

            vm.other = vm.acquiring


    getPermission()
    $rootScope.$on 'USER_LOADED', () ->
        getPermission()

    vm.openPage = (id,slug) ->
        $state.go('pages', {id: id, slug: slug})
        return

    #TODO temporary notification message
    vm.alertBox = () ->
        alertModal.showMessage 'Message','Dear Customer! This section is under development and will soon be available.', 'OK', ()->
        return

    vm.getClass = (path, allpaths) ->
        className = ''
        location = $location.path()
        paths = path.split('/')
        if location.charAt(0) == '/'
            location = location.substr(1)
        locs = location.split('/')
        if allpaths
            if angular.isArray(allpaths)
                for path in allpaths
                    if locs.indexOf(path) > -1
                        return 'active'
            className = if locs.indexOf(paths[0]) > -1 then 'active' else ''

        if paths.length == 1
            className = if locs[locs.length-1] == paths[0] then 'active' else ''
        else
            className = if location == path then 'active' else ''

    return
]

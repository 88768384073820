'use strict'

angular.module('IBR')
# переводит одно слово ALL (используется в селектах когда значение ALL)
.filter 'all', [ '$translate', ($translate) ->
	(input) ->
		switch input
			when 'ALL' then $translate.instant 'ALL.ALL'
			when 'ALL_BULKS' then $translate.instant 'BULK_PAYMENTS.ALL_BULKS'
		return if input == 'ALL' then $translate.instant 'ALL.ALL' else input
	]

.filter 'allBulks', [ '$translate', ($translate) ->
	(input) ->
		return if input == 'ALL' then $translate.instant 'BULK_PAYMENTS.ALL_BULKS' else input
	]
# фильтр для перевода на заданный язык (не глобальный)
.filter 'trs', [ '$rootScope', ($rootScope) ->
	(key, lang) ->
		if !lang
			lang = $rootScope.lang

		data = window.translations[lang]
		path = key.split '.'

		if !data || path.length != 2
			return key

		if !data[path[0]]
			return key

		return data[path[0]][path[1]] || key
	]
# вспомагательный фильтр для перевода полей в реквизитах счета
.filter 'adtr', [ '$rootScope', '$filter', ($rootScope, $filter) ->
	(key, currency, lang) ->
		
		t1 = t2 = key

		if currency == 'AZN'
			if lang == 'az'
				t1 = t2 = $filter('trs')(key, 'az')
			else
				t1 = $filter('trs')(key, 'az')
				t2 = $filter('trs')(key, lang)
		else if currency == 'RUB'
			if lang == 'en'
				t1 = $filter('trs')(key, 'en')
				t2 = $filter('trs')(key, 'ru')
			else
				t1 = $filter('trs')(key, 'az')
				t2 = $filter('trs')(key, 'ru')
		else
			t1 = $filter('trs')(key, 'az')
			t2 = $filter('trs')(key, lang)

		return if t1 != t2 then t1 + ' / ' + t2 else t1
	]

# локализация мультиязыковых полей (когда приходят варианты для каждого языка )
.filter 'localization', [ '$rootScope', ($rootScope) ->
	(input, key) ->
		if angular.isArray(input) && input.length
			for item in input
				if item.language == $rootScope.currentLang.keyu
					return item.translation
		return ''
	]
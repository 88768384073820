'use strict'

angular.module('IBR')
    .filter 'dateformat', ->
        (input) ->
            delimiter = '.'
            ("0" + input.day).slice(-2) + delimiter + ("0" + input.month).slice(-2) + delimiter + input.year

    .filter 'datetimeformat', ->
        (input)->
            if !input
                return ''
            if input.constructor != Date
                date = new Date input
                if date == 'Invalid Date'
                    return '';
            else
                date = input
            day = processDateUnit date.getDate()
            month = processDateUnit(date.getMonth()+1)
            year = date.getFullYear()
            hours = processDateUnit( date.getHours() )
            minutes = processDateUnit( date.getMinutes() )
            return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes

    .filter 'datenotime', [ '$filter', ($filter) ->
        (input) ->
            if !input then return ''
            if angular.isString(input) then noZoneInput = input.replace(/(.+)(\+.+)/g, '$1') else noZoneInput = input
            return $filter('date')(noZoneInput, 'dd.MM.yyyy')
    ]
    .filter 'dt', [ '$filter', ($filter) ->
        (input, format, timezone) ->
            if !input then return ''
            date = input
            # date = if input.indexOf('Z+') == -1 then input.replace('+', 'Z+') else input
            # console.log(date)
            return $filter('date')(new Date(date), format, timezone)
    ]

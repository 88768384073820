'use strict'
angular.module('IBR').controller 'ArchiveCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'transactionActions', 'appUtils', '$translate', '$q', 'LxDialogService', 'alertModal', 'authoritiesFactory', 'apiAccounts', 'apiPayment', 'apiBulkPayments', 'apiTemplates', 'transactionModal', 'asanModal', 'transactionResultModal', 'apiNotification', 'pagerService', ($rootScope, $scope, $state, $stateParams, $timeout, transactionActions, appUtils, $translate, $q, LxDialogService, alertModal, authoritiesFactory, apiAccounts, apiPayment, apiBulkPayments, apiTemplates, transactionModal, asanModal, transactionResultModal, apiNotification, pagerService) ->
    vm = this
    $scope.vm = vm

    #constants
    NOTE_DIALOG = 'user-note-dial'
    TEMPLATE_NAME_DIALOG = 'user-template-dial'
    DELETE_DIALOG = 'user-delete-dial'

    OPTION_ALL_CUSTOMER = { name: 'ALL' }
    OPTION_ALL_ACCOUNT = { iban: 'ALL' }

    STATUSES = [
        'ACCEPTED'
        'EXPIRED'
        'DELETED'
    ]
    CURRENCIES = [
        'ALL'
        'AZN'
        'USD'
        'EUR'
        'RUB'
        'GBP'
    ]
    # TYPES = [
    #     "ALL"
    #     "INTERNAL_ACCOUNT_TO_ACCOUNT"
    #     "EXCHANGE"
    #     "AZIPS"
    #     "XOHKS"
    #     "BUDGET"
    #     "BUDGET_VAT"
    #     "INTERNATIONAL"
    #     "INTERNATIONAL_RUB"
    #     "SALARY"
    # ]
    TYPES = [
        "ALL"
        "IT"
        "FX"
        "AZ"
        "XO"
        "XT"
        "XV"
        "FP"
        "RP"
        "PI"
        "PT"
        "PV"
        "SP"
    ]
    SOURCES = [
        { value: 'ALL', code: 'ALL' }
        { code: 'BU', value: 'BULK_UPLOAD'}
        { code: 'İO', value: 'INTERNET_OFFICE'}
    ]
    PAGE_SIZES = [10, 50, 100]

    #tmp variables
    tempCustomers = []
    allCanApprove = false

    #scope variables
    vm.showInfoPendings = false
    vm.constants =
        TYPES: TYPES
        CURRENCIES: CURRENCIES
        STATUSES: STATUSES

    vm.tConstants =
        TYPES: TYPES
        SOURCES: SOURCES

    vm.filterDefaults =
        #radio
        status: $stateParams.status || 'ALL_ARCHIVE'
        #table
        page: 1
        onPage: PAGE_SIZES[0]
        startDate: new Date(new Date().setHours(0,0,0,0))
        stopDate: new Date()
        currency: 'ALL'
        transactionStatus: 'ALL' #table status
        isBulk: false
        sort: 'lastModifiedDate'
        sortDir: 'DESC'
        #form
        customer: OPTION_ALL_CUSTOMER
        account: OPTION_ALL_ACCOUNT
        type: $stateParams.type || 'ALL'
        source: SOURCES[0]
        amountMin: ''
        amountMax: ''
    

    vm.loaded = false
    vm.loaders = {}
    vm.actions = {} #API transaction actions

    
    vm.customers = []
    vm.accounts = []
    vm.customerAccounts = {}
    vm.statusCount = {}

    vm.transactions = []
    vm.batches = []

    vm.transactions = []
    vm.batches = []

    vm.filter = angular.copy vm.filterDefaults

    vm.pager = {}
    vm.pageSizes = PAGE_SIZES



    vm.selected = {}
    vm.authSchema = null

    vm.selProps = {} #rules for current selection
    vm.actionSet = [] #general allowed actions for page


    changeCustomers = () ->
        vm.filter.customer = OPTION_ALL_CUSTOMER
        vm.customers = appUtils.filterCustomers tempCustomers
        if vm.customers.length is 1
            vm.filter.customer = vm.customers[0]
        else if vm.customers.length > 1
            vm.customers.unshift OPTION_ALL_CUSTOMER

    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        changeCustomers()

    $scope.$watch 'vm.filter', (val) ->
        # console.log 'CHANGE_FILTER'
        $scope.$broadcast 'CHANGE_FILTER'
    , true


    vm.getBulks = () ->
        console.log 'GET_BULKS'
        cids = vm.customers.map (c) ->
            return c.cid
        vm.bulks = apiBulkPayments.getList cids

    initTransactions = ->
        apiAccounts.getCustomerAccounts()
            .then (data) ->
                tempCustomers = data.customers
                changeCustomers()
                vm.accounts = data.accounts
                vm.customerAccounts = data.customerAccounts

                if $stateParams.cid
                    for customer in vm.customers
                        if customer.cid is $stateParams.cid
                            vm.filter.customer = customer
                            break

                $timeout( ->
                    if $stateParams.iban
                        for account in vm.accounts
                            if account.iban is $stateParams.iban
                                vm.filter.account = account
                                break
                , 300)

                # vm.getStatusCount()
                vm.search true
            .finally () ->
                vm.loaded = true
        return

    vm.getBatch = (batch) ->
        if batch.transactions && batch.transactions.length
            return
        batch.loading = true
        return apiPayment.getBatch batch.batchId
            .then (res) ->
                batch.loading = false
                batch.transactions = res.data
                return res.data
            , (err) ->
                batch.loading = false

    # поиск транзакций
    vm.search = (buttonClick, paginate) ->
        if !paginate
            vm.filter.page = 1 #сбрасываем пагинацию
        vm.loaders.search = true #показываем лодер
        if buttonClick
            vm.getStatusCount()

            if vm.filter.type && vm.filter.type != 'ALL'
                vm.tConstants.TYPES = ['ALL', vm.filter.type]
            else
                vm.tConstants.TYPES = TYPES

            if vm.filter.isBulk
                vm.filter.source = SOURCES[0]
                vm.tConstants.SOURCES = [SOURCES[0], SOURCES[1]]
            else
                vm.tConstants.SOURCES = SOURCES

        apiPayment.getArchivePayments vm.filter
            .then (res) ->
                vm.actionDeselect() #сбрасываем чекбоксы
                vm.loaders.search = false
                vm.transactions = []
                vm.batches = []

                actionSet = []
                for tr in res.data.records
                    # собираем все разрешенные действия для показа кнопок в футере
                    for action in tr.transactionActionTypeSet
                        if actionSet.indexOf(action) == -1
                            actionSet.push action

                    if tr.batchId && !tr.ref
                        vm.batches.push tr
                    else
                        # modify
                        if tr.code is 'IT' || tr.code is 'FX'
                            tr.beneficiaryName = tr.credit
                        vm.transactions.push tr
                vm.actionSet = actionSet

                vm.pager = pagerService.getPager res.data.totalRecordsCount, vm.filter.page, vm.filter.onPage

                # get count
                result = {}
                result[vm.filter.status] = res.data.totalRecordsCount
                setStatusCount result
            , (error) ->
                vm.loaders.search = false

    vm.refresh = (isOk) ->
        if isOk
            vm.search true, true

    vm.actionSetPage = (page) ->
        vm.filter.page = page
        vm.search false, true

    vm.setPageSize = (size) ->
        vm.filter.onPage = size
        vm.search()


    setStatusCount = (result, reset) ->
        if reset
            ALL = vm.statusCount.ALL_ARCHIVE
            vm.statusCount =
                ALL_ARCHIVE: ALL
        for status of result
            vm.statusCount[status] = result[status]

    vm.getStatusCount = () ->
        return
        countFilter =
            pageParams:
                pageNumber: 0
                size: 0
            sort:
                fieldName: ''
                order: 'ASC'

        filter = apiPayment.proccessFilters vm.filter
        if filter && filter.length
            countFilter.filterSpecification = filter

        vm.statusCountLoading = true
        return apiNotification.getNotification countFilter
            .then (response) ->
                result = {}
                response.data.forEach (res) ->
                    result[res.statusNoteType] = res.count
                setStatusCount result, true
                vm.statusCountLoading = false
           , (error) ->
                vm.statusCountLoading = false


    modalSPErrorShowed = false
    vm.actionSelect = (selectedTransactions, selectedBatches, selectedTransactionsInBatch) ->
        vm.selProps = transactionActions.getSelProps(selectedTransactions, selectedBatches, selectedTransactionsInBatch, vm.authSchema)
        vm.selectedTransactions = selectedTransactions
        vm.selectedBatches = selectedBatches
        vm.selectedTransactionsInBatch = selectedTransactionsInBatch

        if (vm.selProps.spConflict || (vm.selProps.fewTransactions && vm.selProps.allIsSalary)) && !modalSPErrorShowed
            alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('INFO.SP_CONFLICT'), 'OK', () ->
                modalSPErrorShowed = true
        

    vm.actionDeselect = () ->
        $scope.$broadcast 'DESELECT'



    # NOTE DIALOGS AS PROMISES

    noteDialog = {
        open: (type, callback) ->
            vm.templateName = ''
            vm.userNote = ''
            vm.loaders.note = false
            if type is 'NOTE'
                LxDialogService.open NOTE_DIALOG
                vm.sendData = () ->
                    callback vm.userNote
            else if type is 'TEMPLATE'
                vm.sendData = () ->
                    callback vm.templateName
                LxDialogService.open TEMPLATE_NAME_DIALOG
            else if type is 'DELETE'
                vm.sendData = () ->
                    callback vm.userNote
                LxDialogService.open DELETE_DIALOG
        close: () ->
            vm.loaders.note = false
            LxDialogService.close NOTE_DIALOG
            LxDialogService.close TEMPLATE_NAME_DIALOG
            LxDialogService.close DELETE_DIALOG
    }


    ######################### ACTIONS #########################

    # SHOW NOTE DIALOG FOR: 'SEND_TO_CHANGE', 'DELETE', 'SAVE_AS_TEMPLATE'


    #########################################################
    ######################### SINGLE ########################
    #########################################################
    # DOWNLOAD
    vm.actions.download = (type, transaction, lang) ->
        # console.log type, transaction, lang
        $rootScope.loaders[type] = true
        apiPayment.transactionFile(transaction.ref, lang.lngu, type)
            .then (response) ->
                window.utils.downloadFile response
                $rootScope.loaders[type] = false
            .catch () ->
                $rootScope.loaders[type] = false



    # CREATE TEMPLATE
    vm.actions.saveTemplate = (transaction, btnKey) ->
        deferred = $q.defer()
        noteDialog.open 'TEMPLATE', (templateName) ->
            vm.loaders.note = true
            return apiTemplates.createTemplateFromTransaction transaction, templateName
                .then (res) ->
                    noteDialog.close()
                    return transactionResultModal.successModal btnKey
                .then (res) ->
                    deferred.resolve res
                .catch (err) ->
                    vm.loaders.note = false
        return deferred.promise

    #########################################################
    ########################## GROUP ########################
    #########################################################

    # DOWNLOAD ONE
    vm.actionDownloadSelected = (type, lang) ->
        vm.actions.downloadGroup type, vm.selectedTransactions, lang


    vm.actions.downloadGroup = (type, transactions, lang) ->
        $rootScope.loaders[type] = true
        refs = {}
        for tr in transactions
            refs[tr.ref] = tr.ref.substring 6,8

        apiPayment.transactionsFile refs, lang.lngu, type
            .then (response) ->
                window.utils.downloadFile response
                $rootScope.loaders[type] = false
            .catch () ->
                $rootScope.loaders[type] = false


    ########### END ACTIONS ###########
     
    # btnKey передаем в экшн, тк он можен понадобится для интерпретации сообщений при выполнении сервиса (например может быть подписание + отправка)

    vm.transactionAction = (transaction, btnKey) ->
        console.log 'transactionAction', transaction, btnKey
        ref = transaction.ref
        code = ref.substring 6, 8
        switch btnKey
            when 'COPY'
                transactionModal.closeInfo()
                payment = angular.copy transaction
                payment.isCopy = true
                delete payment.ref
                delete payment.clientRef
                delete payment.source
                delete payment.bulkRef
                delete payment.uuid
                switch code
                    when 'FX'
                        $state.go 'exchange-payment', { payment: payment }
                    when 'IT'
                        $state.go 'internal-payment', { payment: payment }
                    when 'AZ'
                        $state.go 'domestic-payment', { payment: payment }
                    when 'XO'
                        $state.go 'domestic-payment', { payment: payment }
                    when 'XT'
                        $state.go 'domestic-payment', { payment: payment }
                    when 'XV'
                        $state.go 'domestic-payment', { payment: payment }
                    when 'PI'
                        $state.go 'instant-payment', { archive: payment }
                    when 'PT'
                        $state.go 'instant-payment', { archive: payment }
                    when 'PV'
                        $state.go 'instant-payment', { payment: payment }
                    when 'FP'
                        $state.go 'swift-payment', { payment: payment }
                    when 'RP'
                        $state.go 'swift-payment', { payment: payment }
                    when 'SP'
                        $state.go 'salary-payment', { payment: payment }
                return
            when 'SAVE_AS_TEMPLATE'
                vm.actions.saveTemplate transaction, btnKey
                    .then (res) ->
                        transactionModal.closeInfo()
                        vm.refresh true

    vm.groupAction = (transactions, btnKey) ->
        console.log 'groupAction', transactions, btnKey


    ########### END COMMON ACTIONS ###########


    ###### TRANSACTION DIALOG #####
    vm.transactionDialog = (transaction, btnKey) ->
        if !transaction
            transaction = vm.selectedTransactions[0]
        transactionModal.openInfo transaction, btnKey, vm.transactionAction, vm.actions.download

    vm.transactionsDialog = (btnKey) ->
        transactionModal.openGroup vm.selectedTransactions, null, btnKey, vm.groupAction
        
    vm.batchDialog = (batch, btnKey) ->
        vm.getBatch batch
            .then () ->
                transactionModal.openGroup null, [batch], btnKey, vm.groupAction

    ###### END TRANSACTION DIALOG #####



    # #controller onload
    # authoritiesFactory.checkAuthority('ACCOUNTS', 'READ').then (response) ->
    #     if !response
    #         vm.loaded = true
    #         vm.transactionsLoaded = true
    #         alertModal.showError $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('ERRORS.ACCOUNTS_READ'), 'Ok'
    #     else
    #         initTransactions() #initialization

    initTransactions()

    return vm
]
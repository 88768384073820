'use strict'

angular.module('IBR')
    .directive 'domesticPaymentForm', [ '$rootScope', '$translate', '$timeout', '$filter', 'LxDialogService', 'apiPayment', 'apiAccounts',  ($rootScope, $translate, $timeout, $filter, LxDialogService, apiPayment, apiAccounts ) ->
        {
            resctict: 'E'
            templateUrl: '/components/domestic-payment-form.html'
            scope:
                cids: '=?'
                payment: '=?'
                loaded: '=?'
                txnType: '=?'
                isTemplate: '=?'
                isValid: '=?'
                isReady: '=?'
                isLocked: '=?'
            controller: ['$scope', 'authoritiesFactory', 'alertModal',  ($scope, authoritiesFactory, alertModal) ->
                $scope.PAYMENT_TYPE_AZIPS = 'AZ'
                $scope.PAYMENT_TYPE_XOHKS = 'XO'
                $scope.PAYMENT_TYPE_BUDGET = 'XT'
                $scope.PAYMENT_TYPE_BUDGET_VAT = 'XV'

                $scope.isValid = if angular.isDefined($scope.isValid) then $scope.isValid else true
                $scope.isReady = if angular.isDefined($scope.isReady) then $scope.isReady else false
                $scope.isLocked = if angular.isDefined($scope.isLocked) then $scope.isLocked else false
                $scope.payment = if angular.isDefined($scope.payment) then $scope.payment else {}
                $scope.isTemplate = if angular.isDefined($scope.isTemplate) then $scope.isTemplate else false
                $scope.txnType = if angular.isDefined($scope.txnType) then $scope.txnType else $scope.PAYMENT_TYPE_AZIPS

                $scope.isEditing = false
                $scope.accounts = []
                $scope.customers = []
                $scope.codes = []
                $scope.classCodes = []
                $scope.validFields = {}
                $scope.code = {}
                $scope.codeclass = ""
                $scope.showCodeInput = false
                $scope.credAccounts = []
                $scope.code = { object: '' }
                $scope.name = { object: '' }

                $scope.helperMsg = 'PAYMENT.FIND_HELPER'

                hideStatuses = ['CLOSED']

                $scope.accountChecked = true
                clientRefLoadingTrigger = false



                $scope.payment =
                    beneficiaryCorporateCustomerName: ""
                    credit: ""
                    notes: ""
                    narrative: ""
                    beneficiaryNarrative: ""
                    beneficiaryCorporateCustomerTaxId: ""
                    amount: ""
                    beneficiaryBankCode: ""
                    beneficiaryBankName: ""

                allCustomers = []
                allAccounts = []

                processAccounts = (accounts) ->
                    customerAccounts = {}
                    allCustomers = []
                    accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
                    for account in accounts
                        if account.currency is "AZN"
                            cid = account.corporateCustomer.cid
                            if !(account.status in hideStatuses) && account.iban
                                if !customerAccounts[cid]
                                    allCustomers.push account.corporateCustomer
                                    customerAccounts[cid] = {}
                                    customerAccounts[cid].accounts = []
                                    customerAccounts[cid].corporateCustomer = account.corporateCustomer
                                customerAccounts[cid].accounts.push account
                    filterBySelected()
                    $scope.isReady = $scope.customers.length && accounts && accounts.length
                    customerAccounts

                filterBySelected = () ->
                    $scope.customers = $filter('filterSelectedCustomers')(allCustomers)
                    $scope.cids = $scope.customers.map (customer) ->
                        return customer.cid
                    $scope.accounts = $filter('selectedCustomers')(allAccounts)
                    if $scope.customers.length == 1
                        $scope.payment.customer = $scope.customers[0]
                    $scope.isReady = $scope.customers.length

                $rootScope.$on 'SELECT_CUSTOMERS', () ->
                    filterBySelected()

                resetCode=->
                    $scope.showCodeInput=false
                    $timeout(->
                        $scope.showCodeInput = true
                    )

                initPaymentForm = (txnType) ->
                    resetCode()
                    if $scope.txnType
                        $scope.loaded = false

                        apiAccounts.getPaymentAccounts(txnType).then (response) ->
                            $scope.accounts = processAccounts response.data
                            allAccounts = angular.copy $scope.accounts, []
                            if $scope.customers.length == 1
                                $scope.payment.customer = $scope.customers[0]
                            $scope.loaded = true
                            $scope.$emit('FORM_READY')
                            return
                        .catch (error) ->
                            $scope.loaded = true
                            $scope.$emit('FORM_READY')
                            return

                getClientRef = () ->
                    clientRefLoadingTrigger = true
                    apiPayment.getRef($scope.txnType, $scope.payment.customer.cid).then (response) ->
                            clientRefLoadingTrigger = false
                            if response.data.clientRef
                                $scope.payment.clientRef = response.data.clientRef
                        , (error) ->
                            clientRefLoadingTrigger = false
                    return

                getLvlCodes = () ->
                    apiPayment.getDomesticCodes("lvl").then (response) ->
                        $scope.lvlCodes = response.data.sort (a, b) -> if a.code > b.code then 1 else if b.code > a.code then -1 else 0
                    apiPayment.getDomesticCodes("class", '*').then (response) ->
                        $scope.classCodes = response.data.sort (a, b) -> if a.code > b.code then 1 else if b.code > a.code then -1 else 0
                    return

                resetFields = (button) ->
                    clientRef = $scope.payment.clientRef
                    customer = $scope.payment.customer
                    $scope.validFields = {}
                    $scope.payment =
                        clientRef: clientRef
                        customer: customer
                        beneficiaryCorporateCustomerName: ""
                        credit: ""
                        notes: ""
                        narrative: ""
                        beneficiaryNarrative: ""
                        beneficiaryCorporateCustomerTaxId: ""
                        amount: ""
                        beneficiaryBankCode: ""
                        beneficiaryBankName: ""
                    $scope.code = { object: '' }
                    $scope.name = { object: '' }
                    $scope.accountChecked = true
                    $scope.code.class = ""
                    $scope.codeLvl = ""

                    domesticData = apiPayment.getDomesticData $scope.txnType
                    angular.extend($scope.payment, domesticData)

                    # switch $scope.txnType
                    #     when $scope.PAYMENT_TYPE_BUDGET
                    #         $scope.payment.beneficiaryBankCode = "210005"
                    #         $scope.payment.beneficiaryBankName = $translate.instant('DOMESTIC_PAYMENT.TITLE_TREASURY_FULL')
                    #         $scope.payment.beneficiaryBankTaxId = "1401555071"
                    #         $scope.payment.beneficiaryBankCorrespondentAccountIban = "AZ41NABZ01360100000000003944"
                    #         $scope.payment.beneficiaryBankSwiftCode = "CTREAZ22"
                    #         $scope.payment.beneficiaryBankCode = "210005"
                    #     when $scope.PAYMENT_TYPE_BUDGET_VAT
                    #         $scope.payment.beneficiaryBankCode = "210027"
                    #         $scope.payment.beneficiaryBankName = $translate.instant('DOMESTIC_PAYMENT.TITLE_VAT_FULL')
                    #         $scope.payment.beneficiaryBankTaxId = "1401555071"
                    #         $scope.payment.beneficiaryBankCorrespondentAccountIban = "AZ07NABZ01360100000000004944"
                    #         $scope.payment.beneficiaryBankSwiftCode = "CTREAZ24"
                    #         $scope.payment.beneficiaryBankCode = "210027"
                    #         $scope.payment.credit = "AZ48CTRE00000000000000014001"

                    resetCode()

                $scope.filterClass = (val) ->
                    type = "class"
                    val=val+''
                    searchQuery ="code:*"+val+"*"
                    apiPayment.getDomesticCodes(type, searchQuery).then (response) ->
                        $scope.classCodes = response.data
                    return


                $scope.$watch 'txnType', (value) ->
                    $scope.helperMsg = 'PAYMENT.FIND_HELPER'
                    if value then initPaymentForm(value)
                    switch value
                        when $scope.PAYMENT_TYPE_BUDGET
                            $scope.payment.beneficiaryBankCode = "210005"
                            $scope.payment.beneficiaryBankName = $translate.instant('DOMESTIC_PAYMENT.TITLE_TREASURY_FULL')
                            $scope.payment.beneficiaryBankTaxId = "1401555071"
                            $scope.payment.beneficiaryBankCorrespondentAccountIban = "AZ41NABZ01360100000000003944"
                            $scope.payment.beneficiaryBankSwiftCode = "CTREAZ22"
                            $scope.payment.beneficiaryBankCode = "210005"
                            getLvlCodes()
                        when $scope.PAYMENT_TYPE_BUDGET_VAT
                            $scope.payment.beneficiaryBankCode = "210027"
                            $scope.payment.beneficiaryBankName = $translate.instant('DOMESTIC_PAYMENT.TITLE_VAT_FULL')
                            $scope.payment.beneficiaryBankTaxId = "1401555071"
                            $scope.payment.beneficiaryBankCorrespondentAccountIban = "AZ07NABZ01360100000000004944"
                            $scope.payment.beneficiaryBankSwiftCode = "CTREAZ24"
                            $scope.payment.beneficiaryBankCode = "210027"
                            $scope.payment.credit = "AZ48CTRE00000000000000014001"
                        else
                            $scope.payment.beneficiaryBankCode = ""
                            $scope.payment.beneficiaryBankName = ""
                            $scope.payment.beneficiaryBankTaxId = ""
                            $scope.payment.beneficiaryBankCorrespondentAccountIban = ""
                            $scope.payment.beneficiaryBankSwiftCode = ""
                            $scope.payment.beneficiaryBankCode = ""
                            $scope.payment.credit = ""

                # $scope.$on 'init', ( = ->
                #     initDomesticPayment()

                $scope.$on 'PAYMENT_RESET', (event, clearRef) ->
                    if $scope.isEditing
                        clearRef = true
                        $scope.isEditing = false
                    resetFields()
                    if clearRef
                        $scope.payment.clientRef = ''

                $scope.search =
                    codeInput: ''
                    bankNameInput: ''

                $scope.searchDialog = () ->
                    $scope.codes = []
                    $scope.search =
                        codeInput: ''
                        bankNameInput: ''
                    LxDialogService.open "domestic-search-dial"
                    return

                $scope.searchCodeDialog = () ->
                    $scope.classificationCodes = []
                    $scope.search =
                        code: ''
                        description: ''
                    LxDialogService.open "code-class-search"

                $scope.bigSearch = () ->
                    if ($scope.search.codeInput.length > 2 || $scope.search.bankNameInput.length > 2)
                        searchQuery ='(code:*' + $scope.search.codeInput + '*)AND(name:*' + $scope.search.bankNameInput + '*)'
                        switch $scope.txnType
                            when $scope.PAYMENT_TYPE_BUDGET
                                apiPayment.getDomesticCodes("payments", searchQuery).then (response) ->
                                    $scope.codes = response.data
                            else
                                apiPayment.getDomesticCodes("bank", searchQuery).then (response) ->
                                    $scope.codes = response.data
                    else
                        $scope.codes = []
                    return

                $scope.searchCodeClass = () ->
                    if ($scope.search.code.length > 2)
                        searchQuery = '(code:*' + $scope.search.code + '*)'
                        apiPayment.getDomesticCodes("class", searchQuery).then (response) ->
                            $scope.classificationCodes = response.data
                    else
                        $scope.classificationCodes = []
                    return

                $scope.filterCodes = (val, autoselect) ->
                    if(val && val.length > 1)
                        $scope.helperMsg = ''
                        searchQuery ="code:*"+val+"*"
                        switch $scope.txnType
                            when $scope.PAYMENT_TYPE_BUDGET
                                apiPayment.getDomesticCodes("payments", searchQuery).then (response) ->
                                    $scope.codes = response.data
                                    if $scope.codes.length == 0
                                        $scope.helperMsg = 'DOMESTIC_PAYMENT.ACCS_NOT_FOUND'
                                    if $scope.codes.length is 1 && autoselect
                                        $scope.writeAll $scope.codes[0]
                            else
                                apiPayment.getDomesticCodes("bank", searchQuery).then (response) ->
                                    $scope.codes = response.data
                                    if $scope.codes.length == 0
                                        $scope.helperMsg = 'DOMESTIC_PAYMENT.CODES_NOT_FOUND'
                                    if $scope.codes.length is 1 && autoselect
                                        $scope.writeAll $scope.codes[0]
                    else
                        $scope.helperMsg = 'PAYMENT.FIND_HELPER'
                        $scope.codes = []
                    return

                $scope.chooseCode = (val) ->
                    LxDialogService.close "domestic-search-dial"
                    $scope.writeAll(val)
                    return

                $scope.chooseClassificationCode = (val) ->
                  $scope.code.class = val
                  LxDialogService.close "code-class-search"
                  $scope.payment.budgetClassificationCode = val.code
                  return

                $scope.setCodeClass = (val) ->
                    $scope.validFields.codeClass = false
                    $scope.payment.budgetClassificationCode = if val then val.code else ''

                $scope.setCodeLvl = (val) ->
                    $scope.validFields.codeLvl = false
                    $scope.payment.budgetLevelCode = if val then val.code else  ''

                $scope.writeAll = (val) ->
                    $scope.validFields.code = false
                    if !val
                        if $scope.txnType is $scope.PAYMENT_TYPE_BUDGET
                            delete $scope.name.object
                            delete $scope.payment.beneficiaryCorporateCustomerName
                            delete $scope.payment.credit
                            delete $scope.payment.beneficiaryCorporateCustomerTaxId
                        else
                            delete $scope.code.object
                            delete $scope.payment.beneficiaryBankName
                            delete $scope.payment.beneficiaryBankCorrespondentAccountIban
                            delete $scope.payment.beneficiaryBankTaxId
                            delete $scope.payment.beneficiaryBankSwiftCode
                    else
                        switch $scope.txnType
                            when $scope.PAYMENT_TYPE_BUDGET
                                $scope.name.object = val
                                $scope.payment.beneficiaryCorporateCustomerName = val.name
                                $scope.payment.credit = val.code
                                $scope.payment.beneficiaryCorporateCustomerTaxId = val.taxId
                            else
                                $scope.code.object = val
                                $scope.payment.beneficiaryBankCode = val.code
                                $scope.payment.beneficiaryBankName = val.name
                                $scope.payment.beneficiaryBankSwiftCode = val.swiftCode
                                $scope.payment.beneficiaryBankTaxId = val.taxId
                                $scope.payment.beneficiaryBankCorrespondentAccountIban = val.correspondentAccountIban
                                if $scope.payment.credit
                                    $scope.compareSwift()
                        $scope.codes = []
                    return

                #CBR-1715
                $scope.compareSwift = () ->
                    $scope.validFields.compare = false
                    if(!$scope.payment.credit || !$scope.payment.beneficiaryBankSwiftCode)
                        return
                    else
                        testCode = $scope.payment.beneficiaryBankCode
                        testSwift = $scope.payment.beneficiaryBankSwiftCode
                        testAcc = $scope.payment.credit
                        $scope.validFields.compare = testSwift.substring(0,4) != testAcc.substring(4,8)
                        #exceptions
                        if CONFIG.BANK_SWIFT && CONFIG.BANK_SWIFT[testCode] && CONFIG.BANK_SWIFT[testCode] == testAcc.substring(4,8)
                            $scope.validFields.compare = false
                        
                        # if(testSwift[0] != testAcc[4] || testSwift[1] != testAcc[5] || testSwift[2] != testAcc[6] || testSwift[3] != testAcc[7])
                        #     $scope.validFields.compare = true
                    return

                #@TODO make checking account in a way, it needs, not by internal checking way
                $scope.checkAccount = () ->
                    # AZ62BRES00380394403617708201
                    if $scope.payment.credit
                        if $scope.payment.credit && typeof($scope.payment.credit) == 'string' && $scope.payment.credit.length == 28
                            ibanQuery =
                                iban: $scope.payment.credit
                            apiPayment.checkIban(ibanQuery).then (response) ->
                                if response.data && response.data.result
                                    $scope.accountChecked = true
                                else
                                    $scope.accountChecked = false
                                return
                            , (error) ->
                                $scope.accountChecked = true
                            return
                        else
                            $scope.accountChecked = false
                        return

                $scope.$watch 'payment.customer', ( ->
                    $scope.payment.clientRef = ''
                    if $scope.payment.customer && $scope.payment.customer.cid && !clientRefLoadingTrigger
                        $scope.payment.debit = ''
                    return
                ), true

                $scope.$watch 'payment.debit', ( ->
                    if $scope.payment.debit && $scope.payment.debit.iban && !$scope.payment.clientRef && !$scope.isEditing
                        getClientRef()
                    return
                ), true

                # $scope.$watch 'payment', ( ->
                #     if !(angular.equals($scope.validFields,{}))
                #         for own key of $scope.payment
                #             if $scope.validFields[key] is true and $scope.payment[key]
                #                 $scope.validFields[key]= false
                # ),true


                $scope.searchTemplate = ->
                    $scope.templateBtnLock = true
                    $scope.$emit 'TEMPLATE_SEARCH'

                $scope.listTemplate = ->
                    $scope.templateBtnLock = true
                    $scope.$emit 'TEMPLATE_LIST'

                $scope.$on 'TEMPLATES_FOUND', () ->
                    $scope.templateBtnLock = false

                $scope.$on 'TEMPLATES_ERR', () ->
                    $scope.templateBtnLock = false

                $scope.$on 'setPayment', (event, payment) ->
                    $scope.isEditing = true
                    if payment.isCopy
                        $scope.isEditing = false
                        delete payment.isCopy

                    if payment.bulkId
                        $scope.payment.bulkId = payment.bulkId
                    if payment.bulkRef
                        $scope.payment.bulkRef = payment.bulkRef
                    if payment.bulkName
                        $scope.payment.bulkName = payment.bulkName
                    if payment.source
                        $scope.payment.source = payment.source
                    if payment.uuid
                        $scope.payment.uuid = payment.uuid

                    $scope.txnType = payment.code || payment.typeCode
                    cid = payment.initiatorCid || payment.corporateCustomerInitiatorCid

                    $timeout( ->
                        $scope.payment.ref = payment.ref || ''
                        $scope.payment.typeCode = $scope.txnType
                        for customer in $scope.customers
                            if customer.cid == cid
                                $scope.payment.customer = customer
                    , 100 )

                    $timeout( ->

                        clientRefLoadingTrigger = true
                        for account in $scope.accounts[cid].accounts
                            if payment.debit == account.iban
                                $scope.payment.debit = account

                        if payment.clientRef
                            $scope.payment.clientRef = payment.clientRef

                        $scope.payment.amount = (payment.debitAmount || payment.amount) + ''
                        $scope.payment.narrative = payment.narrative || ''
                        $scope.payment.notes = payment.notes || ''
                        $scope.payment.beneficiaryNarrative = payment.beneficiaryNarrative || ''

                        if payment.bankInfo
                            bankInfoFilling({transfer: payment})

                        if payment.beneficiaryName
                            $scope.payment.beneficiaryCorporateCustomerName = payment.beneficiaryName


                        if $scope.txnType != $scope.PAYMENT_TYPE_BUDGET_VAT
                            $scope.payment.credit = payment.credit

                        if payment.beneficiaryTaxId
                            $scope.payment.beneficiaryCorporateCustomerTaxId = payment.beneficiaryTaxId
                            if payment.code is $scope.PAYMENT_TYPE_BUDGET
                                $scope.name.object =
                                    name: payment.beneficiaryName
                                    code: payment.credit
                                    taxId: payment.beneficiaryTaxId

                                # console.log($scope.name.object)

                                $scope.codeLvl =
                                    code: payment.budgetLevelCode
                                $scope.code.class =
                                    code: payment.budgetClassificationCode

                    , 200 )

                    return

                # $scope.$on 'resetEditing', () ->
                #     $scope.isEditing = false

                tmpDebit = {} # variable to cash debit account, were found
                $scope.$on 'TEMPLATE_SELECT', (event, template, isPayment) ->
                    if !isPayment then $scope.isEditing = true #If filling fields from template for payment, we don't block Customer Field

                    for customer in $scope.customers
                        if customer.cid == template.transfer.corporateCustomerInitiator.cid
                            $scope.payment.customer = customer
                    resetFields(true)


                    $scope.payment.narrative = template.transfer.narrative || ''
                    $scope.payment.notes = template.transfer.notes || ''
                    $scope.payment.amount = (template.transfer.amount || '') + ''

                    $timeout( ->
                        if template.transfer.debit && template.transfer.debit.iban
                            tmpDebit = $filter('filter')($scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts, {iban: template.transfer.debit.iban})[0]
                            $scope.payment.debit = angular.copy tmpDebit, {}
                        else
                            $scope.payment.debit = ''
                        if $scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts[0] && !$scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts[0].deleted
                            $scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts.unshift({
                              deleted: true,
                              customName: "PAYMENT.SELECT_ACCOUNT"
                            })
                    )
                    $timeout(->
                        beneficiaryInfoFilling(template)
                    )
                    if $scope.txnType is $scope.PAYMENT_TYPE_AZIPS || $scope.txnType is $scope.PAYMENT_TYPE_XOHKS
                        bankInfoFilling(template)
                        $scope.payment.beneficiaryNarrative = template.transfer.beneficiaryNarrative

                    $scope.payment.templateName = template.name
                    return

                beneficiaryInfoFilling = (template)->
                    $scope.payment.beneficiaryCorporateCustomerName = template.transfer.beneficiary.name
                    $scope.payment.beneficiaryCorporateCustomerTaxId = template.transfer.beneficiary.taxId || ''
                    if $scope.txnType is $scope.PAYMENT_TYPE_BUDGET
                        $scope.name.object =
                            name: template.transfer.beneficiary.name
                            code: template.transfer.credit.iban
                            taxId: template.transfer.beneficiary.taxId

                        $scope.codeLvl =
                            code: template.transfer.budgetLevelCode
                        $scope.code.class =
                            code: template.transfer.budgetClassificationCode
                    else
                        if $scope.txnType != $scope.PAYMENT_TYPE_BUDGET_VAT
                            $scope.payment.credit = template.transfer.credit.iban
                    return

                bankInfoFilling = (template)->
                    $scope.filterCodes template.transfer.bankInfo.code, true
                    # $scope.payment.beneficiaryBankCode = template.transfer.bankInfo.code
                    # $scope.payment.beneficiaryBankName = template.transfer.bankInfo.name
                    # $scope.payment.beneficiaryBankCorrespondentAccountIban = template.transfer.bankInfo.correspondentAccountIban
                    # $scope.payment.beneficiaryBankTaxId = template.transfer.bankInfo.taxId
                    # $scope.payment.beneficiaryBankSwiftCode = template.transfer.bankInfo.swiftCode

                    # $scope.code.object =
                    #     code: template.transfer.bankInfo.code
                    #     name: template.transfer.bankInfo.name
                    #     swiftCode: template.transfer.bankInfo.swiftCode
                    #     taxId: template.transfer.bankInfo.taxId
                    #     correspondentAccountIban: template.transfer.bankInfo.correspondentAccountIban
                    # return

                $scope.validCheck = () ->
                    if !$scope.isTemplate
                        $scope.validFields.amount = !$scope.payment.amount
                        $scope.validFields.zeroAmount = parseFloat($scope.payment.amount) <= 0
                        if $scope.txnType == $scope.PAYMENT_TYPE_XOHKS
                            $scope.validFields.bigAmount = ($scope.payment.amount && $scope.payment.amount.replace(/\s/g, '') > 40000)
                        return

                $scope.checkTaxId = () ->
                    # if $scope.txnType is $scope.PAYMENT_TYPE_BUDGET
                    #     $scope.validFields.codeClass = !$scope.payment.budgetClassificationCode
                    #     $scope.validFields.codeLvl = !$scope.payment.budgetLevelCode
                    # $scope.validFields.paymentDetails = !$scope.payment.narrative || $scope.payment.narrative.length < 1
                    if $scope.txnType is $scope.PAYMENT_TYPE_BUDGET_VAT
                        $scope.validFields.beneficiaryCorporateCustomerTaxId = !$scope.payment.beneficiaryCorporateCustomerTaxId || ($scope.payment.beneficiaryCorporateCustomerTaxId && $scope.payment.beneficiaryCorporateCustomerTaxId.length < 10)
                    else
                        $scope.validFields.beneficiaryCorporateCustomerTaxId = $scope.payment.beneficiaryCorporateCustomerTaxId  && $scope.payment.beneficiaryCorporateCustomerTaxId.length && $scope.payment.beneficiaryCorporateCustomerTaxId.length < 10

                    return


                $scope.$watch('payment.clientRef', (val) ->
                    if val
                        $scope.validFields.clientRef = false
                )
                $scope.$watch('payment.beneficiaryCorporateCustomerName', (val) ->
                    if val
                        $scope.validFields.name = false
                )
                $scope.$watch('payment.credit', (val) ->
                    if val
                        $scope.validFields.credit = false
                )


                $scope.$on 'PAYMENT_LOCAL_VALIDATE', (event, isTemplate) ->
                    $scope.validFields.templateName = false
                    $scope.validFields.customer = !$scope.payment.customer
                    $scope.validFields.clientRef = !$scope.payment.clientRef
                    $scope.validFields.debit = !$scope.payment.debit || angular.equals($scope.payment.debit, {})
                    $scope.validFields.credit = !$scope.payment.credit
                    $scope.validFields.amount = !$scope.payment.amount
                    $scope.validFields.name = !$scope.payment.beneficiaryCorporateCustomerName

                    #????
                    $scope.checkTaxId()

                    if $scope.txnType is $scope.PAYMENT_TYPE_XOHKS || $scope.txnType is $scope.PAYMENT_TYPE_AZIPS
                        $scope.validFields.codeObject = !$scope.code.object

                    if $scope.txnType is $scope.PAYMENT_TYPE_BUDGET
                        $scope.validFields.codeClass = !$scope.payment.budgetClassificationCode
                        $scope.validFields.codeLvl = !$scope.payment.budgetLevelCode
                    $scope.validFields.paymentDetails = !$scope.payment.narrative || $scope.payment.narrative.length < 1

                    $scope.validCheck()

                    validateForTemplate = [
                        'name'
                        'credit'
                        'codeObject'
                        'codeClass'
                        'codeLvl'
                        'beneficiaryCorporateCustomerTaxId'
                        'compare'
                    ]

                    $scope.compareSwift()

                    isValid = true
                    for key, value of $scope.validFields
                        if isTemplate && validateForTemplate.indexOf(key) == -1 #поля нет в массиве для валидации
                            $scope.validFields[key] = false
                            value = false #поля, которые не валидируем задаем валидными
                        isValid *= !value #собираем результат
                        


                    isValid *= $scope.accountChecked
                    $scope.isValid = isValid

                    if $scope.isTemplate
                        $scope.validFields.templateName = !$scope.payment.templateName
                        $scope.isValid = $scope.isValid && !$scope.validFields.templateName

                    #modify
                    if $scope.code.object && !$scope.payment.beneficiaryBankTaxId
                        $scope.payment.beneficiaryBankTaxId = "0000000000"

                    $scope.$emit 'PAYMENT_LOCAL_VALIDATED', $scope.isValid

                    if !$scope.isValid
                        setTimeout( () ->
                            scrollElement = if $scope.isTemplate then document.querySelector('.dialog__scrollable') else document.documentElement
                            scrollToElement = scrollElement.querySelector('.text-field-error')
                            window.utils.scrollTo scrollElement, scrollToElement, 1000
                        , 500 )

                $scope.$on 'storageSet', (event, field, val) ->
                    $scope.payment[field] = val
            ]
        }
]

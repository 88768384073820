angular.module('IBR')
.controller 'PaymentInstantCtrl', [ '$rootScope', '$q', '$timeout', '$translate', '$scope', '$stateParams', 'apiPayment', 'apiTemplates', 'authoritiesFactory', 'transactionActions', 'alertModal', 'asanModal', 'transactionResultModal', 'LxDialogService', ( $rootScope, $q, $timeout, $translate, $scope, $stateParams, apiPayment, apiTemplates, authoritiesFactory, transactionActions, alertModal, asanModal, transactionResultModal, LxDialogService ) ->

    #constants
    CODE_PI = 'PI'
    CODE_PT = 'PT'
    CODE_PV = 'PV'
    
    DIALOG_MODAL = 'modal-payment-instant'
    TEMPLATE_NAME_MODAL = 'modal-create-template'

    # this
    vm = this

    vm.loaded = false
    vm.loaders = {}
    vm.payment = {}
    vm.cids = []
    vm.isLocked = false #additional
    vm.validFields = {}
    vm.templateName = ''
    vm.cutOff = false
    vm.currentCode = CODE_PI

    #valid payment object for API
    validatedPayment = {}
    
    #checkPermissions
    vm.permissions = {}
    $q.all([
        authoritiesFactory.checkAuthority 'PI', 'CREATE'
        authoritiesFactory.checkAuthority 'PT', 'CREATE'
        authoritiesFactory.checkAuthority 'PV', 'CREATE'
    ]).then (responses) ->
        vm.permissions[CODE_PI] = responses[0]
        vm.permissions[CODE_PT] = responses[1]
        vm.permissions[CODE_PV] = responses[2]

        if !vm.permissions[vm.currentCode]
            vm.isLocked = true
            vm.loaded = true
            alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ACCESS_DENIED'), 'Ok'

    vm.getTabClass = (code) ->
        className = ''
        if !vm.permissions[code]
            className = 'not-allowed'
        if code is vm.currentCode
            className = 'active'
        return className
    #checkPermissions END

    # payment for initialized
    progress = false
    $scope.$on 'FORM_READY', (event) ->
        console.log "FORM_READY"
        if $stateParams.payment && !progress
            $scope.$broadcast 'setPayment', $stateParams.payment #fill form if it is change action
            progress = true
        if $stateParams.archive && !progress
            archive_payment = $stateParams.archive
            archive_payment.isCopy = true
            archive_payment.createdFromTemplate = false
            $scope.$broadcast 'setPayment', archive_payment
            progress = true

        if $stateParams.template && !progress
            if vm.currentCode != $stateParams.template.transfer.code
                vm.currentCode = $stateParams.template.transfer.code
            $scope.$broadcast 'TEMPLATE_SELECT', $stateParams.template, true
            $stateParams.template = null
            progress = true

    searchTemplate = (extended) ->
        tempTemplates = []
        type = apiPayment.getPaymentType vm.currentCode
        template = {
            pageParams: {
                pageNumber: 1
                size: 10000
            }
            sort: {
                fieldName: "name"
                order: "ASC"
            }
            filterSpecification: [
                {
                    fieldName: "type"
                    inverted: "false"
                    operator: "EQUALS"
                    values: [ type ]
                }
                {
                    fieldName: "initiatorCid"
                    inverted: "false"
                    operator: "IN"
                    values: if (vm.payment.customer && vm.payment.customer.cid) then [vm.payment.customer.cid] else vm.cids
                }
            ]
        }

        apiTemplates.getTemplates template
            .then (response) ->
                tempTemplates = response.data.records
                return
            .then ->
                vm.templates = tempTemplates
                # unlockTemplateBtn()
                $scope.$broadcast 'TEMPLATES_FOUND'
                if extended
                    LxDialogService.open 'temp-search-dialog'
                else
                    LxDialogService.open 'temp-list-dialog'
            .catch (err) ->
                $scope.$broadcast 'TEMPLATES_ERR'
                # vm.templateFilling = true
        return

    $scope.$on 'TEMPLATE_SEARCH', () ->
        searchTemplate true

    $scope.$on 'TEMPLATE_LIST', () ->
        searchTemplate false

    vm.chooseTemplate = (template) ->
        $scope.$broadcast 'TEMPLATE_SELECT', template, true

    $scope.$on 'GET_LIMITS', () ->
#        console.log "$scope.$on 'GET_LIMITS'", vm.payment
        validatedPayment = payment = processPayment vm.payment
        # payment.lang = $rootScope.currentLang.lngu
        $rootScope.customAlert = true

#        console.log "GET_LIMITS payment", payment
        return apiPayment.getLimits vm.currentCode, payment
            .then (res) ->
                $scope.$broadcast 'LIMITS_RESULT', res
            .catch (err) ->
                $scope.$broadcast 'LIMITS_RESULT', err

    processPayment = (payment) ->
#        console.log "processPayment", payment
        p = angular.copy(payment, {})
        p.lang = $rootScope.currentLang.lngu
        p.initiatorCid = payment.customer.cid

        if typeof(p.debit) == 'object' && !angular.equals(p.debit, {})
            p.currency = p.debit.currency
            p.debit = p.debit.iban

        if typeof(p.credit) == 'object'
            p.credit = p.credit.iban
        if typeof(p.customer) == 'object'
            p.uuid = p.customer.cid
            delete p.customer

        if !p.getByAlias
            delete p.ipsTemplateAlias

        switch vm.currentCode
            when CODE_PV
                p.beneficiaryBankName = "Dovlet Xezinedarligi Agentliyi (EDV)"
            when CODE_PT
                p.beneficiaryBankName = "Dovlet Xezinedarligi Agentliyi"
        if p.amount
            p.amount = Number p.amount.replace(/\s/g,'')
        p.status = 0

        for own key of p
            if (typeof p[key] == 'undefined' || !p[key].length && typeof p[key] == 'string')
                delete p[key]
        if p.notes == ''
            delete p.notes
        return p

    processTemplate = (payment) ->
        template = processPayment payment
        for own key of template
            if !template[key].length && key != "customer" && typeof template[key] == 'string'
                delete template[key]
        if template.debit
            template.debitIban = vm.payment.debit.iban
            delete template.debit

        template.code = payment.typeCode

        delete template.typeCode
        delete template.credit
        delete template.uuid
        delete template.clientRef
        delete template.currency
        delete template.status

        template.creditIban = vm.payment.credit
        template.cid = vm.payment.customer.cid

        return template

    showLimitMsg = (remark, limitInfo) ->
        messages = [{ type: 'err', msg: remark }]
        if limitInfo
            for msg in limitInfo.split(';')
                if msg.length > 2
                    messages.push ({ type: 'txt', msg: msg })

        alertModal.showLimits $translate.instant('INSTANT_PAYMENT.LIMITS'), messages, $translate.instant('DIALOG.OK'), () ->
            vm.loaders.validation = false
    

    paymentCheck = () ->
#        console.log "paymentCheck", vm.payment

        notOk = false
        showFinalModal = true

        validatedPayment = payment = processPayment vm.payment
        $rootScope.customAlert = true
        return apiPayment.validate vm.currentCode, payment
            .then (res) ->
                #successfully validated
                $rootScope.customAlert = false
                vm.validationMessages = []

                notOk = false
                cutOffMessage = {
                    isOk: true
                    message: ''
                    class: ''
                }
                limitMessage = {
                    isOk: true
                    message: ''
                    class: ''
                }
                checkIbanMessage = {
                    isOk: true
                    message: ''
                    class: ''
                }
                
                for m in res.data
                    switch m.paramName
                        #iban check
                        when 'checkBeneficiaryAccBank'
                            checkIbanMessage.message = m.message
                            checkIbanMessage.class = 'error'
                            $scope.$broadcast 'account_iban_not_exist'
                            notOk = true
                        # cut off service
                        when 'cutOffStatus'
                            if m.message == 'Success'
                                cutOffMessage.isOk = cutOffMessage.isOk and true
                            else if m.message == 'Failure'
                                cutOffMessage.isOk = false
                                cutOffMessage.class = 'error'
                                notOk = true
                        when 'cutOffResult'
                            cutOffMessage.message = m.message

                        when 'cutOffDay'
                            if m.message == 'Y'
                                cutOffMessage.isOk = cutOffMessage.isOk and true
                            else if m.message == 'N'
                                cutOffMessage.isOk = false
                                cutOffMessage.class = 'error'
                        when 'cutOffTime'
                            if m.message == 'Within'
                                cutOffMessage.isOk = cutOffMessage.isOk and true
                            else if m.message == 'Outof'
                                cutOffMessage.isOk = false
                                cutOffMessage.class = 'error'

                        # limits service
                        when 'limitStatus'
                            if m.message == 'Success'
                            else if m.message == 'Failure'
                                limitMessage.isOk = false
                                limitMessage.class = 'error'
                                notOk = true
                        when 'limitResult'
                            limitMessage.message = m.message
                        when 'limitKeyword'
                            if m.message == 'NotFound'
                                limitMessage.isOk = limitMessage.isOk and true
                            else if m.message == 'Found'
                                limitMessage.isOk = false
                        when 'limitInfo'
                            limitMessage.limitInfo = m.message
    

                if cutOffMessage.isOk
                    cutOffMessage.class = 'info'
                if limitMessage.isOk
                    limitMessage.class = 'info'

                vm.validationMessages = [
                    cutOffMessage,
                    limitMessage,
                    checkIbanMessage
                ]

                if !limitMessage.isOk
                    showFinalModal = false
                    showLimitMsg(limitMessage.message, limitMessage.limitInfo)
                

                #get comission
                data = {
                    cid: vm.payment.customer.cid
                    paymentType: vm.currentCode
                    amount: payment.amount
                    currency: payment.currency
                    debit: payment.debit
                    credit: payment.credit
                    beneficiaryBankCode: payment.beneficiaryBankCode
                    customerTaxId: payment.beneficiaryCorporateCustomerTaxId
                    narrative: payment.narrative
                }

                
                return apiPayment.getCommis data
            .then (res) ->
                console.log('#validation response', res.data) #Check_BenefAccBank ???
                if res.data.currency != undefined && res.data.amount != undefined
                    vm.payment.commisAmount = res.data.amount
                    vm.payment.commisCurrency = res.data.currency

                return transactionActions.getPaymentButtons vm.currentCode, vm.payment.customer.cid, vm.payment.customer.authSchema, vm.payment.debit.iban
            .then (btns) ->
                if showFinalModal
                    buttons = angular.copy(btns)
                    if notOk
                        for btn in buttons
                            if btn.key != 'DELETE'
                                btn.disabled = true
                    vm.loaders.validation = false
                    vm.buttons = buttons
                    paymentDialog.open()
            .catch (err) ->
                console.log 'err catch'
                vm.loaders.validation = false
                if $rootScope.customAlert
                    if err && err.data && err.data.constraints && err.data.constraints[0] && err.data.constraints[0].code is '0038'
                        alertModal.showError $translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ERROR_CORP_LIMIT'), 'Ok'
                    else
                        alertModal.httpError err


    paymentDialog = {}

    paymentDialog.open = () ->
        vm.loaders = {}
        LxDialogService.open DIALOG_MODAL
        return

    paymentDialog.close = () ->
        vm.loaders = {}
        LxDialogService.close DIALOG_MODAL

    vm.closePaymentDialog = () ->
        LxDialogService.close DIALOG_MODAL


    vm.initPayment = (code) ->
        if vm.permissions[code] and vm.loaded
            if vm.currentCode != code
                vm.currentCode = code
                vm.resetPayment true

    vm.resetPayment = (needClearRef) ->
        $scope.$broadcast 'PAYMENT_RESET', needClearRef
    
    vm.presendPayment = () ->
        vm.loaders.validation = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false

    vm.presendTemplate = () ->
        vm.loaders.template = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false

    $scope.$on 'PAYMENT_LOCAL_VALIDATED', (event, isValid) ->
        if isValid
            if vm.loaders.validation
                paymentCheck()
                    # .then () ->
                    #     paymentDialog.open()
                    # .catch () ->
                    #     vm.loaders = {}
            else if vm.loaders.template
                vm.loaders = {}
                LxDialogService.open TEMPLATE_NAME_MODAL
        else
            vm.loaders.validation = false


    vm.sendPayment = (btnKey) ->
        if vm.loaders.actions
            return
        vm.loaders.actions = true
        vm.loaders[btnKey] = true
#        console.log 'sendPayment', btnKey
#        console.log 'send Payment', vm.payment
        authSchema = vm.payment.customer.authSchema
        switch btnKey
            when 'SEND_TO_SIGN', 'SEND_TO_SIGN__APPROVE'

                apiPayment.create vm.currentCode, false, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'APPROVE'
                apiPayment.create vm.currentCode, btnKey, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        if res.data.responseType is 'COMPLETE'
                            return transactionResultModal.successPaymentModal authSchema, btnKey
                        else
                            $q.reject()
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1', 'SIGN_2'
                asanModal.signPayment vm.currentCode, validatedPayment, btnKey
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1__SEND_TO_CBS'
                asanModal.signPayment vm.currentCode, validatedPayment, 'SIGN_1'
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        validatedPayment.ref = res.data.refSet[0]
                        alertModal.showLoader()
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs validatedPayment.ref #todo where ref from?
                    .then (res) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        return transactionResultModal.sendToBank btnKey, validatedPayment, null, res.data, res.status
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                paymentDialog.close()
                                vm.resetPayment true

    
    vm.sendTemplate = () ->
        if !vm.templateName
            vm.validFields.templateName = true
            return

        template = processTemplate vm.payment

        template.name = vm.templateName
        vm.loaders.template = true
        apiTemplates.addTemplate vm.currentCode, template
            .then (response) ->
                LxDialogService.close TEMPLATE_NAME_MODAL

                header = $translate.instant 'TEMPLATES_PAYMENT.TITLE_DIALOG'
                messages = [
                    { type: 'msg', msg: $translate.instant 'INTERNAL_PAYMENT.TEMPLATE_DONE' }
                ]
                if template.getByAlias
                    messages.push { type: 'info', msg: $translate.instant 'INSTANT_PAYMENT.TEMPLATE_FROM_ALIAS' }

                alertModal.show header, messages, 'OK'
                vm.templateName = ''
                vm.loaders.template = false
            .catch (err) ->
                console.log err
                vm.loaders.template = false
                vm.templateName = ''

    return
]
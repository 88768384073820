# график платежей

angular.module('IBR').component 'creditSchedule', {

    templateUrl: '/popup/credit-schedule.html'
    controllerAs: "vm"
    controller: ['$rootScope', '$scope', 'LxDialogService', 'apiCredits', ($rootScope, $scope, LxDialogService, apiCredits) ->

        vm = this

        vm.btnLoaders = {}

        scheduleQuery =
            loanNumber: ''
            cid: ''
            lang: $rootScope.currentLang.lngu
            
        vm.schedule = {
            lang: $rootScope.currentLang
            types: [
                {
                    name: 'LOAN.TYPE_SCHEDULE'
                    value: 'schedule'
                }
                {
                    name: 'LOAN.TYPE_ACTUAL'
                    value: 'actual'
                }
            ]
            data: {}
            fileType: 'xlsx'
        }

        vm.schedule.type = vm.schedule.types[0]

        vm.schedule.download = () ->
            vm.btnLoaders.scheduleDownload = true
            apiCredits.downloadFile scheduleQuery, vm.schedule.type.value, vm.schedule.fileType
                .then (res) ->
                    vm.btnLoaders.scheduleDownload = false
                    window.utils.downloadFile res
                , (err) ->
                    vm.btnLoaders.scheduleDownload = false

        vm.schedule.changeLang = () ->
            scheduleQuery.lang = vm.schedule.lang.lngu

        $scope.$on 'SHOW_CREDIT_SCHEDULE', (event, number, cid) ->
        
            scheduleQuery.loanNumber = number
            scheduleQuery.cid = cid

            vm.schedule.buttonDisabled = true
            $rootScope.shadowLoader = true
            apiCredits.getDetails scheduleQuery
                .then (res) ->
                    $rootScope.shadowLoader = false
                    vm.schedule.buttonDisabled = false
                    vm.schedule.data = res.data
                    LxDialogService.open 'credit-schedule-dialog'
                , (err) ->
                    $rootScope.shadowLoader = false
                    vm.schedule.buttonDisabled = false

        return vm
    ]
}
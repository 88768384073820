'use strict'

angular.module('IBR')
.factory 'alertModal', [ '$rootScope', '$timeout', '$translate', '$sce', 'LxDialogService', ($rootScope, $timeout, $translate, $sce, LxDialogService) ->

  that = {}
  that.errorOpened = false
  that.callbackFunction = null

  $rootScope.alertCallback = () ->
    that.errorOpened = false
    LxDialogService.close 'alert-dialog'
    LxDialogService.close 'limits-dialog'
    if that.callbackFunction
      that.callbackFunction()
      that.callbackFunction = null
    return

  #temporary
  asanErrorMap = {
    "ASAN-AUTH_CERT_IS_NOT_VALID_ERROR":	"ASAN_STATUS_AUTH_CERT_IS_NOT_VALID_ERROR"
    "ASAN-INTERNAL_ERROR":	"ASAN_STATUS_INTERNAL_ERROR"
    "ASAN-INVALID_PARAMS_ERROR": "ASAN_STATUS_INVALID_PARAMS_ERROR"
    "ASAN-NO_AUTH_CERT_ERROR": "ASAN_STATUS_NO_AUTH_CERT_ERROR"
    "ASAN-NO_SIGN_CERT_ERROR": "ASAN_STATUS_NO_SIGN_CERT_ERROR"
    "ASAN-PHONE_NUMBER_AND_PERSONAL_CODE_NOT_RELATED_ERROR":	"ASAN_STATUS_PHONE_NUMBER_AND_PERSONAL_CODE_NOT_RELATED_ERROR"
    "ASAN-SERVICE_OVERLOADED_ERROR":	"ASAN_STATUS_SERVICE_OVERLOADED_ERROR"
    "ASAN-SIGN_CERT_IS_NOT_VALID_ERROR":	"ASAN_STATUS_SIGN_CERT_IS_NOT_VALID_ERROR"
    "ASAN-UNKNOWN_PHONE_NUMBER_ERROR":	"ASAN_STATUS_UNKNOWN_PHONE_NUMBER_ERROR"
    "ASAN-EXPIRED_TRANSACTION":	"ASAN_STATUS_EXPIRED_TRANSACTION"
    "ASAN-NOT_VALID":	"ASAN_STATUS_NOT_VALID"
    "ASAN-PHONE_ABSENT":	"ASAN_STATUS_PHONE_ABSENT"
    "ASAN-SENDING_ERROR":	"ASAN_STATUS_SENDING_ERROR"
    "ASAN-SIM_ERROR":	"ASAN_STATUS_SIM_ERROR"
    "ASAN-USER_CANCEL":	"ASAN_STATUS_USER_CANCEL"
  }

  trStatuses = {
    '0': 'ERRORS.ERROR_OFFLINE_DESCRIPTION'
    '100': 'ERRORS.ERROR_UNREACHABLE_DESCRIPTION'
    '400': 'ERRORS.ERROR_DATA_DESCRIPTION'
    '401': 'ERRORS.ERROR_CREDENTIALS_DESCRIPTION'
    '403': 'ERRORS.ERROR_PARAMETERS_DESCRIPTION'
    '404': 'ERRORS.ERROR_URL_DESCRIPTION'
    '405': 'ERRORS.ERROR_METHOD_DESCRIPTION'
    '408': 'ERRORS.ERROR_TIMEOUT_DESCRIPTION'
    '409': 'ERRORS.ERROR_REQUEST_DESCRIPTION'
    '500': 'ERRORS.ERROR_SERVER_DESCRIPTION'
    '507': 'ERRORS.ERROR_LIMIT_DESCRIPTION'
  }

  that.getError = (res) ->

    method = res.config.method
    url = res.config.url.replace(CONFIG.RESOURCE_SERVER, '')
    status = res.status.toString()
    data = res.data

    # console.log(url, method)

    if trStatuses[status]
      trKey = trStatuses[status]
    else
      trKey = 'ERRORS.COMMON_SERVER_ERR'

    # if exists errorCode
    if data && data.errorCode

      # general translation
      trKeyCode = 'ERRORS.'+ data.errorCode

      #temporary
      if data.errorCode.indexOf('ASAN') > -1
        if asanErrorMap[data.errorCode]
          trKeyCode = 'ERRORS.'+ asanErrorMap[data.errorCode]
        else
          if data.errorCode.indexOf('ASAN-') > -1
            trKeyCode = 'ERRORS.'+ data.errorCode.replace('ASAN-', 'ASAN_ERROR_CODE_');

      # custom translation
      if data.errorCode == 'CORE-0002'

        console.log 'CORE-0002'

        if data.constraints[0]
          if data.constraints[0].code == '0038'
            trKeyCode = 'ERRORS.ERROR_CORP_LIMIT'

          else if data.constraints[0].paramName == 'BD-0039'
            trKeyCode = 'TEMPLATES_PAYMENT.PAYMENT_EXISTS'

          else if data.constraints[0].code == '0040' && data.constraints[0].paramName == 'credit'
            trKeyCode = 'ERRORS.ERROR_INVALID_ACCOUNT'

          else if data.constraints[0].code == '0077' && data.constraints[0].paramName == 'rate'
            trKeyCode = 'ERRORS.EXPIRERR'

      else if data.errorCode == 'CORE-0037'
        trKeyCode = 'ERRORS.ERROR_CORP_LIMIT'

      else if data.errorCode == 'CORE-0008'
        if url.indexOf("/transfers/templates") > -1
          trKeyCode = 'ERRORS.NO_AUTHORITY'
        else if url.indexOf("/transfers/group/") > -1 && url.indexOf('sign') > -1
          trKeyCode = 'ERRORS.NO_AUTHORITY_BATCH'
        else
          trKeyCode = 'ERRORS.ERROR_CORP_LIMIT'

      else if data.errorCode == "CORE-0053" || data.errorCode == 'CORE-0037'
        trKeyCode = 'ERRORS.ERROR_CAST_LIMIT'

      else if data.errorCode == "CORE-0069"
        trKeyCode = 'ERRORS.ERROR_0069'
      
      else if data.errorCode == "WS-0085"
        trKeyCode = 'ERRORS.WS-0085-'+$rootScope.tmpString

      # if translation exists
      translationExists = false
      if $translate.instant(trKeyCode) != trKeyCode
        translationExists = true
        trKey = trKeyCode
      else
        console.log('No translation for errorCode: '+trKeyCode)

      #default error message for send-to-cbs requests
      if (trKey == 'ERRORS.COMMON_SERVER_ERR' || trKey == 'ERRORS.ERROR_SERVER_DESCRIPTION' || !translationExists ) && res.config.url.indexOf('send-to-cbs') > -1
        trKey = 'MESSAGES.CBS_NO_ERUNI_ERROR'
    
    $rootScope.tmpString = ''
    return trKey

    # @TODO PDF/aDoc error (error in PDF', err)

  that.httpError = (res) ->

    apply = () ->
      if $rootScope.customAlert
        $rootScope.customAlert = false
        return
      translationKey = that.getError(res)
      
      type = 'err'
      #костыль по хотелкам банка
      if translationKey == 'ERRORS.ERROR_0069'
        type = 'default'

      that.show(
        $translate.instant('DIALOG.ATTENTION'), 
        { type: type, msg: $translate.instant(translationKey) }, 
        $translate.instant('DIALOG.CLOSE')
      )
    if $rootScope.translationLoaded
      apply()
    else
      $rootScope.$on 'TRANSLATIONS_LOADED', () ->
        apply()

  #obj message(s) with type
  that.show = (title, message, btn, callback) ->
    if callback
      that.callbackFunction = callback
    # open alert
    $rootScope.alert_title = title
    if angular.isArray(message)
      $rootScope.alert_msg = message
    else
      $rootScope.alert_msg = [ message ]

    $rootScope.alert_btn = btn
    LxDialogService.open 'alert-dialog'

  that.showLimits = (title, message, btn, callback) ->
    if callback
      that.callbackFunction = callback
    # open alert
    $rootScope.alert_title = title
    if angular.isArray(message)
      $rootScope.alert_msg = message
    else
      $rootScope.alert_msg = [ message ]

    $rootScope.alert_btn = btn
    LxDialogService.open 'limits-dialog'

  that.showError = (title, message, btn, callback) ->
    console.log 'showError', title, message, btn, callback
    if that.errorOpened
      return
    that.errorOpened = true
    if callback
      that.callbackFunction = callback
    # open alert
    $rootScope.alert_title = title
    $rootScope.alert_msg = [ { type: 'err', msg: message } ]
    $rootScope.alert_btn = btn
    LxDialogService.open 'alert-dialog'

  #only single text message
  that.showMessage = (title, message, btn, callback) ->
    if callback
      that.callbackFunction = callback
    # open alert
    $rootScope.alert_title = title
    $rootScope.alert_msg = [ { type: 'msg', msg: message } ]

    $rootScope.alert_btn = btn
    LxDialogService.open 'alert-dialog'

  that.showInfo = (title, message, btn, callback) ->
    if callback
      that.callbackFunction = callback
    # open alert
    $rootScope.alert_title = title
    $rootScope.alert_msg = [ { type: 'info', msg: message } ]

    $rootScope.alert_btn = btn
    LxDialogService.open 'alert-dialog'

  that.blinkTab = (msg) ->
    if msg
      that.blinkTabInterval = setInterval( () ->
        $rootScope.title = if $rootScope.title == $rootScope.defaultTitle then msg else $rootScope.defaultTitle
      , 1000)
    else
      $rootScope.title = $rootScope.defaultTitle
      clearInterval(that.blinkTabInterval)

  that.confirm = (title, message, callback, btnOk, btnCancel, del) ->
    $rootScope.confirmBtnOk = btnOk || "ALL.YES"
    $rootScope.confirmBtnCancel = btnCancel || "ALL.NO"
    $rootScope.confirmTitle = title
    $rootScope.confirmMessage = $sce.trustAsHtml message
    $rootScope.confirmBtnOkColor = if del then 'red' else 'primary'
    $rootScope.confirmCallback = () ->
      callback()
      LxDialogService.close 'confirm-dialog'
    $rootScope.confirmCancel = () ->
      LxDialogService.close 'confirm-dialog'

    LxDialogService.open 'confirm-dialog'

  that.confirmDelete = (message, callback, okBtn, cancelBtn) ->
    that.confirm $translate.instant('DIALOG.ATTENTION'), message, callback, (okBtn || 'DIALOG.DELETE'), (cancelBtn || 'DIALOG.CANCEL'), true

  that.showLoader = () ->
    $rootScope.shadowLoader = true
    # LxDialogService.open 'modal-loader'

  that.hideLoader = () ->
    $rootScope.shadowLoader = false
    # LxDialogService.close 'modal-loader'

  return that
]

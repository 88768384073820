'use strict'

angular.module('IBR')
.directive 'spacePrevent', [ '$timeout', ($timeout) ->
  {
    restrict:'A'
    require:'ngModel',
    link: (scope, element, attributes)->
      if attributes.spacePrevent != 'select'
        element.on('keypress', (e) ->
          if e.which == 32
            e.preventDefault()
            e.stopPropagation()
        )
      else
        $timeout ->
          el = element[0].querySelector('.lx-select-selected-wrapper')
          el.addEventListener('click', () ->
            $timeout ->
              jInput = $('.search-filter__input input')
              allChars = "["
              canUse = attributes.canUse
              if canUse.indexOf('9') > -1 then allChars+='0-9'
              if canUse.indexOf('e') > -1 then allChars+='A-Za-z'
              allChars+="]"
              jInput.inputmask({
                mask: "*{*}",
                greedy: false
                definitions: {
                  '*': {
                    validator: allChars,
                    casing: "upper"
                  }
                }
                oncleared: ()->
                  self.value = ''
              })
          )
  }
]

'use strict'

angular.module('IBR').factory 'pagerService', ()->

    pagerService = {}

    pagerService.getPager = (totalItems, currentPage, pageSize) ->
        startPage
        endPage
        currentPage = currentPage || 1
        pageSize = pageSize || 10
        totalPages = Math.ceil(totalItems / pageSize)



        if totalPages <= 10
            startPage = 1
            endPage = totalPages
        else
            if currentPage <= 6
                startPage = 1
                endPage = 10
            else if currentPage + 4 >= totalPages
                startPage = totalPages - 9
                endPage = totalPages
            else
                startPage = currentPage - 5
                endPage = currentPage + 4

        pages = Array.apply(null, Array(totalPages)).map((_, i)-> i+1)
        currentPage = if currentPage > pages.length then pages.length else currentPage
        currentPage = if currentPage > pages.length then pages.length else currentPage
        startIndex = (currentPage - 1) * pageSize
        endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)


        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        }

    pagerService

'use strict'

angular.module('IBR')
.factory 'asanModal', [ '$rootScope', '$q', 'LxDialogService', 'apiPayment', 'transactionActions', 'alertModal', ($rootScope, $q, LxDialogService, apiPayment, transactionActions, alertModal) ->

    that = {}

    ASAN_FORM = 'asan-modal-form'
    ASAN_NOTIFY = 'asan-modal-notify'

    $rootScope.modals.asan = {}

    asanVerify = () ->
        if $rootScope.modals.asan.asanPhone && $rootScope.modals.asan.asanUserId
            if (/^[+994]/).test $rootScope.modals.asan.asanPhone
                return $rootScope.modals.asan.asanPhone
            else
                return '+994' + $rootScope.modals.asan.asanPhone
        else
            false

    # подписание транзакции
    sendTransactionAsan = (transaction, action, isAsan) ->
        phone = asanVerify()
        $rootScope.modals.asan.asanError = false
        if !phone && !isAsan
            $rootScope.modals.asan.asanError = true
            return that.errorCallback()
        ref = transaction.ref
        data = {
            requestType: 'SIGN'
            reference: ref
        }

        if !isAsan
            data.phoneNumber = phone
            data.asanId = $rootScope.modals.asan.asanUserId

        $rootScope.modals.asan.loading = true
        apiPayment.sign action, data
            .then (res) ->
                $rootScope.currentUser.isAsanDataExists = true
                LxDialogService.close ASAN_FORM
                LxDialogService.open ASAN_NOTIFY
                $rootScope.modals.asan.asanCode = res.data.verificationCode

                data = {
                    transactionId: res.data.transactionId.toString()
                    requestType: 'START_POLLING'
                    reference: ref
                }

                return apiPayment.sign action, data
            .then (res) ->
                $rootScope.modals.asan.loading = false
                LxDialogService.close ASAN_NOTIFY
                that.successCallback res
            , (err) ->
                $rootScope.modals.asan.loading = false
                LxDialogService.close ASAN_NOTIFY
                if data.requestType == 'START_POLLING'
                    that.errorCallback err

    # for create/change
    paymentAsan = (code, payment, action, isAsan) ->
        # console.log 'paymentAsan', code, payment, action, isAsan
        # action: SIGN_1, SIGN_2
        phone = asanVerify()
        $rootScope.modals.asan.asanError = false
        if !phone && !isAsan
            $rootScope.modals.asan.asanError = true
            return that.errorCallback()

        data = {
            requestType: 'SIGN'
            transferRequest: payment
        }

        if payment.ref
            data.ref = payment.ref
            data.reference = payment.ref

        if !isAsan
            data.phoneNumber = phone
            data.asanId = $rootScope.modals.asan.asanUserId

        $rootScope.modals.asan.loading = true
        apiPayment.create code, action, data
            .then (res) ->
                $rootScope.currentUser.isAsanDataExists = true
                LxDialogService.close ASAN_FORM
                LxDialogService.open ASAN_NOTIFY
                $rootScope.modals.asan.asanCode = res.data.verificationCode

                data = {
                    transactionId: res.data.transactionId.toString()
                    requestType: 'START_POLLING'
                    reference: data.reference
                }

                return apiPayment.create code, action, data
            .then (res) ->
                $rootScope.modals.asan.loading = false
                LxDialogService.close ASAN_NOTIFY
                that.successCallback res
            .catch (err) ->
                $rootScope.modals.asan.loading = false
                LxDialogService.close ASAN_NOTIFY
                that.errorCallback err
                # if data.requestType == 'START_POLLING'
                #     that.errorCallback err

        
        # PAYMENT_TYPE,vm.signType,data,ref

    
    # подписание пачки
    # если группа транзакций sendBatchAsan([...], null, 'SIGN_1')
    # если пачка sendBatchAsan(null, '12345', 'SIGN_1')
    sendGroupAsan = (transactions, batchId, action, isAsan) ->
        console.log transactions, batchId, action, isAsan
        phone = asanVerify()
        $rootScope.modals.asan.asanError = false
        if !phone && !isAsan
            $rootScope.modals.asan.asanError = true
            return that.errorCallback()

        data = {
            # phoneNumber: phone
            # asanId: $rootScope.modals.asan.asanUserId
            requestType: 'SIGN'
            isBatch: false
        }

        if !isAsan
            data.phoneNumber = phone
            data.asanId = $rootScope.modals.asan.asanUserId

        if batchId
            data.isBatch = true
            data.batchId = batchId
        else
            data.transferCursors = transactionActions.trCursors transactions
            
        # вызываем общий для пачек метод
        $rootScope.modals.asan.loading = true
        return apiPayment.sendGroupTransactions action, data
            .then (res) ->
                $rootScope.currentUser.isAsanDataExists = true
                LxDialogService.close ASAN_FORM
                LxDialogService.open ASAN_NOTIFY
                $rootScope.modals.asan.asanCode = res.data.verificationCode
                data = {
                    transactionId: res.data.transactionId.toString()
                    requestType: 'START_POLLING'
                    isBatch: data.isBatch
                }
                return apiPayment.sendGroupTransactions action, data
            .then (res) ->
                $rootScope.modals.asan.loading = false
                LxDialogService.close ASAN_NOTIFY
                that.successCallback res
            .catch (err) ->
                $rootScope.modals.asan.loading = false
                LxDialogService.close ASAN_NOTIFY
                # console.log('errorCallback')
                if data.requestType == 'START_POLLING'
                    that.errorCallback err

    openDialog = () ->
        $rootScope.modals.asan.asanError = ''
        LxDialogService.open ASAN_FORM
        $rootScope.modals.asan.asanPhone = ''
        $rootScope.modals.asan.asanUserId = ''

    $rootScope.modals.asan.close = () ->
        that.errorCallback()
        LxDialogService.close ASAN_FORM
        LxDialogService.close ASAN_NOTIFY

    $rootScope.modals.asan.sendAsan = () ->
        if that.type is 'TRANSACTION'
            sendTransactionAsan that.transaction, that.action
        else if that.type is 'GROUP'
            sendGroupAsan that.transactions, that.batchId, that.action
        else if that.type is 'PAYMENT'
            paymentAsan that.code, that.payment, that.action

    that.signOne = (transaction, action) ->
        deferred = $q.defer()
        that.successCallback = deferred.resolve
        that.errorCallback = deferred.reject
        that.type = 'TRANSACTION'
        that.action = action
        that.transaction = transaction

        if $rootScope.currentUser.isAsanDataExists
            sendTransactionAsan transaction, action, true
        else
            openDialog()

        return deferred.promise

    that.signGroup = (transactions, batchId, action) ->
        # console.log('signGroup',transactions, batchId, action)
        deferred = $q.defer()
        that.successCallback = deferred.resolve
        that.errorCallback = deferred.reject

        that.type = 'GROUP'
        that.action = action
        that.batchId = batchId
        that.transactions = transactions
        
        if $rootScope.currentUser.isAsanDataExists
            sendGroupAsan that.transactions, that.batchId, that.action, true
        else
            openDialog()

        return deferred.promise

    that.signPayment = (code, payment, action) ->
        # console.log 'signPayment', code, payment, action
        deferred = $q.defer()
        that.successCallback = deferred.resolve
        that.errorCallback = deferred.reject

        that.type = 'PAYMENT'
        that.code = code
        that.action = action
        that.payment = payment
        
        # $rootScope.currentUser.isAsanDataExists = true #for test
        if $rootScope.currentUser.isAsanDataExists
            paymentAsan code, payment, action, true
        else
            openDialog()
        return deferred.promise

    that
]

'use strict'

angular.module('IBR')
.filter 'sumformat', ->
    (input, key) ->
        if input
            if angular.isString(input)
                input = input.replace(/[^\d.]/g, '')
            sum = parseFloat input
        else if !key
            sum = 0
        if angular.isNumber(sum)
            return sum.toFixed(2).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        else return

'use strict'

angular.module('IBR')
.directive('timer', ->
    {
        restrict: 'E'
        scope: {
            time: '@'
            callback: '&'
            progress: '@?'
            size: '@?'
        }
        template: '<div ng-if="progress" class="progress arc" ng-class="size">
            <svg width="120" height="120" viewBox="0 0 120 120">
                <circle class="full" cx="60" cy="60" r="57" fill="none" stroke="#e6e6e6" stroke-width="6" />
                <circle class="current" cx="60" cy="60" r="57" fill="none" stroke="#2147b2" stroke-width="6" stroke-dasharray="{{dasharray}}" stroke-dashoffset="{{dashoffset}}" />
            </svg>
            <div class="timer"><span>{{mm}}</span><span>:</span><span>{{ss}}</span></div>
        </div>
        <div ng-if="!progress" class="timer">
            <span>{{mm}}</span><span>:</span><span>{{ss}}</span>
        </div>'
        controller: [ '$scope', '$element', '$attrs', '$translate', '$interval', ($scope, $element, $attrs, $translate, $interval) ->

            $scope.mm = '00'
            $scope.ss = '00'
            interval = null

            $scope.dasharray = 57*2*Math.PI
            $scope.dashoffset = 0

            init = ->
                time = parseInt($scope.time || 300)
                left = time
                $scope.dasharray
                interval = $interval(->
                    left--
                    $scope.dashoffset = (1-left/$scope.time)*$scope.dasharray

                    mm = Math.floor left/60
                    ss = left - mm*60

                    $scope.mm = if mm < 10 then '0' + mm else mm
                    $scope.ss = if ss < 10 then '0' + ss else ss

                    if left == 0
                        $interval.cancel interval
                        if $scope.callback
                            $scope.callback()
                , 1000 )

            init()

            $scope.$on 'TIMER_RESET', () ->
                $interval.cancel interval
                init()

            return
        ]
    }
)

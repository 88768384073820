'use strict'

angular.module('IBR')
.directive 'scrollTopButton', [ () ->
    {
        restrict: 'E'
        template: '<div ng-click="scrollTop()" class="scroll-top"><i class="mdi mdi-chevron-up"></i></div>'
        controller: [ '$scope', '$element', '$attrs', ($scope, $element, $attrs) ->
            onScroll = () ->
                $element[0].style.display = if document.documentElement.scrollTop > 200 then 'block' else 'none'

            window.addEventListener('scroll', onScroll)
            onScroll()

            $scope.scrollTop = () ->
                window.utils.scrollTo(document.documentElement, false, 1000)
        ]
    }
]

'use strict'

angular.module('IBR').component 'archiveActions', {

    templateUrl: '/pages/archive/actions.html'
    bindings:
        loaders: '<'
        selProps: '<' #набор критериев характеризующих коллекцию выбранных транзакций (набор собираем в сервисе transactionActions)
        filter: '<' #форма с фильтрами
        actionSet: '<' #доступные действия для набора
        transactionDialog: '='
        transactionsDialog: '='
        actionDownloadSelected: '='
    controllerAs: "vm"
    controller: ['$rootScope', '$scope', 'transactionActions', ($rootScope, $scope, transactionActions) ->

        vm = this

        vm.showDownload = false
        vm.enableDownload = false
        #набор кнопок действий
        vm.buttons = angular.copy(transactionActions.buttons)
        
        
        vm.currentLang = angular.copy $rootScope.currentLang

        # если хотя бы у одной транзакции доступно подписание, показываем кнопки в футере


        # проверяем доступность кнопок
        checkButtons = () ->

            visible = []
            switch vm.filter.status
                when 'ALL_ARCHIVE'
                    visible = ['SAVE_AS_TEMPLATE']
                when 'ACCEPTED', 'EXPIRED', 'DELETED'
                    visible = ['SAVE_AS_TEMPLATE', 'COPY']

            # console.log('selProps', vm.selProps)
            # console.log('visible', visible)
            # console.log('vm.buttons', vm.buttons)

            vm.showDownloadPdf = vm.filter.status != 'ALL_ARCHIVE'
            vm.showDownloadAdoc = vm.filter.status == 'ACCEPTED'
            vm.enableDownload = vm.selProps.singleBatch || vm.selProps.singleTransaction || vm.selProps.allIsNotSalary
            vm.enableDownloadAdoc = vm.selProps.singleTransaction

            for key of vm.buttons
                button = vm.buttons[key]
                button.show = visible.indexOf(button.key) > -1

                #если сохранить темплейт
                if button.key == "COPY"
                    button.disabled = !vm.selProps.singleTransaction

                if button.key == "SAVE_AS_TEMPLATE"
                    button.disabled = !(vm.selProps.singleTransaction && vm.selProps.isTemplateAllowed) || vm.selProps.allIsExchange


        # перерисовуем кнопки при изменении фильтров
        $scope.$on 'CHANGE_FILTER', () ->
            checkButtons()

        # перерисовуем кнопки при изменении входных параметров
        vm.$onChanges = (e) ->
            if e.filter && e.filter.status || e.selProps
                checkButtons()

        vm.dialogAction = (btnKey) ->
            if vm.selProps.singleTransaction
                vm.transactionDialog null, btnKey
            else if vm.selProps.fewTransactions
                vm.transactionsDialog btnKey

        return vm
    ]
}
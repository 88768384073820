angular.module('IBR')
.controller 'PaymentExchangeCtrl', [ '$rootScope', '$q', '$timeout', '$translate', '$scope', '$stateParams', 'apiPayment', 'transactionActions', 'alertModal', 'asanModal', 'transactionResultModal', 'LxDialogService', ( $rootScope, $q, $timeout, $translate, $scope, $stateParams, apiPayment, transactionActions, alertModal, asanModal, transactionResultModal, LxDialogService ) ->

    # this
    vm = this

    vm.loaded = false
    vm.loaders = {}
    vm.payment = {}
    vm.cids = []
    vm.isLocked = false #additional
    vm.validFields = {}
    vm.blackOut = false

    #valid payment object for API
    validatedPayment = {}

    #constants
    CODE = 'FX'
    DIALOG_MODAL = 'modal-payment-exchange'

    apiPayment.getBlackout CODE
        .then (res) ->
            if res.data.result
                alertModal.showError $translate.instant('DIALOG.TRANSFER'), $translate.instant('EXCHANGE_PAYMENT.BLACKOUT'), 'OK', (answer) ->
                    vm.blackOut = true

    # payment for initialized
    progress = false
    $scope.$on 'FORM_READY', (event) ->
        if $stateParams.payment && !progress
            $scope.$broadcast 'setPayment', $stateParams.payment #fill form if it is change action
            progress = true


    processPayment = (payment) ->
        p = angular.copy(payment, {})
        p.initiatorCid = payment.customer.cid
        if typeof(p.debit) == 'object'
            p.debitCurrency = p.debit.currency
            p.debit = p.debit.iban
        if typeof(p.credit) == 'object'
            p.creditCurrency = p.credit.currency
            p.credit = p.credit.iban
        if typeof(p.customer) == 'object'
            p.uuid = p.customer.cid
            delete p.customer
        p.debitAmount = Number p.amount.replace(/\s/g,'')
        p.creditAmount = Number p.purchaseAmount.replace(/\s/g,'')
        if p.debitAmount == 0
            delete p.debitAmount
        if p.creditAmount == 0
            delete p.creditAmount
        delete p.amount
        delete p.commisAmount
        delete p.commisCurrency
        delete p.currency
        delete p.selfAccount
        delete p.uuid
        delete p.purchaseAmount
        if p.notes == ''
            delete p.notes
        return p

    paymentCheck = () ->
        #fake
        validatedPayment = payment = processPayment vm.payment
        return apiPayment.validate CODE, payment
            .then (res) ->
                return transactionActions.getPaymentButtons CODE, vm.payment.customer.cid, vm.payment.customer.authSchema, vm.payment.debit.iban
            .then (buttons) ->
                vm.loaders.validation = false
                vm.buttons = buttons
                paymentDialog.open()
            .catch (err) ->
                vm.loaders.validation = false


    paymentDialog = {}

    paymentDialog.open = () ->
        vm.loaders = {}
        LxDialogService.open DIALOG_MODAL
        return

    paymentDialog.close = () ->
        vm.loaders = {}
        LxDialogService.close DIALOG_MODAL

    vm.closePaymentDialog = () ->
        LxDialogService.close DIALOG_MODAL

    vm.resetPayment = (needClearRef) ->
        $scope.$broadcast 'PAYMENT_RESET', needClearRef
    
    vm.presendPayment = () ->
        vm.loaders.validation = true
        $scope.$broadcast 'PAYMENT_LOCAL_VALIDATE', false


    $scope.$on 'PAYMENT_LOCAL_VALIDATED', (event, isValid) ->
        if isValid
            if vm.loaders.validation
                paymentCheck()
                    # .then () ->
                    #     paymentDialog.open()
                    # .catch () ->
                    #     vm.loaders = {}
        else
            vm.loaders.validation = false

    vm.sendPayment = (btnKey) ->
        console.log 'sendPayment', btnKey
        authSchema = vm.payment.customer.authSchema
        switch btnKey
            when 'SEND_TO_SIGN', 'SEND_TO_SIGN__APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create CODE, false, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create CODE, btnKey, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        if res.data.responseType is 'COMPLETE'
                            return transactionResultModal.successPaymentModal authSchema, btnKey
                        else
                            $q.reject()
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1', 'SIGN_2'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment CODE, validatedPayment, btnKey
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}

            when 'SIGN_1__SEND_TO_CBS'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment CODE, validatedPayment, 'SIGN_1'
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        validatedPayment.ref = res.data.refSet[0]
                        alertModal.showLoader()
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs validatedPayment.ref #todo where ref from?
                    .then (res) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        return transactionResultModal.sendToBank btnKey, validatedPayment, null, res.data, res.status
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                paymentDialog.close()
                                vm.resetPayment true


    return
]
'use strict'

angular.module('IBR')
.directive 'clickListener', [ '$window', '$rootScope', ($window,$rootScope) ->
    {
        restrict: 'A'
        link: (scope, element) ->
            onClick=(e)->
                $rootScope.$broadcast('clickListener::click')
                return
            cleanUp=()->
                angular.element($window).off('click',onClick)
                return
            angular.element($window).on('click',onClick)
            scope.$on('$destroy',cleanUp);
            return
    }
]

'use strict'

angular.module('IBR')
.factory 'apiTranslation', [ '$http', '$rootScope', '$translateProvider', '$timeout', '$q', ($http, $rootScope, $translateProvider, $timeout, $q) ->

    that = {}
    
    translateUrl = CONFIG.TRANSLATION_SERVER + '/localizations'
    if CONFIG.LOCAL
        translateUrl = 'localizations'

    that.load = (key) ->
        url = translateUrl + '?lang=' + key
        return $http.get url
            .then (res) ->
                $translateProvider.translations key, res.data
                window.translations[key] = res.data
                return res.data
            
    that.init = (currentLang) ->
        window.translations = {}
        otherKeys = []
        for l in $rootScope.languages
            if currentLang != l.key
               otherKeys.push l.key

        #at fitst load current language
        return $timeout( ->
            return that.load currentLang
                .then () ->
                    #after load others
                    for lngkey in otherKeys
                        that.load lngkey
        , 1500)

    that
]

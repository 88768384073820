'use strict'

angular.module('IBR')
.factory 'currencyFactory', [ '$http', ($http) ->
    urlBase = CONFIG.RESOURCE_SERVER + "/currency-rates"
    currencyFactory = {}

    currencyFactory.getRates = ->
        $http.get urlBase

    currencyFactory
]

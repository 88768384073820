'use strict'

angular.module('IBR')
.filter 'currentCustomers', ['$rootScope',($rootScope) ->
    (input) ->
        if input && input.length
            return input
        else if $rootScope.loaded
            if $rootScope.selectedCorporateCustomers && $rootScope.selectedCorporateCustomers.length
                return $rootScope.selectedCorporateCustomers
            else
                return $rootScope.currentUser.corporateCustomers
        return []
    ]

.filter 'selectedCustomers', ['$rootScope',($rootScope) ->
    (input) ->
        filteredByCustomersSelect = {}
        if $rootScope.loaded && $rootScope.selectedCorporateCustomers && $rootScope.selectedCorporateCustomers.length
            angular.forEach $rootScope.selectedCorporateCustomers, (customer) ->
                if input[customer.cid]
                    filteredByCustomersSelect[customer.cid] = input[customer.cid]
                return
            return filteredByCustomersSelect
        else
           return input
        return
    ]

.filter 'filterSelectedCustomers', ['$rootScope',($rootScope) ->
    (input) ->
        if $rootScope.loaded && $rootScope.selectedCorporateCustomers && $rootScope.selectedCorporateCustomers.length
            return input.filter( (customer) ->
                find = false
                for selectedCustomer in $rootScope.selectedCorporateCustomers
                    if customer.cid == selectedCustomer.cid
                        find = true
                        break
                return find
            )
        else
            return input
    ]

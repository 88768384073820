'use strict'

angular.module('IBR')
.controller 'StatementCtrl', [ '$scope', '$q', '$timeout', '$rootScope', '$window', '$filter', '$translate', '$state', '$stateParams', 'pagerService', 'apiPayment', 'apiAccounts', 'alertModal', 'LxNotificationService', ($scope, $q, $timeout, $rootScope,$window, $filter, $translate, $state, $stateParams, pagerService, apiPayment, apiAccounts, alertModal, LxNotificationService) ->

    PAGE_SIZE = 50
    TIMEOUTSECONDS = 20000
    MIN_DATE = '2010-01-05T00:00'


    iban = $stateParams.iban
    customerId = $stateParams.customer
    isSalaryIban = $stateParams.isSalaryIban

    vm = this
    $scope.vm = vm
    vm.loaded = false
    vm.transLoaded = true
    vm.searchLoaded = false
    vm.fTimeOutTrans = false
    vm.fSubmmitClick = false
    vm.accounts = {}
    vm.customers = []
    vm.transactions = []
    vm.filteredTransactions = []
    vm.transactionsTimeout = []
    vm.loaders = {}
    vm.pager = {}

    vm.foreignDownload = false

    vm.seachQuery = {}

    vm.amountP = PAGE_SIZE

    vm.creditSumm = 0
    vm.debitSumm = 0
    vm.preDateChanged = {}
    vm.startDate = 'start-date'
    vm.endDate = 'end-date'
    vm.inCurrentDay = false

    vm.dateValid = false

    $scope.$watchCollection '[transactionFilter.narrative, transactionFilter.ref, transactionFilter.beneficiaryAccount.iban,  vm.transactions]', (oldFilters, newFilters) ->
        transactions = $filter('filter')(vm.transactions, $scope.transactionFilter)
        vm.filteredTransactions = transactions
        vm.sort(vm.sortBy, true)

    vm.setAmountP = (amountP)->
        if vm.amountP == amountP
            return
        vm.amountP = amountP
        vm.setPage(1)

    vm.sort = (type, justSort) ->
        if !justSort
            if vm.sortBy == type
                vm.sortUp = !vm.sortUp
            else
                vm.sortBy = type
                vm.sortUp = false
        switch type
            when 'credit'
                vm.filteredTransactions.sort( (t1,t2) ->
                    credit1 = if t1.type == 'CREDIT' then t1.amount else 0
                    credit2 = if t2.type == 'CREDIT' then t2.amount else 0
                    return if credit1 > credit2  then 1 else -1
                )
            when 'debit'
                vm.filteredTransactions.sort( (t1,t2) ->
                    debit1 = if t1.type == 'DEBIT' then t1.amount else 0
                    debit2 = if t2.type == 'DEBIT' then t2.amount else 0
                    return if debit1 > debit2 then 1 else -1
                )
            when 'balance'
                vm.filteredTransactions.sort( (t1,t2) ->
                    return if t1.balance > t2.balance  then 1 else -1
                )
            when 'date'
                vm.filteredTransactions.sort( (t1,t2) ->
                    return if new Date(t1.transactionDate) > new Date(t2.transactionDate)  then 1 else -1
                )

        if vm.sortUp
            vm.filteredTransactions.reverse()
        vm.setPage(vm.pager.currentPage)

#---Message for TimeOut
    vm.completeMsg =
        sendTitle: $filter('translate')('MESSAGES.TITLEINFO')
        sendBody: $filter('translate')('MESSAGES.BODY_INFO')
        sendOk: $filter('translate')('MESSAGES.OK_INFO')

    vm.setPage=(page) ->
        if !vm.filteredTransactions.length
            vm.items = []
            vm.pager.pages = 0
            return
        vm.pager = pagerService.getPager vm.filteredTransactions.length, page, vm.amountP
        if page < 1
            return
        vm.items = vm.filteredTransactions.slice vm.pager.startIndex, vm.pager.endIndex + 1
        return

    toDateTime = (dateStr, end) ->
        offset = dateStr.getTimezoneOffset() * 60 * 1000
        return if end then new Date(new Date(dateStr).setHours(23,59,59,99) - offset).toISOString() else new Date(new Date(dateStr).setHours(0,0,0,0) - offset).toISOString()

    $rootScope.$on '$translateChangeSuccess', () ->
        vm.seachQuery.lang = $rootScope.currentLang
        vm.completeMsg =
            sendTitle: $translate.instant('MESSAGES.TITLE_INFO')
            sendBody: $translate.instant('MESSAGES.BODY_INFO')
            sendOk: $translate.instant('MESSAGES.OK_INFO')
        return

    vm.searchQuery =
        cid: null,
        lang: $rootScope.currentLang
        debit: iban
        credit: iban
        status: 3

##------$timeout for Message by calling q - response
    vm.checkStatementResponse = () ->
        deferred = $q.defer()
        vm.fTimeOutTrans = false
        $timeout.cancel(requestTimeout)
        requestTimeout = $timeout (->
            trSet = vm.transactionsTimeout.length
            if trSet == 0 && !vm.fTimeOutTrans
                vm.transLoaded = true
                vm.fTimeOutTrans = true
                vm.fSubmmitClick = false
        #---Message for TimeOut
                alertModal.showMessage vm.completeMsg.sendTitle, vm.completeMsg.sendBody, vm.completeMsg.sendOk, (answer) ->
                    deferred.resolve()
                    return
            else
                vm.fTimeOutTrans = false
                vm.fSubmmitClick = false
                vm.transLoaded = true
                deferred.resolve()
        ),  TIMEOUTSECONDS
        return deferred.promise

    vm.searchPayments = ->

        todayDate = new Date()
        endDate = new Date(vm.preDateChanged.stopDate)
        vm.inCurrentDay = todayDate.setHours(0,0,0,0) == endDate.setHours(0,0,0,0)

        vm.transactionsTimeout = []
        takeResp = ''
        vm.searchQuery.startDate = toDateTime vm.preDateChanged.startDate
        vm.searchQuery.stopDate = toDateTime vm.preDateChanged.stopDate, true
        vm.searchQuery.iban = vm.account.iban
        vm.searchQuery.cid = vm.customer.cid
        vm.transLoaded = false
        vm.fSubmmitClick = true
        vm.foreignDownload = false
        apiPayment.searchAccountStatements(vm.searchQuery).then (response) ->
            vm.isChanged = false
            vm.transactionsTimeout = response.data
#            if !vm.fTimeOutTrans
            $scope.transactionFilter = {
                narrative: '',
                ref: '',
                beneficiaryAccount: {iban: ''}
            }
            vm.sortBy = null
            vm.sortUp = false
            vm.transactions = response.data && response.data.transactions || []
            vm.record = response.data
            vm.loaded = true
            vm.searchLoaded = true
            vm.transLoaded = true
            vm.fTimeOutTrans = false
            vm.fSubmmitClick = false
            return
        .catch (error) ->
            vm.loaded = true
            vm.transLoaded = true
            if error.status is 504
                vm.fTimeOutTrans = false
            else
                vm.fTimeOutTrans = true
            vm.fSubmmitClick = false
            return
#        vm.checkStatementResponse().then (qResp) ->
#            takeResp = qResp
#            return
        return

    $rootScope.$on 'SELECT_CUSTOMERS', ()->
        if $rootScope.selectedCorporateCustomers && $rootScope.selectedCorporateCustomers.length
            vm.customers = angular.copy $rootScope.selectedCorporateCustomers
        else
            vm.customers = tmpAccounts
        if vm.customers.length == 1
            vm.customer = vm.customers[0]

    tmpAccounts = []
    processAccounts = (accounts) ->
        statementAccounts = {}
        accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
        angular.forEach accounts, (account) ->
            cid = account.corporateCustomer.cid
            if account.iban
                if !statementAccounts[cid]
                    vm.customers.push account.corporateCustomer
                    statementAccounts[cid] = {}
                    statementAccounts[cid].accounts = []
                    statementAccounts[cid].corporateCustomer = account.corporateCustomer
                statementAccounts[cid].accounts.push account

        tmpAccounts = angular.copy vm.customers, []

        statementAccounts
        

    processAccount = (accounts, customers) ->
        statementAccount = {}
        if iban != '' && customerId != ''
            for customer in customers
                if(customer.cid == customerId)
                    statementAccount.customer = customer
                    break
            for account in accounts[customerId].accounts
                if account.iban == iban
                    statementAccount.account = account
                    break
            if !statementAccount.account
                statementAccount.account =
                    iban: iban
                if isSalaryIban
                    statementAccount.account.customName = 'SALARY.SALARY_REPORT'
            vm.customer = statementAccount.customer
            vm.account = statementAccount.account
        #else
        if $rootScope.selectedCorporateCustomers && $rootScope.selectedCorporateCustomers.length
            vm.customers = angular.copy $rootScope.selectedCorporateCustomers

        if vm.customers.length is 1
            vm.customer = vm.customers[0]
        return

    orderAccount = (obj) ->
        arrOpen = []
        arrClosed = []
        arrSorted = []
        keys = obj
        i = 0
        while i < keys.length
            if keys[i].status == 'OPEN'
                arrOpen.push(keys[i])
            if keys[i].status == 'CLOSED'
                arrClosed.push(keys[i])
            i++
        arrSorted = arrOpen.concat(arrClosed)
        arrSorted

    initStatement = ->
        apiAccounts.getAccounts().then (response) ->
            vm.accounts = processAccounts orderAccount response.data
            processAccount(vm.accounts, vm.customers)
            vm.loaded = true
            return
        ,(error) ->
            vm.loaded = true
            return
        return

    initStatement()


    # binding date inputs
    vm.dateRange = new DateRange({
        yearLimit: true
    })

    vm.saveDocument = () ->
        if !vm.record
            return

        # vm.searchQuery.fileType = 
        vm.loaders.download = true
        apiPayment.printStatement(vm.searchQuery, vm.foreignDownload)
            .then (response) ->
                vm.loaders.download = false
                window.utils.downloadFile response
                $timeout ->
                    vm.fSubmmitClick = false
                    return
                , 1000
            , (error) ->
                vm.loaders.download = false
                return
            return

    $scope.$watch 'statement.customer', ( (newval, oldval) ->
        if oldval && vm.customer && vm.customer.cid
            vm.account = ''
        delete vm.preDateChanged.startDate
        delete vm.preDateChanged.stopDate
        return
    ), true

    $scope.$watch 'statement.account', ( () ->
        delete vm.preDateChanged.startDate
        delete vm.preDateChanged.stopDate
        return
    ), true

    $scope.$watchGroup ['statement.account', 'statement.customer', 'statement.preDateChanged.stopDate', 'statement.preDateChanged.startDate'], ( ->
        if vm.record
            vm.isChanged = true
            delete vm.record
            vm.transactions = []
        return
    ), true

    return
]

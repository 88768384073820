'use strict'

angular.module('IBR')
.factory 'authInterceptor', [ '$rootScope', ($rootScope) ->
  inject =
    request: (config) ->
      token = localStorage.getItem('token')
      if token
        config.headers['Authorization'] = token
        config.headers['X-USER-LANG'] = $rootScope.currentLang.lngu
      else
        delete config.headers['Authorization']
      return config
  inject
]

.factory 'errorInterceptor', [ '$q', '$injector', '$rootScope', ($q, $injector, $rootScope) ->
  inject =
    responseError: (res) ->
      #костыль CBR-1989
      matchUrls = [
        'create-approve'
        'create-sign1'
        'create-sign2'
        'azips'
        'budget'
        'budget_vat'
        'xohks'
        'exchange'
        'create'
        'salary'
        'account-to-account'
        'international-rub'
        'american'
        'english'
        'other'
        'swift'
        'pi'
        'pt'
        'pv'
      ]
      
      urlPath = res.config.url.split('/')
      if res.data.errorCode == 'WS-0072' and urlPath.indexOf('transfers') > -1 and urlPath.indexOf('group') == -1
        lastPath = urlPath[urlPath.length - 1] || urlPath[urlPath.length - 2]
        if matchUrls.indexOf(lastPath) > -1
          return $q.resolve('ERRORS.TIMEOUT_PAYMENT_SUCCESS')

      $rootScope.$broadcast('HTTP_ERROR', res)
      return $q.reject(res)
  inject
]

'use strict'

angular.module('IBR')
.factory 'gaFactory', [ '$q','$http','$rootScope', '$timeout', ($q,$http,$rootScope,$timeout) ->

    urlBase = CONFIG.RESOURCE_SERVER+"/google/auth"

    that = {}

    that.activate = (data) ->
        $http.post(urlBase + '/registration', data)
    
    that.deactivate = (data) ->
       $http.post(urlBase + '/deregistration', data)

    that
]

'use strict'

angular.module('IBR')
.controller 'WidgetCurrencyCtrl', [ '$rootScope', '$scope', '$state', 'currencyFactory', 'authoritiesFactory', 'alertModal', ($rootScope, $scope, $state, currencyFactory, authoritiesFactory, alertModal) ->

    vm = this
    vm.opened = false
    vm.rates = []

    baseLimit = 4
    vm.displayLimit = baseLimit
    vm.loaded = false

    vm.openExchange = () ->
        $state.go('exchange-payment')
        return

    initRates = () ->
        currencyFactory.getRates().then (response) ->
            vm.rates = response.data
            vm.loaded = true
            return
        ,(error) ->
            vm.loaded = true
            vm.error = true
            return
        return

    vm.init = () ->
        if !vm.loaded
            authoritiesFactory.checkAuthority 'CURRENCY_RATES_WIDGET', 'READ'
                .then (res) ->
                    if res then initRates()



    vm.toggleLimit = () ->
        length = vm.rates.length
        if vm.displayLimit == length
            vm.displayLimit = baseLimit
        else
            vm.displayLimit = length
        return

    #TODO  temporary notification message
    vm.alertBox = () ->
        alertModal.showMessage 'Message','Dear Customer! This section is under development and will soon be available.', 'OK', ()->
        return
    return
]

'use strict'

angular.module('IBR')
.controller 'ProfileCtrl', [ '$rootScope','$scope','$translate','$timeout','$filter','apiProfile', 'gaFactory', 'LxDialogService','alertModal', ($rootScope,$scope,$translate,$timeout,$filter,apiProfile,gaFactory,LxDialogService,alertModal) ->

    MAX_LENGHT = 20

    vm = this
    vm.loaded = false
    vm.currentProfile ={}
    vm.dialogId = 'changePwd'

    vm.maxLenght = MAX_LENGHT

    vm.currPwd = ''
    vm.newPwd = ''
    vm.confirmPwd = ''

    vm.currPwdEmpty = false
    vm.newPwdEmpty = false
    vm.confirmPwdEmpty = false
    vm.pwdNotMatch = false
    vm.pwdToShort = false
    vm.pwdLiteral = false
    vm.pwdCapLiteral = false
    vm.pwdOnlyNumber = false
    vm.pwdOnlyLatin = false
    vm.wrongCurrPwd = false
    vm.samePwd = false

    vm.lockGAButton = false

    vm.lang = []

    vm.selectedLang = ""

    processProfile = (data) ->
        profile = angular.copy data, {}
        delete profile.contactInfo
        delete profile.corporateCustomers
        profile.phone = data.contactInfo.phoneNumber
        profile.email = data.contactInfo.email

        profile

    init = () ->
        apiProfile.getProfile().then ( response ) ->
            vm.loaded = true
            vm.currentProfile = processProfile response.data
            vm.language = $rootScope.selectLang { lng: vm.currentProfile.lang }
        return
    init()

    vm.openDialog = () ->
        LxDialogService.open vm.dialogId
        return

    resetErrors = () ->
        vm.currPwdEmpty = false
        vm.newPwdEmpty = false
        vm.confirmPwdEmpty = false
        vm.pwdNotMatch = false
        vm.pwdToShort = false
        vm.pwdLiteral = false
        vm.pwdCapLiteral = false
        vm.pwdOnlyNumber = false
        vm.pwdOnlyLatin = false
        vm.wrongCurrPwd = false
        vm.samePwd = false
        return

    clearFields = ->
        vm.currPwd = ''
        vm.newPwd = ''
        vm.confirmPwd = ''
        resetErrors()
        return

    passwordValidation = () ->

        literalRegex = /[a-z]/
        literalCapitalRegex = /[A-Z]/
        numbericRegex = /\d/
        latinRegex = /[а-яА-ЯəöüıçşğÇÖÜĞŞƏ\s]/

        resetErrors()

#        emptiness check
        if vm.currPwd is '' then vm.currPwdEmpty = true
        if vm.newPwd is '' then vm.newPwdEmpty = true
        if vm.confirmPwd is '' then vm.confirmPwdEmpty = true
#        length check
        if vm.newPwd.length < 8 then vm.pwdToShort = true
#        literal check
        if !literalRegex.test(vm.newPwd) then vm.pwdLiteral = true
        if !literalCapitalRegex.test(vm.newPwd) then vm.pwdCapLiteral = true
        if latinRegex.test(vm.newPwd) then vm.pwdOnlyLatin = true
#        numberic check
        if !numbericRegex.test(vm.newPwd) then vm.pwdOnlyNumber = true
#        match check
        if vm.newPwd != vm.confirmPwd then vm.pwdNotMatch = true
        if vm.currPwd is vm.newPwd then vm.samePwd = true

        result = !(vm.currPwdEmpty ||
            vm.newPwdEmpty ||
            vm.confirmPwdEmpty ||
            vm.pwdToShort ||
            vm.pwdLiteral ||
            vm.pwdCapLiteral ||
            vm.pwdOnlyLatin ||
            vm.pwdOnlyNumber ||
            vm.pwdNotMatch ||
            vm.samePwd)

    vm.updatePwd = () ->
        if passwordValidation()
            data =
                oldPassword: vm.currPwd
                newPassword: vm.newPwd
            apiProfile.changePwd(data).then (response)->
                if response.status = 200
                    alertModal.showMessage $translate.instant('PROFILE.DIALOG_TITLE'), $translate.instant('PROFILE.DIALOG_SUCCES'), 'Ok',(answer)->
                        return
                clearFields()
                LxDialogService.close vm.dialogId
                return
            .catch (err) ->
                if err.status is 500 and err.data.errorCode is "AUTH-0028"
                    vm.wrongCurrPwd = true
        return

    vm.changeUserLanguage = () ->
        apiProfile.changeLang { lang: vm.language.lng }
        return

    $scope.$on 'lx-dialog__close-end', (_event, _dialogId) ->
        if (vm.dialogId == _dialogId)
            clearFields()
        return

    vm.activateGa = () ->
        $scope.$broadcast 'GA_DIALOG_ON'

    vm.deactivateGa = () ->
        vm.lockGAButton = true
        $scope.$broadcast 'GA_DIALOG_OFF'

    $scope.$on 'GA_UPDATED', () ->
        init()

    $scope.$on 'GA_MODAL', () ->
        vm.lockGAButton = false

    



    vm.showPassword = {}
    $scope.$on 'MOUSEUP', () ->
        vm.showPassword = {}

    mouseDownListener = (e) ->
        if e.target.id == 'btnEye1' || e.target.id == 'btnEye2' || e.target.id == 'btnEye3'
            $timeout( ->
                vm.showPassword[e.target.id] = true
            )
        

    mouseUpListener = (e) ->
        $timeout( ->
            vm.showPassword = {}
        )

    document.addEventListener 'mousedown', mouseDownListener
    document.addEventListener 'touchstart', mouseDownListener
    document.addEventListener 'mouseup', mouseUpListener
    document.addEventListener 'touchend', mouseUpListener

    $scope.$on '$destroy', () ->
        # $timeout.cancel ticking
        document.removeEventListener 'mousedown', mouseDownListener
        document.removeEventListener 'touchstart', mouseDownListener
        document.removeEventListener 'mouseup', mouseUpListener
        document.removeEventListener 'touchend', mouseUpListener

    return


    return
]

'use strict'

angular.module('IBR')
.controller 'WidgetDepositCtrl', [ '$rootScope', '$scope', '$state', '$filter', 'depositsFactory', 'authoritiesFactory', 'alertModal', ($rootScope, $scope, $state, $filter, depositsFactory, authoritiesFactory, alertModal) ->

    vm = this

    vm.deposits = {}
    vm.loaded = false
    vm.error = false
    hideStatuses = ['CLOSED']
    vm.isOpened = false

    vm.customerCaption = true

    vm.headerClass = 'relative'

    vm.openDeposit = (iban) ->
        $state.go('deposits', {iban: iban})
        return

    processDeposits = (deposits) ->
        customerDeposits = {}
        deposits = $filter('orderBy')(deposits, 'corporateCustomer.name')
        angular.forEach deposits, (deposit) ->
            deposit.isInput = false
            if !(deposit.status in hideStatuses)
                cid = deposit.corporateCustomer.cid
                if !customerDeposits[cid]
                    customerDeposits[cid] = {}
                    customerDeposits[cid].deposits = []
                    customerDeposits[cid].corporateCustomer = deposit.corporateCustomer
                    delete deposit.corporateCustomer
                customerDeposits[cid].deposits.push deposit
                return
        customerDeposits

    initDeposits = () ->

        if $rootScope.currentUser.corporateCustomers.length <= 1
            vm.customerCaption = false

        depositsFactory.getDeposits().then (response) ->
            vm.deposits = processDeposits response.data
            vm.loaded = true
            return
        ,(error) ->
            vm.loaded = true
            vm.error = true
            return
        return

    vm.updateDepositName = (deposit, name, iban) ->
        if (name != undefined) && (name || deposit.nickname) && (name != deposit.nickname)
            depositsFactory.setNickname(iban, name).then (response) ->
                deposit.nickname = name
                alertModal.showMessage $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('MESSAGES.CHANGE_ACCEPTED'), $filter('translate')('MESSAGES.OK_INFO'), (answer) ->
                    return
                return
        return

    vm.updateDepositNameEnter = (deposit, keyEvent, name, iban) ->
        if keyEvent.which == 13
            vm.updateDepositName(deposit, name, iban)
        return

    vm.loadDeposits = () ->
        vm.isOpened = !vm.isOpened
        if vm.isOpened
            authoritiesFactory.checkAuthority 'DEPOSITS_WIDGET', 'READ'
                .then (res)->
                    if res then initDeposits()
        return

    vm.isInput = (deposit) ->
        deposit.isInput = !deposit.isInput
        return

    return
]

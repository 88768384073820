'use strict'

#AZ78BRES00380394400262924501

getSelectedKeys = (obj) ->
    keys = []
    for prop of obj
        if obj[prop]
            keys.push prop
    return keys

angular.module('IBR')
.controller 'SalaryPaymentCtrl', [ 'appUtils', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$filter', '$translate', '$q', 'pagerService', 'authoritiesFactory', 'fileParser', 'fileValidation', 'apiPayment', 'apiAccounts', 'transactionActions', 'alertModal', 'asanModal', 'transactionResultModal', 'LxDialogService', ( appUtils, $rootScope, $scope, $state, $stateParams, $timeout, $filter, $translate, $q, pagerService, authoritiesFactory, fileParser, fileValidation, apiPayment, apiAccounts, transactionActions, alertModal, asanModal, transactionResultModal, LxDialogService) ->
    
    fileParser.initXLSX()

    vm = this

    CODE = 'SP'
    DIALOG_MODAL = 'modal-payment-salary'
    validatedPayment = {}

    stdErrors = (key) ->
        return { type: 'err', msg: $translate.instant(key) }


    parseXLSX = (file) ->
        return fileParser.readXLSX file
            .then (doc) ->
                hiddenSheeet = doc.getSheet 'HidSh'
                dataSheet = doc.getSheet 'No_rename'

                errors = []
                if !hiddenSheeet || hiddenSheeet.getCell(3, 89) == 'q1'
                    errors.push stdErrors('SALARY.NOT_TEMPLATE')
                else if !dataSheet
                    errors.push stdErrors('SALARY.INVALID_TEMPLATE')

                if errors.length
                    throw { type: 'TEMPLATE_ERRORS', data: errors }
                    return

                language = dataSheet.getCell('D2')

                employersData = dataSheet.mapRows({
                    nameDoc: 'E'
                    iban: 'F'
                    summ: 'G'
                    base: 'H'
                    note: 'I'
                }, 7, ['iban'])

                parsers = {
                    summ: (val) ->
                        v = val.toString().replace(',', '.')
                        v = Number(v).toFixed(2)
                        return Number(v)
                }
                
                errorsData = fileValidation.validateSalary employersData
                if errorsData.length
                    throw { type: 'DATA_ERRORS', data: errorsData }
                else
                    return fileParser.mapData employersData, parsers


    vm.loaded = false
    vm.loaders = {}

    vm.payment = 
        customer: null
        debit: null
        narrative: ''
        notes: ''

    vm.pageSizes = [ 50, 100 ]
    vm.pageSize = 50
    vm.allSelected = false

    vm.accounts = []
    vm.customers = []
    vm.validFields = {}

    vm.editing = '' #current editing cell id ($index+'_prop')
    vm.editCell = (index, prop) ->
        if vm.editing != index + '_' + prop
            vm.editing = index + '_' + prop

    vm.rowNote = ''
    vm.rowBase = ''

    allCustomers = []
    allAccounts = []


    $scope.$on 'storageSet', (event, field, val) ->
        vm.payment[field] = val

    customersLoaded = ->
        vm.customers = appUtils.filterCustomers allCustomers
        if $stateParams.payment
            setPaymentAsync()
        else if vm.customers.length is 1
            vm.payment.customer = vm.customers[0]

    loadAccounts = ->
        apiAccounts.getPaymentAccounts CODE
            .then (res) ->
                vm.loaded = true
                allAccounts = res.data
                customerAccounts = {}
                cidObj = {}
                for account in res.data
                    cidObj[account.corporateCustomer.cid] = account.corporateCustomer
                allCustomers = Object.values cidObj
                customersLoaded()
            

    vm.selectCustomer = ->
        vm.payment.debit = null
        if vm.payment.customer && vm.payment.customer.cid
            vm.accounts = appUtils.accountsByCid allAccounts, vm.payment.customer.cid
        else
            vm.accounts = []
        fileValidation.accounts = vm.accounts

        if vm.payment.customer && vm.payment.debit && vm.payment.narrative
            vm.validate()

    vm.selectAccount = ->
        if vm.payment.customer && vm.payment.customer.cid && vm.payment.debit && !vm.payment.clientRef
            apiPayment.getRef CODE, vm.payment.customer.cid
                .then (res) ->
                    vm.payment.clientRef = res.data.clientRef

        if vm.payment.customer && vm.payment.debit && vm.tableTotalLength
            vm.validate()

    #main

    tableData = []
    filteredData = []
    vm.table = []
    vm.tableTotalLength = 0
    vm.tableErrorsLength = 0
    vm.pager = 
        pages: 0
        currentPage: 1
    vm.filterStatuses = [ 'CHECKED', 'IS_ERROR', 'ALL' ]
    vm.filter =
        status: 'ALL'

    vm.filterSort = 
        sortBy: ''
        sortDir: ''

    vm.uploadXLS = (file) ->
        vm.errorLogUrl = null

        if !fileParser.checkExt file, ['xls', 'xlsx']
            $scope.$broadcast 'CLEAR_FILE'
            alertModal.show $translate.instant('DIALOG.ATTENTION'),[ stdErrors('SALARY.NOT_TEMPLATE') ], $translate.instant('DIALOG.OK')
            return

        parseXLSX file
            .then (res) -> 
                tableData = res
                indexTable 1
                vm.validate() #start validation
            .catch (err) ->
                console.log err
                if err.type == 'TEMPLATE_ERRORS'
                    alertModal.show $translate.instant('DIALOG.ATTENTION'), err.data, $translate.instant('DIALOG.OK')
                if err.type == 'DATA_ERRORS'
                    txt = err.data.join '\r\n'
                    data = new Blob [txt], {type: 'text/plain'}
                    fileLogUrl = window.URL.createObjectURL data
                    LxDialogService.open 'file-log'
                    $timeout () ->
                        document.getElementById('file-log-link').href = fileLogUrl
                    , 300


    vm.setPage = (page) ->
        vm.allSelected = false
        vm.selectAll()
        vm.pager.currentPage = page
        table = filterTable tableData, vm.filter, vm.filterSort.sortBy, vm.filterSort.sortDir
        vm.pager = pagerService.getPager table.length, page, vm.pageSize
        vm.table = table.slice vm.pager.startIndex, vm.pager.endIndex + 1

    vm.setPageSize = (size) ->
        vm.pageSize = size
        vm.setPage 1

    vm.filterTable = -> 
        vm.setPage 1

    #tableData ---->>> vm.table
    indexTable = (page) ->
        vm.tableTotalLength = tableData.length
        for row, index in tableData
            row.index = index + 1
        vm.setPage page || vm.pager.currentPage


    filterTable = (data, filter, sortBy, sortDir) ->
        filtr = angular.copy filter
        if filtr.status == 'ALL'
            delete filtr.status
        filtered = $filter('filter') data, filtr
        if sortBy
            return filtered.sort (a,b) ->
                aV = Number(a[sortBy].toString().replace(/\s/g,''))
                bV = Number(b[sortBy].toString().replace(/\s/g,''))
                
                if sortDir == '-'
                    return bV - aV
                else
                    return aV - bV
        return filtered

    vm.sortBy = (prop, dir) ->
        vm.filterSort.sortBy = prop
        vm.filterSort.sortDir = dir
        vm.setPage 1

    vm.selectToggle = ->
        tmp = []
        for row in vm.table
            if row.selected
                tmp.push row.index
        vm.selectedIndexes = tmp

    vm.selectAll = ->
        for row in vm.table
            row.selected = vm.allSelected
        vm.selectToggle()

    vm.pushRow = ->
        tableData.push({
            errors: {
                iban: true
                summ: true
                base: true
            }
        })
        vm.filter = { status: 'ALL' }
        indexTable vm.pager.endPage


    vm.deleteSelectedRows = ->
        alertModal.confirm(
            $translate.instant('DIALOG.ATTENTION'),
            $translate.instant('DIALOG.DELETE_ROW'),
            () -> deleteSelectedRows(),
            $translate.instant('DIALOG.DELETE'),
            $translate.instant('DIALOG.REFUSE'),
        )

    deleteSelectedRows = ->
        vm.allSelected = false
        tableData = tableData.filter (row) ->
            return vm.selectedIndexes.indexOf(row.index) is -1
        indexTable()

    currentRow = null
    vm.editRowNote = (row) ->
        LxDialogService.open 'note-dial'
        currentRow = row
        vm.rowNote = row.note

    vm.editRowBase = (row) ->
        LxDialogService.open 'base-dial'
        currentRow = row
        vm.rowBase = row.base

    #temp variable for row note dialog
    vm.updateRowNote = ->
        if currentRow
            currentRow.note = vm.rowNote
        LxDialogService.close 'note-dial'

    vm.updateRowBase = ->
        if currentRow
            currentRow.base = vm.rowBase
        LxDialogService.close 'base-dial'

    # update row on change any field
    vm.updateRow = (row, prop) ->
        # console.log('row.errors', row.errors)
        vm.editing = ''
        # если редактируемое поле - счет
        # то отмечаем что он еще не валидировался на сервере и проверяем снова локально
        if prop == 'iban'
            row.ibanValidated = false
            row.errors.iban = checkIban row.iban, vm.accounts
        
        row.errors.summ = !row.summ
        row.errors.base = !row.base
        # если локально счет свалидировался и он еще небыл проверен на сервере - проверяем
        if !row.errors.iban && !row.ibanValidated
            row.loading = true
            validateAccounts [ row.iban ], true, row

        checkValid()
        indexTable()
        
    
    #full reset
    vm.resetPayment = -> 
        vm.valid = false
        vm.validFields = {}
        vm.payment = {}
        customersLoaded() #set customer
        $scope.$broadcast 'CLEAR_FILE'
        tableData = []
        vm.setPage 1
        vm.tableTotalLength = 0
        vm.tableErrorsLength = 0

    #reset confirm dialog
    vm.resetAction = ->
        alertModal.confirm $translate.instant('DIALOG.ATTENTION'), $translate.instant('SALARY.REFRESH_WARNING'), () ->
            vm.resetPayment()

    #go to trransit account statemenets
    vm.goStatements = ->
        alertModal.confirm $translate.instant('DIALOG.ATTENTION'), $translate.instant('SALARY.REFRESH_WARNING'), () ->
            if vm.payment.customer && vm.accounts.length
                apiPayment.checkSalaryAccounts(vm.payment.customer.cid, vm.accounts[0].iban, [])
                    .then (res) ->
                        $state.go('statement', { isSalaryIban: true, iban: res.data.transitAccountNumber, customer: vm.payment.customer.cid })
   
    vm.goArchive = ->
        alertModal.confirm $translate.instant('DIALOG.ATTENTION'), $translate.instant('SALARY.REFRESH_WARNING'), () ->
            query = 
                type: 'SP'
                status: 'ACCEPTED'
                cid: vm.payment.customer && vm.payment.customer.cid
                iban: vm.payment.debit && vm.payment.debit.iban
            $state.go 'archive', query

    # validate only table data, merge form errors and optionaly show error messages
    checkValid = (showErrors) ->
        formValid = true
        for prop of vm.validFields
            formValid = formValid && !vm.validFields[prop]

        tableValid = tableData.length > 0
        amount = 0
        rowErrorCounter = 0

        #count ibans duplicates
        ibans = {}
        for row in tableData
            if row.iban
                if !ibans[row.iban]
                    ibans[row.iban] = 0
                ibans[row.iban]++

        for row in tableData

            row.duplicate = row.iban && ibans[row.iban] && ibans[row.iban] > 1

            validRow = row.ibanValidated
            if row.summ
                amount += if typeof row.summ is 'string' then Number row.summ.replace(/\s/g,'') else row.summ
            
            for prop of row.errors
                validRow = validRow && !row.errors[prop]
            
            if !validRow
                # console.log 'invalid row'
                rowErrorCounter++
                row.status = 'IS_ERROR'
            else
                row.status = 'CHECKED'

            tableValid = tableValid && validRow

        valid = formValid && tableValid

        vm.tableErrorsLength = rowErrorCounter
        vm.valid = valid
        vm.payment.amount = amount
        
        if !valid && showErrors
            setTimeout( () ->
                scrollElement = document.documentElement
                if !tableValid
                    scrollToElement = scrollElement.querySelector('#table-counter')
                # formValid in priority
                if !formValid
                    scrollToElement = scrollElement.querySelector('.text-field-error')
                window.utils.scrollTo scrollElement, scrollToElement, 1000
            , 500 )

    # validate accounts request
    validateAccounts = (ibans, showErrors, row) ->
        if ibans.length && vm.payment.customer && vm.payment.debit
            vm.loaders.actions = true
            vm.loaders.validationAccounts = true
            if row
                row.loading = true
            apiPayment.checkSalaryAccounts(vm.payment.customer.cid, vm.payment.debit.iban, ibans)
                .then (res) ->
                    vm.loaders = {}
                    # res.data = { checkErrors: {}, employees: [] }
                    vm.transitAccountNumber = res.data && res.data.transitAccountNumber || null
                    for row in tableData
                        row.ibanValidated = true
                        row.loading = false
                        #fill errors
                        for iban of res.data.checkErrors
                            if iban is row.iban && res.data.checkErrors[iban]
                                row.errors.iban = 'ERRORS.' + res.data.checkErrors[iban]
                        #extend row data
                        for data in res.data.employees
                            if data.account.number is row.iban
                                row.errors.iban = false
                                if data.account.currency != vm.payment.debit.currency
                                    row.errors.iban = 'SALARY.INVALID_CURRENCY'
                                row.currency = data.account.currency
                                row.name = data.fullName
                                #extend data
                    checkValid showErrors
                    indexTable()
                , (err) ->
                    if row
                        row.errors.iban = 'ERRORS.ERROR_CHECK_ACCOUNT'
                    for row in tableData
                        row.loading = false
                    vm.loaders = {}
        else
            checkValid showErrors

    processPayment = () ->
        p = {
            ref: vm.payment.ref || '' #used for change
            initiatorCid: vm.payment.customer.cid
            clientRef: vm.payment.clientRef
            debit: vm.payment.debit.iban
            currency: vm.payment.debit.currency
            amount: Number vm.payment.amount
            narrative: vm.payment.narrative
            notes: vm.payment.notes
            salaryDetails: tableData.map (row) ->
                tmp =
                    orderNumber: row.index
                    fullName: row.name
                    accountNumber: row.iban
                    amount: if typeof row.summ is 'string' then Number row.summ.replace(/\s/g,'') else row.summ
                    narrative: row.base
                if row.note
                    tmp.notes = row.note
                if row.uuid
                    tmp.uuid = row.uuid
                return tmp
        }
        for prop of p
            if !p[prop]
                delete p[prop]
        return p
    
    if $rootScope.loaded
        loadAccounts()
    $rootScope.$on 'USER_LOADED', () ->
        loadAccounts()
    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        customersLoaded()

    # SET PAYMENT SYNC
    setPayment = ->
        if $stateParams.payment
            p = $stateParams.payment

            vm.payment = 
                ref: p.ref
                clientRef: p.clientRef
                salaryDetails: p.salaryDetails
                amount: p.amount
                narrative: p.narrative
                notes: p.notes

            tableData = p.salaryDetails.map (row) ->
                uuid: row.uuid
                index: row.orderNumber
                name: row.fullName
                iban: row.accountNumber
                summ: row.amount
                base: row.narrative
                notes: row.notes
                errors: {}
            indexTable()

    setPaymentAsync = ->
        p = $stateParams.payment
        for customer in vm.customers
            if customer.cid == p.corporateCustomerInitiatorCid
                vm.payment.customer = customer
                break
        # after customer ngChange
        $timeout( ->
            for account in vm.accounts
                if account.iban == p.debit
                    vm.payment.debit = account
                    break
        , 300 )

    setPayment()

    paymentDialog = {}

    paymentDialog.open = () ->
        vm.loaders = {}
        LxDialogService.open DIALOG_MODAL
        return

    paymentDialog.close = () ->
        vm.loaders = {}
        LxDialogService.close DIALOG_MODAL

    vm.closePaymentDialog = () ->
        LxDialogService.close DIALOG_MODAL

    checkIban = (iban) ->
        if iban.length is 28
            return null
        else
            return 'ERRORS.ERROR_INVALID_ACCOUNT'

    #validate for and table then show errors
    vm.validate = ->
        vm.validFields.customer = !(vm.payment.customer && vm.payment.customer.cid)
        vm.validFields.debit = !vm.payment.debit
        vm.validFields.narrative = !vm.payment.narrative

        ibans = []
        for row in tableData
            row.errors.iban = checkIban row.iban, vm.accounts
            if !row.errors.iban
                ibans.push row.iban

        validateAccounts ibans, true


    vm.nextAction = ->
        #validate

        vm.validFields.customer = !(vm.payment.customer && vm.payment.customer.cid)
        vm.validFields.debit = !vm.payment.debit
        vm.validFields.narrative = !vm.payment.narrative

        checkValid true
        
        if vm.valid
            validatedPayment = processPayment()
            
            vm.salaryDetails = validatedPayment.salaryDetails

            vm.loaders.actions = true
            vm.loaders.validation = true
            apiPayment.validate CODE, validatedPayment
                .then (response) ->
                    data = {
                        paymentType: CODE
                        cid: vm.payment.customer.cid
                        amount: validatedPayment.amount
                        currency: validatedPayment.currency
                        debit: validatedPayment.debit
                        narrative: validatedPayment.narrative
                        credit: vm.transitAccountNumber
                    }

                    return apiPayment.getCommis data
                .then (res) ->
                    if(res.data.currency != undefined && res.data.amount != undefined)
                        vm.payment.commisAmount = res.data.amount
                        vm.payment.commisCurrency = res.data.currency
                    return transactionActions.getPaymentButtons CODE, vm.payment.customer.cid, vm.payment.customer.authSchema, vm.payment.debit.iban
                .then (buttons) ->
                    vm.loaders = {}
                    vm.buttons = buttons
                    paymentDialog.open()
                .catch (err)->
                    vm.loaders = {}



    vm.sendPayment = (btnKey) ->
        console.log 'sendPayment', btnKey
        authSchema = vm.payment.customer.authSchema
        switch btnKey
            when 'SEND_TO_SIGN', 'SEND_TO_SIGN__APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create CODE, false, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        console.log err
                        vm.loaders = {}

            when 'APPROVE'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                apiPayment.create CODE, btnKey, validatedPayment
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        if res.data.responseType is 'COMPLETE'
                            return transactionResultModal.successPaymentModal authSchema, btnKey
                        else
                            $q.reject()
                    .then () ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        console.log err
                        vm.loaders = {}

            when 'SIGN_1', 'SIGN_2'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment CODE, validatedPayment, btnKey
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        vm.loaders = {}
                        return transactionResultModal.successPaymentModal authSchema, btnKey
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        console.log err
                        vm.loaders = {}

            when 'SIGN_1__SEND_TO_CBS'
                vm.loaders.actions = true
                vm.loaders[btnKey] = true
                asanModal.signPayment CODE, validatedPayment, 'SIGN_1'
                    .then (res) ->
                        return transactionResultModal.timeoutModal res
                    .then (res) ->
                        validatedPayment.ref = res.data.refSet[0]
                        alertModal.showLoader()
                        $rootScope.customAlert = true
                        return apiPayment.sendToCbs validatedPayment.ref #todo where ref from?
                    .then (res) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        return transactionResultModal.sendToBank btnKey, validatedPayment, null, res.data, res.status
                    .then (res) ->
                        paymentDialog.close()
                        vm.resetPayment true
                    .catch (err) ->
                        vm.loaders = {}
                        alertModal.hideLoader()
                        msg = { type: 'msg', msg: $translate.instant 'MESSAGES.SEND_TO_CBS_ONLY_SIGNED' }
                        if $rootScope.customAlert
                            alertModal.show $translate.instant('DIALOG.ATTENTION'), msg, 'Ok', () ->
                                paymentDialog.close()
                                vm.resetPayment true


    return
]
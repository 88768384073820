'use strict'

$(document).on 'click', '.text-field__icon', () ->
    $(this).parent('.text-field').find('input').datepicker().data('datepicker').show()

(($) ->
    $.fn.datepicker.language['en'] =
        days: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        daysShort: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
        daysMin: ['Su','Mo','Tu','We','Th','Fr','Sa']
        months: ['January','February','March','April','May','June','July','August','September','October','November','December']
        monthsShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        today: 'Today'
        clear: 'Clear'
        dateFormat: 'mm/dd/yyyy'
        timeFormat: 'hh:ii aa'
        firstDay: 0

    $.fn.datepicker.language['az'] =
        days: ['Bazar','Bazar ertəsi','Cümə axşamı','Wednesday','Çərşənbə günü','Cümə','Şənbə']
        daysShort: ["Baz", "BzE", "ÇAx", "Çər", "CAx", "Cüm", "Şən"]
        daysMin: ["B", "Be", "Ça", "Ç", "Ca", "C", "Ş"]
        months: ['Yanvar','Fevral','Mart','Aprel','May','İyun','İyul','Avqust','Sentyabr','Oktyabr','Noyabr','Dekabr']
        monthsShort: ["Yan","Fev","Mar","Apr","May","İyn","İyl","Avq","Sen","Okt","Noy","Dek"]
        today: 'Bu gün'
        clear: 'Aydındır'
        dateFormat: 'mm/dd/yyyy'
        timeFormat: 'hh:ii aa'
        firstDay: 1
    return
) jQuery


angular.module('IBR')
    .directive 'airDatepicker', [ '$filter', '$rootScope', '$timeout', ($filter, $rootScope, $timeout)->
        {
            restrict: 'A'
            require: '?ngModel'
            scope:
                minDate: '='
                maxDate: '='
            link: ($scope, $element, $attrs, ngModel) ->
                today = new Date
                picker = {}
                # $element[0].type = 'date'
                #VALIDATE
                allowedKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 189, 96,97,98,99,100,101,102,103,104,105]
                arrowKeys = [37,38,39,40, 46, 8] #37 - 40 = Arrow Keys, 8 - Backspace, 46 - Delete
                # 190 .
                # 191 /
                # patterns = [
                #     /2/
                #     /20/
                #     /20\d{1}/
                #     /20\d{2}/
                #     /20\d{2}-/
                #     /20\d{2}-[01]/
                #     /20\d{2}-(0[1-9]|1[012])/
                #     /20\d{2}-(0[1-9]|1[012])-/
                #     /20\d{2}-(0[1-9]|1[012])-[0123]/
                #     /20\d{2}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])/
                # ]
                patterns = [
                    /[0123]/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)[01]/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)(0[1-9]|1[012])/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)(0[1-9]|1[012])(\.|\/)/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)(0[1-9]|1[012])(\.|\/)2/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)(0[1-9]|1[012])(\.|\/)20/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)(0[1-9]|1[012])(\.|\/)20\d{1}/
                    /(0[1-9]|1[0-9]|2[0-9]|3[01])(\.|\/)(0[1-9]|1[012])(\.|\/)20\d{2}/
                ]
                fullDateRegExp = /(0[1-9]|1[0-9]|2[0-9]|3[01])\.(0[1-9]|1[012])\.(20\d{2})/

                $element[0].setAttribute 'type', 'tel'

                $element[0].addEventListener 'keydown', (event) ->

                    if arrowKeys.indexOf(event.which) is -1
                        val = event.target.value
                        if event.which != 190
                            if val.length == 2 || val.length == 5
                                val += '.'
                                event.target.value += '.'

                        if allowedKeys.indexOf(event.which) == -1
                            event.preventDefault()
                            return

                        testStr = val
                        expected = val

                        if event.which != 8
                            testStr = val.substr(0, @selectionStart) + event.key
                            expected = testStr + val.substr(@selectionEnd)
                        else
                            testStr = val.substr(0, @selectionStart - 1)
                            expected = testStr + val.substr(@selectionStart)

                        allow = new RegExp(patterns[testStr.length - 1]).test(testStr)
                        if (!allow or expected.length > 10) and event.which != 8
                            event.preventDefault()
                    return

                $element[0].addEventListener 'keyup', (event) ->
                    if arrowKeys.indexOf(event.which) is -1
                        val = event.target.value
                        if fullDateRegExp.test(val)
                            picker.selectDate ngModel.$modelValue
                    return

                #end VALIDATE

                ngModel.$formatters.push (value) ->
                    if !value
                        picker.clear()
                    else
                        if value instanceof Date
                            return $filter('date')(value, 'dd.MM.yyyy')

                ngModel.$parsers.push (value) ->
                    date = undefined
                    offset = undefined
                    if value
                        if(angular.isString(value) && fullDateRegExp.test(value))
                            rightVal = value.replace(fullDateRegExp, "$2.$1.$3")
                            date = new Date(rightVal)
                        else
                            if !angular.isString(value) then date = new Date(value) else return ''
                        if date instanceof Date and !isNaN(date.getTime())
                            offset = date.getTimezoneOffset() * 60 * 1000
                            offset = 0
                            date = new Date(date.getTime() - offset)
                            return date
                        else
                            return ''

                picker = $($element).datepicker(
                    language: $rootScope.lang
                    dateFormat: 'dd.mm.yyyy'
                    keyboardNav: false
                    autoClose: true
                    showEvent: null

                    onSelect: (formattedDate, date, that) ->
                        if date instanceof Date
                            ngModel.$setViewValue date
                ).data('datepicker')

                setTimeout (->
                    picker.selectDate ngModel.$modelValue
                    return
                ), 100

                $scope.$watch 'minDate', (value) ->
                    if value
                        picker.update 'minDate', new Date(value)
                    return

                $scope.$watch 'maxDate', (value) ->
                    if value
                        picker.update 'maxDate', new Date(value)
                    return

                $rootScope.$watch 'lang', (value) ->
                    picker.update 'language', value
                    return
        }
    ]

'use strict'

angular.module('IBR')
.directive('selectTranslate', ->
    {
        restrict: 'A'
        controller: [ '$element', '$translate', '$timeout', '$filter', '$rootScope', ($element, $translate, $timeout, $filter, $rootScope) ->
            placeholder = null
            txt = ''
            $timeout ()->
                $rootScope.$on '$translateChangeSuccess', () ->
                    lable = $element.find('span')
                    if (lable.hasClass("lx-select-label"))
                        placeholder = lable[0]
                        txt = ($element[0].attributes['select-translate'].value.toUpperCase())
                        placeholder.innerHTML = $translate.instant(txt)
            , 1000
        ]
    }
)
.directive('clearSelectConfirm', ->
    {
        restrict: 'A'
        controller: [ '$element', 'alertModal', '$translate', '$timeout', ($element, alertModal, $translate, $timeout) ->
            $element[0].addEventListener 'click', (e) ->
                if e.target.className.indexOf('lx-select-selected__tag') > -1
                    el = e.target
                    e.stopPropagation()
                    alertModal.confirm(
                        $translate.instant('DIALOG.ATTENTION'),
                        $translate.instant('DIALOG.CLEAR_INFO'),
                        () ->
                            if el
                                $timeout( ->
                                    angular.element(el).triggerHandler('click')
                                )
                        ,
                        $translate.instant('DIALOG.CLEAR'),
                        $translate.instant('DIALOG.CANCEL'),
                    )
            , true
        ]
    }
)
.directive('translate', ->
    {
        restrict: 'E'
        controller: [ '$element', '$attrs', '$timeout', '$translate', '$rootScope', ($element, $attrs, $timeout, $translate, $rootScope) ->
            content = ''
            cont = () ->
                content = $attrs.val || $element[0].innerHTML
            trs = () ->
                txt = $translate.instant $attrs.key
                $element[0].innerHTML = txt.replace '$', content
            $timeout cont, 0
            $timeout trs, 100
            $rootScope.$on '$translateChangeSuccess', () ->
                $timeout trs, 100
        ]
    }
)


'use strict'

angular.module('IBR')
.factory 'lumxValidationFactory', ->
    validationFactory = {}

    validationFactory.email = (email) ->
        if email
            return /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/.test(email)
        false

    validationFactory.amount = (amount) ->
        if amount
            return /^\d+(\.\d{1,2})?$/.test(amount)
        false

    validationFactory.password = (password, min) ->
        errors = []
        if password
            if password.search(/[a-z]/i) < 0
                errors.push 'Your password must contain at least one letter.'
            if password.search(/[0-9]/) < 0
                errors.push 'Your password must contain at least one digit.'
            if min && password.length <= parseInt(min)
                errors.push 'Your password must be longer.'
            if errors.length > 0
                return false
            else
                return true
        false

    validationFactory.phone = (phone) ->
        if phone
            return /^\s*(?:\+?(\d{1,4}))[-. (]*(\d{2})[-. )]*(\d{3})[-. ]*(\d{2})[-. ]*(\d{2})\s*$/.test(phone)
        false

    validationFactory.text = (text,min,max) ->
        if text && text.length >= parseInt(min) && text.length <= parseInt(max)
            return true
        false

    validationFactory.name = ( name ) ->
        name;

    validationFactory.surname = ( surname ) ->
        surname

    validationFactory.fin = ( fin ) ->
        return /^\n{6}$/.test fin

    validationFactory
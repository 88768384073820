'use strict'

angular.module('IBR')
.filter 'empty', ->
    (input) ->
        if typeof(input) != 'object'
            return true
        else
            angular.equals({}, input)

.filter 'isString', ->
    (input) ->
        return typeof input is 'string'

.filter 'ellipsis', ->
    (input, limit) ->
        return if input && input.length > limit then input.slice(0, limit) + '...' else input

.filter 'trimdots', ->
    (input) ->
        return input.trim().replace(/\./g,'')
'use strict'

angular.module('IBR').component 'trActions', {

    templateUrl: '/pages/transactions/actions.html'
    bindings:
        loaders: '<'
        selProps: '<' #набор критериев характеризующих коллекцию выбранных транзакций (набор собираем в сервисе transactionActions)
        filter: '<' #форма с фильтрами
        actionSet: '<' #доступные действия для набора
        transactionDialog: '='
        transactionsDialog: '='
        batchDialog: '='
        actionDownloadSelected: '='
    controllerAs: "vm"
    controller: ['$rootScope', '$scope', 'transactionActions', ($rootScope, $scope, transactionActions) ->

        vm = this

        vm.showDownload = false
        vm.enableDownload = false
        #набор кнопок действий
        vm.buttons = angular.copy(transactionActions.buttons)
        
        
        vm.currentLang = angular.copy $rootScope.currentLang

        # если хотя бы у одной транзакции доступно подписание, показываем кнопки в футере

        # проверяем доступность кнопок
        checkButtons = () ->

            visible = []
            switch vm.filter.status
                when 'PENDING_FOR_SEND_TO_CBS'
                    visible = ['SAVE_AS_TEMPLATE', 'DELETE', 'SEND_TO_CBS' ]
                when 'SENT_TO_CHANGE'
                    visible = ['SAVE_AS_TEMPLATE', 'DELETE', 'CHANGE']
                when 'SENT_TO_CBS'
                    visible = ['SAVE_AS_TEMPLATE']
                when 'REJECTED_BY_CBS'
                    visible = ['SAVE_AS_TEMPLATE', 'DELETE']
                when 'PENDING_FOR_APPROVE'
                    visible = ['SAVE_AS_TEMPLATE', 'DELETE', 'SEND_TO_CHANGE', 'APPROVE', 'APPROVE__SEND_TO_CBS']
                when 'PENDING_FOR_SIGNATURE'
                    visible = ['SAVE_AS_TEMPLATE', 'DELETE', 'SEND_TO_CHANGE']

            actionSet = vm.actionSet.slice()
            # добавляем + отправить в банк при наличии права на SIGN_1/SIGN_2 (хотя бы в одной транзаеции на странице)
            if actionSet.indexOf('SIGN_1') > -1
                actionSet.push('SIGN_1__SEND_TO_CBS')
            if actionSet.indexOf('SIGN_2') > -1
                actionSet.push('SIGN_2__SEND_TO_CBS')

            visible = visible.concat actionSet

            # console.log('selProps', vm.selProps)
            # console.log('visible', visible)
            # console.log('vm.buttons', vm.buttons)

            vm.showDownload = vm.filter.status != 'ALL_ACTIVE'
            vm.enableDownload = vm.selProps.allIsNotSalary || vm.selProps.singleTransaction


            # console.log vm.selProps


            for key of vm.buttons
                button = vm.buttons[key]
                isInCommon = vm.selProps.commonButtons && vm.selProps.commonButtons.indexOf(button.key) > -1
                button.show = visible.indexOf(button.key) > -1

                # если подписание
                if ['SIGN_1', 'SIGN_2', 'SIGN_1__SEND_TO_CBS', 'SIGN_2__SEND_TO_CBS'].indexOf(button.key) > -1

                    isEnabled = vm.selProps.isOneCid #один сид
                    isEnabled = isEnabled && isInCommon #если допустимые экшен-тайпы для нескольких пересекаются
                    isEnabled = isEnabled && !vm.selProps.exhangeConflict #если все не обмен или все обмен
                    isEnabled = isEnabled && (!vm.selProps.multiple || vm.selProps.canGroupSignature ) #если выбрана одна или все разрешено подписать
                    isEnabled = isEnabled && !vm.selProps.fewBatches #если выбрана не больше одной пачки
                    isEnabled = isEnabled && !(vm.selProps.batches && vm.selProps.transactions) # если не выбраны вместе пачка и транзакция

                    button.disabled = !isEnabled


                # если подтверждение
                else if ['APPROVE', 'APPROVE__SEND_TO_CBS'].indexOf(button.key) > -1
                    button.disabled = !vm.selProps.isOneCid || !isInCommon
                
                #если изменение
                else if button.key == 'CHANGE'
                    button.disabled = !vm.selProps.singleTransaction

                #если сохранить темплейт
                else if button.key == "SAVE_AS_TEMPLATE"
                    button.disabled = !(vm.selProps.singleTransaction && vm.selProps.isTemplateAllowed) || vm.selProps.allIsExchange
                
                else
                    button.disabled = !isInCommon


        # перерисовуем кнопки при изменении фильтров
        $scope.$on 'CHANGE_FILTER', () ->
            checkButtons()

        # перерисовуем кнопки при изменении входных параметров
        vm.$onChanges = (e) ->
            if e.filter && e.filter.status || e.selProps
                checkButtons()

        vm.dialogAction = (btnKey) ->
            if vm.selProps.singleTransaction
                vm.transactionDialog null, btnKey
            else if vm.selProps.singleBatch
                vm.batchDialog null, btnKey #one batch
            else
                vm.transactionsDialog btnKey #batch and transactions

        return vm
    ]
}
angular.module('IBR').factory 'fileParser', ['$translate', '$q', ($translate, $q) ->
    that = {}

    that.initXLSX = () ->
        xlsxLib = document.createElement 'script'
        xlsxLib.src = '/js/xlsx-0.17.js'
        document.head.appendChild xlsxLib


    that.checkExt = (file, allowedExt) ->
        ext = file.name.split('.').pop()
        return if allowedExt.includes ext then ext else false

    # prototypes
    XLSXSheet = (sheet) ->
        this.sheet = sheet
        this.sheetData = XLSX.utils.sheet_to_json sheet, { raw: true, header: 1 }
        return this
    
    XLSXSheet::getCell = (cellName) ->
        cell = this.sheet[cellName]
        return if cell then cell.v else ''


    XLSXSheet::mapRows = (objectMap, fromIndex, reqFields) ->
        i = fromIndex
        data = []
        while i < this.sheetData.length
            row = {}
            for key of objectMap
                cellName = objectMap[key] + i
                row[key] = { cell: cellName, value: this.getCell(cellName).toString(), prop: key }
            exists = true
            for f in reqFields
                exists = exists && row[f].value
            if exists
                data.push row
            i++
        return data

    XLSXDoc = (workbook) ->
        this.workbook = workbook
        return this

    XLSXDoc::getSheet = (sheetName) ->
        sheet = this.workbook.Sheets[sheetName]
        return if sheet then new XLSXSheet sheet else null

    XLSXDoc::getSheetByCode = (code) ->
        for s in this.workbook.Workbook.Sheets
            if s.CodeName is code
                return this.getSheet s.name
                
    XLSXDoc::getSheetByIndex = (sheetIndex) ->
        sheetName = this.workbook.SheetNames[sheetIndex]
        sheet = this.workbook.Sheets[sheetName]
        return if sheet then new XLSXSheet sheet else null
            
    # end prototypes


    that.readXLSX = (file) ->
        deferred = $q.defer()

        reader = new FileReader()

        reader.onload = (e) ->
            data = e.target.result || e.target.content
            workbook = XLSX.read data, { type: 'binary' }
            deferred.resolve new XLSXDoc(workbook)

        reader.onerror = (err) ->
            deferred.reject err

        reader.readAsBinaryString file
        return deferred.promise

    that.readCSV = (file, delim, headers) ->
        delim = delim || ','
        deferred = $q.defer()

        reader = new FileReader()

        reader.onload = (e) ->
            content = e.target.result || e.target.content
            textLines = content.split(/\r\n|\n/)
            # headers = textLines[0].split delim 
            data = []
            i = 0
            while i < textLines.length
                delim = if textLines[i].indexOf(';') > -1 then ';' else ','
                dataArray = textLines[i].split delim
                if dataArray.length + 1 == headers.length
                    dataObject = {}
                    j = 0
                    while j < headers.length
                        dataObject[headers[j]] = { cell: j+':'+i, value: dataArray[j], prop: headers[j] }
                        j++
                    # console.log dataObject
                    data.push dataObject
                i++
            deferred.resolve data

        reader.onerror = (err) ->
            deferred.reject err

        reader.readAsBinaryString file
        return deferred.promise

    that.mapData = (data, parsers) ->
        return data.map (row, index) ->
            item = { index: index, errors: {} }
            for prop of row
                value = row[prop].value
                if parsers && parsers[prop]
                    value = parsers[prop](value)
                item[prop] = value

            return item


    return that

]
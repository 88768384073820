'use strict'


getSelectedKeys = (obj) ->
    keys = []
    for prop of obj
        if obj[prop]
            keys.push prop
    return keys

angular.module('IBR')
.controller 'BulkPaymentsCtrl', [ 'appUtils', '$rootScope', '$scope', '$filter', '$timeout', '$translate', '$q', 'pagerService', 'authoritiesFactory', 'fileParser', 'fileValidation', 'apiBulkPayments', 'apiAccounts', 'transactionModal', 'alertModal', 'asanModal', 'transactionResultModal', 'LxDialogService', ( appUtils, $rootScope, $scope, $filter, $timeout, $translate, $q, pagerService, authoritiesFactory, fileParser, fileValidation, apiBulkPayments, apiAccounts, transactionModal, alertModal, asanModal, transactionResultModal, LxDialogService ) ->
    
    fileParser.initXLSX()

    vm = this
    
    DATE = $filter('date')(new Date(), 'dd.MM.yyyy')
    PAGE_SIZES = [50, 100]
    ALL_ACC = { iban: 'ALL' }
    CODE = 'IT'
    TYPES = [
        "IT"
        "AZ"
        "XO"
        "XT"
        "XV"
    ]
    STATUSES = [
        'ALL'
        'PENDING_FOR_VALIDATION'
        'VALIDATION_ERROR'
        'VALIDATION_PROCESS'
        'PENDING_FOR_CREATION'
        'CREATION_ERROR'
        'CREATION_PROCESS'
        'CREATED'
    ]
    
    CURRENCIES = [
        # 'ALL'
        'AZN'
        # 'USD'
        # 'EUR'
        # 'RUB'
        # 'GBP'
    ]

    # функция обрабатывает ошибки валидации с сервера
    getConstrains = (validationErrorDTO, transferType) ->

        mapC = {
            'debit_0038':  { prop: 'debitAmount',               message: $translate.instant('BULK_PAYMENTS.INVALID_AMOUNT') }

            'debit_0039':  { prop: 'debitIban',                 message: $translate.instant('BULK_PAYMENTS.INVALID_IBAN') }
            'debit_0040':  { prop: 'debitIban',                 message: $translate.instant('BULK_PAYMENTS.INVALID_IBAN') }

            'credit_0039': { prop: 'creditIban',                message: $translate.instant('BULK_PAYMENTS.INVALID_IBAN') }
            'credit_0040': { prop: 'creditIban',                message: $translate.instant('BULK_PAYMENTS.NO_CREDIT') }

            'transfer_amount_0038':  { prop: 'debitAmount',     message: $translate.instant('BULK_PAYMENTS.INVALID_AMOUNT') }
            'currency_0041': { prop: 'currency',                message: $translate.instant('BULK_PAYMENTS.INVALID_CURRENCY') }
            'credit_0051': { prop: 'beneficiaryTaxId',          message: $translate.instant('BULK_PAYMENTS.INVALID_TAXID') }
            'credit_0088': { prop: 'budgetLevelCode',           message: $translate.instant('BULK_PAYMENTS.INVALID_LEV_CODE') }
            'credit_0089': { prop: 'budgetClassificationCode',  message: $translate.instant('BULK_PAYMENTS.INVALID_CLS_CODE') }
            'credit_0090': { prop: 'beneficiaryBankCode',       message: $translate.instant('BULK_PAYMENTS.INVALID_BANK_CODE') }
            'credit_0091': { prop: 'creditIban',                message: $translate.instant('BULK_PAYMENTS.NO_CREDIT') }

            'debit_0042':  { prop: 'rule',                      message: $translate.instant('BULK_PAYMENTS.INVALID_RULE') }
            'credit_0042': { prop: 'rule',                      message: $translate.instant('BULK_PAYMENTS.INVALID_RULE') }
        }

        if !validationErrorDTO || !validationErrorDTO.constraints
            return []

        errors = {}
        for constraint in validationErrorDTO.constraints
            key = constraint.paramName+'_'+constraint.code
            val = mapC[key]
            if key == 'credit_0040' && transferType == 'IT'
                val = { prop: 'creditIban', message: $translate.instant('BULK_PAYMENTS.NOT_IBR_CREDIT') }
            if key == 'credit_0040' && ['AZ','XO','XT'].includes transferType
                val = { prop: 'creditIban', message: $translate.instant('BULK_PAYMENTS.INVALID_CREDIT') }
            errors[key] = val || constraint

        if errors.credit_0091 && errors.credit_0040
            delete errors.credit_0040

        output = []
        for key of errors
            output.push errors[key]

        return output


    REPORT_FILE_NAMES = {
        UPLOAD_ERROR: 'Batch upload Error Report BR '+DATE
        VALIDATION_ERROR: 'Batch check Error Report BR '+DATE
        CREATION_ERROR: 'Batch create Error Report BR '+DATE
    }


    
    vm.loaded = false
    vm.searched = false
    vm.loaders = {}
    vm.buttons = {
        delete: false
        validate: false
        create: false
        download: false
    }
    vm.currentLang = $rootScope.currentLang
    vm.fileIsReady = false

    vm.tFilters = {}
    vm.tSort = 'orderNumber'

    vm.paymentsPage = 1
    vm.paginator = {
        sizes: [ 10, 50, 100 ]
        size: 10
    }

    vm.allSelected = false

    # table data
    vm.bulks = []
    vm.payments = []

    # form select values
    vm.accounts = []
    vm.allAccounts = []
    vm.customers = []

    vm.constants = {
        TYPES: TYPES
        CURRENCIES: CURRENCIES
        STATUSES: STATUSES
    }

    vm.filterDefaults = {
        STATUSES: STATUSES
    }

    vm.search = {
        transferTypes: []
        status: 'ALL'
        account: ALL_ACC
        startDate: new Date(new Date().setHours(0,0,0,0))
        stopDate: new Date()
    }
    currentSearch = {}
    vm.filter = {
        currency: 'AZN'
        status: 'ALL'
        page: 1
    }
    vm.filterSort = {
        sortBy: 'createdDate'
        sortDir: 'DESC'
    }
    vm.selectedPayments = {} #list of selected uuids { uuid: boolean_value }


    stdErrors = (key) ->
        return { type: 'err', msg: $translate.instant(key) }


    parseCSV = (file) ->
        headers = [
            'transferType'
            'clientRef'
            'beneficiaryName'
            'beneficiaryAccountIban'
            'beneficiaryTaxId'
            'beneficiaryBankCode'
            'budgetClassificationCode'
            'budgetLevelCode'
            'additionalInfo'
            'debitAmount'
            'narrative'
            'userNote'
        ]
        return fileParser.readCSV file, ',', headers
            .then (bulkData) ->
                fileValidation.filterBulk bulkData
                errorsData = fileValidation.validateBulk bulkData
                if errorsData.length
                    throw { type: 'DATA_ERRORS', data: errorsData }
                else
                    return fileParser.mapData bulkData

    parseXLSX = (file) ->
        return fileParser.readXLSX file
            .then (doc) ->
                dataSheet = doc.getSheet 'Batch'

                errors = []
                if !dataSheet
                    errors.push stdErrors('BULK_PAYMENTS.INVALID_TEMPLATE')

                if errors.length
                    throw { type: 'TEMPLATE_ERRORS', data: errors }
                    return

                language = dataSheet.getCell('B2')

                # console.log dataSheet.getCell('F5')

                bulkData = dataSheet.mapRows({
                    transferCode: 'C'
                    transferType: 'D'
                    clientRef: 'E'
                    beneficiaryName: 'F'
                    beneficiaryAccountIban: 'G'
                    beneficiaryTaxId: 'H'
                    beneficiaryBankCode: 'I'
                    budgetClassificationCode: 'J'
                    budgetLevelCode: 'K'
                    additionalInfo: 'L'
                    debitAmount: 'M'
                    narrative: 'N'
                    userNote: 'O'
                }, 5, ['transferType'])

                console.log bulkData
                
                fileValidation.filterBulk bulkData

                if !bulkData.length
                    throw { type: 'TEMPLATE_ERRORS', data: [stdErrors('BULK_PAYMENTS.EMPTY_TEMPLATE')] }

                errorsData = fileValidation.validateBulk bulkData
                if errorsData.length
                    throw { type: 'DATA_ERRORS', data: errorsData }
                else
                    return fileParser.mapData bulkData

    # иницыализация кастомного компонента
    vm.dateRange = new DateRange({
        moreNow: true
    })

    # объединяем данные формы поиска и фильтр в хедере таблицы
    mergeFilters = (first, second) ->
        filter = {}
        for prop of first
            if first[prop] && first[prop] != 'ALL'
                filter[prop] = first[prop]
        for prop of second
            if second[prop] && second[prop] != 'ALL'
                filter[prop] = second[prop]
        return filter

    # основная функция поиска
    searchBulks = (isSearch, keepOpened) ->

        filter = mergeFilters currentSearch, vm.filter

        # console.log 'searchBulks', filter

        if !filter.customer
            return

        filterQuery = {
            pageParams: { pageNumber: vm.filter.page, size: vm.paginator.size }
            sort: {
                fieldName: vm.filterSort.sortBy
                order: vm.filterSort.sortDir
            }
            filterSpecification: [
                {
                    fieldName: 'initiatorCid'
                    values: [filter.customer.cid]
                    operator: "EQUALS"
                    inverted: "false"
                }
            ]
        }

        if filter.transferTypes && filter.transferTypes.length
            for type in filter.transferTypes
                filterQuery.filterSpecification.push({
                    fieldName: "transferTypes"
                    values: [type]
                    operator: "LIKE"
                    inverted: "false"
                })

        if filter.type
            filterQuery.filterSpecification.push({
                fieldName: "transferTypes"
                values: [filter.type]
                operator: "LIKE"
                inverted: "false"
            })
        
        if filter.startDate
            sDate = new Date(filter.startDate.setHours(0,0,0,0))
            filterQuery.filterSpecification.push({
                fieldName: "createdDate"
                values: [sDate.toIsoString()]
                operator: "MORE_THAN"
                inverted: "false"
            })

        if filter.stopDate
            # fDate = new Date(vm.transactionFilter.stopDate)
            fDate = new Date(filter.stopDate.setHours(23,59,59,99))
            filterQuery.filterSpecification.push({
                fieldName: "createdDate"
                values: [fDate.toIsoString()]
                operator: "LESS_THAN"
                inverted: "false"
            })


        if filter.account && filter.account.iban != 'ALL'
            filterQuery.filterSpecification.push({
                fieldName: "debit"
                inverted: "false"
                operator: "EQUALS"
                values: [filter.account.iban]
            })

        if filter.amount
            filterQuery.filterSpecification.push({
                fieldName: "amount"
                inverted: "false"
                operator: "EQUALS"
                values: [filter.amount]
            })

        if filter.status
            filterQuery.filterSpecification.push({
                fieldName: "status"
                inverted: "false"
                operator: "EQUALS"
                values: [filter.status]
            })

        if filter.bulkName
            filterQuery.filterSpecification.push({
                fieldName: "bulkName"
                inverted: "false"
                operator: "LIKE"
                values: ["%" + filter.bulkName + "%"]
            })
        if filter.ref
            filterQuery.filterSpecification.push({
                fieldName: "ref"
                inverted: "false"
                operator: "LIKE"
                values: ["%" + filter.ref + "%"]
            })

        vm.loaders.search = true
        vm.loaders.actions = true
        if !keepOpened
            vm.openedBulkId = null

        return apiBulkPayments.getBulks filterQuery
            .then (res) ->
                resetSelection(true)
                vm.searched = true
                vm.loaders.actions = false
                vm.loaders.search = false
                vm.bulksPager = pagerService.getPager res.count, vm.filter.page, vm.paginator.size
                vm.bulks = res.records
                vm.paymentsFilter()
            .catch (err) ->
                resetSelection(true)
                vm.loaders.actions = false
                vm.loaders.search = false

    # функция обновляет в таблице данные пачки
    updateCurrent = (bulk) ->
        vm.bulks = vm.bulks.map (b) ->
            return if b.bulkId is bulk.bulkId then bulk else b
        vm.paymentsFilter true
    
    vm.setBulksPage = (page) ->
        vm.filter.page = page
        # console.log 'setBulksPage'
        searchBulks()

    vm.setPaymentsPage = (page) ->
        vm.paymentsPage = page
        if !getSelectedBulk()
            vm.selectedPayments = {}
        vm.paymentsFilter true

    vm.setPageSize = (size) ->
        vm.paginator.size = size
        # console.log 'setPageSize'
        searchBulks()



    # обработчик открытия пачки
    vm.togglePayments = (bulk) ->
        vm.tFilters = {}
        vm.tSort = 'orderNumber'
        vm.openedBulkId = if vm.openedBulkId is bulk.bulkId then null else bulk.bulkId
        if vm.openedBulkId
            vm.paymentsFilter(true)

        # vm.loadingBulkId = null
        # if vm.openedBulkId is bulk.bulkId
        #     # close
        #     vm.openedBulkId = null
        #     vm.loadingBulkId = bulk.bulkId
        #     vm.refreshAction bulk.bulkId
        #         .then (res) ->
        #             vm.loadingBulkId = null
        #         .catch (err) ->
        #             vm.loadingBulkId = null
        # else
        #     # open
        #     vm.openedBulkId = bulk.bulkId
        #     if vm.openedBulkId
        #         vm.paymentsFilter(true)
            

    ########################################################################
    ######################### CHECKBOXES LOGIC #############################
    ########################################################################
    
    vm.openedBulkId = null
    # сбрасываем все чекбоксы
    resetSelection = (recheck) ->
        vm.allBulksSelected = false
        vm.selectedBulks = {} #deselect all bulks
        vm.selectedPayments = {} #deselect all payments
        vm.allPaymentsSelected = false
        if recheck
            checkActions()

    # сбрасываем все чекбоксы при инициализации
    resetSelection()


    resetFilters = (andForm) ->
        vm.filter = {
            status: 'ALL'
            currency: 'AZN'
            page: 1
        }
        if andForm
            vm.search.transferTypes = []
            vm.search.transferTypes = []
            vm.search.status = 'ALL'
            delete vm.search.account
            delete vm.search.bulkName
            vm.search.startDate = new Date(new Date().setHours(0,0,0,0))
            vm.search.stopDate = new Date()


    # функция возвращает количество платежей с ошибками
    vm.countFailed = (payments) ->
        count = 0
        for p in payments
            if p.status is 'VALIDATION_ERROR' || p.status is 'CREATION_ERROR'
                count++
        return count

    # функция возвращает открытую пачку
    getOpenedBulk = () ->
        bulks = vm.bulks.filter (b) ->
            return vm.openedBulkId is b.bulkId
        return bulks[0]

    # функция возвращает отмеченную пачку
    getSelectedBulk = () ->
        bulks = vm.bulks.filter (b) ->
            return vm.selectedBulks[b.bulkId]
        return bulks[0]

    # функция возвращает массив отмеченных uuid платежей
    getSelectedUuids = () ->
        uuids = []
        for uuid of vm.selectedPayments
            if vm.selectedPayments[uuid]
                uuids.push uuid
        return uuids

    # обработчик чекбокса на пачке
    vm.selectBulk = (bulk) ->
        #check all payments
        isOpened = vm.openedBulkId is bulk.bulkId
        isSelected = vm.selectedBulks[bulk.bulkId]
        resetSelection()
        vm.selectedBulks[bulk.bulkId] = isSelected
        vm.allPaymentsSelected = isSelected && isOpened
        if isSelected
            # select all payments in bulk
            for p in bulk.bulkTransferDTOList
                vm.selectedPayments[p.uuid] = true
        else
            # deselect all payments in bulk
            vm.selectedPayments = {}

        checkActions()

    # локальный фильтр таблицы платежей
    vm.paymentsFilter = (check) ->
        if !check
            return
        bulk = getOpenedBulk()
        if bulk
            payments = bulk.bulkTransferDTOList
            index = 0
            for p in payments
                index++
                p.index = index
                p.errors = {}
                for e in getConstrains(p.validationErrorDTO, p.transferType)
                    if e.message
                        p.errors[e.prop] = e.message

            filtered = $filter('filter')(payments, vm.tFilters)
            filtered = $filter('orderBy')(filtered, vm.tSort)
            vm.paymentsPager = pagerService.getPager filtered.length, vm.paymentsPage, vm.paginator.size
            vm.payments = $filter('limitTo')(filtered, vm.paymentsPager.pageSize, vm.paymentsPager.startIndex)
            checkSelection()
            checkActions()

    # слушатель локального фильтра таблицы платежей
    $scope.$watch 'bulkPayments.tFilters', (val) ->
        vm.paymentsFilter(true)
    , true

    $scope.$watch 'bulkPayments.tSort', (val) ->
        vm.paymentsFilter(true)
    , true

    # обработчик на таблице с платежами одной пачки
    # select all visible
    vm.selectAllPayments = (bulk) ->
        visibleUuids = vm.payments.map (p) ->
            return p.uuid
        if vm.allPaymentsSelected
            for uuid in visibleUuids
                vm.selectedPayments[uuid] = true
        else
            for uuid in visibleUuids
                vm.selectedPayments = {}
        checkSelection()
        checkActions()

    # обработчик на чекбоксе отдельного платежа
    vm.selectPayment = (bulk, payment) ->
        checkSelection()
        checkActions()


    #check checkboxes binding
    # функция связывает все чекбоксы между собой
    checkSelection = ->
        uuids = getSelectedUuids()
        visibleUuids = vm.payments.map (p) ->
            return p.uuid

        # deselect hidden payments if bulk not selected
        if !getSelectedBulk()
            for uuid of vm.selectedPayments
                if !visibleUuids.includes uuid
                    vm.selectedPayments[uuid] = false
            uuids = getSelectedUuids()

        openedBulk = getOpenedBulk()
        if openedBulk
            vm.selectedBulks = {}
            vm.selectedBulks[openedBulk.bulkId] = openedBulk.bulkTransferDTOList.length is uuids.length #all checked
            
            allSelected = true #all visible checked
            for p in vm.payments
                allSelected = allSelected && vm.selectedPayments[p.uuid]
            vm.allPaymentsSelected = allSelected


    ########################################################################
    ######################### END CHECKBOXES LOGIC #########################
    ########################################################################

    # actions
    # функция проверки условий показа кнопок действий
    checkActions = () ->

        vm.buttons = {
            delete: false
            validate: false
            create: false
            payments: false
            download: false
        }
        
        selectedBulk = getSelectedBulk()
        openedBulk = getOpenedBulk()

        if !openedBulk && !selectedBulk
            return

        bulk = selectedBulk || openedBulk

        selectedPayments = bulk.bulkTransferDTOList.filter (p) ->
            return vm.selectedPayments[p.uuid]

        if !selectedPayments.length
            return

        allow = {
            delete: true
            validate: true
            create: true
            payments: true
            download: true
        }

        allSelected = selectedPayments.length == bulk.bulkTransferDTOList.length

        allow.validate = allSelected
        allow.create = allSelected
        
        for p in selectedPayments
            # console.log 'isCreated', p.status, apiBulkPayments.isCreated p.status
            allow.delete = allow.delete && !apiBulkPayments.isCreated(p.status) && ![ 'VALIDATION_PROCESS', 'CREATION_PROCESS' ].includes p.status
            allow.validate = allow.validate && [ 'PENDING_FOR_VALIDATION', 'VALIDATION_ERROR' ].includes p.status
            allow.create = allow.create && [ 'PENDING_FOR_CREATION', 'CREATION_ERROR' ].includes p.status
            allow.payments = allow.payments && apiBulkPayments.isCreated p.status
            allow.download = allow.download && ![ 'VALIDATION_PROCESS', 'CREATION_PROCESS' ].includes p.status


        if ['VALIDATION_ERROR'].includes bulk.status
            allow.validate = allSelected
        if ['CREATION_ERROR'].includes bulk.status
            allow.create = allSelected
        

        vm.buttons = allow

                
    # обработчик кнопки удаления
    vm.actionDeleteSelected = ->
        selectedBulk = getSelectedBulk()
        openedBulk = getOpenedBulk()
        uuids = getSelectedUuids()
        count = if selectedBulk then selectedBulk.bulkTransferDTOList.length else uuids.length

        delMsg = $translate.instant('BULK_PAYMENTS.DELETE_MSG')
        delMsg = delMsg.replace '$', count
        alertModal.confirmDelete delMsg, () ->
            if selectedBulk
                apiBulkPayments.deleteBulk selectedBulk.bulkId
                    .then () ->
                        searchBulks()
            else if openedBulk && uuids.length
                apiBulkPayments.deletePayments openedBulk.bulkId, uuids
                    .then () ->
                        resetSelection(true)
                        # удаляем платеж локально
                        openedBulk.bulkTransferDTOList = openedBulk.bulkTransferDTOList.filter (p) ->
                            return !uuids.includes p.uuid
                        vm.refreshAction()
        , 'DIALOG.DELETE', 'DIALOG.REFUSE'
                        # vm.paymentsFilter true

    # обработчик кнопки валидации
    vm.actionValidateSelected = ->
        selectedBulk = getSelectedBulk()
        if !selectedBulk
            return
        vm.loaders.actions = true
        vm.loaders.validate = true
        apiBulkPayments.validateBulk selectedBulk.bulkId
            .then () ->
                vm.loaders.actions = false
                vm.loaders.validate = false
                searchBulks()
                
            .catch (err) ->
                console.log err
                vm.loaders.actions = false
                vm.loaders.validate = false

    # обработчик кнопки создания в "Актуальных переводах"
    vm.actionCreateSelected = ->
        # console.log('CREATE')
        selectedBulk = getSelectedBulk()
        if !selectedBulk
            return
        vm.loaders.actions = true
        vm.loaders.create = true
        apiBulkPayments.createBulk selectedBulk.bulkId
            .then () ->
                vm.loaders.actions = false
                vm.loaders.create = false
                searchBulks()
            .catch (err) ->
                vm.loaders.actions = false
                vm.loaders.create = false

    # обработчик кнопки скачивания отчета в футере
    vm.actionDownloadSelected = (type) ->
        bulk = getSelectedBulk() || getOpenedBulk()
        data = {
            lang: vm.currentLang.lngu
            reportType: if type == 'PAYMENTS' then 'PAYMENTS' else 'BULK'
            bulkId: bulk.bulkId
            uuids: getSelectedUuids()
        }
        fileType = if type == 'XLSX' then 'xlsx' else 'pdf'
        vm.loaders.actions = true
        vm.loaders[type] = true
        apiBulkPayments.downloadFile data, fileType
            .then (response) ->
                window.utils.downloadFile response
                vm.loaders.actions = false
                vm.loaders[type] = false
            .catch (err) ->
                vm.loaders.actions = false
                vm.loaders[type] = false

    # обработчик главной кнопки поиска
    vm.searchAction = ->
        vm.filter.page = 1
        currentSearch = angular.copy vm.search
        if vm.search.status != 'ALL'
            vm.filter.status = vm.search.status
            vm.filterDefaults.STATUSES = [ vm.search.status ]
        else
            vm.filter.status = 'ALL'
            vm.filterDefaults.STATUSES = vm.constants.STATUSES
        searchBulks(true)

    # обновление отдельной открытой пачки
    vm.refreshAction = (bulkId) ->
        bulkId = bulkId || vm.openedBulkId
        vm.loaders.refresh = true
        return apiBulkPayments.getBulk bulkId
            .then (bulk) ->
                vm.loaders.refresh = false
                updateCurrent bulk
            .catch (error) ->
                vm.loaders.refresh = false

    oldFilter = angular.copy vm.filter

    vm.filterTable = (useDelay) ->
        delay = if useDelay then 2000 else 0
        $timeout.cancel searchTimeout
        searchTimeout = $timeout () ->
            # console.log vm.filter, oldFilter
            if !angular.equals(vm.filter, oldFilter)
                oldFilter = angular.copy vm.filter
                # console.log 'vm.filterTable searchBulks'
                searchBulks()
        , delay
        

    # hack for LUMX (clear action)
    $scope.$watch 'bulkPayments.filter.ref', (val, oldVal) ->
        if oldVal && !val
            vm.filterTable()
    $scope.$watch 'bulkPayments.filter.bulkName', (val, oldVal) ->
        if oldVal && !val
            vm.filterTable()
    $scope.$watch 'bulkPayments.filter.type', (val, oldVal) ->
        if oldVal && !val
            vm.filterTable()

    # функция локальной сортировки
    vm.sortByAction = (prop, dir) ->
        vm.filterSort.sortBy = prop
        vm.filterSort.sortDir = dir || 'ASC'
        searchBulks()

    # действие на открытие окна загрузки
    vm.uploadAction = ->
        vm.upload = {}
        LxDialogService.open 'bulk-upload-dialog'

    # вычитываем имя файла при выборе
    bulkFile = null
    vm.selectFile = (file) ->
        # console.log file
        vm.upload.filename = file.name
        vm.upload.name = file.name.replace(/\.[^/.]+$/, "").slice(0, 100)
        bulkFile = file
        vm.fileIsReady = true
    
    # обработчик на кнопке парсинга и загрузки данных
    vm.uploadBulk = () ->
        vm.loaders.file = true
        $timeout () ->
            uploadBulk()
        , 300

    # функция парсинга и загрузки данных
    localValidationText = ''
    uploadBulk = () ->
        validExt = fileParser.checkExt bulkFile, ['csv', 'xls', 'xlsx']

        if !validExt
            vm.loaders.file = false
            $scope.$broadcast 'CLEAR_FILE'
            alertModal.show $translate.instant('DIALOG.ATTENTION'), [ stdErrors('SALARY.NOT_TEMPLATE') ], $translate.instant('DIALOG.OK')
            return

        parserFunction = if validExt == 'csv' then parseCSV else parseXLSX

        fileValidation.debitIban = vm.upload.account.iban

        parserFunction bulkFile
            .then (trs) ->
                if !trs.length
                    alertModal.show $translate.instant('DIALOG.ATTENTION'), [ stdErrors('SALARY.NOT_TEMPLATE') ], $translate.instant('DIALOG.OK')
                    return
                return apiBulkPayments.uploadBulk({
                    bulkName: vm.upload.name
                    debit: vm.upload.account.iban
                    debitCurrency: vm.upload.account.currency
                    initiatorCid: vm.search.customer.cid
                    transferList: trs.map (t) ->
                        t.number = t.index + 1
                        delete t.index
                        delete t.errors
                        for prop of t
                            if !t[prop]
                                delete t[prop]
                        return t
                })
            .then (res) ->
                LxDialogService.close 'bulk-upload-dialog'
                vm.loaders.file = false
                resetFilters(true)
                vm.searchAction()
                # searchBulks()
            .catch (err) ->
                console.log err
                vm.loaders.file = false
                if err.type == 'TEMPLATE_ERRORS'
                    alertModal.show $translate.instant('DIALOG.ATTENTION'), err.data, $translate.instant('DIALOG.OK')
                if err.type == 'DATA_ERRORS'
                    localValidationText = err.data.join ';\r\n'
                    LxDialogService.open 'file-log'

    # открываем окно деталей платежа
    vm.showPaymentDetails = (payment) ->

        transactionModal.openInfo payment, [], null, (type, transaction, lang) ->

            if transaction.ref
                transactionModal.downloadAction type, transaction, lang
            else
                data = {
                    lang: lang.lngu
                    reportType: 'PAYMENTS'
                    bulkId: transaction.bulkId
                    uuids: [ transaction.uuid ]
                }
                $rootScope.loaders[type] = true
                apiBulkPayments.downloadFile data, 'pdf'
                    .then (response) ->
                        window.utils.downloadFile response
                        $rootScope.loaders[type] = false
                        vm.loaders.actions = false
                        vm.loaders[type] = false
                    .catch (err) ->
                        $rootScope.loaders[type] = false
                        vm.loaders.actions = false
                        vm.loaders[type] = false


    # скачивание отчета локальной валидации
    vm.validationReport = () ->
        window.utils.makeFile REPORT_FILE_NAMES['UPLOAD_ERROR']+'.txt', localValidationText, 'text/plain'

    # скачивание отчета по ошибкам для отдельного платежа
    vm.reportPaymentErrors = (payment) ->
        messages = getConstrains(payment.validationErrorDTO, payment.transferType).map (e) ->
            return e.message
        text = messages.join ';\r\n'
        window.utils.makeFile REPORT_FILE_NAMES[payment.status]+'.txt', text + ';', 'text/plain'

    # скачивание отчета по ошибкам для пачки
    vm.reportBulkErrors = (bulk) ->
        errors = []
        text = ''
        messages = {}

        payments = $filter('orderBy')(bulk.bulkTransferDTOList, 'orderNumber')
        payments.map (payment) ->
            text = ''
            messages = getConstrains(payment.validationErrorDTO, payment.transferType).map (e) ->
                return e.message + ';'
            if messages.length
                errors.push $translate.instant('CELL_VALIDATION.STRING_NUMBER').replace('$', payment.orderNumber)
            messages.join '\r\n'
            errors = errors.concat messages
        text = errors.join '\r\n'
        window.utils.makeFile REPORT_FILE_NAMES[bulk.status]+'.txt', text, 'text/plain'



    vm.chooseFile = () ->
        $('#fileinput').click()
    ##################
    # GENERAL
    # предзагрузка данных
    ##################
    customerAccounts = {}
    vm.takeAccounts = () ->
        allAccounts = [ ALL_ACC ]
        accounts = []
        if vm.search.customer && vm.search.customer.cid
            for acc in customerAccounts[vm.search.customer.cid]
                allAccounts.push acc
                if acc.currency == 'AZN' && acc.type == 'CURRENT'
                    accounts.push acc
        else
            vm.accounts = []
        vm.allAccounts = allAccounts
        vm.accounts = accounts
        fileValidation.accounts = allAccounts
        vm.searchAction()


    allCustomers = []
    listAccounts = []

    customersLoaded = ->
        vm.customers = appUtils.filterCustomers allCustomers
        if vm.customers.length is 1
            vm.search.customer = vm.customers[0]
        else
            delete vm.search.customer
        vm.loaded = true

    loadAccounts = ->
        vm.loaded = false
        apiAccounts.getAccounts()
            .then (res) ->
                cidObj = {}
                customerAccounts = {}
                for account in res.data
                    if account.corporateCustomer
                        cid = account.corporateCustomer.cid
                        if !customerAccounts[cid] then customerAccounts[cid] = []
                        customerAccounts[cid].push account
                        cidObj[account.corporateCustomer.cid] = account.corporateCustomer
                
                allCustomers = Object.values cidObj
                customersLoaded()

    
    vm.initialRequest = false
    if $rootScope.loaded
        if !vm.initialRequest
            loadAccounts()
            vm.initialRequest = true

    $rootScope.$on 'USER_LOADED', () ->
        if !vm.initialRequest
            loadAccounts()
            vm.initialRequest = true

    $rootScope.$on 'SELECT_CUSTOMERS', () ->
        if vm.loaded
            loadAccounts()

    return
]
'use strict'

angular.module('IBR').component 'selectUniq', {

    template: '<lx-select ng-if="!vm.showAll" ng-model="vm.value"
        lx-allow-clear="true"
        lx-choices="vm.options"
        lx-display-filter="false"
        lx-fixed-label="true"
        ng-change="vm.setValue()"
        lx-label="{{vm.label | translate}}">
            <lx-select-selected>{{ $selected.txt | translate }}</lx-select-selected>
            <lx-select-choices>{{ $choice.txt | translate }}</lx-select-choices>
    </lx-select>
    <lx-select ng-if="vm.showAll" ng-model="vm.value"
        lx-allow-clear="false"
        lx-choices="vm.options"
        lx-display-filter="false"
        lx-fixed-label="true"
        ng-change="vm.setValue()"
        lx-label="{{vm.label | translate}}">
            <lx-select-selected>{{ $selected.txt | translate }}</lx-select-selected>
            <lx-select-choices>{{ $choice.txt | translate }}</lx-select-choices>
    </lx-select>'
    bindings:
        ngModel: '='
        ngChange: '&'
        collection: '<'
        filter: '<'
        label: '@'
        prop: '@'
        map: '<'
        showAll: '<'
    controllerAs: "vm"
    controller: ['$scope', '$translate', ($scope, $translate) ->

        vm = this
        vm.choices = []
        vm.value = null

        defaultValue = { txt: 'ALL.ALL' }

        init = ->
            if vm.collection && vm.prop
                props = vm.prop.split("||")
                if vm.filter
                    collection = window.utils.filter vm.collection, vm.filter
                else
                    collection = vm.collection

                tmp = []
                if vm.showAll
                    tmp = [defaultValue]
                    vm.value = defaultValue

                keys = []
                for item in collection
                    key = props.map (prop) ->
                        return item[prop]
                    .join('_')
                    if keys.indexOf(key) is -1
                        t = {}
                        for prop in props
                            t[prop] = item[prop]
                        t.txt = item[props[0]] || item[props[1]]
                        
                        if vm.map && vm.map[t.txt]
                            t.txt = vm.map[t.txt]

                        keys.push key
                        tmp.push t

                vm.options = tmp

                # console.log(tmp)

        vm.setValue = ->
            props = vm.prop.split("||")
            vm.ngModel = vm.ngModel || {}
            for prop in props
                vm.ngModel[prop] = vm.value && vm.value[prop]
            if vm.ngChange
                vm.ngChange()

        vm.$onChanges = (e) ->
            if e.collection && e.prop || e.filter
                init()

        return vm
    ]
}

angular.module('IBR').directive 'uselect', () ->
    {
        restrict: 'E'
        transclude: true
        replace: true
        template: '<div ng-transclude></div>'
        scope: {
            choices: '='
            collection: '<'
            prop: '@'
        }
        controller: [ '$scope', ($scope) ->
            if $scope.collection && $scope.prop
                props = $scope.prop.split("||")
                tmp = []
                keys = []
                for item in $scope.collection
                    key = props.map (prop) ->
                        return item[prop]
                    .join('_')
                    if keys.indexOf(key) is -1
                        keys.push key
                        tmp.push(item[props[0]] || item[props[1]])

                $scope.choices = tmp
        ]
    }
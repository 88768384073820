'use strict'

angular.module('IBR')
.controller 'DepositsCtrl', [ '$rootScope', '$scope', '$stateParams', '$filter', 'LxNotificationService', 'depositsFactory', 'authoritiesFactory', 'alertModal', ($rootScope, $scope, $stateParams, $filter, LxNotificationService, depositsFactory, authoritiesFactory, alertModal) ->

    vm = this

    DEFAULT_CURRENCIES_TYPE_ALL = {
        value: "",
        title: "ALL"
    }

    CURRENCIES = [
        DEFAULT_CURRENCIES_TYPE_ALL,
        {value: 'AZN', title: 'AZN'},
        {value: 'USD', title: 'USD'},
        {value: 'RUB', title: 'RUB'},
        {value: 'EUR', title: 'EUR'},
        {value: 'GBP', title: 'GBP'},
    ]

    vm.deposits = {}
    vm.loaded = false
    hideStatuses = ['CLOSED']
    vm.currentDeposits = {}
    vm.depositFilter = {
        currency: DEFAULT_CURRENCIES_TYPE_ALL
    }

    vm.currencies = CURRENCIES

    vm.customerCaption = true
    vm.headerClass = 'relative'

    setPassedDeposit = () ->
        iban = $stateParams.iban
        if iban
            if !vm.currentDeposits[iban]
                vm.currentDeposits[iban] = true
            vm.depositFilter.iban = iban
        vm.loaded = true
        return

    processDeposits = (deposits) ->
        customerDeposits = {}
        deposits = $filter('orderBy')(deposits, 'corporateCustomer.name')
        angular.forEach deposits, (deposit) ->
            deposit.isInput = false
            cid = deposit.corporateCustomer.cid
            if !customerDeposits[cid]
                customerDeposits[cid] = {}
                customerDeposits[cid].deposits = []
                customerDeposits[cid].blockedDeposits = []
                customerDeposits[cid].corporateCustomer = deposit.corporateCustomer
                delete deposit.corporateCustomer
            if !(deposit.status in hideStatuses)
                customerDeposits[cid].deposits.push deposit
            else
                customerDeposits[cid].blockedDeposits.push deposit
            return
        customerDeposits

    initDeposits = () ->

        if $rootScope.currentUser
            if $rootScope.currentUser.corporateCustomers
                if $rootScope.currentUser.corporateCustomers.length <= 1
                    vm.customerCaption = false

        depositsFactory.getDeposits().then (response) ->
            vm.deposits = processDeposits response.data
            setPassedDeposit()
            return
        ,(error) ->
            vm.loaded = true
            return
        return

    initDeposits()

    vm.setCurrentDeposit = (iban) ->
        $rootScope.$broadcast 'TOGGLERS_UPDATED'
        if vm.currentDeposits[iban]
            delete vm.currentDeposits[iban]
        else
            vm.currentDeposits[iban] = true
        return

    vm.updateDepositName = (deposit, name, iban) ->
        if (name != undefined) && (name || deposit.nickname) && (name != deposit.nickname)
            depositsFactory.setNickname(iban, name).then (response) ->
                deposit.nickname = name
                alertModal.showMessage $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('MESSAGES.CHANGE_ACCEPTED'), $filter('translate')('MESSAGES.OK_INFO'), (answer) ->
                    return
                return
            ,(error) ->
                return
        return

    vm.updateDepositNameEnter = (deposit, keyEvent, name, iban) ->
        if keyEvent.which == 13
            vm.updateDepositName(deposit, name, iban)
        return

    vm.isInput = (deposit) ->
        deposit.isInput = !deposit.isInput
        return

    vm.reload = () ->
        if !vm.depositFilter.name.length then delete vm.depositFilter.name
        return

    return
]

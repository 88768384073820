'use strict'

angular.module('IBR')
.controller 'DashboardCtrl', [ '$scope', '$state', '$rootScope', '$translate', 'apiUser', 'alertModal', ($scope, $state, $rootScope, $translate, apiUser, alertModal) ->

    vm = this

    vm.user = false

    if localStorage.getItem('isPwdChanged')
        alertModal.showMessage $translate.instant('PROFILE.DIALOG_TITLE'), $translate.instant('PROFILE.DIALOG_SUCCES'), 'Ok',(answer)->
            localStorage.setItem('isPwdChanged', false)
            return
        return

    vm.updateAppUser = () ->
        apiUser.getUser().then (response) ->
            vm.user = response.data
            user = JSON.stringify(response.data)
            localStorage.setItem('user',user)
            $rootScope.currentUser = response.data
            return

    return
]

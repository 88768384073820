'use strict'

# sticky table head
angular.module('IBR')
.directive 'stickyHead', [ '$timeout', '$rootScope', ($timeout, $rootScope) ->
  {
    restrict:'A'
    scope: {
      reinit: '=?'
      local: '=?'
    }

    link: (scope, element, attributes)->
      #variables
      resizeTimeout = null
      timeOut = null
      headerFixed = false
      timeOffset = 500

      #elements
      tHead = element[0]
      table = tHead.parentNode

      tFoot = document.createElement('TFOOT')
      tFoot.className = "tfoot-spacer"
      footer = document.createElement('TR') #work with it
      tFoot.appendChild(footer)
      table.appendChild(tFoot)

      footer = table.querySelector('tfoot tr')


      resize = (stick) ->
        clearTimeout(resizeTimeout)
        footer.innerHTML = ''
        table.style['margin-top'] = ''
        tHead.className = ''
        tHead.style.width = ''
        for th in tHead.getElementsByTagName("TH")
          th.style.width = ''

        headerIsFixed = false

        #rebuild after timeout
        if stick
          resizeTimeout = setTimeout(() ->
            tHead.style.width = table.offsetWidth + 'px'

            for th in tHead.getElementsByTagName("TH")
              fth = document.createElement('TH')
              th.style.width = fth.style.width = th.offsetWidth + 'px'
              footer.appendChild(fth)


            table.style['margin-top'] = tHead.offsetHeight + 'px'
            tHead.className = 'head-fixed'

            headerIsFixed = true

          , 300 )

      resize(false)


      onScroll = () ->
        if  window.innerWidth < 1140
          return

        tableRect = table.getBoundingClientRect()
        headOffset = table.getBoundingClientRect().top + document.documentElement.scrollTop
        if tableRect.top < 0 && tableRect.bottom > 0 && tableRect.height > window.innerHeight
          fixed = true
        else
          fixed = false

        # only if flag changed
        if headerFixed != fixed
          headerFixed = fixed
          clearTimeout(timeOut) #reset timer after every change
          if headerFixed
            timeOut = setTimeout(() ->
              resize(true)
            , timeOffset)
          else
            clearTimeout(timeOut) #fix
            resize(false)


        return

      $(window).on 'scroll', () ->
        if !scope.local
          onScroll() #on scroll

      $rootScope.$on 'TOGGLERS_UPDATED', () -> #on togglers
        onScroll()

      scope.$watch 'reinit', (val) -> #on loaded
        if val
          onScroll()

      onScroll() #on init
  }
]
'use strict'

angular.module('IBR')
.factory 'apiBulkPayments', [ '$http', '$timeout', '$filter', 'transactionActions', ($http,$timeout,$filter,transactionActions) ->

    that = {}

    baseUrl = ( CONFIG.USE_MOCK || CONFIG.RESOURCE_SERVER ) + "/transfers/bulk"

    that.uploadBulk = (data) ->
        return $http.put baseUrl, data


    getTypesStr = (trs) ->
        sequence = ['IT', 'AZ', 'XO', 'XT', 'XV']
        types = {}
        for t in trs
            types[t.transferType] = if types[t.transferType] then types[t.transferType] + 1 else 1
        result = Object.keys(types).map (type) ->
            return { code: type, count: types[type] }
        result.sort (a, b) ->
            return sequence.indexOf(a.code) - sequence.indexOf(b.code)
        return result

    
    correctStatus = (status, cid) ->
        if status == 'CREATED'
            authScheme = transactionActions.getAuthScheme cid
            if authScheme == 'SING_APPROVE'
                return 'PENDING_FOR_SIGNATURE_AND_CONFIRMATION'
            else if authScheme == 'SIGN1_SIGN2'
                return 'PENDING_FOR_SIGNATURES_1_AND_2'
            else if authScheme == 'ONE_SIGN'
                return 'PENDING_FOR_SIGNATURE'
        return status

    summAmount = (trs) ->
        return trs.reduce (sum, curr) ->
            return sum + Number(curr.debitAmount)
        , 0

    summCommission = (trs) ->
        amount = trs.reduce (sum, curr) ->
            val = if curr.commission then Number curr.commission.amount else 0
            return sum + val
        , 0
        return { amount: amount, currency: 'AZN' }

    getFirstDate = (payments) ->
        dates = []
        for p in payments
            if p.createdInTransfersDate
                dates.push p.createdInTransfersDate
        dates.sort()
        return if dates.length then dates[0] else ''

    collectNames = (payments) ->
        names = []
        for p in payments
            if p.createdInTransfersBy && names.indexOf(p.createdInTransfersBy) == -1
                names.push p.createdInTransfersBy
        return names
        

    #createdInTransfersBy
    extendBulk = (bulk) ->
        try
            bulk.currency = "AZN"
            bulk.types = getTypesStr bulk.bulkTransferDTOList
            # bulk.summ = summAmount bulk.bulkTransferDTOList
            bulk.paymentsLength = bulk.bulkTransferDTOList.length
            bulk.commission = summCommission bulk.bulkTransferDTOList
            bulk.firstDate = getFirstDate bulk.bulkTransferDTOList
            bulk.names = collectNames bulk.bulkTransferDTOList
            cid = bulk.corporateCustomerInitiatorDTO && bulk.corporateCustomerInitiatorDTO.cid
            # bulk.viewStatus = correctStatus bulk.status, cid
            # for p in bulk.bulkTransferDTOList
            #     p.viewStatus = correctStatus p.status, cid
            return bulk
        catch e
            console.log e

    that.getList = (filter, cids) ->
        return $http.get baseUrl + '/all/'+filter+'?cids=' + cids.join(',')

    that.getBulks = (filter) ->
        return $http.post baseUrl + '/filter', filter
            .then (res) ->
                bulks = res.data.records.map extendBulk
                return { records: bulks, count: res.data.totalRecordsCount }

    that.getBulk = (bulkId) ->
        return $http.get baseUrl + '/' + bulkId
            .then (res) ->
                return extendBulk res.data

    that.deleteBulk = (bulkId) ->
        return $http.delete baseUrl + '/' + bulkId

    that.createBulk = (bulkId) ->
        return $http.post baseUrl + '/' + bulkId + '/create'

    that.deletePayments = (bulkId, uuids) ->
        return $http.post baseUrl + '/' + bulkId + '/delete' , { uuids: uuids}

    that.validateBulk = (bulkId) ->
        return $http.post baseUrl + '/' + bulkId


    that.isCreated = (status) ->
        return !['PENDING_FOR_VALIDATION','CREATION_PROCESS','VALIDATION_PROCESS','VALIDATION_ERROR','CREATION_ERROR','PENDING_FOR_CREATION'].includes status

    that.getHistory = (uuid) ->
        return $http.get baseUrl + '/history/' + uuid
            .then (res) ->
                history = []
                if res.data && res.data.historyDetails
                    for record in res.data.historyDetails
                        if !that.isCreated record.data.status
                            history.push record
                return { data: history }
                


    that.downloadFile = (data, fileType) ->
        $http.post CONFIG.RESOURCE_SERVER + '/file-contents/bulk-upload/statement/'+ fileType, data, { responseType: 'blob' }



    that
]

'use strict'

accNumbFormat = ->
  {
    require: 'ngModel'
    link: (scope, element, attr, ngModelCtrl) ->
      fromUser = (text) ->
        transformedInput = text.replace(/^[^A-Za-z0-9]/, '')
        if transformedInput != text
          ngModelCtrl.$setViewValue transformedInput
          ngModelCtrl.$render()
        transformedInput

      ngModelCtrl.$parsers.push fromUser
      return
  }

angular.module('IBR')
  .directive 'accNumbFormat', accNumbFormat
'use strict'

angular.module('IBR')
    .directive 'internalPaymentForm', [ '$rootScope', '$q', '$timeout', '$filter', 'apiPayment', 'apiAccounts', ($rootScope, $q, $timeout, $filter, apiPayment, apiAccounts ) ->
        {
            resctict: 'E'
            templateUrl: '/components/internal-payment-form.html'
            scope:
                cids: '=?'
                payment: '=?'
                loaded: '=?'
                isTemplate: '=?'
                isValid: '=?'
                isReady: '=?'
                isLocked: '=?'
            controller: ['$scope', 'authoritiesFactory', 'alertModal', ($scope, authoritiesFactory, alertModal) ->
                PAYMENT_TYPE = 'IT'

                $scope.isValid = if angular.isDefined($scope.isValid) then $scope.isValid else true
                $scope.isReady = if angular.isDefined($scope.isReady) then $scope.isReady else false
                $scope.isLocked = if angular.isDefined($scope.isLocked) then $scope.isLocked else false
                $scope.payment = if angular.isDefined($scope.payment) then $scope.payment else {}
                $scope.isTemplate = if angular.isDefined($scope.isTemplate) then $scope.isTemplate else false


                $scope.loaders = {}
                $scope.isEditing = false
                $scope.accounts = []
                $scope.customers = []
                $scope.validFields = {}
                $scope.credAccounts =[]

                $scope.selfAccount = false
                $scope.otherAccount = true
                $scope.otherAccountCurrency = ''

                $scope.currencyChecked = true
                $scope.accountChecked = true

                hideStatuses = ['CLOSED']
                clientRefLoadingTrigger = false


                getClientRef = ->
                    clientRefLoadingTrigger = true
                    apiPayment.getRef(PAYMENT_TYPE, $scope.payment.customer.cid).then (response) ->
                        if response.data.clientRef
                            $scope.payment.clientRef = response.data.clientRef
                        clientRefLoadingTrigger = false
                        return
                    , (error) ->
                        clientRefLoadingTrigger = false
                        return
                    return

                allCustomers = []
                allAccounts = []

                filterBySelected = () ->
                    $scope.customers = $filter('filterSelectedCustomers')(allCustomers)
                    $scope.cids = $scope.customers.map (customer) ->
                        return customer.cid
                    $scope.accounts = $filter('selectedCustomers')(allAccounts)
                    if $scope.customers.length == 1
                        $scope.payment.customer = $scope.customers[0]
                    $scope.isReady = $scope.customers.length

                $rootScope.$on 'SELECT_CUSTOMERS', () ->
                    filterBySelected()

                processAccounts = (accounts) ->
                    customerAccounts = {}
                    accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
                    for account in accounts
                        cid = account.corporateCustomer.cid
                        if !(account.status in hideStatuses) && account.iban
                            if !customerAccounts[cid]
                                allCustomers.push account.corporateCustomer
                                customerAccounts[cid] = {}
                                customerAccounts[cid].accounts = []
                                customerAccounts[cid].corporateCustomer = account.corporateCustomer
                            customerAccounts[cid].accounts.push account
                    filterBySelected()
                    $scope.isReady = $scope.customers.length && accounts && accounts.length
                    customerAccounts

                initPaymentForm = ->
                    console.log('initPaymentForm')
                    apiAccounts.getPaymentAccounts(PAYMENT_TYPE).then (response) ->
                        $scope.accounts = processAccounts response.data
                        allAccounts = angular.copy $scope.accounts, []
                        if $scope.customers.length == 1
                            $scope.payment.customer = $scope.customers[0]
                        apiPayment.getCreditAccounts().then (response)->
                            $scope.credAccounts = response.data
                            $scope.loaded = true
                            $scope.$emit 'FORM_READY'
                            return
                        .catch (error) ->
                            $scope.loaded = true
                            $scope.$emit 'FORM_READY'
                            return
                        return

                authoritiesFactory.checkAuthority('INTERNAL_ACCOUNT_TO_ACCOUNT', 'CREATE')
                    .then (res) ->
                        if res
                            initPaymentForm()
                        else
                            $scope.loaded = true
                            $scope.isLocked = true
                            alertModal.showError $filter('translate')('DIALOG.ATTENTION'), $filter('translate')('ERRORS.ACCESS_DENIED'), 'Ok'

                $scope.checkAccountCustomer = () ->
                    $scope.checkAccountTo()
                    $scope.validFields.thirdPerson = false
                    if $scope.selfAccount
                        return
                    if $scope.payment.customer && $scope.payment.customer.cid
                        for account in $scope.accounts[$scope.payment.customer.cid].accounts
                            if $scope.payment.credit == account.iban
                                $scope.validFields.thirdPerson = true
                                break

                $scope.checkAccountTo = (debitChanged) ->
                    if $scope.payment.debit && $scope.payment.debit.currency && $scope.payment.credit
                        if $scope.otherAccountCurrency
                            $scope.currencyChecked = $scope.otherAccountCurrency == $scope.payment.debit.currency
                        if $scope.payment.credit.currency
                            $scope.currencyChecked = $scope.payment.credit.currency == $scope.payment.debit.currency
                    if debitChanged && !$scope.currencyChecked
                        $scope.payment.credit = ''
                        $scope.currencyChecked = true


                $scope.checkAccount = () ->
                    deferred = $q.defer()
                    $scope.resetErrors()

                    if $scope.otherAccount && $scope.payment.credit && typeof($scope.payment.credit) == 'string' && $scope.payment.credit.length == 28
                        ibanQuery =
                            iban: $scope.payment.credit

                        apiPayment.checkAccount(ibanQuery).then (response) ->
                            if response.data && response.data.isIbanCorrect
                                $scope.accountChecked = true
                                $scope.payment.beneficiaryName = response.data.customerName
                                $scope.payment.beneficiaryTaxId = response.data.customerTaxId
                                if response.data.currency && $scope.payment.debit && $scope.payment.debit.currency && response.data.currency != $scope.payment.debit.currency
                                    $scope.otherAccountCurrency = response.data.currency
                                    $scope.currencyChecked = false
                            else
                                $scope.accountChecked = false
                            if response.data.currency != $scope.payment.debit.currency
                                $scope.currencyChecked = false
                            else
                                $scope.currencyChecked = true

                            deferred.resolve()
                        , (error) ->
                            $scope.accountChecked = true
                            deferred.resolve()
                    else
                        $scope.accountChecked = false
                        deferred.resolve()

                    return deferred.promise

                # select account from
                $scope.filterProcess = () ->
                    console.log("filterProcess")
                    if $scope.payment.customer && $scope.payment.debit
                        arr = $scope.credAccounts
                        $scope.accountsTo = []
                        for i in arr
                            if(i.iban != $scope.payment.debit.iban && i.currency is $scope.payment.debit.currency && $scope.payment.customer.id is i.customerId)
                                $scope.accountsTo.push(i)
                        return
                    return


                $scope.resetErrors = () ->
                    $scope.currencyChecked = true
                    if $scope.payment.debit && $scope.payment.debit.iban != $scope.payment.credit
                        $scope.validFields.debAsCred = false
                        $scope.validFields.credit = false
                    if parseFloat($scope.payment.amount) > 0 then $scope.validFields.amountNotNull = false
                    return

                $scope.switchAccount = (type) ->
                    if type == 'self'
                        $scope.otherAccount = !$scope.otherAccount
                        $scope.payment.beneficiaryName = ''
                        $scope.payment.beneficiaryTaxId = ''
                        $scope.loaders.validation = false
                    else
                        $scope.selfAccount = !$scope.selfAccount
                        delete $scope.payment.credit
                    return

                # $scope.$watch 'payment', ( ->
                #     if !(angular.equals($scope.validFields,{}))
                #             for own key of $scope.payment
                #                 if $scope.validFields[key] is true and $scope.payment[key]
                #                    $scope.validFields[key]= false
                # ),true

                resetFields = () ->
                    $scope.validFields = {}
                    for own key of $scope.payment
                        if (key != 'clientRef' && key != 'customer')
                            delete $scope.payment[key]
                        else if (key == 'debit')
                            $scope.payment[key] = {}
                    $scope.selfAccount = false
                    $scope.otherAccount = true
                    $scope.payment.isToOwn = false
                    return

                $scope.searchTemplate = ->
                    $scope.templateBtnLock = true
                    $scope.$emit 'TEMPLATE_SEARCH'

                $scope.listTemplate = ->
                    $scope.templateBtnLock = true
                    $scope.$emit 'TEMPLATE_LIST'

                $scope.$on 'TEMPLATES_FOUND', () ->
                    $scope.templateBtnLock = false

                $scope.$on 'TEMPLATES_ERR', () ->
                    $scope.templateBtnLock = false

                $scope.$on 'storageSet', (event, field, val) ->
                    $scope.payment[field] = val

                $scope.$on 'PAYMENT_RESET', (event, clearRef) ->
                    if $scope.isEditing
                        clearRef = true
                        $scope.isEditing = false
                    resetFields()
                    if clearRef
                        $scope.payment.clientRef = ''

                $scope.$on 'setPayment', (event, payment) ->
                    $scope.isEditing = true
                    if payment.isCopy
                        $scope.isEditing = false
                        delete payment.isCopy


                    if payment.bulkId
                        $scope.payment.bulkId = payment.bulkId
                    if payment.bulkRef
                        $scope.payment.bulkRef = payment.bulkRef
                    if payment.bulkName
                        $scope.payment.bulkName = payment.bulkName
                    if payment.source
                        $scope.payment.source = payment.source
                    if payment.uuid
                        $scope.payment.uuid = payment.uuid

                    $scope.txnType = payment.code || payment.typeCode
                    cid = payment.initiatorCid || payment.corporateCustomerInitiatorCid

                    $timeout( ->
                        $scope.payment.ref = payment.ref || ''
                        $scope.payment.typeCode = $scope.txnType
                        for customer in $scope.customers
                            if customer.cid == cid
                                $scope.payment.customer = customer
                    , 100 )

                    $timeout( ->

                        clientRefLoadingTrigger = true
                        for account in $scope.accounts[cid].accounts
                            if payment.debit == account.iban
                                $scope.payment.debit = account

                        if payment.clientRef
                            $scope.payment.clientRef = payment.clientRef

                        $scope.payment.isToOwn = payment.isToOwn || false
                        $scope.selfAccount = payment.isToOwn || false
                        $scope.otherAccount = !$scope.selfAccount
                        $scope.payment.amount = (payment.debitAmount || payment.amount) + ''
                        $scope.payment.narrative = payment.narrative || ''
                        $scope.payment.notes = payment.notes || ''
                        $scope.payment.beneficiaryNarrative = payment.beneficiaryNarrative || ''

                    , 200 )

                    $timeout( ->
                        if $scope.selfAccount
                            for account in $scope.credAccounts
                                if account.iban == payment.credit
                                    $scope.payment.credit = account
                        else
                            $scope.payment.credit = payment.credit
                            console.log 'checkAccount'
                            $scope.checkAccount()
                    , 300 )

                    return

                tmpDebit = {} # variable to cash debit account, were found
                $scope.$on 'TEMPLATE_SELECT', (event, template, isPayment) ->
                    $scope.accountChecked = true
                    if !isPayment then $scope.isEditing = true #If filling fields from template for payment, we don't block Customer Field

                    for customer in $scope.customers
                        if customer.cid == template.transfer.corporateCustomerInitiator.cid
                            $scope.payment.customer = customer
                    resetFields()

                    $scope.selfAccount = template.transfer.isToOwn
                    $scope.otherAccount = !$scope.selfAccount
                    $scope.payment.isToOwn = template.transfer.isToOwn
                    $scope.payment.amount = (template.transfer.amount || '') + ''
                    $scope.payment.narrative = template.transfer.narrative || ''
                    $scope.payment.notes = template.transfer.notes || ''

                    $timeout( ->
                        if template.transfer.debit && template.transfer.debit.iban
                            tmpDebit = $filter('filter')($scope.accounts[template.transfer.corporateCustomerInitiator.cid].accounts, {iban: template.transfer.debit.iban})[0]
                            $scope.payment.debit = angular.copy tmpDebit, {}
                        else
                            $scope.payment.debit = ''
                    )

                    $timeout( ->
                        if $scope.selfAccount
                            for account in $scope.credAccounts
                                if account.iban == template.transfer.credit.iban
                                    $scope.payment.credit = account
                        else
                            $scope.payment.credit = template.transfer.credit.iban
                    , 100)

                    $scope.payment.templateName = template.name
                    return

                $scope.$watch 'payment.customer', ( ->
                    $scope.payment.clientRef = ''
                    if $scope.payment.customer && $scope.payment.customer.cid
                        $scope.payment.debit = ''
                    if !$scope.otherAccount
                        $scope.payment.credit = ''
                    return
                ), true

                $scope.$watch 'payment.debit', ( ->
                    if $scope.payment.debit && $scope.payment.debit.iban && !$scope.payment.clientRef && !$scope.isEditing
                        getClientRef()
                    return
                ), true

                $scope.$watch 'selfAccount', (value) ->
                    if value
                        $scope.payment.isToOwn = true
                    else
                        $scope.payment.isToOwn = false

                $scope.$on 'PAYMENT_LOCAL_VALIDATE', (event, isTemplate) ->
                    # console.log 'validate'
                    $scope.checkAccount().then () ->
                        $scope.checkAccountCustomer()
                        $scope.validFields.account_iban_not_exist = false
                        $scope.validFields.templateName = false
                        $scope.validFields.customer = !$scope.payment.customer
                        $scope.validFields.debit = !$scope.payment.debit
                        $scope.validFields.credit = !$scope.payment.credit
                        $scope.validFields.amount = !$scope.payment.amount
                        $scope.validFields.narrative = !$scope.payment.narrative
                        $scope.validFields.clientRef = !$scope.payment.clientRef
                        $scope.validFields.debAsCred = $scope.payment.debit && ($scope.payment.debit.iban == $scope.payment.credit)
                        $scope.validFields.amountNotNull = parseFloat($scope.payment.amount) <= 0

                        validateForTemplate = ['customer', 'debit', 'credit', 'thirdPerson']

                        isValid = true
                        for key, value of $scope.validFields
                            if isTemplate && validateForTemplate.indexOf(key) == -1 #поля нет в массиве для валидации
                                $scope.validFields[key] = false
                                value = false #поля, которые не валидируем задаем валидными (true - невалидное)
                            isValid *= !value #собираем результат

                        $scope.isValid = isValid && !(!$scope.accountChecked && $scope.otherAccount)

                        $scope.$emit 'PAYMENT_LOCAL_VALIDATED', $scope.isValid

                        if $scope.templateEdit
                            $scope.validFields.templateName = !$scope.payment.templateName
                            $scope.isValid = $scope.isValid && !$scope.validFields.templateName

                        if !$scope.isValid
                            setTimeout( () ->
                                scrollElement = if $scope.isTemplate then document.querySelector('.dialog__scrollable') else document.documentElement
                                scrollToElement = scrollElement.querySelector('.text-field-error')
                                window.utils.scrollTo scrollElement, scrollToElement, 1000
                            , 500 )

                $scope.$on 'account_iban_not_exist', ()->
                    $scope.validFields.account_iban_not_exist = true
            ]
        }
]

'use strict'

angular.module('IBR')
.controller 'WidgetAccountCtrl',  ['$rootScope', '$scope', '$state', '$filter', '$translate', 'apiAccounts', 'authoritiesFactory', 'alertModal', 'LxDialogService', ($rootScope, $scope, $state, $filter, $translate, apiAccounts, authoritiesFactory, alertModal, LxDialogService) ->

    vm = this

    vm.accounts = {}
    vm.loaded = false
    hideStatuses = ['CLOSED']

    vm.customerCaption = true

    vm.headerClass = 'relative'

    vm.openAccount = (iban) ->
        $state.go('accounts', {iban: iban})
        return

    vm.showAccountStatement = (iban, customer) ->
        $state.go('statement', {iban: iban, customer: customer})
        return

    vm.showAccountArchive = (iban, customer) ->
        $state.go('archive', {iban: iban, customer: customer})
        return

    vm.updateAccountName = (account, name,iban) ->
        if (name != undefined) && (name || account.customName) && (name != account.customName)
            apiAccounts.updateAccountName(iban, name).then (response) ->
                account.customName = name
                alertModal.showMessage $translate.instant('DIALOG.ATTENTION'), $translate.instant('MESSAGES.CHANGE_ACCEPTED'), $translate.instant('MESSAGES.OK_INFO'), (answer) ->
                    return
                return
        return

    vm.updateAccountNameEnter = (account, keyEvent, name, iban) ->
        if keyEvent.which == 13
            vm.updateAccountName(account, name, iban)
        return

    processAccounts = (accounts) ->
        customerAccounts = {}
        accounts = $filter('orderBy')(accounts, 'corporateCustomer.name')
        angular.forEach accounts, (account) ->
            account.isInput = false
            if !(account.status in hideStatuses)
                cid = account.corporateCustomer.cid
                if !customerAccounts[cid]
                    customerAccounts[cid] = {}
                    customerAccounts[cid].accounts = []
                    customerAccounts[cid].corporateCustomer = account.corporateCustomer
                    delete account.corporateCustomer
                customerAccounts[cid].accounts.push account
            return
        customerAccounts

    initAccounts = () ->

        if $rootScope.currentUser.corporateCustomers.length <= 1
            vm.customerCaption = false

        apiAccounts.getAccounts().then (response) ->
            vm.accounts = processAccounts response.data
            vm.loaded = true
            return
        ,(error) ->
            vm.loaded = true
            vm.error = true
            return
        return

    authoritiesFactory.checkAuthority 'ACCOUNTS_WIDGET', 'READ'
        .then (res) ->
            if res then initAccounts()

    vm.isInput = (account) ->
        account.isInput = !account.isInput
        return

    # ACCOUNT DETAIL WIDGET
    vm.showDetails = (account, cid) ->
        $scope.$broadcast 'SHOW_ACCOUNT_DETAILS', account, cid

    return
]

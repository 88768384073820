'use strict'

angular.module('IBR')
.filter 'sortByChar', ->
    (input, prop) ->
        # return input
        sequence = 'AaBbCcÇçDdEeƏəFfGgĞğHhXxIiİi̇JjKkQqLlMmNnOoÖöPpRrSsŞşTtUuÜüVvXxYyZzWwАаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя0123456789'.split('')

        getWeight = (str) ->
            strArr = str.split ''
            strWeight = 0
            for char, i in strArr
                index = sequence.indexOf(char)
                weight = if index > -1 then sequence.length - index else 0
                strWeight += weight / ( ( i * sequence.length ) + 1)
            return strWeight


        if input && Array.isArray input
            input.sort (a, b) ->
                return getWeight(b[prop]) - getWeight(a[prop])

        return input
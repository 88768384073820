'use strict'

angular.module('IBR')
.controller 'LanguageCtrl', [ '$scope','$rootScope','$translate', ($scope,$rootScope,$translate) ->

    vm = this
    videoLnk = document.getElementById("video-link-client")
    bankLnk = document.getElementById("bank-link-client")

    setPage = (val) ->
        if bankLnk && videoLnk
            link = 'https://www.bankrespublika.az/content/'+ val+'/174'
            videoLnk.setAttribute('href', link)
            link = 'https://www.bankrespublika.az/lang/'+ val
            bankLnk.setAttribute('href', link)
        return

    vm.showDropdown = false
    vm.changeLanguage = (language) ->
        $translate.use language.key
        return

    vm.showLanguageDropdown = () ->
        vm.showDropdown = !vm.showDropdown
        return

    $rootScope.$on '$translateChangeSuccess', (event, data) ->
        language = data.language
        setPage(language)
        $rootScope.lang = language
        $rootScope.currentLang = $rootScope.selectLang { key: $rootScope.lang }
        localStorage.setItem 'language', language
        vm.showDropdown = false
        return

    return
]

'use strict'

initialState = if CONFIG.ENV == 'prod' then '_login' else 'login'
initialState = 'login'

angular.module('IBR', [
  'ui.router'
  'ui.router.state.events'
  'pascalprecht.translate'
  'lumx'
  'ui.mask'
  # 'ngSanitize'
]).constant()
.config [ '$stateProvider', '$urlRouterProvider', '$translateProvider', '$httpProvider', '$provide', 'uiMask.ConfigProvider', ($stateProvider, $urlRouterProvider, $translateProvider, $httpProvider, $provide, uiMaskConfigProvider) ->

  $httpProvider.interceptors.push('errorInterceptor')
  $httpProvider.interceptors.push('authInterceptor')

  $provide.value('$translateProvider', $translateProvider)

  $urlRouterProvider.otherwise('/' + initialState)


  uiMaskConfigProvider.maskDefinitions({
    'a': /[a-z]/
    'A': /[A-Z]/
    'e': /[a-zA-Z]/
    '9': /[0-9]/
    'w': /[a-z0-9]/
    'W': /[A-Z0-9]/
    '*': /[a-zA-Z0-9]/
    't': /-/
    'x': /[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}/
  })


  $stateProvider.state 'login',
    url: '/login'
    templateUrl: 'pages/login.html'
    controller: 'LoginCtrl as login'
    middleware: [ 'authMiddleware' ]

  $stateProvider.state '_login',
    url: '/_login'
    templateUrl: 'pages/_login.html'
    controller: '_LoginCtrl as _login'

  .state 'dashboard',
    url: '/dashboard'
    templateUrl: 'pages/dashboard.html'
    controller: 'DashboardCtrl as dashboard'
    middleware: [ 'authMiddleware' ]
    title: 'TITLES.DASHBOARD'

  .state 'transactions',
    url: '/transactions'
    templateUrl: 'pages/transactions/transactions.html'
    controller: 'TransactionsCtrl as transactions'
    middleware: [ 'authMiddleware' ]
    params: {
      status: ''
    }

  .state 'archive',
    url: '/archive'
    templateUrl: 'pages/archive/archive.html'
    controller: 'ArchiveCtrl as archive'
    middleware: [ 'authMiddleware' ]
    params: {
      type: ''
      iban: ''
      cid: ''
      status: ''
    }

  .state 'internal-payment',
    url: '/transactions/internal-payment'
    templateUrl: 'pages/internal-payment.html'
    controller: 'PaymentInternalCtrl as internalPayment'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null,
    }

  .state 'domestic-payment',
    url: '/transactions/domestic-payment'
    templateUrl: 'pages/domestic-payment.html'
    controller: 'PaymentDomesticCtrl as dPayment'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null
    }

  .state 'instant-payment',
    url: '/transactions/instant-payment'
    templateUrl: 'pages/instant-payment.html'
    controller: 'PaymentInstantCtrl as instantPayment'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null,
      template: null,
      archive: null
    }

  .state 'exchange-payment',
    url: '/transactions/exchange-payment'
    templateUrl: 'pages/exchange-payment.html'
    controller: 'PaymentExchangeCtrl as paymentExchange'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null
    }

  .state 'salary-payment',
    url: '/transactions/salary-payment'
    templateUrl: 'pages/salary-payment.html'
    controller: 'SalaryPaymentCtrl as slPayment'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null
    }

  .state 'bulk-payments',
    url: '/transactions/bulk-payments'
    templateUrl: 'pages/bulk-payments.html'
    controller: 'BulkPaymentsCtrl as bulkPayments'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null
    }

  .state 'swift-payment',
    url: '/transactions/swift-payment'
    templateUrl: 'pages/swift-payment.html'
    controller: 'SwiftPaymentCtrl as sPayment'
    middleware: [ 'authMiddleware' ]
    params: {
      payment: null
    }

  .state 'accounts',
    url: '/accounts'
    templateUrl: 'pages/accounts.html'
    controller: 'AccountsCtrl as accounts'
    middleware: [ 'authMiddleware' ]
    params: {
      iban: ''
    }

  .state 'statement',
    url: '/accounts/statement'
    templateUrl: 'pages/statement.html'
    controller: 'StatementCtrl as statement'
    middleware: [ 'authMiddleware' ]
    params: {
      iban: '',
      customer: '',
      isSalaryIban: false
    }

  .state 'credits',
    url: '/credits'
    templateUrl: 'pages/credits.html'
    controller: 'CreditsCtrl as credits'
    middleware: [ 'authMiddleware' ]
    params: {
      accountNo: ''
    }

  .state 'deposits',
    url: '/deposits'
    templateUrl: 'pages/deposits.html'
    controller: 'DepositsCtrl as deposits'
    middleware: [ 'authMiddleware' ]
    params: {
      iban: ''
    }

  .state 'cards',
    url: '/cards'
    templateUrl: 'pages/cards.html'
    controller: 'CardsCtrl as cards'
    middleware: [ 'authMiddleware' ]

  .state 'templates',
    url: '/templates'
    templateUrl: 'pages/templates.html'
    controller: 'TemplatesCtrl as templatectrl'
    middleware: [ 'authMiddleware' ]

  .state 'profile',
    url: '/profile'
    templateUrl: 'pages/profile.html'
    controller: 'ProfileCtrl as profile'
    middleware: [ 'authMiddleware' ]

  .state 'services',
    url: '/services'
    templateUrl: 'pages/services.html'
    controller: 'ServicesCtrl as services'
    middleware: [ 'authMiddleware' ]

  .state 'acquiring',
    url: '/acquiring'
    templateUrl: 'pages/acquiring.html'
    controller: 'AcquiringCtrl as acquiring'
    middleware: [ 'authMiddleware' ]

  translateUrl = CONFIG.TRANSLATION_SERVER + '/localizations'
  if CONFIG.LOCAL
    translateUrl = 'localizations'


  $translateProvider
    # .useUrlLoader(translateUrl)
    .preferredLanguage(CONFIG.DEFAULT_LANGUAGE)
    .useSanitizeValueStrategy()

  return
]
.run [ '$q', '$rootScope', '$state', '$transitions', '$injector', 'apiTranslation', 'alertModal', 'apiUser', 'AuthFactory', 'lumxValidationFactory', '$translate', '$timeout', 'LxDialogService', 'authoritiesFactory', ($q, $rootScope, $state, $transitions, $injector, apiTranslation, alertModal, apiUser, AuthFactory, lumxValidationFactory, $translate, $timeout, LxDialogService, authoritiesFactory ) ->

  #init globals
  $rootScope.initialState = initialState
  $rootScope.modals = {
    transaction: {
      loaders: {}
    }
    transactions: {
      loaders: {}
    }
    asan: {
      loaders: {}
    }
    result: {
      loaders: {}
    }
  }
  $rootScope.loaders = {}
  $rootScope.translationLoaded = false
  $rootScope.loaded = false
  $rootScope.ENV = CONFIG.ENV
  $rootScope.title = CONFIG.SITENAME
  $rootScope.validation = lumxValidationFactory

  #from localStorage
  # $rootScope.local = {
  #   autoLogout: Number(localStorage.getItem('autoLogout') || 0)
  # }
  # $rootScope.toggleAutoLogout = () ->
  #   localStorage.setItem('autoLogout', $rootScope.local.autoLogout)
  #   if $rootScope.local.autoLogout
  #     $rootScope.$broadcast 'AUTO_LOGOUT_ON'
  #   else
  #     $rootScope.$broadcast 'AUTO_LOGOUT_OFF'

  #init from localStorage language
  $rootScope.languages = [
    {
      "key": "az"
      "lng": "aze"
      "keyu": "AZ"
      "lngu": "AZE"
      "name": "Azerbaijani"
    }
    {
      "key": "en"
      "lng": "eng"
      "keyu": "EN"
      "lngu": "ENG"
      "name": "English"
    }
    {
      "key": "ru"
      "lng": "rus"
      "keyu": "RU"
      "lngu": "RUS"
      "name": "Russian"
    }
  ]


  $rootScope.links = {
    atms:
      az: "https://www.bankrespublika.az/content/az/14"
      ru: "https://www.bankrespublika.az/content/ru/14"
      en: "https://www.bankrespublika.az/content/en/14"

    branches:
      az: "https://www.bankrespublika.az/az/branches"
      ru: "https://www.bankrespublika.az/ru/branches"
      en: "https://www.bankrespublika.az/en/branches"

    tarifs:
      az: "https://www.bankrespublika.az/uploads/pdfs/Tarifler%20%28huquqi_sexsler%29_az.pdf"
      ru: "https://www.bankrespublika.az/uploads/pdfs/Tarifler%20%28huquqi_sexsler%29_ru.pdf"
      en: "https://www.bankrespublika.az/uploads/pdfs/Tarifler%20%28huquqi_sexsler%29_EN%20%281%29.pdf"

    rules:
      az: "https://www.bankrespublika.az/uploads/pdfs/istifade_qaydalari.pdf"
      ru: "https://www.bankrespublika.az/uploads/pdfs/istifade_qaydalariru.pdf"
      en: "https://www.bankrespublika.az/uploads/pdfs/istifade_qaydalarien.pdf"
  }

  $rootScope.STATUSES_COLOR_MAP = {
    ALL_ARCHIVE: 'color-all'
    ALL_ACTIVE: 'color-all'

    EXPIRED: 'color-expired'
    DELETED: 'color-deleted'
    ACCEPTED: 'color-accepted'

    PENDING_FOR_VALIDATION: 'color-sent'
    CREATION_PROCESS: 'process'
    VALIDATION_PROCESS: 'process'
    VALIDATION_ERROR: 'color-errors'
    CREATION_ERROR: 'color-errors'
    PENDING_FOR_CREATION: 'color-waiting'
    CREATED: 'color-checked'

    SENT_TO_CHANGE: 'color-change'
    PENDING_FOR_SIGNATURE: 'color-pending-sign'
    PENDING_FOR_SIGNATURE_1: 'color-pending-sign'
    PENDING_FOR_SIGNATURE_2: 'color-pending-sign'
    PENDING_FOR_SIGNATURES_1_AND_2: 'color-pending-sign'
    PENDING_FOR_RELEASE_TO_CBS: 'color-pending-send'
    SENT_TO_CBS: 'color-sent'
    SENT_TO_REVISION: 'color-change'
    REJECTED: 'color-rejected'
    PENDING_FOR_SIGNATURE_AND_CONFIRMATION: 'color-pending-confirm'
    PENDING_FOR_CONFIRMATION: 'color-pending-confirm'
    #additional
    REJECTED_BY_CBS: 'color-rejected'
    PENDING_FOR_SEND_TO_CBS: 'color-pending-send'
    PENDING_FOR_APPROVE: 'color-pending-approve'

    # PENDING_FOR_SIGNATURE_AND_CONFIRMATION: 'color-checked'
    # PENDING_FOR_SIGNATURES_1_AND_2: 'color-checked'
    # PENDING_FOR_SIGNATURE: 'color-checked'
  }

  $rootScope.selectLang = (obj) ->
    lang = $rootScope.languages[0]
    for l in $rootScope.languages
      if obj.key && l.key == obj.key
        return l
      if obj.lng && l.lng == obj.lng
        return l

    return lang

  $rootScope.lang = localStorage.getItem('language') || CONFIG.DEFAULT_LANGUAGE
  $rootScope.currentLang = $rootScope.selectLang { key: $rootScope.lang }


  
  apiTranslation.init($rootScope.lang).then () ->
    $translate.use $rootScope.lang
    $rootScope.translationLoaded = true
    $rootScope.$broadcast 'TRANSLATIONS_LOADED'

  # init data
  $rootScope.selectedCorporateCustomers = []  #for select
  $rootScope.customers = [] #avaliable customers: all || selected
  $rootScope.isBlockedSelect = false

  $rootScope.toglers = {}
  $rootScope.temporaryTogglers =
    domestic: 'AZ'
    swift: 'FP'
  # END init data

  # mobile navbar
  $rootScope.navbarIsOpen = false
  $rootScope.toggleNavbar = ->
    $rootScope.navbarIsOpen = !$rootScope.navbarIsOpen
  $rootScope.hideNavbar = ($event) ->
    if $event.target.tagName == 'BODY'
      $rootScope.navbarIsOpen = false
    return

  $(document).on 'click', 'header .navbar a', () ->
    $rootScope.navbarIsOpen = false
  # END mobile navbar

  $rootScope.stopPropagation = ($event) ->
    $event.stopPropagation()

  # http errors listener
  $rootScope.$on 'HTTP_ERROR', (event, res) ->
    if res
      if res.status == 401
        alertModal.showError($translate.instant('DIALOG.ATTENTION'), $translate.instant('ERRORS.ERROR_INVALID_TOKEN'), $translate.instant('DIALOG.OK'))
        console.log('http error logout')
        AuthFactory.logout()
      else
        $timeout(->
          alertModal.httpError(res)
        , 200 )
    return

  $rootScope.openChat = () ->
    lang = $rootScope.lang
    window.newWindow = window.open('https://www.bankrespublika.az/webim/client.php?locale='+lang+'&style=bnkres&frm=intbank&url='+escape(document.location.href)+'&referrer='+escape(document.referrer), 'webim', 'toolbar=0,scrollbars=0,location=0,status=1,menubar=0,width=640,height=640,resizable=1')
    window.newWindow.focus()
    window.newWindow.opener = window

  $rootScope.logout = ->
    console.log('$rootScope.logout')
    AuthFactory.logout()

  $rootScope.selectCustomers = (isInitial) ->
    selectedCustomers = $rootScope.selectedCorporateCustomers
    $rootScope.customers = angular.copy(if selectedCustomers.length then selectedCustomers else $rootScope.currentUser.corporateCustomers)
    cids = selectedCustomers.map (customer) ->
      return customer.cid
    localStorage.setItem 'selectedCids', JSON.stringify cids
    $timeout(->
      # console.log 'SELECT_CUSTOMERS'
      $rootScope.$broadcast 'SELECT_CUSTOMERS', isInitial
    )

  # CALL MIDDLEWARE

  $transitions.onBefore {}, (t) ->
    isLogin = AuthFactory.isLogin()
    # console.log('onBefore isLogin', isLogin)
  
    if isLogin
      if t.to().name == $rootScope.initialState
        console.log('transitions.onBefore logout')
        AuthFactory.logout()
        return true

      # return authoritiesFactory.checkAuthority $state.func, 'READ'
      #   .then (authorized) ->
      #     console.log('authorized', $state.func, authorized)
      #     if $state.func && !authorized
      #       return t.router.stateService.target $rootScope.initialState

    else if t.to().name != $rootScope.initialState
      return t.router.stateService.target $rootScope.initialState

    return true

  $transitions.onSuccess {}, (t) ->
    $rootScope.currentState = t.to()
    setTitle $rootScope.currentState.title

  # reset state and init
  profileLoaded = ->
    if $rootScope.currentUser && $rootScope.currentUser.corporateCustomers && $rootScope.currentUser.corporateCustomers.length

      if $rootScope.currentUser.corporateCustomers.length is 1
        $rootScope.isBlockedSelect = true

      selectedCustomers = []
      storedCidsJson = localStorage.getItem 'selectedCids'
      if storedCidsJson
        storedCids = JSON.parse storedCidsJson
        for customer in $rootScope.currentUser.corporateCustomers
          if storedCids.indexOf(customer.cid) > -1
            selectedCustomers.push customer

      $rootScope.customers = if selectedCustomers.length then selectedCustomers else angular.copy $rootScope.currentUser.corporateCustomers

      if $rootScope.customers.length is 1
        $rootScope.selectedCorporateCustomers = angular.copy $rootScope.customers
      else
        $rootScope.selectedCorporateCustomers = selectedCustomers

      if !$rootScope.selectedCorporateCustomers.length
        #because ng-change not fired for empty value in lumx
        $rootScope.selectCustomers(true)
      
    $rootScope.loaded = true
    $timeout(->
      $rootScope.$broadcast('USER_LOADED')
    )

    return

  setTitle = (title) ->
    t = CONFIG.SITENAME
    if title
      t = CONFIG.SITENAME + ' | ' + $translate.instant(title)
    $rootScope.title = $rootScope.defaultTitle = t

  $rootScope.$watch 'temporaryTogglers', () ->
    $rootScope.$broadcast('TOGGLERS_UPDATED')
  , true


  onEnter = (isRefreshed) ->
    $rootScope.currentUser = {}
    if !isRefreshed
      window.utils.resetStorage(['GA_STEP_NAME', 'GA_STEP_EXP', 'GA_STEP_SECRET'])

    apiUser.getUser()
      .then (response) ->
        $rootScope.currentUser = response.data
        if !isRefreshed
          $state.go CONFIG.DEFAULT_STATE
        profileLoaded()

  $rootScope.$on 'LOGIN', (event, res) ->
    onEnter()

  # if page is refreshed
  if !(window.location.hash == '' || window.location.hash.indexOf('login') > -1) #if not login page
    if AuthFactory.isLogin()
      onEnter(true)
    else
      AuthFactory.reset()

  return
]

'use strict'

angular.module('IBR')
.factory 'fakePayment', [ '$http', '$timeout', '$q', ($http,$timeout,$q) ->

	urlActivePayments = (CONFIG.USE_MOCK || CONFIG.RESOURCE_SERVER) + "/transfers/view/active"
	urlArchivePayments = CONFIG.RESOURCE_SERVER + "/transfers/view/archive"

	PAYMENT_URL =
		IT: CONFIG.RESOURCE_SERVER + "/transfers/internal/account-to-account"
		FX: CONFIG.RESOURCE_SERVER + "/transfers/exchange"
		AZ: CONFIG.RESOURCE_SERVER + "/transfers/domestic/azips"
		XO: CONFIG.RESOURCE_SERVER + "/transfers/domestic/xohks"
		XT: CONFIG.RESOURCE_SERVER + "/transfers/domestic/budget"
		XV: CONFIG.RESOURCE_SERVER + "/transfers/domestic/budget_vat"
		FP: CONFIG.RESOURCE_SERVER + "/transfers/international"
		SWF: CONFIG.RESOURCE_SERVER + "/transfers/international/swift"
		SRT: CONFIG.RESOURCE_SERVER + "/transfers/international/english"
		ABA: CONFIG.RESOURCE_SERVER + "/transfers/international/american"
		OTH: CONFIG.RESOURCE_SERVER + "/transfers/international/other"
		BIC: ''
		RP: CONFIG.RESOURCE_SERVER + "/transfers/international-rub"
		SP: CONFIG.RESOURCE_SERVER + "/transfers/salary"

		PI: "/transfers/instant/pi"
		PT: "/transfers/instant/pt"
		PV: "/transfers/instant/pv"

	PAYMENT_VALIDATE_URL =
		IT: CONFIG.RESOURCE_SERVER + "/transfers/internal/account-to-account/validate"
		FX: CONFIG.RESOURCE_SERVER + "/transfers/exchange/validate"
		AZ: CONFIG.RESOURCE_SERVER + "/transfers/domestic/azips/validate"
		XO: CONFIG.RESOURCE_SERVER + "/transfers/domestic/xohks/validate"
		XT: CONFIG.RESOURCE_SERVER + "/transfers/domestic/budget/validate"
		XV: CONFIG.RESOURCE_SERVER + "/transfers/domestic/budget_vat/validate"
		SWF: CONFIG.RESOURCE_SERVER + "/transfers/international/validate/swift"
		SRT: CONFIG.RESOURCE_SERVER + "/transfers/international/validate/english"
		ABA: CONFIG.RESOURCE_SERVER + "/transfers/international/validate/american"
		OTH: CONFIG.RESOURCE_SERVER + "/transfers/international/validate/other"
		BIC: ''
		RP: CONFIG.RESOURCE_SERVER + "/transfers/international-rub/validate"
		SP: CONFIG.RESOURCE_SERVER + "/transfers/salary/validate"

		PI: "/transfers/instant/pi/validate"
		PT: "/transfers/instant/pt/validate"
		PV: "/transfers/instant/pv/validate"

	urlDomesticCodes = CONFIG.RESOURCE_SERVER + "/ib-references/v1/search/"

	PAYMENT_TYPES = {
		IT: 'INTERNAL_ACCOUNT_TO_ACCOUNT'
		FX: 'EXCHANGE'
		AZ: 'AZIPS'
		XO: 'XOHKS'
		XT: 'BUDGET'
		XV: 'BUDGET_VAT'
		FP: 'INTERNATIONAL'
		RP: 'INTERNATIONAL_RUB'
		SP: 'SALARY'
		PI: 'PI'
		PT: 'PT'
		PV: 'PV'
	}
	that = {}

	that.getPaymentType = (code) ->
		if code
			return PAYMENT_TYPES[code] || ''
		return ''


	that.getByAlias = (code, cid, aliasType, aliasValue) ->
		return $timeout(->
			return {
				data: {
					credit: "AZ27BRES00380394403206617302"
					beneficiaryName: "Ben Customer name"
					beneficiaryTaxId: "1234567890"
					bankInfo:
						code: "510448"
						correspondentAccountIban: "AZ51NABZ01350100000000012944"
						name: "YAPI KREDI BANK AZERB. NERIMANOV M "
						swiftCode: "KABAAZ22"
						taxId: "9900009021"
				}
			}
		, 1000 )

	#GET DOMESTIC CODES
	that.getDomesticCodes = (type, searchQuery) ->
		console.log(type, searchQuery)
		return $timeout(->
			return {
				data: [
					{
						code: 12345
						name: "code 1"
						swiftCode: '12345'
						taxId: 12345,
						beneficiaryBankCorrespondentAccountIban: 'AZ32BRES00380594400209841003'
					}
					{
						code: 12345
						name: "code 2"
						swiftCode: '12345'
						taxId: 12345,
						beneficiaryBankCorrespondentAccountIban: 'AZ32BRES00380594400209841003'
					}
				]
			}
		, 1000 )

	#GET REF
	that.getRef = (txnType, cid) -> 
		return $timeout(->
			return {
				data: {
					clientRef: '629245'+txnType+'0000036'
				}
			}
		, 1000 )

	#GET COMMISSION
	that.getCommis = (data) ->
		return $timeout(->
			return {
				data: {
					amount: 1
					currency: 'AZN'
				}
			}
		, 1000 )

	#STATEMENTS
	that.searchAccountStatements = (searchQuery) ->
		$http.get CONFIG.RESOURCE_SERVER + "/accounts"+'/'+searchQuery.iban+'/statement?from='+searchQuery.startDate+'&to='+searchQuery.stopDate

	#STATEMENT PRINT
	that.printStatement = (searchQuery) ->
		$http.get CONFIG.RESOURCE_SERVER + '/file-contents/'+searchQuery.iban+'/statement/'+searchQuery.fileType+'?from='+searchQuery.startDate+'&to='+searchQuery.stopDate+'&lang='+searchQuery.lang, {responseType : 'arraybuffer'}

	that.validate = () ->
		return $timeout(->
			return {
				data: {}
			}
		, 1000 )

	that.create = (code, action, data) ->

		res = {
			transactionDTO: data
			warnings: []
		} #response

		if data.ref
			console.log 'fake change', code, action, data
		else
			console.log 'fake create', code, action, data

		switch action
			when 'SIGN_1', 'SIGN_2'
				if data.requestType is 'SIGN'
					res = {
						responseType: "PENDING_FOR_VERIFICATION"
						transactionId: "140478689099357334"
						verificationCode: "5349"
					}
				else if data.requestType is 'START_POLLING'
					# req = requestType: "START_POLLING", transactionId: "140478689099357334"
					res = {
						refSet: ["629245IT0000036"]
						responseType: "COMPLETE"
						warnings: []
					}
			when 'APPROVE'
				res = {
					refSet: ["629245IT0000036"]
					responseType: "COMPLETE"
					warnings: []
				}

		return $timeout(->
			return {
				data: res
			}
		, 1000 )

	#CHECK CUT OFF
	that.checkCutOff = (code) ->
		# console.log type
		$http.get CONFIG.RESOURCE_SERVER + '/transfers/check/operation-day/' + code

	#GET BLACKOUT
	that.getBlackout = (code) ->
		$http.get CONFIG.RESOURCE_SERVER + "/transfers/check/blackout/" + code

	#CHECK INTERNAL IBAN
	that.checkAccount = (iban) ->
		$http.post CONFIG.RESOURCE_SERVER + "/accounts/credit/check", iban

	#CHECK DOMESTIC IBAN
	that.checkIban = (iban) ->
		return {
				data: {
					result: true
				}
			}

	#CHECK EMPLOYERS IBANS
	that.checkSalaryAccounts = (cid, debitIban, creditIbans) ->
		$http.post CONFIG.RESOURCE_SERVER + "/transfers/salary/employees/check", { cid: cid, debitAccount: debitIban, creditAccounts: creditIbans }

	#GET CREDIT ACCOUNTS
	that.getCreditAccounts = () ->
		$http.get CONFIG.RESOURCE_SERVER + "/accounts/info"

	#GET_ACTIONS
	that.getAction = (code, data) -> 
		$http.post PAYMENT_URL[code]+'/actions', data

	#APPROVE
	that.approve = (ref) ->
		console.log 'fake approve', ref
		code = ref.substring 6, 8
		console.log PAYMENT_URL[code]+'/approve/'+ref
		return $timeout(->
			return {
				data: {
					transactionDTO: {
						cutOff: true
					}
					warnings: []
				}
			}
		,1000)

	that.sign = (action, data) ->
		console.log 'fake sign', action, data
		timeout = 1000
		if data.requestType is 'SIGN'
			res = {
				responseType: "PENDING_FOR_VERIFICATION"
				transactionId: "140478689099357334"
				verificationCode: "5349"
			}
		else if data.requestType is 'START_POLLING'
			timeout = 5000
			res = {
				refSet: ["629245IT0000036"]
				responseType: "COMPLETE"
			}
		return $timeout(->
			return {
				data: res
			}
		, timeout )

	#SEND-TO-CBS
	that.sendToCbs = (ref) ->
		res = {
			transactionResults: [
				{
					resourceId: {
						ref: ref
					},
					resultType: 'string',
					success: false
					transactionStatus: 'INITIATED'
					errors: [
						{
							code: 'string'
							message: 'string'
							module: 'string'
						}
					],
					warnings: [
						{
							code: 'string'
							message: 'string'
						}
					]
				}
			]
		}
		return $timeout(->
			return {
				data: res
			}
		, 1000 )

	#SEND-FOR-CHANGE
	that.sendForChange = (data) ->
		console.log 'fake sendForChange', data
		return $timeout(->
			return {
				data: {
					transactionDTO: {
						cutOff: true
					}
					warnings: []
				}
			}
		,1000)

	#DELETE
	that.delete = (data) ->
		console.log 'fake delete', data
		return $timeout(->
			return {
				data: {
					transactionDTO: {
						cutOff: true
					}
					warnings: []
				}
			}
		,3000)

	#GET BATCH
	that.getBatch = (id) ->
		$http.get CONFIG.RESOURCE_SERVER + "/transfers/view/batch/" + id

	#REJECT
	that.rejectPayment = (data) ->
		console.log 'fake rejectPayment', data
		return $timeout(->
			return {
				data: {
					transactionDTO: {
						cutOff: true
					}
					warnings: []
				}
			}
		,1000)

	# SEND_GROUP
	that.tempTransferCursors = []
	that.sendGroupTransactions = (action, data) ->
		if data.transferCursors && data.transferCursors.length
			that.tempTransferCursors = angular.copy data.transferCursors
		console.log 'fake sendGroupTransactions request', action, data
		initial = {
			transactionDTO: {
				cutOff: true
			}
			warnings: []
		}
		sign_a = {
			responseType: "PENDING_FOR_VERIFICATION"
			transactionId: "140478689099357334"
			verificationCode: "5349"
			warnings: []
		}
		sign_b = {
			refSet: that.tempTransferCursors.map (t) ->
				return t.ref
			responseType: "COMPLETE"
		}
		transactionResults = [{ref: '629245IT0000191'}].map (t) ->
			return {
				resourceId: {
					ref: t.ref
				},
				resultType: 'string'
				success: false
				transactionStatus: 'INITIATED'
				errors: [
					{
						code: 'string'
						message: 'string'
						module: 'string'
					}
				],
				warnings: [
					{
						code: 'string'
						message: 'string'
					}
				]
			}
		switch action
			when "APPROVE"
				res = angular.copy sign_a
				res.refSet = sign_b.refSet
				prefix = 'approve'
			when "SIGN_1", "SIGN_2"
				if data.requestType is 'SIGN'
					res = sign_a
				else if data.requestType is 'START_POLLING'
					res = sign_b
			when "SEND_TO_CHANGE"
				res = initial
			when "REJECT"
				res = initial
			when "SEND_TO_CBS"
				console.log 'SEND_TO_CBS'
				res = {
					"transactionResults":[{"resourceId":{"ref":"629245IT0000179","code":"IT"},"errors":[],"resultType":"TRANSFER","transactionStatus":"SENT_TO_CBS","warnings":[],"success":true},{"resourceId":{"ref":"629245FP0000058","code":"IT"},"errors":[],"resultType":"TRANSFER","transactionStatus":"SENT_TO_CBS","warnings":[],"success":true}]
				}
				# res = {"errorCode":"WS-0002","target":"groupRequest","constraints":[{"paramName":"transferCursors","message":"transferCursors is empty"}]}
			when "DELETE"
				res = initial

		console.log 'fake sendGroupTransactions response', res
		return $timeout(->
			return {
				data: res
			}
		, 1000 )

	getPayments = (active, filter) ->
		url = if active then urlActivePayments else urlArchivePayments
		reqBody =
			pageParams:
				pageNumber: filter.page
				size: filter.onPage
			filterSpecification: that.proccessFilters filter
			sort:
				fieldName: filter.sort || 'lastModifiedDate'
				order : filter.sortDir || 'ASC'

		$http.post url + '/' + filter.status, reqBody

	that.getActivePayments = (filter) ->
		getPayments(true, filter)

	that.getArchivePayments = (filter) ->
		getPayments(false, filter)


	#GET DETAILS
	that.getPaymentDetail = (ref) ->
		code = ref.substring 6, 8
		$http.get PAYMENT_URL[code] + '/' + ref

	#GET HISTORY
	that.getPaymentHistory = (ref) ->
		# console.log type
		code = ref.substring 6, 8
		$http.get PAYMENT_URL[code] + '/history/'+ ref


	#GET FILE
	that.transactionFile = (ref, lang, fileType) ->
		code = ref.substring 6, 8
		if fileType is 'ADOC'
			$http.get CONFIG.RESOURCE_SERVER + "/file-contents/asan-documents/" + code + "/" + ref, { responseType: 'blob' }
		else
			$http.get CONFIG.RESOURCE_SERVER + "/file-contents/transfer-statement-documents/" + code + "/" + ref + "/" + lang + "/" + fileType, { responseType: 'blob' }
	
	that.transactionsFile = (refs, lang, fileType) ->
		data = {
			lang: lang,
			transfers: refs
		}
		$http.post CONFIG.RESOURCE_SERVER+ '/file-contents/transfer-statement-documents/'+ fileType, data, { responseType: 'blob' }

	#CALC RATE
	that.calcExchangeRate = (currFrom, currTo, cid) ->
		data =
			cid: cid
			fromCurrency: currFrom.currency
			toCurrency: currTo.currency

		if currFrom.amount
			data.fromAmount = currFrom.amount
		if currTo.amount
			data.toAmount = currTo.amount
            
		$http.post CONFIG.RESOURCE_SERVER + "/currency/conversion", data


	that.proccessFilters = (filter) ->
		filterSpecification = []
		if filter.account && filter.account.iban != 'ALL'
			filterSpecification.push {
				fieldName: "debit"
				values: [filter.account.iban]
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.type && filter.type != 'ALL'
			filterSpecification.push {
				fieldName: "type"
				values: [filter.type]
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.customer && filter.customer.cid
			filterSpecification.push {
				fieldName : "initiatorCid"
				values : [filter.customer.cid]
				operator : "EQUALS"
				inverted : "false"
			}
		else if filter.cids
			filterSpecification.push {
				fieldName : "initiatorCid"
				values : filter.cids
				operator : "IN"
				inverted : "false"
			}

		if filter.startDate
			sDate = new Date(filter.startDate.setHours(0,0,0,0))
			filterSpecification.push({
				fieldName: "lastModifiedDate"
				values: [sDate.toIsoString()]
				operator: "MORE_THAN"
				inverted: "false"
			})

		if filter.stopDate
			# fDate = new Date(vm.transactionFilter.stopDate)
			fDate = new Date(filter.stopDate.setHours(23,59,59,99))
			filterSpecification.push({
				fieldName: "lastModifiedDate"
				values: [fDate.toIsoString()]
				operator: "LESS_THAN"
				inverted: "false"
			})
		
		if filter.clientRef
			filterSpecification.push {
				fieldName: "clientRef"
				values: [filter.clientRef]
				operator: "LIKE"
				inverted: "false"
			}

		if filter.debit
			filterSpecification.push {
				fieldName: "debit"
				values: [filter.debit],
				operator: "LIKE"
				inverted: "false"
			}

		amountMin = Number filter.amountMin
		if filter.amountMin
			filterSpecification.push {
				fieldName: "debitAmount"
				values: [amountMin],
				operator: "MORE_THAN_OR_EQUALS"
				inverted: "false"
			}

		amountMax = Number filter.amountMax
		if filter.amountMax
			filterSpecification.push {
				fieldName: "debitAmount"
				values: [amountMax],
				operator: "LESS_THAN_OR_EQUALS"
				inverted: "false"
			}

		if filter.paymentDetails
			filterSpecification.push {
				fieldName: "narrative"
				values: [filter.paymentDetails],
				operator: "LIKE"
				inverted: "false"
			}

		# from table
		if filter.narrative
			filterSpecification.push {
				fieldName: "narrative"
				values: [filter.narrative],
				operator: "LIKE"
				inverted: "false"
			}


		if filter.currency && filter.currency != 'ALL'
			filterSpecification.push {
				fieldName: "debitCurrency"
				values: [filter.currency],
				operator: "EQUALS"
				inverted: "false"
			}

		if filter.beneficiaryName
			filterSpecification.push {
				fieldName: "beneficiaryName"
				values: [filter.beneficiaryName],
				operator: "LIKE"
				inverted: "false"
			}

		# from table
		if filter.transactionStatus != 'ALL'
			filterSpecification.push {
				fieldName: "status"
				values: [filter.transactionStatus],
				operator: "EQUALS"
				inverted: "false"
			}

		filterSpecification

	that
]
